import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import {getUser, getVouchers} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import {usePrefix} from "../../../services/Prefix";
import {useNavigate} from "react-router-dom";
import LoginUserModal from "../LoginUserModal";
import UserUtils from "../../../services/User/UserUtils";
import Money from "../../Common/Money";
import {ReactComponent as GiftSVG} from "../../../../images/icons/gift-solid.svg";
import {ReactComponent as PromotionSVG} from "../../../../images/icons/promotion.svg";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import {ReactComponent as VoucherSVG} from "../../../../images/icons/voucher.svg";
import {ReactComponent as CashbackSVG} from "../../../../images/icons/cashback.svg";
import {ReactComponent as ArrowRightSVG} from "../../../../images/icons/arrow-right.svg";
import {getStoreConfigLoyalty} from "../../../services/Store/reducer";
import LoyaltyPointsInfoModalComponent from "../components/LoyaltyPointsInfoModalComponent";
import LoyaltyCashbackInfoModalComponent from "../components/LoyaltyCashbackInfoModalComponent";
import AppConfig from "../../../services/AppConfig";
import {ReactComponent as BackgroundShapeSVG} from "../../../../images/backgrounds/background-shape.svg";

interface OwnProps {
    onSuccess: () => void,
    onHide: () => void,
    setPage: (page?: string) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function UserIndexComponent(props: Props) {
    const [qrcodeBase64, setQrcodeBase64] = useState<undefined | string>(undefined);
    const [loginModal, setLoginModal] = useState(false);
    const [loginQrcode, setLoadingQrcode] = useState(UserUtils.isLoyaltyQrcodeEnabled(props.config_loyalty));
    const [loyaltyPointsInfoModal, setLoyaltyPointsInfoModal] = useState(false);
    const [loyaltyCashbackInfoModal, setLoyaltyCashbackInfoModal] = useState(false);
    const prefix = usePrefix();
    const navigate = useNavigate();
    const auth = useAuth();
    const user = props.user;
    const {t} = useTranslation();

    useEffect(() => {
        if (!user) {
            return;
        }
        const fetchQrcode = async () => {
            if (!UserUtils.isLoyaltyQrcodeEnabled(props.config_loyalty)) return;
            if (user.qrcode) {
                setQrcodeBase64(user.qrcode);  // Set the base64 string
            } else {
                const response = await auth.qrcode();
                if (response) {
                    setQrcodeBase64(response);  // Set the base64 string
                }
            }
        };

        fetchQrcode();
    }, [user?.id]);

    const logout = async () => {
        try {
            await auth.logout();
            props.onHide();
            // navigate(AppConfig.getLinkWithPrefix("/", prefix));
        } catch (err) {
            console.log(err);
        }
    }
    const back = () => {
        props.onHide()
        // navigate(AppConfig.getLinkWithPrefix("/", prefix));
    }

    const test = (e: any) => {
        e.preventDefault();
        setLoyaltyPointsInfoModal(true);
        return;
    }
    return (
        <>
            <div className={"user"}>
                <div className={"user-info mb-5"}>
                    {!AppConfig.isDarkMode() && <BackgroundShapeSVG className="background-shape" />}
                    <div className={"user-info-header"}>
                        <button onClick={props.onHide} className="btn-close ms-auto me-2" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className={"user-info-body"}>

                        {loyaltyPointsInfoModal && (
                            <LoyaltyPointsInfoModalComponent config_loyalty={props.config_loyalty}
                                                             onHide={() => setLoyaltyPointsInfoModal(false)}
                                                             onClick={() => {
                                                                 setLoyaltyPointsInfoModal(false);
                                                                 props.setPage("vouchers");
                                                             }}/>
                        )}
                        {loyaltyCashbackInfoModal && (
                            <LoyaltyCashbackInfoModalComponent config_loyalty={props.config_loyalty}
                                                               onHide={() => setLoyaltyCashbackInfoModal(false)}/>
                        )}
                        {!props.user.verified && (
                            <>
                                <div className={"alert alert-warning"}>
                                    {t("constraints.internal.account_not_verified")}, <a href={"#"}
                                                                           onClick={() => setLoginModal(true)}>
                                    {t("modules.user.action.click_to_verify_account.title")}</a>
                                </div>
                                {loginModal && (
                                    <LoginUserModal onHide={() => setLoginModal(false)} show={true}/>
                                )}
                            </>
                        )}
                        <h5>
                            {`${t("modules.user.field.hello.title")}, ${props.user.name}!`}
                        </h5>
                        {UserUtils.isLoyaltyQrcodeEnabled(props.config_loyalty) && (
                            <>

                                <>
                                    {qrcodeBase64 && (
                                        <>
                                            <div className={"user-info-qrcode"}>
                                                <img src={`data:image/png;base64,${qrcodeBase64}`} alt="QR Code"/>
                                            </div>
                                            <p className={"user-info-qrcode-text"}>
                                                {t("modules.user.field.scan_code_at_restaurant.title")}

                                            </p>
                                        </>
                                    )}
                                </>
                                {/*{loginQrcode ? (*/}
                                {/*    <div className={"w-100"}>*/}
                                {/*        <LoadingContainer>Pobieranie qrcode ...</LoadingContainer>*/}
                                {/*    </div>*/}
                                {/*) : (*/}
                                {/*)}*/}
                            </>
                        )}
                        {(UserUtils.isAnyLoyaltyProgramEnabled(props.config_loyalty)) && (
                            <div className={"user-info-boxes nav nav-pills nav-fill flex-column w-100"}>

                                {UserUtils.isLoyaltyPointsEnabled(props.config_loyalty) && (
                                    <>
                                        <li className="nav-item">
                                            <button onClick={() => setLoyaltyPointsInfoModal(true)}
                                                    className="nav-link">
                                                <GiftSVG/>
                                                <div>
                                                    {t("modules.user.field.points.title")}
                                                    {user?.points  ? (
                                                        <strong>
                                                            {user?.points}
                                                        </strong>
                                                    ) : (
                                                        <small>{t("modules.user.field.lack.title")}</small>
                                                    )}
                                                </div>
                                                <span className={"btn btn-icon"}>
                                                    <InfoSVG/>
                                                </span>
                                            </button>
                                        </li>
                                    </>
                                )}
                                {UserUtils.isLoyaltyGroupsEnabled(props.config_loyalty) && (
                                    <>
                                        {props.user.group_name && (
                                            <li className="nav-item">
                                                <div
                                                    className="nav-link">
                                                    <PromotionSVG/>
                                                    <div>
                                                        {t("modules.user.field.your_group.title")}
                                                        <strong>{props.user.group_name}</strong>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </>
                                )}
                                {UserUtils.isLoyaltyCashbackEnabled(props.config_loyalty) && (
                                    <>
                                        <li className="nav-item">
                                            <button onClick={() => setLoyaltyCashbackInfoModal(true)}
                                                    className="nav-link">
                                                <CashbackSVG/>
                                                <div>
                                                    {t("modules.user.field.cashback.title")}
                                                    {props.user.promotional_credits ? (
                                                        <strong>
                                                            <Money
                                                                value={props.user.promotional_credits}/>
                                                        </strong>
                                                    ) : (
                                                        <small>{t("modules.user.field.lack.title")}</small>
                                                    )}
                                                </div>
                                                <span className={"btn btn-icon"}>
                                                    <InfoSVG/>
                                                </span>
                                            </button>
                                        </li>
                                    </>
                                )}
                                {UserUtils.isLoyaltyVoucherEnabled(props.config_loyalty) && (
                                    <li className="nav-item">
                                        <button onClick={() => props.setPage("vouchers")}
                                                className="nav-link ">
                                            <VoucherSVG/>
                                            <div>
                                                {t("modules.user.field.vouchers.title")}
                                                <strong>{props.vouchers ? props.vouchers.length : 0} {t("modules.user.field.available.title")}</strong>
                                            </div>
                                        </button>
                                    </li>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={"user-menu"}>
                    <h3>
                        {t("modules.user.field.account.title")}
                    </h3>
                    <div className={"nav nav-pills nav-fill flex-column"}>

                        <li className="nav-item">
                            <button onClick={() => props.setPage("edit")} className="nav-link"
                                    aria-current="page">
                                {t("common.word.your_data")}
                                <ArrowRightSVG/>
                            </button>
                        </li>
                        <li className="nav-item">
                            <button onClick={() => props.setPage("delete")} className="nav-link"
                                    aria-current="page">
                                {t("modules.user.action.remove_account.title")}
                                <ArrowRightSVG/>
                            </button>
                        </li>
                        <li className="nav-item">

                            <a href={"#"} onClick={(e) => {
                                e.preventDefault();
                                logout()
                            }} className="nav-link"
                               aria-current="page">
                                {t("modules.user.action.logout.title")}
                                <ArrowRightSVG/>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config_loyalty: getStoreConfigLoyalty(state.config),
    vouchers: getVouchers(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserIndexComponent));
