import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import RegisterUserComponent from "./components/RegisterUser";
import LostPasswordUserComponent from "./components/LostPasswordUserComponent";
import {RootState} from "../../../reducers";
import {getStoreConfig, getStoreConfigLoyalty, getStoreDefaultConfig} from "../../services/Store/reducer";
import {getUser} from "../../services/User/reducer";
import LoginUserComponent from "./components/LoginUserComponent";
import VerificationUserComponent from "./components/VerificationUserComponent";
import UserUtils from "../../services/User/UserUtils";
import IframeWidget from "../../utils/IframeWidget";

interface OwnProps {
    page?: string,
    onHide: () => void;
    onSuccess?: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function LoginUserModal(props: Props) {
    const [page, setPage] = useState(props.page ? props.page : "LOGIN");
    const {t} = useTranslation();

    const onShow = () => {
    }
    const onLogged = () => {
        if(props.onSuccess) {
            props.onSuccess();
        }
        checkLogin();
    }
    const checkLogin = () => {
        if (props.user && props.user.name) {
            if (props.user.verified) {
                props.onHide();
            } else {
                setPage("VERIFICATION");
            }
        }
    }
    const onRegistred = () => {
        if(props.onSuccess) props.onSuccess();
        checkLogin();
    }

    const handleOnSuccess = () => {
        if(props.onSuccess) props.onSuccess();
        props.onHide()
    }

    useEffect(() => {
        checkLogin();
    }, [props.user.verified, props.user.name])


    return (
        <>
            <Modal dialogClassName="modal-full modal-login" show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={IframeWidget.onShow}>
                <div className="">
                    <Modal.Header>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"login-box"}>
                        {props.default_config?.layout.image_link && (
                            <div className={"login-box-logo"}>
                                <img alt="Logo location" src={props.default_config?.layout.image_link.default}/>
                            </div>
                        )}
                        {page == "LOGIN" && (
                            <>
                                <LoginUserComponent setPage={setPage} onSuccess={onLogged}/>
                                {UserUtils.isLoyaltyRegistration(props.config_loyalty) && (
                                    <div className={"login-box-register-info"}>
                                        <hr/>
                                        <div className={"text-center"}>
                                            {t("modules.user.field.no_account.title")} <a href={"#"} onClick={(e) => {
                                            e.preventDefault();
                                            setPage("REGISTER")
                                        }}>
                                            {t("common.action.create_an_account")}
                                        </a>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        {page == "REGISTER" && (
                            <>
                                <RegisterUserComponent setPage={setPage} onSuccess={onRegistred}/>
                            </>
                        )}
                        {page == "VERIFICATION" && (
                            <>
                                <VerificationUserComponent setPage={setPage} onSuccess={handleOnSuccess}/>
                            </>
                        )}
                        {page == "LOST_PASSWORD" && (
                            <>
                                <LostPasswordUserComponent setPage={setPage} onSuccess={handleOnSuccess}/>
                            </>
                        )}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    default_config: getStoreDefaultConfig(state.config),
    config_loyalty: getStoreConfigLoyalty(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LoginUserModal));
