import React, {useEffect, useState} from 'react';
import LoadingContainer from "../../../../Common/LoadingContainer";
import {api} from "../../../../../services/Api/api";
import {useTranslation} from "react-i18next";


interface Props {
    paymentMethod: any,
    updatePaymentSettings: (settings: any) => void;
}

const TpayBankTransferPaymentMethodComponent = (props: Props) => {
    const [gateway, setGateway] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [gateways, setGateways] = useState<any[]>([]);
    let paymentMethod = props.paymentMethod;
    const {t} = useTranslation();

    const fetchGateways = async () => {
        try {
            const res = await api.getPaymentGateways(paymentMethod.id) as any;
            setGateways(res);
            setLoading(false);
        } catch (error) {
            setLoading(false)
            setGateways(
                [
                    {
                        "id": "175",
                        "name": "PragmaPay Płatności ratalne dla biznesu",
                        "icon_link": "https://secure.tpay.com/_/g/175.png"
                    },
                    {
                        "id": "103",
                        "name": "Karta płatnicza",
                        "icon_link": "https://secure.tpay.com/_/g/103.png"
                    },
                    {
                        "id": "172",
                        "name": "PayPo",
                        "icon_link": "https://secure.tpay.com/_/g/172.png"
                    },
                    {
                        "id": "173",
                        "name": "BLIK Płacę Później",
                        "icon_link": "https://secure.tpay.com/_/g/173.png"
                    },
                    {
                        "id": "174",
                        "name": "PragmaPay Płatności odroczone dla biznesu",
                        "icon_link": "https://secure.tpay.com/_/g/174.png"
                    },
                    {
                        "id": "113",
                        "name": "Alior Bank SA",
                        "icon_link": "https://secure.tpay.com/_/g/113.png"
                    },
                    {
                        "id": "102",
                        "name": "Bank Pekao SA",
                        "icon_link": "https://secure.tpay.com/_/g/102.png"
                    },
                    {
                        "id": "108",
                        "name": "PKO Bank Polski",
                        "icon_link": "https://secure.tpay.com/_/g/108.png"
                    },
                    {
                        "id": "110",
                        "name": "Inteligo",
                        "icon_link": "https://secure.tpay.com/_/g/110.png"
                    },
                    {
                        "id": "150",
                        "name": "BLIK",
                        "icon_link": "https://secure.tpay.com/_/g/150.png"
                    },
                    {
                        "id": "160",
                        "name": "mBank",
                        "icon_link": "https://secure.tpay.com/_/g/160.png"
                    },
                    {
                        "id": "111",
                        "name": "ING Bank Śląski SA",
                        "icon_link": "https://secure.tpay.com/_/g/111.png"
                    },
                    {
                        "id": "114",
                        "name": "Bank Millennium SA",
                        "icon_link": "https://secure.tpay.com/_/g/114.png"
                    },
                    {
                        "id": "115",
                        "name": "Santander Bank Polska SA",
                        "icon_link": "https://secure.tpay.com/_/g/115.png"
                    },
                    {
                        "id": "132",
                        "name": "Citibank Handlowy SA",
                        "icon_link": "https://secure.tpay.com/_/g/132.png"
                    },
                    {
                        "id": "116",
                        "name": "Credit Agricole Polska SA",
                        "icon_link": "https://secure.tpay.com/_/g/116.png"
                    },
                    {
                        "id": "119",
                        "name": "Velo Bank",
                        "icon_link": "https://secure.tpay.com/_/g/119.png"
                    },
                    {
                        "id": "124",
                        "name": "Bank Pocztowy SA",
                        "icon_link": "https://secure.tpay.com/_/g/124.png"
                    },
                    {
                        "id": "135",
                        "name": "Banki Spółdzielcze",
                        "icon_link": "https://secure.tpay.com/_/g/135.png"
                    },
                    {
                        "id": "133",
                        "name": "BNP Paribas Bank Polska SA",
                        "icon_link": "https://secure.tpay.com/_/g/133.png"
                    },
                    {
                        "id": "159",
                        "name": "Bank Nowy",
                        "icon_link": "https://secure.tpay.com/_/g/159.png"
                    },
                    {
                        "id": "130",
                        "name": "Nest Bank",
                        "icon_link": "https://secure.tpay.com/_/g/130.png"
                    },
                    {
                        "id": "145",
                        "name": "Plus Bank SA",
                        "icon_link": "https://secure.tpay.com/_/g/145.png"
                    },
                    {
                        "id": "166",
                        "name": "Google Pay",
                        "icon_link": "https://secure.tpay.com/_/g/166.png"
                    },
                    {
                        "id": "170",
                        "name": "Apple Pay",
                        "icon_link": "https://secure.tpay.com/_/g/170.png"
                    },
                    {
                        "id": "171",
                        "name": "Visa Mobile",
                        "icon_link": "https://secure.tpay.com/_/g/171.png"
                    },
                    {
                        "id": "109",
                        "name": "Alior Raty",
                        "icon_link": "https://secure.tpay.com/_/g/109.png"
                    },
                    {
                        "id": "148",
                        "name": "Euro Payment",
                        "icon_link": "https://secure.tpay.com/_/g/148.png"
                    },
                    {
                        "id": "157",
                        "name": "Druczek płatności / Przelew z innego banku",
                        "icon_link": "https://secure.tpay.com/_/g/157.png"
                    },
                    {
                        "id": "163",
                        "name": "Visa Checkout",
                        "icon_link": "https://secure.tpay.com/_/g/163.png"
                    }
                ])
        }
    }
    useEffect(() => {
        fetchGateways();
    }, []);


    const updateGateway = (gateway: any) => {
        setGateway(gateway);
        props.updatePaymentSettings({gateway_id: gateway.id});
    }


    if (loading) {
        return (
            <div>
                <LoadingContainer>{t('modules.payment_method.field.fetching_payment_methods.title')}</LoadingContainer>
            </div>
        )
    }
    return (
        <div className="banks row row-cols-3 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 gx-2">
            {
                gateways.map((g: any, i) =>
                    <div className="d-flex align-items-stretch" key={g.name}>
                        <div key={i} className={"bank-item" + (g === gateway ? ' active' : '')}
                             onClick={() => updateGateway(g)}><img src={g.icon_link}/></div>
                    </div>
                )
            }
        </div>
    )
}

export default TpayBankTransferPaymentMethodComponent;