import {VoucherApi} from "../Api/types";

export enum UserActionTypes {
	LOGIN = "@@user/LOGIN",
	UPDATE = "@@user/UPDATE",
	UPDATE_QRCODE = "@@user/UPDATE_QRCODE",
	LOGOUT = "@@user/LOGOUT",
	VOUCHERS = "@@user/VOUCHERS",
}

export type UserState = {
	id?: number | undefined;
	name?: string | undefined;
	email?: string;
	verified?: boolean;
	enabled?: boolean;
	points?: number;
	promotional_credits?: number;
	group_name?: string;
	phone_number?: string;
	token?: string;
	vouchers?: VoucherApi[],
	pending: boolean,
	fetch_status: "NEW" | "PENDING" | "SUCCESS" | "ERROR",
	qrcode?: string
};
