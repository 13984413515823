import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp, useDispatch} from "react-redux";
import {RootState} from "../../../reducers";
import {useLocationService} from "../../services/Location/locationService";
import {getStoreConfig} from "../../services/Store/reducer";
import {getLocation} from "../../services/Location/reducer";
import Languages from "../Common/Languages";
import ErrorFormView from "../Common/ErrorFormView";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import ErrorFormAlertView from "../Common/ErrorFormAlertView";
import LoadingContainer from "../Common/LoadingContainer";
import QRCodeScannerComponent from "../Common/QRCodeScannerComponent";
import {ApiError} from "../../services/Api/types";
import StoreUtils from "../../services/Store/StoreUtils";
import {SessionState} from "../../services/Location/types";
import IframeWidget from "../../utils/IframeWidget";

interface OwnProps {
    roomNumber: string,
    onSave: () => void,
    onHide: () => void,
    show: boolean,
    showLanguages?: boolean
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ChangeRoomServiceNumberModal(props: Props) {
    const [roomNumber, setRoomNumber] = useState(props.roomNumber ? props.roomNumber : "");
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const locationService = useLocationService();

    const onShow = () => {
        setRoomNumber(props.roomNumber ? props.roomNumber : "")
        setErrors([]);
    }
    let possibleEditNumber = props.config?.ordering.number_editable;

    const saveTableNumber = async (number: string) => {
        setLoading(true);
        let sessionData = {
            ...props.session_state,
            order_type: "ROOM_SERVICE",
            room_number: number
        } as SessionState
        try {
            await locationService.update(sessionData);
            props.onSave();
            setLoading(false);
        } catch (err: any) {
            setErrors(err.errors);
            setLoading(false);
        }
        // if (possibleEditNumber) {
        // } else {
        //     props.onSave();
        // }
    }
    const onScan = (data: string) => {
        console.log("SUCCESS SCAN", data)
        if (!data) {
            setErrors([{
                message: t("constraints.internal.wrong_qrcode_link")
            }])
            return;
        }
        if (data.startsWith("http://") || data.startsWith("https://")) {
            try {
                const url = new URL(data);
                const pathSegments = url.pathname.split('/').filter(Boolean);
                const orderType = pathSegments[pathSegments.length - 1];
                if (!StoreUtils.isSameDomain(data, props.config?.domain) || !orderType || orderType !== "ROOM_SERVICE") {
                    setErrors([{
                        message: t("constraints.internal.wrong_qrcode_link")
                    }])
                    return;
                }
                const numberParam = url.searchParams.get("number");
                if (numberParam !== null) {
                    saveTableNumber(numberParam);
                } else {
                    setErrors([{
                        message: t("constraints.internal.wrong_qrcode_link")
                    }])
                }
            } catch (error) {
                setErrors([{
                    message: t("constraints.internal.wrong_qrcode_link")
                }])
            }
        } else {
            setErrors([{
                message: t("constraints.internal.wrong_qrcode_link")
            }])
        }
    }
    return (
        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.show} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("common.action.choose_room")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        {props.showLanguages && (
                            <Languages size={"xl"}/>
                        )}
                        {!props.config?.ordering.scanner_disabled && (
                            <>
                                {errors.length > 0 ? (
                                    <>
                                        <ErrorFormAlertView errors={errors}/>
                                        <button className={"btn btn-default btn-lg w-100 mb-4"}
                                                onClick={() => setErrors([])}>
                                            {t("common.action.scan_again")}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {loading ? (
                                            <div>
                                                <LoadingContainer>
                                                    {t("common.word.processing_in_progress")}
                                                </LoadingContainer>
                                            </div>
                                        ) : (
                                            <>
                                                <p className={"text-center mb-1"}>
                                                    {t("modules.cart.field.scan_room_qr_code.title")}
                                                </p>
                                                <QRCodeScannerComponent onScan={onScan}/>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}


                        {(!possibleEditNumber && !roomNumber) ? (
                            <>
                                {props.config?.ordering.scanner_disabled && (
                                    <div className={"alert alert-danger"}>
                                        {t("constraints.internal.number_room_not_null")}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="form-group">
                                    <input disabled={!possibleEditNumber} type="text" value={roomNumber}
                                           onChange={e => setRoomNumber(e.target.value)}
                                           className={"form-control" + ErrorFormHelper(errors, "number")}
                                           id="room_number"
                                           placeholder={t("modules.room_number_modal.field.room_number.title") + "*"}/>
                                    <label
                                        htmlFor="street">{t("modules.room_number_modal.field.room_number.title")}</label>
                                    <ErrorFormView errors={errors} field="number"/>
                                </div>
                            </>
                        )}
                    </div>
                    {possibleEditNumber && (
                        <button
                            className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                            onClick={() => {
                                saveTableNumber(roomNumber)
                            }}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {t("common.action.save")}
                        </button>
                    )}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    session_state: getLocation(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ChangeRoomServiceNumberModal));