import React, {useEffect, useState} from 'react';
import LoadingContainer from "../../../../Common/LoadingContainer";
import {api} from "../../../../../services/Api/api";
import {useTranslation} from "react-i18next";


interface Props {
    paymentMethod: any,
    updatePaymentSettings: (settings: any) => void;
}

const BluemediaBankTransferPaymentMethodComponent = (props: Props) => {
    const [gateway, setGateway] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [gateways, setGateways] = useState([]);
    const paymentMethod = props.paymentMethod;
    const {t} = useTranslation();


    const fetchGateways = async () => {
        try {
            const res = await api.getPaymentGateways(paymentMethod.id) as any;
            setGateways(res);
            setLoading(false);
        } catch (error) {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchGateways();
    }, []);


    const updateGateway = (gateway: any) => {
        setGateway(gateway);
        props.updatePaymentSettings({gateway_id: gateway.id});
    }


    if (loading) {
        return (
            <div>
                <LoadingContainer>
                    {t('modules.payment_method.field.fetching_payment_methods.title')}
                </LoadingContainer>
            </div>
        )
    }
    return (
        <div className="banks row row-cols-3 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 gx-2">
            {
                gateways.map((g: any) =>
                    <div className="d-flex align-items-stretch">
                        <div className={"bank-item" + (g === gateway ? ' active' : '')}
                             onClick={() => updateGateway(g)}>
                            <img src={g.icon_link}/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default BluemediaBankTransferPaymentMethodComponent;