import React from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getItems, getModifierGroups, getPromotions} from "../../../../services/Menu/reducer";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import {RootState} from "../../../../../reducers";
import StateItemHelper from "../../../../services/Menu/services/State/StateItemHelper";
import MenuShowItemSingleComponent from "./MenuShowItemSingleComponent";
import MenuItemOptionBasicComponent from "./MenuItemOptionBasicComponent";
import MenuItemOptionWithImageComponent from "./MenuItemOptionWithImageComponent";
import {getCart} from "../../../../services/Cart/reducer";
import {getSession} from "../../../../services/Location/reducer";


interface OwnProps {
    stateItem: any,
    modifierGroup: any,
    onUpdate: (stateItem: any) => void,
    onChangePage: (name: any, data?: any, render?: any) => void,
    errors: any,
    quantity: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuModifierGroupsComponent = (props: Props) => {
    const {stateItem, modifierGroup, items, modifier_groups} = props;
    const {t} = useTranslation();
    const cart = props.cart;


    const setQuantityItem = (modifierGroup: any, itemView: any, quantity: any) => {
        StateItemHelper.setQuantityItem(stateItem, modifierGroup, itemView, quantity, items, modifier_groups, props.session.order_type)
        updateState();
    }

    const updateState = () => {
        props.onUpdate(stateItem);
    }

    const editSubLevel = (optionStateItem: any) => {
        props.onChangePage("PICK_ONE", {
            stateItem: optionStateItem,
            render: () => {
                const updateSubItem = (newStateItem: any) => {
                    let copyItem = Object.assign({}, stateItem);
                    StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
                    props.onUpdate(copyItem);
                    props.onChangePage(undefined);
                }
                return (
                    <>
                        <MenuShowItemSingleComponent parent={stateItem} onHide={() => props.onChangePage(undefined)}
                                                     errors={props.errors} itemId={optionStateItem.item_id}
                                                     onUpdate={updateSubItem} stateItem={optionStateItem}/>
                    </>
                )
            }
        })
    }
    const renderInput = (modifierGroup: any, optionItemView: any) => {
        let optionStateItem = optionItemView.state;
        let min_permitted = modifierGroup.min_permitted === undefined ? 0 : modifierGroup.min_permitted;
        let max_permitted = modifierGroup.max_permitted === undefined ? undefined : modifierGroup.max_permitted;
        let disableAdd = false;
        if (modifierGroup.max_permitted !== undefined && modifierGroup.max_permitted <= modifierGroup.quantity) {
            disableAdd = true;
        } else {
            if (optionItemView.max_permitted && optionItemView.max_permitted <= optionItemView.quantity) {
                disableAdd = true;
            }
        }
        let errors = optionItemView.errors;
        if (min_permitted === 1 && max_permitted === 1) {
            let checked = optionItemView.quantity === 1;
            let id = `modifierGroupItemId-${modifierGroup.id}-${optionItemView.id}`;
            return (<MenuItemOptionBasicComponent errors={errors} disableAdd={disableAdd} stateItem={optionStateItem}
                                                  checked={checked} modifierGroupId={modifierGroup.id}
                                                  optionItemView={optionItemView}
                                                  onUpdate={(modifierGroupId: any, optionItemView: any, quantity: any) => setQuantityItem(modifierGroup, optionItemView, quantity)}/>)
        }
        let quantityInfoItemMinPermitted = optionItemView.min_permitted === undefined ? 0 : optionItemView.min_permitted;
        let quantityInfoItemMaxPermitted = optionItemView.max_permitted === undefined ? undefined : optionItemView.max_permitted;
        let singlePick = quantityInfoItemMinPermitted === 1 && quantityInfoItemMaxPermitted === 1;

        return (
            <MenuItemOptionWithImageComponent errors={errors} disableAdd={disableAdd} singlePick={singlePick}
                                              stateItem={optionStateItem}
                                              modifierGroupId={modifierGroup.id} optionItemView={optionItemView}
                                              onUpdate={(modifierGroupId: any, optionItemView: any, quantity: any) => setQuantityItem(modifierGroup, optionItemView, quantity)}
                                              onEdit={editSubLevel}/>
        );
    }
    const getRenderHelpQuantityInfo = (modifierGroup: any) => {
        let additionalInfos = [];
        if (modifierGroup.min_permitted !== undefined && modifierGroup.min_permitted === 1 && modifierGroup.max_permitted !== undefined && modifierGroup.max_permitted === 1) {

        } else {
            if (modifierGroup.min_permitted !== undefined && modifierGroup.min_permitted > 0) {
                additionalInfos.push({title: t("modules.menu.field.min_permitted.title"), value: modifierGroup.min_permitted});
            }
            if (modifierGroup.max_permitted !== undefined) {
                additionalInfos.push({title: t("modules.menu.field.max_permitted.title"), value: modifierGroup.max_permitted});
            }
            if (modifierGroup.quantity !== undefined) {
                additionalInfos.push({title: t("modules.menu.field.default_quantity.title"), value: modifierGroup.quantity});
            }
            if (modifierGroup.charge_above !== undefined) {
                additionalInfos.push({title: t("modules.menu.field.charge_above.title"), value: modifierGroup.charge_above});
            }
        }
        let errorView = null;
        if (modifierGroup.errors !== undefined) {
            errorView = (
                <React.Fragment>
                    {
                        modifierGroup.errors.map((error: any) =>
                            <p className="text-danger error" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        let additionalInfoView = null;
        if (additionalInfos.length !== 0) {
            additionalInfoView = (
                <React.Fragment>
                    <ul className="">
                        {
                            additionalInfos.map((additionalInfo) =>
                                <li key={`${additionalInfo.title}_${additionalInfo.value}`}>{additionalInfo.title}: <strong>{additionalInfo.value}</strong>
                                </li>
                            )
                        }
                    </ul>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {additionalInfoView}
                {errorView}
            </React.Fragment>
        );
    }
    if (modifierGroup.max_permitted !== undefined && modifierGroup.max_permitted <= 0) {
        return null;
    }
    const isSinglePick = (modiferGroup: any) => {
        if (modifierGroup.min_permitted == 1 && modiferGroup.max_permitted == 1) return true;
        return false;
    }
    const singlePick = isSinglePick(modifierGroup);
    return (
        <fieldset className="form-group">

            <legend>
                <h5>
                    {modifierGroup.name} {props.quantity}
                </h5>
                {getRenderHelpQuantityInfo(modifierGroup)}
            </legend>
            <div className={`${singlePick ? "row row-cols-2 gx-2" : ""}`}>
                {
                    modifierGroup.options.sort((a: any, b: any) => a.position - b.position || a.name.localeCompare(b.name)).map((optionItem: any) => {
                        return (
                            <React.Fragment key={modifierGroup.id + "-" + optionItem.id}>
                                {renderInput(modifierGroup, optionItem)}
                            </React.Fragment>
                        )
                    })
                }
            </div>

        </fieldset>
    )
}

const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    modifier_groups: getModifierGroups(state.menu),
    promotions: getPromotions(state.menu),
    session: getSession(state.session),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuModifierGroupsComponent));