import {ConfigApi} from "../Api/types";
import AppConfig from "../AppConfig";
import Utils from "../../utils/Utils";

class StoreUtils {
    static isHomepageMenu(config?: ConfigApi) {
        return true;
    }

    static isMultistore(config?: ConfigApi) {
        return config?.type === "MULTISTORE";
    }

    static isOrderingPreview(config?: ConfigApi) {
        return !config?.ordering.enabled;
    }

    static mergeConfigs(defaultConfig?: ConfigApi, config?: ConfigApi) {
        if (!config) return defaultConfig;
        if (!defaultConfig) return config;
        let newConfig = JSON.parse(JSON.stringify(config)) as ConfigApi;
        if (!newConfig.layout.cover_link) {
            newConfig.layout.cover_link = defaultConfig.layout.cover_link;
        }
        if (!newConfig.layout.image_link) {
            newConfig.layout.image_link = defaultConfig.layout.image_link;
        }
        return newConfig;
    }

    static getOrderTypes(config: ConfigApi) {
        const orderTypes = [];
        if (config.ordering.takeaway) orderTypes.push("PICK_UP");
        if (config.ordering.delivery) orderTypes.push("DELIVERY");
        if (config.ordering.dine_in) orderTypes.push("DINE_IN");
        if (config.ordering.room_service) orderTypes.push("ROOM_SERVICE");
        return orderTypes;
    }

    static getDomainFromUrl(urlString: string) {
        const url = new URL(urlString);
        const segments = url.pathname.split('/').filter(Boolean);
        if (AppConfig.isMultistore()) {
            if (segments.length < 1) return undefined;
            if (segments.includes("inner")) {
                if (segments.length < 2) return undefined;
                return `${segments[1]}/${segments[2]}`
            }
            const subdomain = Utils.getSubdomainFromHostname(urlString);
            return `${subdomain}/${segments[0]}`
        }
        if (segments.includes("inner")) {
            if (segments.length < 1) return false;
            return `${segments[1]}`
        }
        const subdomain = Utils.getSubdomainFromHostname(urlString);
        return `${subdomain}`

    }

    static isSameDomain(urlString: string, storeDomain?: string) {
        if (!storeDomain) return false;
        const url = new URL(urlString);
        if (url.hostname !== window.location.hostname) return false;
        if (url.hostname === storeDomain) return true;
        const urlStoreDomain = this.getDomainFromUrl(urlString);
        if (!urlStoreDomain) return false;
        if (urlStoreDomain !== storeDomain) {
            if (urlStoreDomain.includes("/")) {
                if (storeDomain === urlStoreDomain.split("/")[1]) return true;
            }
            return false;
        }
        return true;
    }

    // static isCartSticky(config: ConfigApi, path: string) {
    //     if(this.isMultistore()){
    //         if(path.)
    //     }
    //     return config?.name === "Tpizza all" || config?.domain === "gk";
    // }
}

export default StoreUtils;
