import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {ReactComponent as FlagPL} from "../../../images/icons/languages/pl.svg";
import {ReactComponent as FlagEN} from "../../../images/icons/languages/en.svg";
import classNames from "classnames";
import {RootState} from "../../../reducers";
import {getStoreState} from "../../services/Store/reducer";
import {getSession, selectLocale} from "../../services/Location/reducer";
import {withTranslation} from "react-i18next";
import {getLangDisplayName} from "../../services/languageUtils";
import {useLanguageService} from "../../services/Language/languageService";

interface OwnProps {
    size: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function Languages(props: Props) {
    const languages = props.store_state.config?.languages;
    const languageService = useLanguageService();
    const sortedLanguages = languages ? [...languages].sort((a, b) => {
        const nameA = a.toLowerCase();
        const nameB = b.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }) : undefined;

    const getIconLang = function (locale: any) {
        switch (locale) {
            case "pl":
                return (<FlagPL className="icon"/>);
            case "en":
                return (<FlagEN className="icon"/>);
        }
        return locale;
    }

    const handleChangeLanguage = async (locale: string) => {
        if(locale !== props.locale) {
            await languageService.change(locale);
        }
    }

    const sizeClass = props.size !== undefined ? " languages-" + props.size : "";
    return (
        <>
            {sortedLanguages && sortedLanguages.length > 1 && (
                <div className={classNames(`languages${sizeClass}`)} title={getLangDisplayName(props.session.locale)}>
                    {sortedLanguages.map((language: any) => {
                        return (
                            <button className="btn" key={language}
                                    onClick={() => handleChangeLanguage(language)}
                                    title={getLangDisplayName(language)}>
                                {getIconLang(language)}
                            </button>
                        )
                    })}
                </div>
            )}
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
    session: getSession(state.session),
    locale: selectLocale(state.session),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(Languages));
