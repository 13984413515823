import {useDispatch} from "react-redux";
import {api} from "../Api/api";
import {fetchStoreError, fetchStorePending, fetchStoreSuccess, fetchStoreSuccessDefault} from "./actions";
import handleException from "../Api/handleException";
import DateService from "../Common/Date/DateService";
import AppConfig from "../AppConfig";
import {ConfigApi} from "../Api/types";
import {CanceledError} from "axios";
import StoreUtils from "./StoreUtils";

interface MenuService {
    init: (config?: ConfigApi, locale?: string, signal?: any) => Promise<ConfigApi>;
    refresh: (locale?: string, signal?: any) => Promise<ConfigApi>;
}

export const useStoreService = (): MenuService => {
    const dispatch = useDispatch();
    const refresh = async (locale?: string, signal?: any) => {
        dispatch(fetchStorePending());
        try {
            let responseConfig = await api.getConfig(locale, signal);
            if(StoreUtils.isMultistore(responseConfig)){
                dispatch(fetchStoreSuccessDefault(responseConfig));
                if(api.placeId){
                    let responseConfigPlace = await api.getConfigForPlace(api.placeId, locale, signal);
                    dispatch(fetchStoreSuccess(responseConfigPlace));
                }
            }else{
                dispatch(fetchStoreSuccessDefault(responseConfig));
                dispatch(fetchStoreSuccess(responseConfig));
            }
            return responseConfig;
        } catch (error) {
            if(error instanceof CanceledError){
                throw error;
            }
            console.log(error);
            dispatch(fetchStoreError(handleException(error)));
            throw error;
        }
    }

    const init = async (config?: ConfigApi, locale?: string, signal?: any) => {
        dispatch(fetchStorePending());
        if(config){
            await initConfig(config);
            return config;
        }
        try {
            let responseConfig = await api.getConfig(locale, signal);
            await initConfig(responseConfig);
            return responseConfig;
        } catch (error) {
            if(error instanceof CanceledError){
                throw error;
            }
            console.log(error);
            dispatch(fetchStoreError(handleException(error)));
            throw error;
        }
    }
    const initConfig = async (config: ConfigApi) => {
        DateService.init(config.date);
        // AppConfig.setTemplate(config.layout.template);
        dispatch(fetchStoreSuccess(config));
        return config;
    }
   
    return {
        init,
        refresh
    };
};
