import Cookies, {CookieGetOptions} from 'universal-cookie';
import {CookieSetOptions} from "universal-cookie/cjs/types";

class CookiesStore {

    static baseName = "/";
    static cookies = new Cookies();
    static initilized = false;

    static init(baseName:string){
        this.initilized = true;
        this.baseName = baseName.endsWith('/') ? baseName.slice(0, -1) : baseName;
        this.cookies = new Cookies(null);
    }
    static set(key:string ,value:any, config?:CookieSetOptions){
        const options = config ? {...config} : {} as CookieSetOptions
        options.path = this.baseName;
        this.cookies.set(key, value, options);
    }
    static get(key:string){
        return this.cookies.get(key);
    }
    static remove(key:string){
        const options = {} as CookieSetOptions
        options.path = this.baseName;
        this.cookies.remove(key, options);
    }
    static setGlobal(key:string ,value:any, config?:CookieSetOptions){
        const options = config ? {...config} : {} as CookieSetOptions
        options.path = "/";
        this.cookies.set(key, value, options);
    }

}
export default CookiesStore;