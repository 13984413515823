import React from 'react';
import {getCart, getOrderPending} from '../../services/Cart/reducer';
import {connect, DispatchProp, useSelector} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";


interface OwnProps {
    saveOrder: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartSubmitComponent = (props: Props) => {
    const {t} = useTranslation();
    const orderPending = props.cart_pending;

    let disabled = orderPending;
    let loading = orderPending;
    return (

        <button onClick={props.saveOrder}
                className={"w-100 btn btn-spinner btn-primary btn-submit" + (loading ? ' loading' : '')} type="button"
                disabled={disabled}>
            <span className="left spinner-border spinner-border-sm"></span>
            {t("common.action.make_order")}
        </button>
    )
}


const mapStateToProps = (state: RootState) => ({
    cart_pending: getOrderPending(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartSubmitComponent));
