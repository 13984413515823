import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {RootState} from "../../../../reducers";
import {getStoreConfigLoyalty, getStoreLayoutComponentsConfig} from "../../../services/Store/reducer";
import {getUser} from "../../../services/User/reducer";
import UserUtils from "../../../services/User/UserUtils";
import AdvertUserComponent from "../../../components/User/components/AdvertUser";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuAdvertComponent = (props: Props) => {
    const menuAdverts = props.storeLayoutComponentsConfig ? props.storeLayoutComponentsConfig.filter(x => x.placement == "menu_advert") : [];
    if (menuAdverts.length === 0) {
        if (UserUtils.isLoyaltyRegistration(props.config_loyalty)) {
            if (!props.user || !props.user.name) {
                return (
                    <div className={"menu-advert"}>
                        <AdvertUserComponent/>
                    </div>
                )
            }
        }
        return null;
    }
    return (
        <div className="container-fluid menu-advert">
            {menuAdverts.map(menuAdvert => {
                return (
                    <div key={menuAdvert.slug} dangerouslySetInnerHTML={{__html: menuAdvert.content}}/>
                )
            })}
        </div>
    )

}


const mapStateToProps = (state: RootState) => ({
    storeLayoutComponentsConfig: getStoreLayoutComponentsConfig(state.config),
    config_loyalty: getStoreConfigLoyalty(state.config),
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuAdvertComponent));