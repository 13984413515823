import React, {Suspense, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../reducers";
import {ReactComponent as MarkerIco} from "../../../../images/icons/marker.svg";
import {ReactComponent as ClockIco} from "../../../../images/icons/clock.svg";
import {ReactComponent as ShareIco} from "../../../../images/icons/share.svg";
import {ReactComponent as PhoneIco} from "../../../../images/icons/phone.svg";
import {ReactComponent as EmailIco} from "../../../../images/icons/email.svg";
import {ReactComponent as ArrowBottomIco} from "../../../../images/icons/arrow-bottom.svg";
import {ReactComponent as GoPOSLogoDark} from "../../../../images/logo/gopos_logo_dark.svg";
import {ReactComponent as GoPOSLogo} from "../../../../images/logo/gopos.svg";
import StringHelper from "../../../services/Common/StringHelper";
import HoursHelper from "../../../services/Menu/services/Availability/HoursHelper";
import DateService from "../../../services/Common/Date/DateService";
import {getDayOfWeek, isTimeAllDay} from "../../../services/Common/Date/DateHelper";
import {Collapse} from "react-bootstrap";
import {Link} from "react-router-dom";
import FooterSocialMedia from "./FooterSocialMedia";
import {selectLocale} from "../../../services/Location/reducer";
import {ConfigApi} from "../../../services/Api/types";
import AppConfig from "../../../services/AppConfig";
import FooterLanguages from "./FooterLanguages";
import {usePrefix} from "../../../services/Prefix";

const Map = React.lazy(() => import('../../Map/Map'));

interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const Footer = (props: Props) => {
    const {t} = useTranslation();
    const [collapseOpenHours, setCollapseOpenHours] = useState(false);
    const center: [number, number] | undefined = props.config?.coordinates ? [Number(props.config.coordinates.latitude), Number(props.config.coordinates.longitude)] : undefined; // Example: Kraków, Poland
    const prefix = usePrefix();
    const zoom = 15;

    if (!props.config) return null;

    const addressFullString = props.config ? StringHelper.getAddressName(props.config.address) : undefined;
    const openHours = props.config ? HoursHelper.getOpenHours(props.config.hours, DateService.now()) : undefined;
    const footerLinks = props.config.layout.links ? [...props.config.layout.links].filter((link: any) => link.type === "FOOTER").sort((a: any, b: any) => a.position - b.position || a.title.localeCompare(b.title)) : [];

    const renderOpenHoursItem = (openHour: any) => {
        let openDayString = getDayOfWeek(openHour.day_from, props.locale);

        if (openHour.day_from !== openHour.day_to) {
            openDayString += " - " + getDayOfWeek(openHour.day_to, props.locale);
        }
        return (
            <>
                <strong>{openDayString}: </strong>
                {isTimeAllDay(openHour.hour_from) && isTimeAllDay(openHour.hour_to) ? t("common.word.whole_day") : `${openHour.hour_from} - ${openHour.hour_to}`}
            </>
        )
    }

    const renderListItem = (icon: any, title: string, value: any, shareIcon: any, link?: any, action?: any) => {

        const handleAction = () => {
            if(action) {
                return action()
            }
        }

        return (
            <div className={`footer-list-item ${action ? 'footer-list-item-action' : ''}`} onClick={handleAction}>
                <div className={"footer-list-icon"}>
                    {icon}
                </div>
                <div className={"footer-list-content"}>
                    {value}
                </div>
                <div className={"footer-list-action"}>
                    {action ? (
                        <button className={"btn btn-sm btn-transparent btn-icon"}>
                            {shareIcon}
                        </button>
                    ) : (
                        <a className={"btn btn-sm btn-transparent btn-icon"} href={link} target={"_blank"}>
                            {shareIcon}
                        </a>
                    )}
                </div>
            </div>
        )
    }

    return (
        <footer className="footer">
            {(!AppConfig.isNativeMobile()) && (
                <>
                    <div className={"container-fluid"}>
                        <div className={"footer-store"}>
                            <div className={"row row-cols-1"}>
                                <div className={"col"}>
                                    <ul className={"footer-list"}>
                                        {addressFullString && (
                                            <li>
                                                <>
                                                    {renderListItem((<MarkerIco/>), t("common.word.address"),
                                                        addressFullString,
                                                        (<ShareIco/>),
                                                        `https://maps.google.com/?q=${addressFullString}`
                                                    )}
                                                </>
                                            </li>
                                        )}
                                        {(openHours && openHours.length > 0) && (
                                            <li>
                                                <div className={"footer-list-item"}
                                                     aria-expanded={collapseOpenHours}
                                                     aria-controls="collapse_footer_hours"
                                                     onClick={() => setCollapseOpenHours(!collapseOpenHours)}
                                                >
                                                    <div className={"footer-list-icon"}>
                                                        <ClockIco/>
                                                    </div>
                                                    <div className={"footer-list-content"}>
                                                        {t("modules.footer.field.working_hours.title")}
                                                        <br/>
                                                        {
                                                            openHours.map((openHour: any, index: any) =>
                                                                <span
                                                                    key={index + "-" + openHour.hour_from + "-" + openHour.hour_to}>{openHour.hour_from} - {openHour.hour_to}</span>
                                                            )
                                                        }
                                                    </div>
                                                    <div className={"footer-list-action"}>
                                                        <div className="btn btn-sm btn-transparent btn-icon"
                                                        >
                                                            <ArrowBottomIco/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Collapse in={collapseOpenHours}>
                                                    <div id="collapse_footer_hours" className={"footer-open-hours"}>
                                                        <ul className="list-unstyled">
                                                            {
                                                                HoursHelper.sortOpenHours(props.config.hours).map((openHour: any) => {

                                                                        return (
                                                                            <li key={"-" + openHour.day_from + "-" + openHour.day_to + "-" + openHour.hour_from + "-" + openHour.hour_to}>
                                                                                {renderOpenHoursItem(openHour)}
                                                                            </li>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </li>
                                        )}
                                        {props.config?.contact.phone && (
                                            <li>
                                                <>
                                                    {renderListItem((<PhoneIco/>), t("common.word.phone_number"),
                                                        props.config?.contact.phone,
                                                        (<ShareIco/>),
                                                        `tel:${props.config?.contact.phone}`
                                                    )}
                                                </>
                                            </li>
                                        )}
                                        {props.config?.contact.email && (
                                            <li>
                                                <>
                                                    {renderListItem((<EmailIco/>), t("common.word.email"),
                                                        props.config?.contact.email,
                                                        (<ShareIco/>),
                                                        `mailto:${props.config?.contact.email}`
                                                    )}
                                                </>
                                            </li>
                                        )}
                                        <FooterLanguages languages={props.config.languages} locale={props.locale} renderListItem={renderListItem}/>
                                    </ul>
                                    <FooterSocialMedia/>
                                </div>

                                {center && (
                                    <div className={"col"}>
                                        <div className={"footer-map"}>
                                            <Suspense fallback={<div>
                                                {t("modules.footer.field.map_loading.title")}
                                            </div>}>
                                                <Map center={center} zoom={zoom}/>
                                            </Suspense>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <FooterSocialMedia config={props.config}/>

                            {footerLinks.length > 0 && (
                                <>
                                    <div className={"footer-links"}>
                                        <ul className="list-inline">
                                            {footerLinks.map((footerLink: any) => {
                                                if (footerLink.link == 'rules') {
                                                    return (
                                                        <li key={footerLink.link}>
                                                            <Link to={AppConfig.getLinkWithPrefix(`/${footerLink.link}`, prefix)}>
                                                                {footerLink.title}
                                                            </Link>
                                                        </li>
                                                    )
                                                }
                                                return (
                                                    <li key={footerLink.link}>
                                                        <a href={footerLink.link}>
                                                            {footerLink.title}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
            <div className={"footer-bottom"}>
                <a className={"footer-powered-by"}
                   href="https://gopos.pl/goorder" target="_blank"
                   title={t("modules.footer.field.online_orders.title")}>
                    <span>
                        {t("modules.footer.field.use_ordering_system.title")}
                    </span>
                    {AppConfig.isDarkMode() ? <GoPOSLogo/> : <GoPOSLogoDark/>}
                </a>
            </div>
        </footer>
    )
}


const mapStateToProps = (state: RootState) => ({
    // config: getStoreConfig(state.config),
    locale: selectLocale(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(Footer));
