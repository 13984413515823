import React from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import {ReactComponent as TrophySolidIco} from "../../../../images/icons/trophy-star-solid.svg";
import {StoreConfigLoyaltyApi} from "../../../services/Api/types";
import RoundedIcon from "../../Common/RoundedIcon";
import IframeWidget from "../../../utils/IframeWidget";


interface OwnProps {
    config_loyalty: StoreConfigLoyaltyApi,
    onHide: () => void,
    onShow?: () => void,
    onClick: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function LoyaltyPointsInfoModalComponent(props: Props) {
    let {t} = useTranslation();

    return (
        <Modal dialogClassName="modal-full" className='loyalty-points-modal small-modal modal-new' show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(props.onShow)}>
            <div>
                <Modal.Header>
                    <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"/>
                </Modal.Header>
            </div>
            <Modal.Body>
                <div className='loyalty-points-modal-body d-flex flex-column align-items-center'>
                    <div className="w-100 d-flex align-items-center justify-content-center mb-3">
                        <RoundedIcon icon={<TrophySolidIco/>}/>
                    </div>
                    <h5 className="mb-2">
                        {t("modules.user.field.points_program.title")}
                    </h5>
                    <p className="text-center">
                        {t("modules.user.field.points_program.description")}
                    </p>
                    {props.config_loyalty.points?.info && (
                        <p className="text-center">
                            {props.config_loyalty.points?.info}
                        </p>
                    )}
                    <button className={"btn btn-primary w-100"} onClick={props.onClick}>
                        {t("modules.user.action.show_vouchers.title")}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LoyaltyPointsInfoModalComponent));
