import React, {useEffect, useState} from 'react';
import {Offcanvas} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as ArrowRightIco} from "../../../images/icons/arrow-right.svg";
import LoginUserModal from "../User/LoginUserModal";
import {api} from "../../services/Api/api";
import LoadingContainer from "../Common/LoadingContainer";
import VoucherProductComponent from "../User/components/Voucher/VoucherProductComponent";
import UserUtils from "../../services/User/UserUtils";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {getUser} from "../../services/User/reducer";

interface OwnProps {
    show: boolean;
    onHide: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const DrawerVouchers = ({show, onHide, user}: Props) => {
    const {t} = useTranslation();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    let [products, setProducts] = useState<any[] | undefined>();
    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const res = await api.getProducts();
            setProducts(res);
        } catch (error) {
            setProducts([]);
        }
    }

    const getFilteredProducts = (free?: boolean) => {
        if (!products) return [];

        if (free) {
            return products.filter((product) => !product.cost);
        }
        return products.filter((product) => product.cost);
    }

    return (
        <>
            {showLoginModal && (
                <LoginUserModal onHide={() => setShowLoginModal(false)} show onSuccess={onHide}/>
            )}
            {showRegistrationModal && (
                <LoginUserModal page="REGISTER" onHide={() => setShowRegistrationModal(false)} show onSuccess={onHide}/>
            )}
            <Offcanvas placement="end" show={show} onHide={onHide} className='drawer'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        {t("modules.navbar.field.vouchers.title")}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-column mb-2">
                        <h5>{t("modules.navbar.field.your_vouchers.title")}</h5>
                        <small>{t("modules.navbar.field.your_vouchers.description")}</small>
                    </div>
                    {
                        !UserUtils.isLogged(user) && (
                            <ul className="navbar-nav justify-content-end flex-grow-1 mt-3">
                                <li className={"nav-item"}>
                                    <button className="nav-link" onClick={() => setShowLoginModal(true)}>
                                        <span>{t("common.action.login")}</span>
                                        <ArrowRightIco/>
                                    </button>
                                </li>
                                <li className={"nav-item"}>
                                    <button className="nav-link register-btn"
                                            onClick={() => setShowRegistrationModal(true)}>
                                        <span>{t("common.action.create_an_account")}</span>
                                        <ArrowRightIco/>
                                    </button>
                                </li>
                            </ul>
                        )
                    }
                    {!products ? <LoadingContainer/> : (
                        <>
                            {products.length > 0 && (
                                <div
                                    className={`d-flex flex-column gap-5 ${UserUtils.isLogged(user) ? "mt-4" : "mt-0"}`}>
                                    {getFilteredProducts(true).length > 0 && (
                                        <div className="d-flex flex-column gap-3">
                                            <h5>{t("modules.navbar.field.free_vouchers.title")}</h5>
                                            {getFilteredProducts(true).map(product => (
                                                <VoucherProductComponent product={product} onSuccessUserModal={onHide}/>
                                            ))}
                                        </div>
                                    )}
                                    {getFilteredProducts().length > 0 && (
                                        <div className="d-flex flex-column gap-3">
                                            <h5>{t("modules.navbar.field.vouchers_to_buy.title")}</h5>
                                            {getFilteredProducts().map(product => (
                                                <VoucherProductComponent product={product} onSuccessUserModal={onHide}/>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(DrawerVouchers));