import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {useCart} from "../../../services/Cart/cartService";
import {RootState} from "../../../../reducers";
import {getOrderErrors, getOrderValid} from "../../../services/Cart/reducer";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import IframeWidget from "../../../utils/IframeWidget";

interface OwnProps {

}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ErrorOrderModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(true);
    const {t} = useTranslation();
    const cartService = useCart();
    const newOrder = () => {
        setLoading(true);
        cartService.clean();
        setLoading(false);
    }

    const fixOrderAction = async () => {
        setLoading(true);
        try {
            await cartService.fixOrder();
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <Modal dialogClassName="modal-full" show={true} onHide={() => IframeWidget.onHide()} onShow={() => IframeWidget.onShow()}>
            <div className="">
                <Modal.Header>
                    <Modal.Title>
                        {t("modules.home_page.field.problem_with_order.title")}
                    </Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                <p>
                    {t("modules.home_page.field.problem_with_order.description")}
                </p>
                <p>
                    <ErrorFormAlertView errors={props.orderErrors}/>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className={"btn-spinner btn-primary btn" + (loading ? ' loading' : '')}
                    disabled={loading} onClick={fixOrderAction}>
                    <span className="left spinner-border spinner-border-sm"></span>
                    {t("modules.home_page.action.fix_order.title")}
                </button>
                <button
                    className={"btn-spinner btn btn-danger" + (loading ? ' loading' : '')}
                    disabled={loading} onClick={newOrder}>
                    <span className="left spinner-border spinner-border-sm"></span>
                    {t("modules.home_page.action.create_new_order.title")}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    orderErrors: getOrderErrors(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ErrorOrderModal));
