import StorageStore from "../Common/Storage/storageStore";
import {Reducer} from "redux";
import {CartActionTypes, CartState} from "./types";
import StringHelper from "../Common/StringHelper";
import AppConfig from "../AppConfig";


let contact = {}
const initialState: CartState = {
    id: undefined,
    payment_method_id: undefined,
    payment_method_name: undefined,
    total_paid: undefined,
    contact: contact,
    tax_id_no: '',
    comment: undefined,
    custom_fields: {},
    number: '',
    items: [],
    promotions: [],
    payment_settings: {},
    estimated_preparation_at: '',
    pickup_at: '',
    type: undefined,
    delivery_fee: undefined,
    sub_total_delivery_fee: undefined,
    discount_delivery_fee: undefined,
    coupon: undefined,
    total: undefined,
    discount_money: 0,
    status: undefined,
    payment_status: undefined,
    sub_total: undefined,
    pending: true,
    errors: [],
    accepted_rules: [],
    isValid: true,
    initialized: false,
    fetch_status: "NEW",
    address: undefined
}

const cartReducer: Reducer<CartState> = (state = initialState, action: any) => {
    switch (action.type) {
        case CartActionTypes.ORDER_PENDING:
            if (action.version !== undefined && state.version !== undefined) {
                if (action.version !== state.version) {
                    return {...state}
                }
            }
            return {
                ...state,
                pending: true,
                fetch_status: "PENDING",
                version: AppConfig.version()
            }
        case CartActionTypes.ORDER_ERROR:
            if (action.version !== undefined && state.version !== undefined) {
                if (action.version !== state.version) {
                    return {...state}
                }
            }
            return {
                ...state,
                initialized: true,
                pending: false,
                // isValid: false,
                errors: action.errors,
                fetch_status: "ERROR",
                version: AppConfig.version()
            }
        case CartActionTypes.ORDER_INVALID:
            if (action.version !== undefined && state.version !== undefined) {
                if (action.version !== state.version) {
                    return {...state}
                }
            }
            return {
                ...state,
                pending: false,
                isValid: false,
                errors: action.errors,
                fetch_status: "ERROR",
                version: AppConfig.version()
            }
        case CartActionTypes.ACCEPT_RULES:
            return {
                ...state,
                accepted_rules: action.accepted_rules,
            }
        case CartActionTypes.CLEAR_BASKET:
            return {
                ...initialState,
                pending: false,
                initialized: true,
                fetch_status: "SUCCESS"
            }
        case CartActionTypes.REFRESH_BASKET:
            if (action.version !== undefined && state.version !== undefined) {
                if (action.version !== state.version) {
                    console.log("WRONG VERSION", action.version, state.version)
                    return {...state}
                }
            }
            let order = action.order as any;
            if (order === undefined || order.id === undefined) {
                let newState = {...initialState};
                if (order !== undefined) {
                    if (order.type) {
                        newState.type = order.type;
                    }
                    if (order.number) {
                        newState.number = order.number;
                    }
                    if (order.estimated_preparation_at) {
                        newState.estimated_preparation_at = order.estimated_preparation_at;
                    }
                    if (order.pickup_at) {
                        newState.pickup_at = order.pickup_at;
                    }

                    let newContact = order.contact;
                    if (newContact && newContact.email) {
                        let contact = {...state.contact};
                        contact.email = newContact.email;
                        newState.contact = contact;
                        const contactCopy = Object.assign({}, contact);
                        StorageStore.setItem('contact', JSON.stringify(contactCopy));
                    }
                }

                newState.pending = false;
                newState.initialized = true;
                newState.fetch_status = "SUCCESS";
                return {
                    ...state,
                    ...newState
                };
            }
            let newTotal = order.total_money;
            let items = order.items;
            let id = order.id;

            let newContact = order.contact;
            let contact = {...state.contact};
            if (newContact !== undefined) {
                contact.name = StringHelper.emptyOrValue(newContact.name);
                contact.email = StringHelper.emptyOrValue(newContact.email);
                contact.phone = StringHelper.emptyOrValue(newContact.phone);
                contact.provider = StringHelper.emptyOrValue(newContact.provider);
                contact.referenceId = StringHelper.emptyOrValue(newContact.referenceId);
            }
            let customFields = {...state.custom_fields};
            let newCustomFields = order.custom_fields;
            if (newCustomFields !== undefined) {

                for (var key in newCustomFields) {
                    var value = customFields[key];
                    if (value === undefined || !value) {
                        customFields[key] = newCustomFields[key];
                    }
                }
            }

            const contactCopy = Object.assign({}, contact);
            StorageStore.setItem('contact', JSON.stringify(contactCopy));
            let deliveryFee = 0;
            if (order.delivery_fee_money) {
                deliveryFee = order.delivery_fee_money;
            }
            let payment_method_id = order.payment_method_id ? order.payment_method_id : state.payment_method_id;
            let payment_method_name = order.payment_method_name ? order.payment_method_name : state.payment_method_name;
            let couponCode = undefined;
            if (order.coupon !== undefined) {
                couponCode = order.coupon.code;
            }
            let couponId = order.coupon ? order.coupon.id : undefined;


            const acceptedRules = [...state.accepted_rules];
            if (order.accept_marketing && !acceptedRules.includes("acceptMarketing")) {
                acceptedRules.push("acceptMarketing");
            }

            return {
                ...state,
                pending: false,
                initialized: true,
                fetch_status: "SUCCESS",
                isValid: true,
                version: AppConfig.version(),
                accepted_rules: acceptedRules,
                items: items,
                id: id,
                total: newTotal,
                comment: order.comment,
                tax_id_no: order.tax_id_no,
                status: order.status,
                number: order.number,
                estimated_preparation_at: order.estimated_preparation_at,
                pickup_at: order.pickup_at,
                payment_status: order.payment_status,
                contact: contact,
                custom_fields: customFields,
                promotions: order.promotions,
                type: order.type,
                coupon: couponCode,
                coupon_id: couponId,
                total_paid: order.total_paid_money,
                discount_money: order.discount_money,
                sub_total: order.sub_total_money,
                delivery_fee: deliveryFee,
                sub_total_delivery_fee: order.sub_total_delivery_fee_money,
                discount_delivery_fee: order.discount_delivery_fee_money,
                payment_method_id: payment_method_id,
                payment_method_name: payment_method_name
            }
        case CartActionTypes.CLEAN:
            return {
                ...initialState,
                version: action.version
            }
        default:
            return state;
    }
}

export default cartReducer;
export const getCartTotal = (state: CartState) => state.total;
export const getOrderPending = (state: CartState) => state.pending;
export const getCart = (state: CartState) => state;
export const getOrderValid = (state: CartState) => state.isValid;
export const getOrderErrors = (state: CartState) => state.errors;
export const getCartPromotions = (state: CartState) => state.promotions;