import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import CartHelper from "../../../services/Cart/Helper/CartHelper";
import {RootState} from "../../../../reducers";


interface OwnProps {
    orderItem: any;
    items: any[];
    modifierGroups: any[];
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const SummaryItem = (props: Props) => {
    const orderItem = props.orderItem;

    const renderMultilevel = (selectedItem: any) => {
        return (
            <div className={"cart-item-multilevel"}>
                <h5>
                    {CartHelper.getItemName(props.items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                </h5>
                <div>
                    {renderSubItemsString(selectedItem, selectedItem.modifier_groups, props.modifierGroups, props.items, 1)}
                </div>
            </div>
        )
    }
    const renderSubItemsString = (orderItem : any, itemModifierGroups: any, modifierGroups: any, items: any, lvl?: number) => {
        if (!itemModifierGroups || itemModifierGroups.length <= 0) return null;

        const level = lvl ?? 0
        const sortedItemModifierGroups =  [...itemModifierGroups]
        const item = props.items.filter((x:any) => x.id === orderItem.item_id)[0];

        if (item && item.modifier_groups){
            const positionMap = new Map(
                item.modifier_groups.map((itemModifierGroup:any) => [itemModifierGroup.modifier_group_id as number, itemModifierGroup.position as number])
            );
                 sortedItemModifierGroups.sort((a:any, b:any) => {
                    const posA = (positionMap.get(a.modifier_group_id) ?? Infinity)  as number;
                    const posB = (positionMap.get(b.modifier_group_id) ?? Infinity)  as number;
                    return posA - posB;
                });
        }
        else {
             sortedItemModifierGroups.sort((a: any, b: any) => a.name.localeCompare(b.name));
        }

        return (
                <>
                    {
                        sortedItemModifierGroups.map((x: any) => {
                            const itemsCount = x.selected_items.length;
                            let newLine = false;
                            let isAnyMultilevel = false;

                            const viewItems = [...x.selected_items].sort((a,b) => a.item_id - b.item_id).map((selectedItem: any, key: any) => {
                                let existModifiers = isModifiers(selectedItem);
                                if (existModifiers) {
                                    isAnyMultilevel = true;
                                    return (
                                        <React.Fragment key={`${x.id}-${selectedItem.id}`}>
                                            {renderMultilevel(selectedItem)}
                                        </React.Fragment>
                                    );
                                }
                                newLine = !existModifiers;
                                return (
                                    <React.Fragment
                                        key={`${x.id}-${selectedItem.id}`}>
                                        {existModifiers ? (
                                            <div style={{paddingLeft: 15 * level}}>
                                                <strong>
                                                    {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}: {` `}
                                                </strong>
                                                <div style={{paddingLeft: 5 * (level + 1)}}>
                                                    {renderSubItemsString(selectedItem, selectedItem.modifier_groups, modifierGroups, items, level + 1)}
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {x.selected_items.length <= 1 ? (
                                                    <>
                                                        {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount - 1 > key && (<>, </>)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount - 1 > key && (<>, </>)}
                                                    </>
                                                )}
                                                {renderSubItemsString(selectedItem, selectedItem.modifier_groups, modifierGroups, items, level + 1)}
                                            </>
                                        )}
                                    </React.Fragment>
                                )
                            });
                            return (
                                <React.Fragment key={x.id}>
                                    {isAnyMultilevel ? (
                                        <></>
                                    ) : (
                                        <>
                                            {x.selected_items.length > 1 ? (
                                                <strong>
                                                    {CartHelper.getItemName(props.modifierGroups, x.modifier_group_id, x.name)}:{` `}
                                                </strong>
                                            ) : (
                                                <strong>
                                                    {CartHelper.getItemName(props.modifierGroups, x.modifier_group_id, x.name)}:{` `}
                                                </strong>
                                            )}
                                        </>
                                    )}
                                    {viewItems}
                                    {newLine && <br/>}
                                </React.Fragment>
                            )
                        })
                    }
                </>
        )
    }

    const isModifiers = (item: any) => {
        return item.modifier_groups && item.modifier_groups.length > 0;
    }

    return (
        <>
            {(isModifiers(orderItem)) && (
              renderSubItemsString(orderItem, orderItem.modifier_groups, props.modifierGroups, props.items)
            )}
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(SummaryItem));