import StorageStore from "../Common/Storage/storageStore";
import UserContextOld from "./UserContextOld";

class UserTokenContext {
    static token: string | undefined = undefined;

    static initialize() {

    }

    static setToken(token: string | undefined) {
        this.token = token;
        if (token !== undefined && token) {
            StorageStore.setItem("user_token", token);
        } else {
            StorageStore.removeItem("user_token");
            UserContextOld.setToken(token);
        }
    }

    static getToken() {
        if (this.token) {
            return this.token;
        }
        let token = StorageStore.getItem("user_token");
        if (token) return token;
        token = UserContextOld.getToken() as any;
        if (token) return token;
        return undefined;
    }
}

export default UserTokenContext;
