import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getStoreConfig} from "../../../../services/Store/reducer";
import {getSession} from "../../../../services/Location/reducer";
import {RootState} from "../../../../../reducers";
import {ReactComponent as CalendarSVG} from "../../../../../images/icons/calendar.svg";
import ChangePickDateModal from "../../../../components/Modal/ChangePickDateModal";
import FormattedTime from "../../../../components/Common/FormattedTime";
import {parseDate} from "../../../../services/Common/Date/DateHelper";

interface OwnProps {
    pickDate: any;
    onHide: () => void;
    menuId?: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuPickDateFilterComponent = (props: Props) => {
    const [modal, setModal] = useState(false);
    const {t} = useTranslation();

    if (!props.config) return null;

    return (
        <div className={"container-fluid"}>
            <button className={"btn btn-outline-primary btn-flex w-100"} onClick={() => setModal(true)}>
                {(props.session.pickup_at) ? (
                    <>
                        <FormattedTime
                            value={parseDate(props.session.pickup_at)}
                            day="numeric"
                            month="long"/>
                        <CalendarSVG/>
                    </>
                ) : (
                    <>
                    {t("common.word.as_soon_as_possible")} <CalendarSVG/>
                    </>
                )}
            </button>
            <ChangePickDateModal pickDate={props.session.pickup_at} showModal={modal}
                                 onHide={() => setModal(false)}/>
        </div>
    )

}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuPickDateFilterComponent));