import React, {FC, ReactNode} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {getStoreState} from "../../services/Store/reducer";
import Navbar from "../../components/Navbar/Navbar";
import FetchData from "../../components/Fetch/FetchData";
import Footer from "../../components/layout/Footer/Footer";

interface OwnProps {
    children?: ReactNode
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const OrderTypeLayout: FC<Props> = (props) => {
    return (
        <FetchData order={true}>
            <Navbar config={props.store_state}/>
            <main>
                {props.children}
            </main>
            <Footer config={props.store_state.config}/>
        </FetchData>
    );
};

const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
})

const connector = connect(mapStateToProps);

export default connector(OrderTypeLayout);