import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {getAvailabilities, getMenus} from "../../services/Menu/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import {formatDate, getDayOfWeek, isTimeAllDay} from "../../services/Common/Date/DateHelper";
import AvailableHelper from "../../services/Menu/services/Availability/AvailableHelper";
import {Modal} from "react-bootstrap";
import {ReactComponent as Marker} from "../../../images/icons/marker.svg";
import {ReactComponent as Clock} from "../../../images/icons/clock.svg";
import {ReactComponent as Info} from "../../../images/icons/info.svg";
import StringHelper from "../../services/Common/StringHelper";
import HoursHelper from "../../services/Menu/services/Availability/HoursHelper";
import IframeWidget from "../../utils/IframeWidget";

interface OwnProps {
    onHide: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const StoreInfoModal = (props: Props) => {
    const {config, menus, availabilities} = props;
    let {t} = useTranslation();
    const {i18n} = useTranslation();
    if (!config) return null;


    const renderOpenHoursItem = (openHour: any) => {
        let openDayString = getDayOfWeek(openHour.day_from, i18n.language);

        if (openHour.day_from !== openHour.day_to) {
            openDayString += " - " + getDayOfWeek(openHour.day_to, i18n.language);
        }
        return (
            <ul className="list-inline">
                <li>
                    <strong>
                        {openDayString}
                    </strong>
                </li>
                <li>
                    {isTimeAllDay(openHour.hour_from) && isTimeAllDay(openHour.hour_to) ? t("common.word.whole_day") : `${openHour.hour_from} - ${openHour.hour_to}`}
                </li>
            </ul>
        )
    }

    const renderOpenDays = (availabilities: any, menu: any) => {
        let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id);
        if (!availability || !availability.dates || availability.dates.length === 0) {
            return null;
        }
        let dates = AvailableHelper.getFutureDates(availability).map((date: any) => {
            let dateFromS = formatDate(date.date_from);
            let dateToS = formatDate(date.date_to);
            if (dateFromS == dateToS) {
                return dateFromS;
            }
            return `${dateFromS} - ${dateToS}`;
        });
        let datesString = dates.join(', ');
        return (
            <div>
                {datesString}
            </div>
        )
    }

    let addressView = null;

    if (config.address) {
        let addressFullString = StringHelper.getAddressName(config.address);
        addressView = (
            <tr>
                <td width="1px">
                    <Marker className="icon"/>
                </td>
                <td>
                    {addressFullString}
                </td>
            </tr>
        )
    }
    let descriptionView = null;
    if (config.description) {
        descriptionView = (
            <tr>
                <td width="1px">
                    <Info className="icon"/>
                </td>
                <td>
                    {config.description}
                </td>
            </tr>
        )
    }
    let companyAddressFullName = config.company.address ? StringHelper.getAddressName(config.company.address) : "";
    return (
            <Modal dialogClassName="modal-full" show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow()} className='store-info-modal'>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("modules.store_info_modal.header.title")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        <table className="table table-borderless">
                            <tbody>
                            {addressView}
                            <tr>
                                <td width="1px">
                                    <Clock className="icon"/>
                                </td>
                                <td>
                                    {
                                        menus.map((menu: any) => {
                                                if (menu.availability === undefined) {
                                                    return null;
                                                }
                                                return (
                                                    <div key={menu.id}>
                                                        <h3>
                                                            {menu.translation.name}
                                                        </h3>
                                                        {renderOpenDays(availabilities, menu)}

                                                        <ul className="list-unstyled">
                                                            {
                                                                HoursHelper.sortOpenHours(menu.availability.hours).map((openHour: any) => {

                                                                        return (
                                                                            <li key={menu.id + "-" + openHour.day_from + "-" + openHour.day_to + "-" + openHour.hour_from + "-" + openHour.hour_to}>
                                                                                {renderOpenHoursItem(openHour)}
                                                                            </li>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                </td>
                            </tr>
                            {descriptionView}
                            {(config.company.tax_id_no || config.company.legal_name) && (
                                <tr>
                                    <td width="1px">
                                        <Info className="icon"/>
                                    </td>
                                    <td>
                                        {config.company.legal_name}
                                        {config.company.tax_id_no && (
                                            <>
                                                , {t("common.word.tax_id_no")}: {config.company.tax_id_no}
                                            </>
                                        )}
                                        {companyAddressFullName && (
                                            <>
                                                , {companyAddressFullName}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
    )
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    menus: getMenus(state.menu),
    availabilities: getAvailabilities(state.menu)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(StoreInfoModal));