import React, {useState} from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {ReactComponent as LeftIco} from "../../../../../images/icons/arrow-left.svg";
import {RootState} from "../../../../../reducers";
import MenuEditItemViewHelper from "../../../../services/Menu/services/ItemView/MenuEditItemViewHelper";
import StateItemHelper from "../../../../services/Menu/services/State/StateItemHelper";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import MenuShowItemSingleComponent from "./MenuShowItemSingleComponent";
import MenuItemBaseModalComponent from "./MenuItemBaseModalComponent";
import Money from "../../../../components/Common/Money";
import {getAvailabilities, getItems, getModifierGroups, getPromotions} from "../../../../services/Menu/reducer";
import MenuItemOptionWithImageComponent from "./MenuItemOptionWithImageComponent";
import {getSession} from "../../../../services/Location/reducer";
import {getCart} from "../../../../services/Cart/reducer";


interface OwnProps {
    stateItem: any,
    modifierGroupId: any,
    id: any,
    onUpdate: (stateItem: any) => void,
    image: any,
    errors: any,
    menu: any,
    onChangePage: (name: any, data?: any, render?: any) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuModifierGroupsPickOneComponent = (props: Props) => {
    const {modifierGroupId, id} = props;
    const [stateItem, setStateItem] = useState(props.stateItem ? JSON.parse(JSON.stringify(props.stateItem)) : undefined);
    const [page, setPage] = useState<any | undefined>(undefined);
    const cart = props.cart;
    const {t} = useTranslation();
    let itemId = stateItem.item_id;
    let data = {
        items: props.items,
        modifier_groups: props.modifier_groups,
        availabilities: props.availabilities,
        pick_date: props.session.pickup_at,
        order_type: props.session.order_type
    };
    let itemView = MenuEditItemViewHelper.getItemViewObj(itemId, stateItem, data);
    if (!itemView) return null;

    let modifierGroupState = stateItem.modifier_groups.filter((x: any) => x.modifier_group_id == modifierGroupId)[0];
    let selectedItemState = modifierGroupState ? modifierGroupState.selected_items[id - 1] : undefined;
    let modifierGroupView = itemView.modifier_groups.filter((x: any) => x.id == modifierGroupId)[0];
    let isValid = false;
    if (selectedItemState) {
        let itemViewOption = modifierGroupView.options.filter((x: any) => x.id === selectedItemState.item_id)[0];
        isValid = MenuEditItemViewHelper.validateStateItem(itemViewOption, selectedItemState, undefined, t);
    }
    const setQuantityItem = (modifierGroupId: any, itemView: any, quantity: any) => {
        StateItemHelper.setItemOption(stateItem, modifierGroupId, itemView.id, quantity, props.items, props.modifier_groups, id, props.session.order_type);
        updateState(stateItem);
    }
    const updateState = (newItem: any) => {
        StateItemHelper.refreshItemPrice(newItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
        let copyItem = Object.assign({}, newItem);
        setStateItem(copyItem);
    }

    const save = () => {
        props.onUpdate(stateItem);
    }
    const renderInput = (item: any, modifierGroup: any, optionItem: any) => {
        let optionStateItem = selectedItemState && selectedItemState.item_id === item.id ? selectedItemState : undefined;
        return (
            <MenuItemOptionWithImageComponent singlePick={true} stateItem={optionStateItem}
                                              modifierGroupId={modifierGroup.id}
                                              optionItemView={optionItem} onUpdate={setQuantityItem}
                                              onEdit={editSubLevel}/>
        );
    }
    const editSubLevel = (stateSubItem: any) => {
        let subItem = ItemHelper.getItem(props.items, stateSubItem.item_id)
        setPage({
            image: subItem.image_link ? subItem.image_link.default : undefined,
            render: () => {
                const updateSubItem = (newStateItem: any) => {
                    let modifierGroupId = newStateItem.parent_modifier_group_id;
                    let copyItem = Object.assign({}, stateItem);
                    copyItem.modifier_groups.forEach((mg: any) => {
                        if (mg.modifier_group_id !== modifierGroupId) return;
                        mg.selected_items[id - 1] = newStateItem;
                    });
                    // StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
                    StateItemHelper.refreshItemPriceWithParent(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart, modifierGroupId, props.items, props.modifier_groups);
                    // StateItemHelper.refreshStateModifierGroupItemOption(copyItem, modifierGroupId, props.items, props.modifier_groups);
                    setStateItem(copyItem);
                    setPage(undefined);
                }
                return (
                    <>
                        <MenuShowItemSingleComponent parent={stateItem} onHide={() => setPage(undefined)}
                                                     errors={props.errors} itemId={stateSubItem.item_id}
                                                     menu={props.menu}
                                                     onUpdate={updateSubItem} stateItem={stateSubItem}/>
                    </>
                )
            }
        })
    }
    if (modifierGroupView.max_permitted !== undefined && modifierGroupView.max_permitted <= 0) {
        return null;
    }
    if (page) {
        return (
            <MenuItemBaseModalComponent image={page.image}>
                <>
                    {page.render()}
                </>
            </MenuItemBaseModalComponent>
        )
    }
    let disabled = !selectedItemState || !isValid;
    let loading = false;
    return (
        <>
            <Modal.Header className={"modal-header-back"}>
                <div className={"back-icon text-primary"} onClick={() => props.onChangePage(undefined)}>
                    <LeftIco className="icon"/>
                </div>
                <Modal.Title>
                    {`${t("common.action.choose")} ${modifierGroupView.name}`}
                    {/*{props.itemGroup ? props.itemGroup.translation ? props.itemGroup.translation.name : itemGroup.name : props.item.translation.name} > Wybierz {modifierGroup.name}*/}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"product-item"}>

                    <div className={"product-item-content"}>
                        <fieldset className="form-group">
                            <legend>
                                <h5>
                                    {modifierGroupView.name}
                                </h5>
                            </legend>
                            <div>
                                {
                                    modifierGroupView.options.sort((a: any, b: any) => a.position - b.position || a.name.localeCompare(b.name)).map((optionItem: any) => {
                                        let item = ItemHelper.getItem(props.items, optionItem.id);
                                        return (
                                            <React.Fragment key={modifierGroupView.id + "-" + optionItem.id}>
                                                {renderInput(item, modifierGroupView, optionItem)}
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </fieldset>
                    </div>

                    <div className="product-item-footer row">
                        <div className="btn-group-submit">
                            <button
                                className={"btn-spinner w-100 btn-primary btn btn-submit btn-submit-fixed-bottom" + (loading ? ' loading' : '')}
                                disabled={disabled} onClick={save}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {t("common.action.add")}
                                <div className="btn-cart-price">
                                    <span className="price">
                                        {selectedItemState && (
                                            <Money
                                                value={selectedItemState.total_price}
                                                currency='PLN'/>
                                        )}
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    modifier_groups: getModifierGroups(state.menu),
    availabilities: getAvailabilities(state.menu),
    session: getSession(state.session),
    promotions: getPromotions(state.menu),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuModifierGroupsPickOneComponent));