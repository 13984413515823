import React from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getItems, getPromotions} from "../../../../services/Menu/reducer";
import {ReactComponent as CheckCircleSVG} from "../../../../../images/icons/check-circle.svg";
import {ReactComponent as RemoveSVG} from "../../../../../images/icons/plus-remove.svg";
import {Button} from "react-bootstrap";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import {RootState} from "../../../../../reducers";
import MenuShowItemSingleComponent from "./MenuShowItemSingleComponent";
import StateItemHelper from "../../../../services/Menu/services/State/StateItemHelper";
import Money from "../../../../components/Common/Money";
import {getCart} from "../../../../services/Cart/reducer";


interface OwnProps {
    itemView: any,
    modifierGroup: any,
    stateItem: any,
    onChangePage: (name: any, data?: any, render?: any) => void,
    onUpdate: (item: any) => void,
    menu: any,
    errors: any,
    quantity: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuModifierGroupsPickComponent = (props: Props) => {
    const {stateItem, modifierGroup, itemView} = props;
    const {t} = useTranslation();
    const cart = props.cart;

    const getRenderHelpQuantityInfo = (modifierGroup: any) => {
        let additionalInfos = [];
        if (modifierGroup.min_permitted !== undefined && modifierGroup.min_permitted === 1 && modifierGroup.max_permitted !== undefined && modifierGroup.max_permitted === 1) {

        } else {
            if (modifierGroup.min_permitted !== undefined && modifierGroup.min_permitted > 0) {
                additionalInfos.push({title: t("modules.menu.field.min_permitted.title"), value: modifierGroup.min_permitted});
            }
            if (modifierGroup.max_permitted !== undefined) {
                additionalInfos.push({title: t("modules.menu.field.max_permitted.title"), value: modifierGroup.max_permitted});
            }
            if (modifierGroup.quantity !== undefined) {
                additionalInfos.push({title: t("modules.menu.field.default_quantity.title"), value: modifierGroup.quantity});
            }
            if (modifierGroup.charge_above !== undefined) {
                additionalInfos.push({title: t("modules.menu.field.charge_above.title"), value: modifierGroup.charge_above});
            }
        }
        let errorView = null;
        if (modifierGroup.errors !== undefined) {
            errorView = (
                <React.Fragment>
                    {
                        modifierGroup.errors.map((error: any) =>
                            <p className="text-danger error" key={error.message}>{error.message}</p>
                        )
                    }
                </React.Fragment>
            )
        }
        let additionalInfoView = null;
        if (additionalInfos.length !== 0) {
            additionalInfoView = (
                <React.Fragment>
                    <ul className="">
                        {
                            additionalInfos.map((additionalInfo) =>
                                <li key={additionalInfo.title}>{additionalInfo.title}: <strong>{additionalInfo.value}</strong>
                                </li>
                            )
                        }
                    </ul>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {additionalInfoView}
                {errorView}
            </React.Fragment>
        );
    }
    if (modifierGroup.max_permitted !== undefined && modifierGroup.max_permitted <= 0) {
        return null;
    }

    const editSubLevel = (stateSubItem: any, id: any) => {
        let subItem = ItemHelper.getItem(props.items, stateSubItem.item_id)
        props.onChangePage("MODAL_EDIT", {
            image: subItem.image_link ? subItem.image_link.default : undefined
        }, () => {
            const updateSubItem = (newStateItem: any) => {
                let modifierGroupId = newStateItem.parent_modifier_group_id;
                let copyItem = Object.assign({}, stateItem);
                copyItem.modifier_groups.forEach((mg: any) => {
                    if (mg.modifier_group_id !== modifierGroupId) return;
                    mg.selected_items[id - 1] = newStateItem;
                });
                StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
                props.onUpdate(copyItem);
                props.onChangePage(undefined);
            }
            return (
                <>
                    <MenuShowItemSingleComponent parent={stateItem} onHide={() => props.onChangePage(undefined)}
                                                 errors={props.errors} itemId={stateSubItem.item_id} menu={props.menu}
                                                 onUpdate={updateSubItem} stateItem={stateSubItem}/>
                </>
            )
        })
    }
    const removeItem = (stateSubItem: any, id: any) => {
        let modifierGroupId = stateSubItem.parent_modifier_group_id;
        let copyItem = Object.assign({}, stateItem);
        copyItem.modifier_groups.forEach((mg: any) => {
            if (mg.modifier_group_id !== modifierGroupId) return;
            mg.selected_items.splice(id - 1, 1);
        });
        StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
        props.onUpdate(copyItem);
    }
    const selectItem = (stateSubItem: any, id: any) => {
        props.onChangePage("PICK_ONE", {
            modifier_group: modifierGroup,
            id: id,
            image: itemView.image_link ? itemView.image_link.default : undefined
        })
    }
    const renderItemInfo = (optionStateItem: any, optionItemView: any) => {
        if (!optionStateItem.modifier_groups) return null;
        let nameOptions = [] as any[];
        {
            optionStateItem.modifier_groups.forEach((mg: any) => {
                let modifierGroupView = optionItemView.modifier_groups.filter((x: any) => x.id === mg.modifier_group_id)[0];
                mg.selected_items.forEach((mgo: any) => {
                    if (mgo.quantity > 0) {
                        let itemView = modifierGroupView.options.filter((x: any) => x.id === mgo.item_id)[0];
                        nameOptions.push(`${mgo.quantity}x${itemView.name}`);
                    }
                })
            })
        }
        if (nameOptions.length === 0) return null;
        return (
            <p>
                {nameOptions.join(", ")}
            </p>
        );
    }
    const renderErrors = (errors: any) => {
        return (
            <>
                {errors.map((error: any) => {
                        return (
                            <p className="product-item-modifiers-errors"
                               key={`${error.message}-${error.resource ? error.resource : ""}`}>{error.message} {error.resource ? error.resource : ""}</p>
                        )
                    }
                )}
            </>
        )
    }
    const renderModifierGroups = (modifierGroup: any) => {
        let min_permitted = modifierGroup.min_permitted === undefined ? 0 : modifierGroup.min_permitted;
        let max_permitted = modifierGroup.max_permitted === undefined ? undefined : modifierGroup.max_permitted;
        const divElements = [];

        let number = 1;
        let stateItemModifierGroups = stateItem.modifier_groups.filter((x: any) => x.modifier_group_id == modifierGroup.id);
        let itemViewModifierGroups = itemView.modifier_groups.filter((x: any) => x.id == modifierGroup.id)[0];

        stateItemModifierGroups.forEach((stateItemModifierGroup: any) => {
            stateItemModifierGroup.selected_items.forEach((optionItem: any) => {
                let optionItemId = number;
                let itemOptionView = itemViewModifierGroups.options.filter((x: any) => x.id == optionItem.item_id)[0];
                let errors = itemOptionView.errors;
                let itemInfo = renderItemInfo(optionItem, itemOptionView);
                divElements.push(
                    <div className="product-modifier-option1"
                         key={`${stateItemModifierGroup.modifier_group_id}-${optionItem.item_id}-${optionItemId}`}>
                        <div className={`product-option-with-image active`}>
                            <div className={"check-circle"}>
                                <CheckCircleSVG/>
                            </div>
                            <div className={"product-option-body"}
                                 onClick={() => itemOptionView.is_change_quantity ? selectItem(optionItem, optionItemId) : {}}>
                                <div className={"card-img"}>
                                    {itemOptionView.image_link && (
                                        <img
                                            src={itemOptionView.image_link.default}/>
                                    )}
                                </div>
                                <div className={"product-option-content"}>
                                    <h4>
                                        {itemOptionView.name}
                                    </h4>
                                    {itemOptionView.description && (
                                        <p>{itemOptionView.description}</p>
                                    )}
                                    <div className={"product-option-actions"}>
                                        <div className={"price"}>
                                            <>
                                                +<Money
                                                value={optionItem.total_price}
                                                currency='PLN'/>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(errors.length > 0 || itemInfo || itemOptionView.is_customize || itemOptionView.is_change_quantity) && (
                                <div className={"product-option-multilevel"}>
                                    {itemInfo}
                                    {renderErrors(errors)}
                                    <div className={"d-flex"}>
                                        {itemOptionView.is_customize && (
                                            <>
                                                <button onClick={() => editSubLevel(optionItem, optionItemId)}
                                                        className={"btn btn-primary flex-grow-1"}>
                                                    {t("modules.menu.action.adjust.title")}
                                                </button>
                                                {" "}
                                            </>
                                        )}
                                        {itemOptionView.is_change_quantity && (
                                            <>
                                                <button onClick={() => selectItem(optionItem, optionItemId)}
                                                        className={"btn btn-outline-primary flex-grow-1"}>
                                                    {t("modules.menu.action.change.title")}
                                                </button>
                                                {" "}
                                                <button onClick={() => removeItem(optionItem, optionItemId)}
                                                        className={"btn btn-icon btn-outline-danger btn-icon last"}>
                                                    <RemoveSVG/>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
                number++;
            })
        })
        // Loop to generate 10 div elements
        for (let i = number; i < min_permitted + 1; i++) {
            divElements.push(
                <div className={"product-modifier-option"} key={`picks_${modifierGroup.id}_${i}`}>
                    <Button disabled={i > number} key={`picks_${modifierGroup.id}_${i}`}
                            className={"btn btn-primary w-100 btn-pick"}
                            onClick={() => selectItem(undefined, i)}>
                        {`${t("modules.menu.action.choose_option.title")} #${i}`}
                    </Button>
                    {/*<Button disabled={i > number} key={`picks_${modifierGroup.id}_${i}`} className={"btn btn-primary w-100 btn-pick"}*/}
                    {/*        onClick={() => selectItem(undefined, i)}>*/}
                    {/*    Wybierz {modifierGroup.name} #{i}*/}
                    {/*</Button>*/}
                </div>
            );
        }
        return (
            <>
                {divElements}
            </>
        )
    }

    return (
        <fieldset className="form-group">
            <legend>
                <h5>
                    {modifierGroup.name} {props.quantity}
                </h5>
                {getRenderHelpQuantityInfo(modifierGroup)}
            </legend>
            <div>
                {renderModifierGroups(modifierGroup)}
            </div>

        </fieldset>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    promotions: getPromotions(state.menu),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuModifierGroupsPickComponent));
