let isIframe = false;
if (window.top != window.self) {
    isIframe = true;
}

class IframeUtils {

    static redirect(url: string) {
        if (isIframe) {
            let message = {type: 'RELOAD_PARENT', url: url};
            window.parent.postMessage(message, "*");
        } else {
            window.location.href = url;
        }
    }
}

export default IframeUtils;
