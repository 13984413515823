import {ConfigApi} from "../types";
import {delay} from "go-core";

export type Props = {
  config: ConfigApi | undefined,
}

function getRandomInt() {
  return 100 + Math.floor(Math.random() * Math.floor(9999));
}

const data : Props = {
  config: undefined
}
const delayTime =  0;
class MockApi {
  static async init(config:ConfigApi){
    data.config = config;
  }
  static async getConfig(){
      await delay(delayTime);
      return data.config;
  }

}

export { MockApi }