import React, {FC} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {Route, Routes} from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {
    getStoreConfig,
    getStoreDefaultConfig,
    getStoreStateError,
    getStoreStatePending
} from "../../services/Store/reducer";
import Footer from "../../components/layout/Footer/Footer";
import {getUser} from "../../services/User/reducer";
import ErrorOrderModal from "../HomePage/components/ErrorOrderModal";
import CartPage from "../Cart/CartPage";
import RulesPage from "../Rules/RulesPage";
import LoginLayout from "../Login/LoginLayout";
import SummaryPage from "../Summary/SummaryPage";
import OrderTypeLayout from "../OrderType/OrderTypeLayout";
import DeliveryOrderTypePage from "../OrderType/DeliveryOrderTypePage";
import DineInOrderTypePage from "../OrderType/DineInOrderTypePage";
import RoomServiceOrderTypePage from "../OrderType/RoomServiceOrderTypePage";
import PickUpOrderTypePage from "../OrderType/PickUpOrderTypePage";
import StoreUtils from "../../services/Store/StoreUtils";
import MenuPage from "../Menu/pages/MenuPage";
import HomePage from "../HomePage/HomePage";
import {getSession} from "../../services/Location/reducer";
import {getOrderValid} from "../../services/Cart/reducer";
import DynamicPage from "../Page/DynamicPage";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & DispatchProp & OwnProps;
const SingleStoreContextComponent: FC<Props> = (props) => {
    const orderValid = props.orderValid;
    const config = props.config;

    if (config === undefined) return null;

    return (
        <>
            {!orderValid && (
                <ErrorOrderModal/>
            )}

            <Routes>
                <Route path={`cart`} element={<CartPage/>}/>
                <Route path={`rules`} element={<RulesPage config={props.config}/>}/>
                <Route path={`login/*`} element={<LoginLayout/>}/>
                <Route path={`summary/:id/:token`} element={<SummaryPage/>}/>
                <Route path={`DELIVERY`} element={<OrderTypeLayout><DeliveryOrderTypePage/></OrderTypeLayout>}/>
                <Route path={`DINE_IN`} element={<OrderTypeLayout><DineInOrderTypePage/></OrderTypeLayout>}/>
                <Route path={`ROOM_SERVICE`}
                       element={<OrderTypeLayout><RoomServiceOrderTypePage/></OrderTypeLayout>}/>
                <Route path={`PICK_UP`} element={<OrderTypeLayout><PickUpOrderTypePage/></OrderTypeLayout>}/>
                <Route path={`/:slug`} element={<DynamicPage/>}/>
                {StoreUtils.isMultistore(props.defaultConfig) ? (
                    <Route path={`*`} element={<>
                        <Navbar/>
                        {/*<NavbarMultiStore config={props.config}/>*/}
                        <Routes>
                            <Route path={`menu/:menuId/*`} element={<MenuPage/>}/>
                            <Route path={`*`} element={<MenuPage/>}/>
                        </Routes>
                        <div className={"body-container-cart"}>
                            <Footer config={props.config}/>
                        </div>
                    </>}/>
                ) : (
                    <>
                        {StoreUtils.isHomepageMenu(config) ? (
                            <>
                                <Route path={`*`} element={<>
                                    <Navbar/>
                                    <Routes>
                                        <Route path={`menu/:menuId/*`} element={<MenuPage/>}/>
                                        <Route path={`*`} element={<MenuPage/>}/>
                                    </Routes>
                                    <div className={"body-container-cart"}>
                                        <Footer config={props.config}/>
                                    </div>
                                </>}/>
                            </>
                        ) : (
                            <>
                                <Route path={`menu/*`} element={<>
                                    <Navbar/>
                                    <Routes>
                                        <Route path={`menu/:menuId/*`} element={<MenuPage/>}/>
                                        <Route path={`*`} element={<MenuPage/>}/>
                                    </Routes>
                                    <div className={"body-container-cart"}>
                                        <Footer config={props.config}/>
                                    </div>
                                </>}/>
                                <Route path={`*`} element={<HomePage/>}/>
                            </>
                        )}
                    </>
                )}
            </Routes>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    defaultConfig: getStoreDefaultConfig(state.config),
    user: getUser(state.user),
    session: getSession(state.session),
    storePending: getStoreStatePending(state.config),
    storeError: getStoreStateError(state.config),
    orderValid: getOrderValid(state.cart)
})

const connector = connect(mapStateToProps);
export default connector(SingleStoreContextComponent);