import React from "react";
import FormattedTime from "./FormattedTime";
import FormattedDate from "./FormattedDate";


interface Props {
    date: Date
}
const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

const FormattedShortDateTime = (props:Props)=>{
    if(isSameDate(props.date, new Date())){
        return (
            <FormattedTime value={props.date}/>
        );
    }
    return (
        <>
            <FormattedDate value={props.date}/>&nbsp;
            <FormattedTime value={props.date}/>
        </>
    );
}

export default FormattedShortDateTime;