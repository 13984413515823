import ItemHelper from "../Item/ItemHelper";
import DateService from "../../../Common/Date/DateService";
import MenuEditItemStateHelper from "../State/MenuEditItemStateHelper";

class MenuEditItemViewHelper {
    static getItemViewObj = (id: any, itemState: any, data: any) => {
        return MenuEditItemViewHelper.getItemView(data.items, data.modifier_groups, data.availabilities, id, undefined, undefined, 1, data.pick_date, itemState, data.order_type);
    }
    static getItemView = (items: any, modiferGroups: any, availabilities: any, id: any, parentItem: any, parentModifierGroup: any, level: any, pick_date: any, itemState: any, order_type?: string) => {
        let item = ItemHelper.getItem(items, id);
        if (item === undefined) {
            return undefined;
        }
        let itemView = {} as any;
        itemView.id = item.id;
        itemView.state = itemState;
        itemView.name = item.translation.name;
        itemView.description = item.translation.description;
        itemView.content = item.translation.content;
        itemView.image_link = item.image_link;
        itemView.order_types = item.order_types;
        itemView.disabled = !ItemHelper.isAvailabile(item, availabilities, DateService.emptyOrNow(pick_date), order_type);
        itemView.modifier_groups = [];
        itemView.min_permitted = 0;
        itemView.default_quantity = 0;
        itemView.availability_id = item.availability_id;
        itemView.max_permitted = undefined;
        itemView.charge_above = undefined;
        itemView.price = ItemHelper.getPriceOvveride(item, parentItem, parentModifierGroup);
        itemView.quantity = 0;
        let itemQuantityInfo = ItemHelper.getItemQuantityInfo(item, parentItem, parentModifierGroup);
        if (itemQuantityInfo !== undefined) {
            itemView.min_permitted = itemQuantityInfo.min_permitted;
            itemView.max_permitted = itemQuantityInfo.max_permitted;
            itemView.charge_above = itemQuantityInfo.charge_above;
            itemView.quantity = itemQuantityInfo.default_quantity !== undefined ? itemQuantityInfo.default_quantity : 0;
            itemView.default_quantity = itemQuantityInfo.default_quantity !== undefined ? itemQuantityInfo.default_quantity : 0;
        }
        itemView.disabled_order_type = false;
        if(itemView.order_types !== undefined && itemView.order_types.length > 0 && !itemView.order_types.includes(order_type)){
            itemView.min_permitted = 0;
            itemView.disabled_order_type = true;
        }

        itemView.errors = [];
        itemView.isValid = true;
        if (level > 2) {
            return itemView;
        }
        let isCustomize = false;
        if (item.modifier_groups !== undefined) {
            item.modifier_groups.forEach((itemModifierGroup: any) => {
                let modifierGroup = ItemHelper.getModifierGroup(modiferGroups, itemModifierGroup.modifier_group_id);
                if (modifierGroup === undefined || modifierGroup.status !== 'ENABLED') {
                    return;
                }
                let modifierGroupView = {
                    id: modifierGroup.id,
                    name: modifierGroup.translation.name,
                    options: [] as any[],
                    min_permitted: 0,
                    max_permitted: undefined,
                    charge_above: undefined,
                    position: itemModifierGroup.position,
                    quantity: 0,
                    errors: [],
                    isValid: true
                } as any
                let quantity_info = ItemHelper.getModifierGroupQuantityInfo(modifierGroup, item);
                if (quantity_info !== undefined) {
                    if (quantity_info.max_permitted !== undefined) {
                        modifierGroupView.max_permitted = quantity_info.max_permitted;
                    }
                    if (quantity_info.min_permitted !== undefined) {
                        modifierGroupView.min_permitted = quantity_info.min_permitted;
                    }
                    if (quantity_info.charge_above !== undefined) {
                        modifierGroupView.charge_above = quantity_info.charge_above;
                    }
                }
                let stateModifierGroup = itemState ? MenuEditItemStateHelper.getStateModifierGroup(itemState.modifier_groups, modifierGroup.id) : undefined;
                let modifierGroupOnlyOne = modifierGroupView.min_permitted === 1 && modifierGroupView.min_permitted === modifierGroupView.max_permitted && modifierGroup.options.length === 1;
                modifierGroup.options.forEach((modifierGroupItem: any) => {
                    let stateSelectedItem = MenuEditItemStateHelper.getStateModifierGroupSelectedItem(stateModifierGroup, modifierGroupItem.entity_id);
                    let newLevel = level + 1;
                    let newParentItem = item;
                    let modifierGroupItemView = MenuEditItemViewHelper.getItemView(items, modiferGroups, availabilities, modifierGroupItem.entity_id, newParentItem, modifierGroup, newLevel, pick_date, stateSelectedItem, order_type);
                    if (modifierGroupItemView === undefined) {
                        return;
                    }
                    let stateSelectedItems = MenuEditItemStateHelper.getStateModifierGroupSelectedItems(stateModifierGroup, modifierGroupItem.entity_id);
                    modifierGroupItemView.states = stateSelectedItems;
                    modifierGroupItemView.position = modifierGroupItem.position;
                    if (modifierGroupItemView !== undefined) {
                        modifierGroupView.options.push(modifierGroupItemView);
                    }
                    let optionQuantity = stateSelectedItems.reduce((sum: any, item: any) => sum + item.quantity, 0);
                    let optionQuantityFree = stateSelectedItems.reduce((sum: any, item: any) => sum + item.quantity_free, 0);
                    modifierGroupItemView.quantity_free = optionQuantityFree;
                    modifierGroupItemView.quantity = optionQuantity;
                    modifierGroupView.quantity = modifierGroupView.quantity + modifierGroupItemView.quantity;
                    modifierGroupItemView.is_change_quantity = true;
                    if ((modifierGroupItemView.min_permitted === modifierGroupItemView.max_permitted && modifierGroupItemView.min_permitted === modifierGroupItemView.default_quantity)) {
                        modifierGroupItemView.is_change_quantity = false;
                    }
                    if (!isCustomize) {
                        if (modifierGroupItemView.modifier_groups.some((x: any) => x.is_customize)) {
                            isCustomize = true;
                        }
                        if (modifierGroupItemView.is_change_quantity) {
                            if (modifierGroupOnlyOne && modifierGroupItemView.default_quantity === 1) {
                            } else {
                                isCustomize = true;
                            }
                        }
                    }
                });
                modifierGroupView.is_customize = isCustomize;
                modifierGroupView.disabled_order_type = !modifierGroupView.options.some((x:any) => !x.disabled_order_type);
                if(!modifierGroupView.disabled_order_type){
                    itemView.modifier_groups.push(modifierGroupView);
                }
            });
        }
        itemView.is_customize = isCustomize;
        return itemView;
    }
    static validateStateItem = (itemView: any, stateItem: any, lvl: any, t: any) => {
        let isValid = true;
        let allErrors = [] as any[];

        itemView.modifier_groups.forEach((itemModifierGroup: any) => {
            let stateItemModifierGroup = stateItem.modifier_groups.filter((x: any) => x.modifier_group_id === itemModifierGroup.id)[0];
            let quantity = stateItemModifierGroup ? stateItemModifierGroup.selected_items.reduce((sum: any, item: any) => sum + item.quantity, 0) : 0;
            if (quantity < itemModifierGroup.min_permitted) {

                if (itemModifierGroup.max_permitted !== undefined && itemModifierGroup.max_permitted === 1 && itemModifierGroup.min_permitted === 1) {
                    itemModifierGroup.errors.push({"message": t("constraints.internal.options_not_empty")});
                    allErrors.push({"message": t("constraints.internal.options_not_empty")})
                } else {
                    itemModifierGroup.errors.push({"message": t("constraints.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                    allErrors.push({"message": t("constraints.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                }
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            if (itemModifierGroup.max_permitted !== undefined && quantity > itemModifierGroup.max_permitted) {
                itemModifierGroup.errors.push({"message": t("constraints.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                allErrors.push({"message": t("constraints.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            itemModifierGroup.options.forEach((itemModifierGroupItem: any) => {
                let stateItemModifierGroupOption = stateItemModifierGroup ? stateItemModifierGroup.selected_items.filter((x: any) => x.item_id === itemModifierGroupItem.id)[0] : undefined;
                let quantityItem = stateItemModifierGroupOption ? stateItemModifierGroupOption.quantity : 0;

                if (quantityItem > 0 && itemModifierGroupItem.disabled) {
                    itemModifierGroupItem.errors.push({"message": t("constraints.internal.item_not_available")});
                    allErrors.push({"message": t("constraints.internal.item_not_available")});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if (quantityItem < itemModifierGroupItem.min_permitted) {

                    itemModifierGroupItem.errors.push({"message": t("constraints.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    allErrors.push({"message": t("constraints.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if (itemModifierGroupItem.max_permitted !== undefined && quantityItem > itemModifierGroupItem.max_permitted) {
                    itemModifierGroupItem.errors.push({"message": t("constraints.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    allErrors.push({"message": t("constraints.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                let newLvl = lvl ? lvl + 1 : 1;
                if (newLvl <= 2) {
                    if (itemModifierGroupItem.quantity > 0) {
                        if (!MenuEditItemViewHelper.validateStateItem(itemModifierGroupItem, stateItemModifierGroupOption, newLvl, t)) {
                            isValid = false;
                        }
                    }
                }
            });
        });
        itemView.all_errors = allErrors;
        return isValid;
    }
    static validateState = (itemView: any, availabilities: any, pick_date: any, lvl: any, t: any) => {
        let isValid = true;
        let allErrors = [] as any[];
        itemView.modifier_groups.forEach((itemModifierGroup: any) => {
            let quantity = itemModifierGroup.quantity;
            if (quantity < itemModifierGroup.min_permitted) {

                if (itemModifierGroup.max_permitted !== undefined && itemModifierGroup.max_permitted === 1 && itemModifierGroup.min_permitted === 1) {
                    itemModifierGroup.errors.push({"message": t("constraints.internal.options_not_empty")});
                    allErrors.push({"message": t("constraints.internal.options_not_empty")})
                } else {
                    itemModifierGroup.errors.push({"message": t("constraints.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                    allErrors.push({"message": t("constraints.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                }
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            if (itemModifierGroup.max_permitted !== undefined && quantity > itemModifierGroup.max_permitted) {
                itemModifierGroup.errors.push({"message": t("constraints.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                allErrors.push({"message": t("constraints.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            itemModifierGroup.options.forEach((itemModifierGroupItem: any) => {
                let quantityItem = itemModifierGroupItem.quantity;

                if (quantityItem > 0 && itemModifierGroupItem.disabled) {
                    itemModifierGroupItem.errors.push({"message": t("constraints.internal.item_not_available")});
                    allErrors.push({"message": t("constraints.internal.item_not_available")});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if (quantityItem < itemModifierGroupItem.min_permitted) {

                    itemModifierGroupItem.errors.push({"message": t("constraints.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    allErrors.push({"message": t("constraints.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if (itemModifierGroupItem.max_permitted !== undefined && quantityItem > itemModifierGroupItem.max_permitted) {
                    itemModifierGroupItem.errors.push({"message": t("constraints.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    allErrors.push({"message": t("constraints.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                let newLvl = lvl ? lvl + 1 : 1;
                if (newLvl <= 2) {
                    if (itemModifierGroupItem.quantity > 0) {
                        if (!MenuEditItemViewHelper.validateState(itemModifierGroupItem, undefined, undefined, newLvl, t)) {
                            isValid = false;
                        }
                    }
                }
            });
        });
        itemView.all_errors = allErrors;
        return isValid;
    }
    static getErrors = (item: any) => {
        let errors = [] as any[];
        item.modifier_groups.forEach((itemModifierGroup: any) => {
            if (itemModifierGroup.errors.length > 0) {
                itemModifierGroup.errors.forEach((error: any) => {
                    errors.push({
                        message: error.message,
                        resource: itemModifierGroup.name
                    })
                })
            }
        });
        return errors;
    }
}

export default MenuEditItemViewHelper;