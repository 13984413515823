import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {getAvailabilities, getCustomFields} from "../../services/Menu/reducer";
import {parseDate} from "../../services/Common/Date/DateHelper";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import {getSession} from "../../services/Location/reducer";
import FormattedTime from "../Common/FormattedTime";
import ChangePickDateModal from "../Modal/ChangePickDateModal";
import {getCart} from "../../services/Cart/reducer";
import {ReactComponent as BasketFastSVG} from "../../../images/icons/basket-fast.svg";
import {ReactComponent as CalendarSVG} from "../../../images/icons/calendar.svg";
import {useLocationService} from "../../services/Location/locationService";


interface OwnProps {
    errors: any[],
    onChange: (cart: any) => void,
    onChangeCustomField: (slug: any, value: any) => void
    data: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartPickDateComponent = (props: Props) => {
    const cart = props.cart;
    const [pickDateModal, setPickDateModal] = useState(false);
    const [tableNumberModal, setTableNumberModal] = useState(false);
    const [roomNumberModal, setRoomNumberModal] = useState(false);
    const cartType = cart.type;
    const cartNumber = cart.number;
    const {t} = useTranslation();
    const locationService = useLocationService();

    const showPickDateModal = () => {
        setPickDateModal(true)
    }
    const hidePickDateModal = () => {
        setPickDateModal(false);
    }

    const setNow = async () => {
        let sessionData = {
            ...props.session,
            pickup_at: undefined
        }
        await locationService.update(sessionData);
    }
    const handleChangeType = async (event: any) => {
        let type = event.target.value;
        switch (type) {
            case "NOW":
                await setNow()
                break;
            case "PICK_DATE":
                showPickDateModal()
                break;
            default:
        }
    }

    return (
        <React.Fragment>
            <div className="card-cart">
                <div className={"container-fluid"}>
                    <h5 className={"card-cart-header"}>
                        {t("modules.cart.field.pickup_date.title")}
                    </h5>
                    <div className={"card-cart-body"}>

                        <div className="btn-groups-columns">
                            <div className="btn-group-toggle btn-checks" data-toggle="buttons">
                                <label
                                    className={"btn btn-default btn-lg btn-check-full" + (!cart.pickup_at ? ' active' : '')}>
                                    <BasketFastSVG/>
                                    <span>
                                        {t("common.word.as_soon_as_possible")}
                                    </span>
                                    <input className="btn-check1" type="radio" name="pick_date" value="NOW"
                                           autoComplete="off"
                                           checked={!props.session.pickup_at} onChange={handleChangeType}/>
                                </label>
                                {props.config?.ordering.pick_date && (
                                    <label
                                        className={"btn btn-default btn-lg btn-check-full" + (cart.pickup_at ? ' active' : '')}>
                                        <CalendarSVG/>
                                        <span>
                                            {t("modules.cart.field.another_date.title")}
                                            {props.session.pickup_at && (
                                                <small className={"accent"}>
                                                    <FormattedTime
                                                        value={parseDate(props.session.pickup_at)}
                                                        day="numeric"
                                                        month="long"/>
                                                </small>
                                            )}
                                    </span>
                                        <input className="btn-check1" type="radio" name="pick_date" value="PICK_DATE"
                                               autoComplete="off"
                                               checked={!!props.session.pickup_at} onChange={handleChangeType}/>
                                    </label>
                                )}

                            </div>
                        </div>
                        {/*{pickDateOptionView}*/}
                    </div>
                </div>
            </div>
            <ChangePickDateModal pickDate={props.session.pickup_at} showModal={pickDateModal}
                                 onHide={hidePickDateModal}/>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    config: getStoreConfig(state.config),
    custom_fields: getCustomFields(state.menu),
    availabilities: getAvailabilities(state.menu),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartPickDateComponent));
