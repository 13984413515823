import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import {ConfigApi, StoreConfigCoordinatesApi} from "../../../../services/Api/types";
import {getUser} from "../../../../services/User/reducer";
import {getSession} from "../../../../services/Location/reducer";
import {usePlaceContext} from "../../../../services/Place/PlaceContext";
import MultistoreMapComponent from "../Map/components/MultistoreMapComponent";
import MultistoreListContentComponent from "../List/components/MultistoreListContentComponent";
import MultistorePlaceFilterCitiesComponent from "./MultistorePlaceFilterCitiesComponent";
import ChangeLocationButtonComponent from "../../components/ChangeLocationButtonComponent";
import {useLocationService} from "../../../../services/Location/locationService";


interface OwnProps {
    config: ConfigApi;
    setFilter: React.Dispatch<Record<string, any>>;
    filter: Record<string, any>
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreMapListComponent = (props: Props) => {
    const {places, nearestPlace, updateCoordinates} = usePlaceContext();
    const [hoverPlace, setHoverPlace] = useState<string | undefined>(undefined);
    const locationService = useLocationService();
    const geolocation = async (coordinates: StoreConfigCoordinatesApi) => {
        await locationService.updateCoordinates({
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
        })
    }
    let filterPlaces = places;
    if (props.filter?.city) filterPlaces = filterPlaces?.filter(x => x.address.city == props.filter.city);
    return (
        <>
            <div className={"multistore-full"}>
                <div className={"multistore-full-map"}>
                    <div className={"multistore-map"}>
                        <MultistoreMapComponent places={places} nearestPlace={nearestPlace}
                                                coordinates={props.session.coordinates}
                                                hoverPlace={hoverPlace}
                                                zoom={8} geolocation={geolocation}/>
                    </div>
                </div>
                <div className={"multistore-full-list"}>
                    <div className={"multistore-list-content"}>
                        <div className={"container-fluid"}>

                            <ChangeLocationButtonComponent config={props.config}/>
                            <MultistorePlaceFilterCitiesComponent places={places} city={props.filter.city}
                                                                  onChange={(value: string) => props.setFilter({
                                                                      ...props.filter,
                                                                      city: value
                                                                  })}/>

                            <MultistoreListContentComponent places={filterPlaces}
                                                            hoverPlace={(place?: string) => setHoverPlace(place)}/>
                        </div>
                    </div>

                    {/*<MultistoreListComponent places={places} onChangeCity={() => {}}*/}
                    {/*                         geolocation={(coordinates?: StoreConfigCoordinatesApi) => { }}*/}
                    {/*                         hoverPlace={(place?:string) => setHoverPlace(place)}*/}
                    {/*/>*/}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);

export default connector(MultistoreMapListComponent);