import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {Route, Routes} from "react-router-dom";
import {getUser} from "../../services/User/reducer";
import MultistoreHomePage from "../Multistore/pages/HomePage/MultistoreHomePage";
import SingleStoreMultistorePage from "../Multistore/pages/Store/SingleStoreMultistorePage";
import {ConfigApi} from "../../services/Api/types";
import LoginLayout from "../Login/LoginLayout";
import MultistoreMapPage from "../Multistore/pages/Map/MultistoreMapPage";
import {PlaceProvider} from "../../services/Place/PlaceContext";
import MultistoreListPage from "../Multistore/pages/List/MultistoreListPage";
import RulesPage from "../Rules/RulesPage";
import NavbarMulti from "../../components/Navbar/NavbarMulti";

interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreContextPage = (props: Props) => {
    return (
        <PlaceProvider>
            <Routes>
                <>
                    <Route path={`login/*`} element={<LoginLayout/>}/>
                    <Route path={`map`} element={<MultistoreMapPage config={props.config}/>}/>
                    <Route path={`list`} element={<MultistoreListPage config={props.config}/>}/>
                    <Route path={``} element={<MultistoreHomePage config={props.config}/>}/>
                    <Route path={`:storeId/*`} element={<SingleStoreMultistorePage/>}/>
                </>
            </Routes>
        </PlaceProvider>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);

export default connector(MultistoreContextPage);