import React from 'react';
import {withTranslation} from "react-i18next";
import BaseSearchComponent from "./BaseSearchComponent";
import {api} from "../../services/Api/api";


interface Props {
    onChange: (value: any) => void
    value: any,
    onBlur: (query: any) => void,
    city: any,
    placeholder: any,
    className?: string
}

const StreetSearchComponent = (props: Props) => {
    const fetch = async (query: any) => {
        let response = await api.getStreets(props.city, query)
        return response.map((c: any) => {
            return {
                description: c
            }
        })
    }
    const onChange = (value: any) => {
        props.onChange(value.description);
    }
    const onBlur = (value: any) => {
        props.onBlur(value.description);
    }


    return (
        <BaseSearchComponent className={props.className} fetch={fetch} value={props.value} onBlur={onBlur} onChange={onChange}
                             placeholder={props.placeholder}/>
    )
}

export default withTranslation()(StreetSearchComponent)