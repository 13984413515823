import React, {useState} from 'react';
import {ReactComponent as PlusIco} from "../../../../images/icons/plus.svg";
import {ReactComponent as CheckIco} from "../../../../images/icons/check.svg";
import {RootState} from "../../../../reducers";
import {getCart} from "../../../services/Cart/reducer";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import CartPromotionsModalComponent from "./CartPromotionsModalComponent";

type Props = ReturnType<typeof mapStateToProps> & DispatchProp;
const CartVouchers = ({cart} : Props) => {
	const {t} = useTranslation();
	const vouchers = cart.promotions.filter((promotion) => promotion.type === 'VOUCHER_CREDIT');
	const [showPromotionsModal, setShowPromotionsModal] = useState(false);

	const anyVoucherOrCouponCode = () => {
		return cart.coupon || vouchers.length > 0;
	}

	const getTitle = () => {
		let result: string[] = [];
		if(cart.coupon) {
			result.push(t("modules.cart.field.coupon_code.title"))
		}

		if(vouchers.length > 0) {
			result.push(`${t("modules.cart.field.voucher.title")}${vouchers.length === 1 ? "" : ` x${vouchers.length}`}`);
		}

		return result.join(', ');
	}

	const handleAddPromotion = () => {
		if(!anyVoucherOrCouponCode()) {
			setShowPromotionsModal(true);
		}
	}

	return (
		<div className="d-flex align-items-center justify-content-between mb-4 cart-vouchers">
			<div className={`d-flex align-items-center gap-2 ${anyVoucherOrCouponCode() ? "": "empty"}`} onClick={handleAddPromotion}>
				{anyVoucherOrCouponCode() ? <CheckIco/> : <PlusIco/>}
				{anyVoucherOrCouponCode() ? <p className="mb-0">{getTitle()}</p> : <span>{t("modules.cart.action.discount_or_activate_voucher.title")}</span>}
			</div>
			{anyVoucherOrCouponCode() && <span onClick={() => setShowPromotionsModal(true)}>{t("modules.cart.action.change.title")}</span>}
			{showPromotionsModal && (
				<CartPromotionsModalComponent onHide={() => setShowPromotionsModal(false)}/>
			)}
		</div>
	)
}

const mapStateToProps = (state: RootState) => ({
	cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartVouchers));