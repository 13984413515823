import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";
import {RootState} from "../../../reducers";
import UserResetPasswordPage from "./pages/UserResetPasswordPage";
import UserActivationPage from "./pages/UserActivationPage";
import {getUser} from "../../services/User/reducer";
import UserAuthPage from "./pages/UserAuthPage";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;
const LoginPage = (props: Props) => {
    return (
        <>
            <div className={"login-page"}>
                <Routes>
                    <Route path={`reset-password`} element={<UserResetPasswordPage/>}/>
                    <Route path={`activation`} element={<UserActivationPage/>}/>
                    <Route path={`auth`} element={<UserAuthPage/>}/>
                </Routes>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LoginPage));