import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {Navigate} from "react-router-dom";
import {RootState} from "../../../reducers";
import LoadingContainer from "../../components/Common/LoadingContainer";
import {useLocationService} from "../../services/Location/locationService";
import {getSession} from "../../services/Location/reducer";
import AppConfig from "../../services/AppConfig";
import {usePrefix} from "../../services/Prefix";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

function DeliveryOrderTypePage(props: Props) {
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const orderType = "DELIVERY";
    const locationService = useLocationService();
    const prefix = usePrefix();


    const init = async () => {

        let sessionData = {
            ...props.session,
            order_type: orderType
        }

        try {
            await locationService.update(sessionData);
            // await props.dispatch(updateOrderData({type: orderType}, props.cart));
            setRedirect(true);
        } catch (error) {
        }
    }
    useEffect(() => {

        init();
    }, []);

    if (redirect) {
        return (
            <Navigate to={AppConfig.getLinkWithPrefix("/", prefix)}/>
        )
    }
    if (loading) {
        return (
            <LoadingContainer/>
        );
    }
    return (
        <Navigate to={AppConfig.getLinkWithPrefix("/", prefix)}/>
    )
}

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);

export default connector(DeliveryOrderTypePage);
