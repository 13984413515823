import {getLang, getLangCountry} from "../../utils";

export const defaultFormatTime = {hourCycle: "h23", hour: "2-digit", minute: "2-digit", second: "2-digit"};

export const formatStringToDate = (dateString: string): Date => {
    if (!dateString) return new Date();
    // let dateTime = Date.parse(dateString);
    // if(!isNaN(dateTime)) return new Date(dateTime);
    let dateTimes = dateString.split(" ");
    if (dateTimes.length === 1) dateTimes = dateString.split("T");
    const date = dateTimes[0].replaceAll(",", "");
    const time = dateTimes[1] || "00:00:00";
    let isUSA = false;
    // if (date.includes("/")) isUSA = getLang().includes("US") || getLang() === "en";
    if (date.includes("/")) {
        const langCountry = getLangCountry();
        isUSA = Boolean(langCountry && langCountry.toUpperCase().includes("US"));
    }

    // if (date.includes("/")) isUSA = getLang().includes("US") || getLang().includes("en");
    const dateNumbers = date.includes("-")
        ? date.split("-")
        : date.includes("/")
            ? date.split("/")
            : date.includes(".")
                ? date.split(".")
                : undefined;
    if (!dateNumbers) return new Date(dateString);
    const [hours, minutes, seconds] = time.split(",")[0].split(":");
    let year = Number(dateNumbers[0]);
    let month = Number(dateNumbers[1]);
    let day = Number(dateNumbers[2]);
    if (dateNumbers[0].length !== 4) {
        year = Number(dateNumbers[2]);
        day = Number(dateNumbers[0]);
    }
    if (isUSA) {
        const tmp = month;
        month = day;
        day = tmp;
    }
    return new Date(year, month - 1, day, Number(hours), Number(minutes), seconds ? Number(seconds) : 0);
    // return new Date(Number(dateNumbers[2]), Number(dateNumbers[1]) - 1, Number(dateNumbers[0]), Number(timeNumbers[0]), Number(timeNumbers[1]), timeNumbers[2] ? Number(timeNumbers[2]) : 0)
};

export const FormatDate = (
    dateString: string | Date,
    timezone?: string,
    showSeconds?: boolean,
    formatOptions?: Record<string, any>,
    locale?: string
): string => {
    if (!dateString) {
        return "";
    }

    let date = dateString instanceof Date ? dateString : formatStringToDate(dateString);
    const dateAsString = typeof dateString === "string" ? dateString : dateString.toISOString();
    if (timezone) {
    }
    let newFormatOptions = formatOptions;
    if (!formatOptions) {
        newFormatOptions = defaultFormatTime;
    }
    let newLocale = locale;
    if (!locale) {
        newLocale = getLang();
    }
    return date.toLocaleDateString(newLocale, {
        ...newFormatOptions,
        second: showSeconds ? "2-digit" : undefined,
    });
    // return dateString.includes("T") || dateString.includes(" ") ? date.toLocaleString(getLang(), {
    //     ...formatDateTimeOptions,
    //     second: showSeconds ? "2-digit" : undefined
    // } as any) : date.toLocaleDateString(getLang());
};

export const parseDateString = (date: string): string => {
    return date.replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
};

export default FormatDate;
