import HoursHelper from "../Menu/services/Availability/HoursHelper";

class StringHelper {

    static emptyOrValue = (value?: string) => {
        return value ? value : "";
    }

    static getAddressName(address: any) {
        let name = '';
        if (address === undefined || (!address.city && !address.street)) {
            return '';
        }
        if (address.street) {
            name += address.street;
            if (address.build_nr) {
                name += " " + address.build_nr;
            }
        }
        if (address.city) {
            if (name) {
                name += ", "
            }
            if (address.zip_code) {
                name += address.zip_code + " ";
            }
            name += address.city;
            if (!address.street) {
                if (address.build_nr) {
                    name += " " + address.build_nr;
                }
            }
        }
        if (address.country) {
            if (name) {
                name += ", "
            }
            name += address.country;
        }
        return name;
    }

    static getWeekday() {
        var weekday = new Array(7);
        weekday[0] = "sunday";
        weekday[1] = "monday";
        weekday[2] = "tuesday";
        weekday[3] = "wednesday";
        weekday[4] = "thursday";
        weekday[5] = "friday";
        weekday[6] = "saturday";
        return weekday;
    }

    static getDayOfWeekNumber(dayOfWeek: any) {
        let weekday = this.getWeekday();
        var index = weekday.indexOf(dayOfWeek.toLowerCase());
        return index;
    }

    static padTo2Digits(num: number) {
        return String(num).padStart(2, '0');
    }
}
export default StringHelper;
