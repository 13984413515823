import ItemHelper from "./services/Item/ItemHelper";
import AgeRestrictedContext from "../Common/AgeRestrictedContext";
import {Reducer} from "redux";
import {getElementTranslation, getElementTranslations} from "../languageUtils";
import {MenusActionTypes, MenuState} from "./types";
import Utils from "../../utils/Utils";

const initialState: MenuState = {
    items: [],
    item_groups: [],
    categories: [],
    modifier_groups: [],
    payment_methods: [],
    menus: [],
    promotions: [],
    custom_fields: [],
    availabilities: [],
    initialized: false,
    pending: false,
    error: undefined,
    fetch_status: "NEW"
}


const getCategoryTranslations = (locale: any, categories: any) => {
    return getElementTranslations(locale, categories, ["name", "description"]);
}
const getItemTranslations = (locale: any, items: any) => {
    items.forEach((element: any) => {
        element.selected_items.forEach((si: any) => {
            let item = items.filter((x: any) => x.id == si.item_id)[0];
            if (item) {
                si.translation = getElementTranslation(locale, item, ["name", "description", "content"]);
            }
        })
    });
    return getElementTranslations(locale, items, ["name", "description", "content"]);
}
const getItemGroupTranslations = (locale: any, itemGroups: any) => {
    return getElementTranslations(locale, itemGroups, ["name"]);
}

const getMenuTranslations = (locale: any, menus: any) => {
    return getElementTranslations(locale, menus, ["name"]);
}
const getModifierGroupTranslations = (locale: any, modifierGroups: any) => {
    return getElementTranslations(locale, modifierGroups, ["name"]);
}
const isExistOrderType = (orderTypes: any[], orderType: string) => {
    if (!orderTypes) return true;
    if (orderTypes.length === 0) return true;
    return orderTypes.includes(orderType);
}
const menuReducer: Reducer<MenuState> = (state = initialState, action: any): MenuState => {
    switch (action.type) {
        case MenusActionTypes.PENDING:
            return {
                ...state,
                pending: true,
                fetch_status: "PENDING"
            }
        case MenusActionTypes.ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
                fetch_status: "ERROR"
            }
        case MenusActionTypes.SUCCESS:
            var data = action.data;
            var dataMenus = data.menus;
            var locale = data.locale;
            var showPriceWithMin = true;
            dataMenus.items.forEach((item: any) => {
                if (item.price === undefined) {
                    return;
                }
                let fullPrice = item.price.amount;
                let fullPricesForOrderTypes = {
                    "DELIVERY": item.price.amount,
                    "PICK_UP": item.price.amount,
                    "DINE_IN": item.price.amount,
                    "ROOM_SERVICE": item.price.amount,
                };
                let minPossiblePrice = 0;
                item.selected_items = [];
                item.translation = {
                    name: item.name,
                    description: item.description,
                    content: item.content,
                };
                let isAlcohol = AgeRestrictedContext.isItemAlcohol(item);
                let isEnergyDrink = AgeRestrictedContext.isItemEnergyDrink(item);
                item.modifier_groups.forEach((itemModifierGroup: any) => {
                    let modifierGroup = ItemHelper.getModifierGroup(dataMenus.modifier_groups, itemModifierGroup.modifier_group_id);
                    if (modifierGroup === undefined) {
                        return;
                    }
                    let modifierGroupQuantityInfo = ItemHelper.getModifierGroupQuantityInfo(modifierGroup, item);
                    let modifierGroupSelectedItems = [] as any[];
                    modifierGroup.options.forEach((modiferGroupOption: any) => {
                        if (modiferGroupOption.type === "ITEM") {
                            let modiferGroupOptionItem = ItemHelper.getItem(dataMenus.items, modiferGroupOption.entity_id);
                            if (modiferGroupOptionItem == undefined) {
                                console.log("Error, item not exist: " + modiferGroupOption.entity_id);
                                return;
                            }
                            if (!isAlcohol && AgeRestrictedContext.isItemAlcohol(modiferGroupOptionItem)) isAlcohol = true;
                            if (!isEnergyDrink && AgeRestrictedContext.isItemEnergyDrink(modiferGroupOptionItem)) isEnergyDrink = true;
                            let itemQuantityInfo = ItemHelper.getItemQuantityInfo(modiferGroupOptionItem, item, modifierGroup);

                            if (itemQuantityInfo !== undefined && (itemQuantityInfo.default_quantity > 0 || itemQuantityInfo.min_permitted > 0)) {
                                let selectedItemPrice = ItemHelper.getPriceOvveride(modiferGroupOptionItem, item, modifierGroup);
                                let minQuantityRequired = Utils.zeroIfNull(itemQuantityInfo.default_quantity);
                                if (showPriceWithMin) {
                                    minQuantityRequired = minQuantityRequired > Utils.zeroIfNull(itemQuantityInfo.min_permitted) ? minQuantityRequired : Utils.zeroIfNull(itemQuantityInfo.min_permitted);
                                }
                                if (minQuantityRequired > 0) {
                                    let totalPrice = 0;
                                    let quantityFree = 0;
                                    if (itemQuantityInfo.charge_above > 0) {
                                        let quantityChargeAbove = minQuantityRequired - itemQuantityInfo.charge_above;
                                        if (quantityChargeAbove > 0) {
                                            totalPrice = selectedItemPrice * quantityChargeAbove;
                                            quantityFree = itemQuantityInfo.charge_above;
                                        } else {
                                            quantityFree = minQuantityRequired;
                                        }
                                        fullPrice += totalPrice;
                                        if (isExistOrderType(modiferGroupOptionItem.order_types, "DELIVERY")) fullPricesForOrderTypes.DELIVERY += totalPrice;
                                        if (isExistOrderType(modiferGroupOptionItem.order_types, "ROOM_SERVICE")) fullPricesForOrderTypes.ROOM_SERVICE += totalPrice;
                                        if (isExistOrderType(modiferGroupOptionItem.order_types, "DINE_IN")) fullPricesForOrderTypes.DINE_IN += totalPrice;
                                        if (isExistOrderType(modiferGroupOptionItem.order_types, "PICK_UP")) fullPricesForOrderTypes.PICK_UP += totalPrice;
                                    } else {
                                        if (selectedItemPrice !== undefined) {
                                            totalPrice = minQuantityRequired * selectedItemPrice;
                                            fullPrice += totalPrice;
                                            if (isExistOrderType(modiferGroupOptionItem.order_types, "DELIVERY")) fullPricesForOrderTypes.DELIVERY += totalPrice;
                                            if (isExistOrderType(modiferGroupOptionItem.order_types, "ROOM_SERVICE")) fullPricesForOrderTypes.ROOM_SERVICE += totalPrice;
                                            if (isExistOrderType(modiferGroupOptionItem.order_types, "DINE_IN")) fullPricesForOrderTypes.DINE_IN += totalPrice;
                                            if (isExistOrderType(modiferGroupOptionItem.order_types, "PICK_UP")) fullPricesForOrderTypes.PICK_UP += totalPrice;
                                        }
                                    }

                                    let selectedItem = {
                                        item_id: modiferGroupOptionItem.id,
                                        name: modiferGroupOptionItem.name,
                                        quantity: minQuantityRequired,
                                        total_price: totalPrice,
                                        quantity_free: quantityFree
                                    }
                                    modifierGroupSelectedItems.push(selectedItem);
                                    item.selected_items.push(selectedItem);
                                }
                            }
                        }
                    });
                    if (modifierGroupQuantityInfo !== undefined && modifierGroupQuantityInfo.charge_above > 0) {
                        let chargeAboveQuantity = modifierGroupQuantityInfo.charge_above;
                        modifierGroupSelectedItems.filter(selectedItem => selectedItem.quantity > selectedItem.quantity_free).sort((a, b) => b.price - a.price).forEach(selectedItem => {
                            if (chargeAboveQuantity <= 0) {
                                return;
                            }
                            let quantityToFreeCharge = selectedItem.quantity - selectedItem.quantity_free;
                            quantityToFreeCharge = quantityToFreeCharge > chargeAboveQuantity ? chargeAboveQuantity : quantityToFreeCharge;
                            selectedItem.quantity_free += quantityToFreeCharge;
                            let priceForOne = selectedItem.total_price / selectedItem.quantity;
                            let minusPrice = priceForOne * quantityToFreeCharge;
                            selectedItem.total_price = selectedItem.total_price - minusPrice;
                            chargeAboveQuantity = chargeAboveQuantity - quantityToFreeCharge;
                            fullPrice -= minusPrice;


                            fullPricesForOrderTypes.DELIVERY -= minusPrice;
                            fullPricesForOrderTypes.ROOM_SERVICE -= minusPrice;
                            fullPricesForOrderTypes.DINE_IN -= minusPrice;
                            fullPricesForOrderTypes.PICK_UP -= minusPrice;
                        });
                    }
                    if (showPriceWithMin && modifierGroupQuantityInfo !== undefined && modifierGroupQuantityInfo.min_permitted > 0) {
                        let selectedQuantity = modifierGroupSelectedItems.reduce((a, b) => a + b.quantity, 0);
                        let quantityForAddPrice = modifierGroupQuantityInfo.min_permitted - selectedQuantity;
                        if (modifierGroupQuantityInfo.charge_above > 0) {
                            quantityForAddPrice = modifierGroupQuantityInfo.charge_above > quantityForAddPrice ? 0 : quantityForAddPrice - modifierGroupQuantityInfo.charge_above;
                        }
                        if (quantityForAddPrice > 0) {
                            let addMinPossiblePrice = undefined as any;
                            modifierGroup.options.forEach((mdo: any) => {
                                let modiferGroupOptionItem = ItemHelper.getItem(dataMenus.items, mdo.entity_id);
                                let selectedItemPrice = ItemHelper.getPriceOvveride(modiferGroupOptionItem, item, modifierGroup);
                                if (!selectedItemPrice) selectedItemPrice = 0;

                                if (addMinPossiblePrice === undefined || addMinPossiblePrice > selectedItemPrice) {
                                    addMinPossiblePrice = selectedItemPrice;
                                }
                                if (selectedItemPrice <= 0) return;
                            })

                            if (addMinPossiblePrice !== undefined && addMinPossiblePrice > 0) {
                                minPossiblePrice += addMinPossiblePrice * quantityForAddPrice;
                            }
                        }
                    }
                });
                item.order_types_full_price = fullPricesForOrderTypes;
                item.order_types_min_price = {
                    DELIVERY: fullPricesForOrderTypes.DELIVERY + minPossiblePrice,
                    ROOM_SERVICE: fullPricesForOrderTypes.ROOM_SERVICE + minPossiblePrice,
                    PICK_UP: fullPricesForOrderTypes.PICK_UP + minPossiblePrice,
                    DINE_IN: fullPricesForOrderTypes.DINE_IN + minPossiblePrice,
                };
                minPossiblePrice += fullPrice;
                item.full_price = fullPrice;
                item.min_possible_price = minPossiblePrice;
                item.alcohol = isAlcohol;
                item.energy_drink = isEnergyDrink;
            });

            var items = getItemTranslations(locale, dataMenus.items);
            var categories = getCategoryTranslations(locale, dataMenus.categories);
            var menus = getMenuTranslations(locale, dataMenus.menus);
            var modifierGroups = getModifierGroupTranslations(locale, dataMenus.modifier_groups);
            var itemGroups = getItemGroupTranslations(locale, dataMenus.item_groups);
            var isAlcohol = items.some((x: any) => AgeRestrictedContext.isItemAlcohol(x));
            if (isAlcohol) {
                AgeRestrictedContext.setAlcohol(true);
            }

            return {
                ...state,
                items: items,
                item_groups: itemGroups,
                categories: categories,
                modifier_groups: modifierGroups,
                payment_methods: dataMenus.payment_methods,
                availabilities: dataMenus.availabilities,
                custom_fields: dataMenus.custom_fields,
                promotions: dataMenus.promotions,
                menus: menus,
                fetch_status: "SUCCESS",
                pending: false,
                initialized: true,
            }
        case MenusActionTypes.CLEAN:
            return {
                ...initialState
            }
        default:
            return state;
    }
}

export default menuReducer;
export const getItems = (state: any) => state.items;
export const getCategories = (state: any) => state.categories;
export const getItemGroups = (state: any) => state.item_groups;
export const getModifierGroups = (state: any) => state.modifier_groups;
export const getPaymentMethods = (state: any) => state.payment_methods;
export const getAvailabilities = (state: any) => state.availabilities;
export const getCustomFields = (state: any) => state.custom_fields;
export const getMenus = (state: any) => state.menus;
export const getMenuState = (state: any) => state;
export const getPromotions = (state: any) => state.promotions;