import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ConfigApi} from "../../../services/Api/types";
import {RootState} from "../../../../reducers";
import {getSession} from "../../../services/Location/reducer";
import {FormatAddressString} from "go-core/components/Formatters/FormatAddress";
import ChangeLocationMultistoreModal from "./ChangeLocationMultistoreModal";
import {ReactComponent as SearchSVG} from "../../../../images/icons/search.svg";
import {ReactComponent as EditSVG} from "../../../../images/icons/edit.svg";
import {ReactComponent as LocationSVG} from "../../../../images/icons/location.svg";
import {useLocationService} from "../../../services/Location/locationService";


interface OwnProps {
    config: ConfigApi,
    size?: string,
    hideGeolocation?: boolean,
    onSuccess?:() => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ChangeLocationButtonComponent(props: Props) {
    const [addressModal, setAddressModal] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const locationService = useLocationService();
    const {t} = useTranslation();

    const size = props.size === undefined ? "btn-lg" : props.size;

    const geolocation = async () => {
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(function (position) {
            locationService.updateCoordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
            if(props.onSuccess) props.onSuccess();
            setLoadingLocation(false);
        }, (err) => {
            console.log("geolocationwrong");
            if (err && err.message === "User denied Geolocation") {
                alert(t("constraints.internal.user_blocked_geolocation"));
            } else {
                alert(t("constraints.internal.location_fetching_failed"));
            }
            setLoadingLocation(false);
        });
    }
    const onSuccessLocation = () => {
        if(props.onSuccess) props.onSuccess();
        setAddressModal(false);
    }
    return (
        <div className={"multistore-location multistore-places-buttons"}>
            <button className={`btn btn-outline-primary btn-flex w-100${size ? ` ${size}` : ""}`}
                    onClick={() => setAddressModal(true)}>
                {(props.session.address && props.session.address.city) ? (
                    <>
                        {FormatAddressString(props.session.address)} <EditSVG/>
                    </>
                ) : (
                    <>
                        {t("common.action.select_location")} <SearchSVG/>
                    </>
                )}
            </button>
            {addressModal && (
                <ChangeLocationMultistoreModal showModal={addressModal}
                                               onHide={() => setAddressModal(false)}
                    onChange={onSuccessLocation}
                />
            )}
            {(!props.hideGeolocation) && (
                <button
                    className={`btn btn-outline-primary btn-spinner${size ? ` ${size}` : ""}${loadingLocation ? " loading" : ""}`} onClick={geolocation}>
                    <span className="left spinner-border spinner-border-sm"></span>
                    {!loadingLocation && (<LocationSVG/>)}
                </button>
            )}
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ChangeLocationButtonComponent));