import React, {FC, ReactNode, useEffect, useState} from 'react';
import {ConfigApi} from "../services/Api/types";
import CookiesStore from "../services/Common/Storage/cookiesStore";
import {useLocation, useNavigate} from "react-router-dom";
import FirewallModalComponent from "../components/Firewall/FirewallModalComponent";

interface Props {
    children?: ReactNode;
    config: ConfigApi
}


const FirewallPage: FC<Props> = (props) => {
    const [passwordProtection, setPasswordProtection] = useState(undefined);
    const [logged, setLogged] = useState(CookiesStore.get("passwordLogged"));
    const navigate = useNavigate();
    const location = useLocation();
    let firewall = props.config.firewall;

    const removeQueryParam = async (paramKey: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(paramKey);

        navigate(
            {
                pathname: location.pathname,
                search: searchParams.toString(),
            },
            {replace: true}
        );
        // navigate.replace({search: searchParams.toString()});
    }
    useEffect(() => {
        if (firewall.enabled) {
            let passwordLoggedTmp = CookiesStore.get("passwordLogged");
            if (passwordLoggedTmp) {
                setLogged(true);
                removeQueryParam("password_protection");
                CookiesStore.set("passwordLogged", true, {expires: new Date((new Date()).getTime() + 30 * 60 * 1000)});
                return;
            }
            let urlSearchParams = new URLSearchParams(location.search);
            let passwordProtection = urlSearchParams.get("password_protection");

            if (passwordProtection === firewall.password) {
                setLogged(true);
                removeQueryParam("password_protection");
                CookiesStore.set("passwordLogged", true, {expires: new Date((new Date()).getTime() + 30 * 60 * 1000)});
            }
        }
    }, []);

    if (!logged
        && firewall?.enabled
        && firewall.password != passwordProtection
    ) {
        return (
            <>
                Protection
                <FirewallModalComponent password={firewall.password} onLogged={() => {
                    setLogged(true);
                }}/>
            </>
        )
    }

    return (
        <>
            {props.children}
        </>
    )
};

export default FirewallPage;
