import {useDispatch} from "react-redux";
import {changeLocale} from "../languageUtils";
import {useStoreService} from "../Store/storeService";
import {useMenuService} from "../Menu/menuService";
import {updateLocale} from "../Location/actions";

interface LanguageService {
    change: (locale: string) => Promise<void>;
}

export const useLanguageService = (): LanguageService => {
    const dispatch = useDispatch();
    const storeService = useStoreService();
    const menuService = useMenuService();

    const change = async (locale: string) => {
        await changeLocale(locale);
        await storeService.refresh(locale);
        await menuService.fetch(locale);
        dispatch(updateLocale(locale));
    }

    return {
        change
    };
};
