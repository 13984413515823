import {useLanguageService} from "../../services/Language/languageService";
import {getLangDisplayName, getPossibleLanguages} from "../../services/languageUtils";
import {Collapse} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import React, {useState} from "react";
import {ReactComponent as CheckIco} from "../../../images/icons/check.svg";
import {ReactComponent as ArrowBottomIco} from "../../../images/icons/arrow-bottom.svg";

interface Props {
    locale: string;
    languages: string[];
    onHideDrawer: () => void;
}

const DrawerLanguages = ({ languages, locale, onHideDrawer }: Props) => {
    const languageService = useLanguageService();
    const possibleLanguages = getPossibleLanguages(languages);
    const [expand, setExpand] = useState(false);
    const {t} = useTranslation();
    const [loadingIndex, setLoadingIndex] = useState(-1);

    if (possibleLanguages.length === 0) return null;

    const changeLocaleInternal = async (newLocale: string, index: number) => {
        if(newLocale !== locale) {
            setLoadingIndex(index);
            await languageService.change(newLocale);
            setLoadingIndex(-1);
            onHideDrawer();
        }
    }

    return (
        <ul className="navbar-nav justify-content-end flex-grow-1">
            <h4>{t('common.word.language')}</h4>
            <li className={"nav-item"}>
                <div className="nav-link"
                     aria-expanded={expand}
                     aria-controls="collapse_drawer_languages"
                     onClick={() => setExpand((prev) => !prev)}
                >
                    <div className={"nav-link-multiline"}>
                        {getLangDisplayName(locale)}
                    </div>
                    <ArrowBottomIco/>
                </div>
                <Collapse in={expand} className='drawer-languages'>
                    <div id="collapse_drawer_languages">
                        <ul className="list-unstyled">
                            {
                                possibleLanguages.map((language, index) =>(
                                    <li key={language} onClick={() => changeLocaleInternal(language, index)}>
                                        <p className='mb-0'>
                                            {getLangDisplayName(language)}
                                            {loadingIndex === index && <span className="left spinner-border spinner-border-sm ms-2"/>}
                                        </p>
                                        {language === locale && <CheckIco/>}
                                    </li>
                                    )
                                )
                            }
                        </ul>
                    </div>
                </Collapse>
            </li>
        </ul>
    )
}

export default withTranslation()(DrawerLanguages)
