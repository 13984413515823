import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {ConfigApi} from "../../services/Api/types";
import {DateHelper, getWeekDay} from "../../services/Common/Date/DateHelper";
import AvailableHelper from "../../services/Menu/services/Availability/AvailableHelper";
import DateService from "../../services/Common/Date/DateService";
import ErrorFormAlertView from "../Common/ErrorFormAlertView";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import ErrorFormView from "../Common/ErrorFormView";
import {getSession} from "../../services/Location/reducer";
import StringHelper from "../../services/Common/StringHelper";
import IframeWidget from "../../utils/IframeWidget";

interface OwnProps {
    showModal: boolean,
    orderType: any,
    config: ConfigApi,
    dates: any,
    weekDays: any,
    availabilities: any,
    button_clear_title: any,
    button_save_title: any,
    title: any,
    onHide: () => void
    onSave: (date: any) => void,
    value: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ChangeDateModal(props: Props) {
    const [errors, setErrors] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const {t} = useTranslation();
    const {title, dates, weekDays, button_clear_title, button_save_title} = props;


    const handleChangeDate = (event: any) => {
        let newTime = time;
        let date = DateHelper.fromString(event.target.value + " " + newTime + ":00")
        if (props.availabilities !== undefined && !AvailableHelper.isAvailabile(props.availabilities, date)) {
            newTime = "";
        }
        setDate(event.target.value);
        setTime(newTime);
    }

    const handleChangeTime = (event: any) => {
        setTime(event.target.value);
    }

    const clearDate = async () => {
        setLoading(true);
        setErrors([]);
        setTime("");
        setDate("");
        await save(undefined);
    }


    const saveHandle = async () => {
        let errors = [];

        if (!date) {
            errors.push({"field": "date", "message": t("constraints.internal.date_must_be_not_empty"), "code": "date_must_be_not_empty"})
        }
        if (!time) {
            errors.push({"field": "time", "message": t("constraints.internal.time_must_be_not_empty"), "code": "time_must_be_not_empty"})
        }else{
            var today = DateService.now();
            let minTodayHour = 0;
            let minTodayMinute = 0;

            if (date === "" || date === DateHelper.formatOnlyDate(today)) {
                let todayWithMinStoreMinutes = props.session ? AvailableHelper.getAddMinutesToPickDateFromNow(props.orderType, props.session.estimated_preparation_duration, props.session.estimated_delivery_duration) : today;
                minTodayHour = todayWithMinStoreMinutes.getHours();
                minTodayMinute = todayWithMinStoreMinutes.getMinutes();
                if(DateHelper.formatOnlyDate(todayWithMinStoreMinutes) != DateHelper.formatOnlyDate(today)){
                    minTodayHour = 24;
                    minTodayMinute = 0;
                }
                let hourSplit = Number(time.split(":")[0]);
                let minuteSplit = Number(time.split(":")[1]);
                if(minTodayHour > hourSplit  || (hourSplit == minTodayHour && minTodayMinute > minuteSplit)){
                    errors.push({"field": "time", "message": t("constraints.internal.time_must_be_not_empty"), "code": "time_must_be_not_empty"})
                }
            }
        }
        if (errors.length > 0) {
            setErrors(errors);
            setLoading(false);
            return;
        }
        await save(DateHelper.fromString(date + " " + time + ":00"));
    }


    const save = async (date: any) => {
        setErrors([]);
        setLoading(true);
        try {

            const response = await props.onSave(date);
            setLoading(false);
            IframeWidget.onHide(props.onHide)
        } catch (error: any) {
            setErrors(error.errors);
            setLoading(false);
        }

    }

    const onShow = () => {
        let states = {} as any;
        if (props.value) {
            let date = props.value;
            if (!(date instanceof Date)) {
                date = DateHelper.fromString(props.value);
            }


            if (props.availabilities === undefined || AvailableHelper.isAvailabile(props.availabilities, date)) {
                states.time = getDefaultTime(date);
                states.date = DateHelper.formatOnlyDate(date);
            }
        }
        setDate(states.date ? states.date : "");
        setTime(states.time ? states.time : "");
    }


    const getTime = (i: any) => {
        return (i >= 24) ? "00" : (i < 10) ? "0" + i : i;
    }

    const getDateFormat = (today: any, i: any) => {
        let weekDayName = i === 0 ? t("common.word.today") : i === 1 ? t("common.word.tomorrow") : getWeekDay(today, props.session.locale);
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        return weekDayName + ", " + dd + '.' + mm;
    }


    const isValidDate = (dates: any, date: any, weekDays: any) => {
        if (weekDays) {
            var weekdayNumber = date.getDay();
            let weekDayNumbers = weekDays.map((weekDay: any) => StringHelper.getDayOfWeekNumber(weekDay));
            if (!weekDayNumbers.includes(weekdayNumber)) return false;
        }

        if (!dates) {
            return true;
        }
        return dates.some((x: any) => {
            return date >= x.date_from && date <= x.date_to;
        })
    }

    const buildDateOptions = (dates: any, weekDays: any) => {

        var today = DateService.now();
        var arr = [];
        let min = 0;
        let max = 61;
        arr.push(<option key={-1} value={""}>{t("common.action.pick_date")}</option>)

        for (let i = min; i <= max; i++) {
            if (i > 0) {
                today.setDate(today.getDate() + 1);
            }
            if (!isValidDate(dates, today, weekDays)) {
                continue;
            }
            let iValue = DateHelper.formatOnlyDate(today);
            arr.push(<option key={i} value={iValue}>{getDateFormat(today, i)}</option>)
        }
        return arr;
    }


    const isMinuteAvailable = (i: any, minHours: any, maxHours: any, minMinutes: any, maxMinutes: any, time: any) => {
        if (i !== minHours && i !== maxHours) {
            return true;
        }
        if (i === minHours && minMinutes > time) {
            return false;
        }
        if (i === maxHours && maxMinutes < time) {
            return false;
        }
        return true;
    }
    const buildOptions = () => {
        var today = DateService.now();
        let minTodayHour = 0;
        let minTodayMinute = 0;

        if (date === "" || date === DateHelper.formatOnlyDate(today)) {
            let todayWithMinStoreMinutes = props.session ? AvailableHelper.getAddMinutesToPickDateFromNow(props.orderType, props.session.estimated_preparation_duration, props.session.estimated_delivery_duration) : today;
            minTodayHour = todayWithMinStoreMinutes.getHours();
            minTodayMinute = todayWithMinStoreMinutes.getMinutes();
            if(DateHelper.formatOnlyDate(todayWithMinStoreMinutes) != DateHelper.formatOnlyDate(today)){
                minTodayHour = 24;
                minTodayMinute = 0;
            }
        }

        var arr = [];
        arr.push(<option key={-1} value={""}>{t("common.action.pick_time")}</option>)
        if (!date) {
            return arr;
        }
        let max = 23;
        let hours = AvailableHelper.getHoursAvailable(props.availabilities, DateHelper.fromString(date + " 00:00:00"));
        if (!hours || hours.length <= 0) return arr;
        var minTime = hours && hours.length > 0 ? hours.reduce((a, b) => a.hour_from > b.hour_from ? a : b).hour_from : undefined;
        var maxTime = hours && hours.length > 0 ? hours.reduce((a, b) => a.hour_to > b.hour_to ? a : b).hour_to : undefined;
        var minHours = minTime ? parseInt(minTime.split(":")[0]) : 0;
        var maxHours = maxTime ? parseInt(maxTime.split(":")[0]) : 23;
        var minMinutes = minTime ? parseInt(minTime.split(":")[1]) : 0;
        var maxMinutes = maxTime ? parseInt(maxTime.split(":")[1]) : 59;
        if (maxHours <= 0) {
            maxHours = 23;
        }
        if (maxMinutes === 0) {
            maxMinutes = 59;
            maxHours = maxHours - 1;
        }
        let minHourWithEstimate = AvailableHelper.getAddMinutesToPickDate(props.orderType, props.session.estimated_preparation_duration, props.session.estimated_delivery_duration);
        if (minHourWithEstimate > 0) {
            minMinutes = minMinutes + minHourWithEstimate;
            if (minMinutes >= 60) {
                const extraHours = Math.floor(minMinutes / 60);
                minHours += extraHours;
                minMinutes %= 60; // Keep the remaining minutes less than 60
            }
            let maxHourWithEstimate = minHourWithEstimate > 15 ? minHourWithEstimate - 15 : 0;
            maxMinutes = maxMinutes - maxHourWithEstimate;
            if (maxMinutes <= 0) {
                const extraHours = Math.floor(maxMinutes / 60);
                maxHours -= extraHours;
                maxMinutes %= 60; // Keep the remaining minutes less than 60
            }
        }

        if (minHours < minTodayHour || (minHours === minTodayHour && minMinutes < minTodayMinute)) {
            minHours = minTodayHour;
            minMinutes = minTodayMinute;
        }
        maxHours = maxHours <= max ? maxHours : max;
        for (let i = minHours; i <= maxHours; i++) {
            let iS = getTime(i);
            if (isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 0)) {
                let iValue1 = iS + ":" + "00";
                let i1 = iValue1;
                arr.push(<option key={iValue1} value={iValue1}>{i1}</option>)
            }
            if (isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 15)) {
                let iValue2 = iS + ":" + "15";
                let i2 = iValue2;
                arr.push(<option key={iValue2} value={iValue2}>{i2}</option>)
            }
            if (isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 30)) {
                let iValue3 = iS + ":" + "30";
                let i3 = iValue3;
                arr.push(<option key={iValue3} value={iValue3}>{i3}</option>)
            }
            if (isMinuteAvailable(i, minHours, maxHours, minMinutes, maxMinutes, 45)) {
                let iValue4 = iS + ":" + "45";
                let i4 = iValue4;
                arr.push(<option key={iValue4} value={iValue4}>{i4}</option>)
            }
        }

        return arr;
    }
    const getDefaultTime = (today: any) => {
        // var hours = today.getUTCHours();
        var hours = today.getHours();
        // hours = hours + 1;
        // var minutes = today.getUTCMinutes();
        var minutes = today.getMinutes();
        if (minutes > 0) {
            if (minutes <= 15) {
                minutes = 15;
            } else if (minutes <= 30) {
                minutes = 30;
            } else if (minutes <= 45) {
                minutes = 45;
            } else if (minutes <= 60) {
                minutes = 15;
                hours = hours + 1;
            }
        } else {
            minutes = "00";
        }
        return getTime(hours) + ":" + minutes;
    }
    return (

        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.showModal} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {title ? title : t("modules.date_modal.header.title")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    {props.config.ordering.pick_date ? (
                        <>
                            <div>
                                <ErrorFormAlertView errors={errors}/>
                                <div className="form-group">
                                    <select value={date}
                                            className={"form-control" + ErrorFormHelper(errors, "date")}
                                            name="date_modal_form_date" onChange={(e) => handleChangeDate(e)}>
                                        {buildDateOptions(dates, weekDays)}
                                    </select>
                                    <label
                                        htmlFor="date_modal_form_date">{t("modules.date_modal.field.date.title")}</label>
                                    <ErrorFormView errors={errors} field="date"/>
                                </div>
                                <div className="form-group">
                                    <select value={time}
                                            className={"form-control" + ErrorFormHelper(errors, "time")}
                                            name="date_modal_form_time" onChange={(e) => handleChangeTime(e)}>
                                        {buildOptions()}
                                    </select>
                                    <label
                                        htmlFor="date_modal_form_time">{t("modules.date_modal.field.time.title")}</label>
                                    <ErrorFormView errors={errors} field="time"/>
                                </div>
                            </div>
                            <button
                                className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                                onClick={() => {
                                    saveHandle()
                                }}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {button_save_title ? button_save_title : t("common.action.save")}
                            </button>
                            <button
                                className={"btn-spinner btn-block btn-default btn btn-submit" + (loading ? ' loading' : '')}
                                onClick={() => {
                                    clearDate()
                                }}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {button_clear_title ? button_clear_title : t("common.action.set_as_soon_as_possible")}
                            </button>
                        </>
                    ) : (
                        <>
                            <ErrorFormAlertView errors={errors}/>
                            
                            <button
                                className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                                onClick={() => {
                                    clearDate()
                                }}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {button_clear_title ? button_clear_title : t("common.action.set_as_soon_as_possible")}
                            </button>
                        </>
                    )}

                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ChangeDateModal));
