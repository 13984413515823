import React, {ReactNode} from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {ReactComponent as LeftIco} from "../../../../../images/icons/arrow-left.svg";
import {RootState} from "../../../../../reducers";


interface OwnProps {
    back: any,
    onHide: () => void,
    title: any,
    children?: ReactNode
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuShowItemModalComponent = (props:Props) => {
    let back = props.back;
    let onHide = props.onHide;
    let title = props.title;

    return (
        <>
            {back ? (
                <Modal.Header className={"modal-header-back"}>
                    <a className={"back-icon text-primary"} href={"#"} onClick={() => onHide()}>
                        <LeftIco className="icon"/>
                    </a>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
            ) : (
                <Modal.Header>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                    <button onClick={onHide} className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </Modal.Header>
            )}
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </>
    )

}

const mapStateToProps = (state: RootState) => ({
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuShowItemModalComponent));