import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import {ConfigApi} from "../../../../services/Api/types";
import {getStoreConfigLoyalty} from "../../../../services/Store/reducer";
import Footer from "../../../../components/layout/Footer/Footer";
import AdvertUserComponent from "../../../../components/User/components/AdvertUser";
import UserUtils from "../../../../services/User/UserUtils";
import {getUser} from "../../../../services/User/reducer";
import Hero from "../../../HomePage/components/Hero/Hero";
import LoadingContainer from "../../../../components/Common/LoadingContainer";
import NavbarMulti from "../../../../components/Navbar/NavbarMulti";
import {ReactComponent as MapSVG} from "../../../../../images/icons/map.svg";
import {ReactComponent as ListSVG} from "../../../../../images/icons/list.svg";
import {getSession} from "../../../../services/Location/reducer";
import PlaceItemMultistoreComponent from "../../components/PlaceItemMultistoreComponent";
import {Link, useNavigate} from "react-router-dom";
import ChangeLocationButtonComponent from "../../components/ChangeLocationButtonComponent";
import FetchPlaces from "../../../../components/Fetch/FetchPlaces";
import {usePlaceContext} from "../../../../services/Place/PlaceContext";
import MultistorePlaceFilterCitiesComponent from "../components/MultistorePlaceFilterCitiesComponent";

interface OwnProps {
    config: ConfigApi
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreHomePage = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [filterCity, setFilterCity] = useState<string | undefined>(undefined);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {places} = usePlaceContext();

    let filterPlaces = places;
    if (filterCity) filterPlaces = filterPlaces?.filter(x => x.address.city == filterCity);

    const onSuccessLocation = () => {
        navigate("/map")
    }
    return (
        <>
            <NavbarMulti config={props.config}/>
            <Hero config={props.config}/>

            <div className={"multistore-places"}>
                <div className={"container-fluid"}>
                    <FetchPlaces>
                        <>
                            <ChangeLocationButtonComponent config={props.config} onSuccess={onSuccessLocation}/>
                            {loading ? (
                                <LoadingContainer/>
                            ) : (
                                <>
                                    <div className={"multistore-places-content"}>
                                        <MultistorePlaceFilterCitiesComponent places={places} city={filterCity}
                                                                              onChange={(value: string) => setFilterCity(value)}/>
                                        {filterPlaces ? (
                                            <>
                                                {filterPlaces.slice(0, 20).map(place => {
                                                    return (
                                                        <React.Fragment key={place.domain}>
                                                            <PlaceItemMultistoreComponent key={place.domain}
                                                                                          place={place}/>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {t("modules.multistore.field.no_restaurants.title")}
                                            </>
                                        )}
                                    </div>
                                    <div className={"multistore-places-buttons"}>
                                        <Link to={"list"} className={"btn btn-default btn-flex w-100 btn-lg"}>
                                            <>
                                                {t("modules.multistore.action.show_all.title")} <ListSVG/>
                                            </>
                                        </Link>
                                        <Link to={"map"} className={"btn btn-default btn-lg"}>
                                            <MapSVG/>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </>
                    </FetchPlaces>
                </div>
            </div>
            {(UserUtils.isLoyaltyRegistration(props.config_loyalty) && (!props.user || !props.user.name)) && (
                <div className={"homepage-advert-user"}>
                    <AdvertUserComponent/>
                </div>
            )}
            <Footer config={props.config}/>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config_loyalty: getStoreConfigLoyalty(state.config),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);

export default connector(MultistoreHomePage);