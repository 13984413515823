import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {useTranslation, withTranslation} from "react-i18next";
import Money from "../Common/Money";
import {getCart} from "../../services/Cart/reducer";


interface OwnProps {
    goToCart: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartButtonComponent = (props: Props) => {
    const cart = props.cart;
    const {t} = useTranslation();

    let disabled = cart.pending || cart.id == undefined;
    let loading = cart.pending;
    return (
        <button onClick={props.goToCart}
                className={"btn-spinner btn btn-primary btn-submit" + (loading ? ' loading' : '')} disabled={disabled}>
            <span className="left spinner-border spinner-border-sm"></span>
            <div className="left btn-submit-quantity">
                {cart.items.length}
            </div>
            {t("common.action.show_order")}
            <div className="right btn-cart-price">
                <Money value={cart.total}/>
            </div>
        </button>
    )
}

const mapStateToProps = (state: RootState) => ({
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartButtonComponent));