import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreDefaultConfig} from "../../services/Store/reducer";
import {Collapse, Offcanvas} from "react-bootstrap";
import {ConfigApi, ConfigLayoutMenuApi} from "../../services/Api/types";
import {Link} from "react-router-dom";
import {ReactComponent as ArrowRightIco} from "../../../images/icons/arrow-right.svg";
import {ReactComponent as ArrowBottomIco} from "../../../images/icons/arrow-bottom.svg";
import {ReactComponent as GiftIco} from "../../../images/icons/gift-solid.svg";
import FooterSocialMedia from "../layout/Footer/FooterSocialMedia";
import StringHelper from "../../services/Common/StringHelper";
import {ReactComponent as ShareIco} from "../../../images/icons/share.svg";
import HoursHelper from "../../services/Menu/services/Availability/HoursHelper";
import DateService from "../../services/Common/Date/DateService";
import {getDayOfWeek, isTimeAllDay} from "../../services/Common/Date/DateHelper";
import {getUser} from "../../services/User/reducer";
import LoginUserModal from "../User/LoginUserModal";
import {selectLocale} from "../../services/Location/reducer";
import UserUtils from "../../services/User/UserUtils";
import AppConfig from "../../services/AppConfig";
import StoreUtils from "../../services/Store/StoreUtils";
import UserModal from "../User/UserModal";
import DrawerLanguages from "./DrawerLanguages";
import RoundedIcon from "../Common/RoundedIcon";

interface OwnProps {
    show: boolean,
    onHide: () => void,
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function Drawer(props: Props) {
    const [collapseOpenHours, setCollapseOpenHours] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [registrationModal, setRegistrationModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const {t} = useTranslation();

    const menuLinks = props.config?.layout.links ? [...props.config?.layout.links].filter(x => x.type === "MENU").sort((a, b) => a.position - b.position || a.title.localeCompare(b.title)) : undefined;

    let renderStoreLinkView = function (storeLink: ConfigLayoutMenuApi) {
        if (storeLink.link.includes("http") || storeLink.link.includes("www")) {
            return (
                <a className="nav-link" href={storeLink.link}>
                    <span>{storeLink.title}</span>
                    <ArrowRightIco/>
                </a>
            )
        }
        return (
            <Link className="nav-link" to={`${storeLink.link}`}>
                <span>{storeLink.title}</span>
                <ArrowRightIco/>
            </Link>
        )
    }

    let addressFullString = props.config ? StringHelper.getAddressName(props.config.address) : undefined;

    let openHours = props.config ? HoursHelper.getOpenHours(props.config.hours, DateService.now()) : undefined;
    const renderOpenHoursItem = (openHour: any) => {
        let openDayString = getDayOfWeek(openHour.day_from, props.locale);

        if (openHour.day_from !== openHour.day_to) {
            openDayString += " - " + getDayOfWeek(openHour.day_to, props.locale);
        }
        return (
            <>
                <strong>{openDayString}: </strong>
                {isTimeAllDay(openHour.hour_from) && isTimeAllDay(openHour.hour_to) ? "Cały dzień" : `${openHour.hour_from} - ${openHour.hour_to}`}
            </>
        )
    }
    return (
        <Offcanvas placement={"end"} show={props.show} onHide={props.onHide} className='drawer'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {props.config?.layout.image_link ? (
                        <>
                            <img alt="Logo location" src={props.config?.layout.image_link.default}/>
                        </>
                    ) : (
                        <>
                            {props.config?.name}
                        </>
                    )}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {(AppConfig.isMultistore() && !StoreUtils.isMultistore(props.config)) && (
                    <>

                        <ul className="navbar-nav justify-content-end flex-grow-1">
                            <li className={"nav-item"}>
                                <Link className="nav-link" to={`/`}>
                                    <div className={"nav-link-multiline"}>
                                        <small>{addressFullString}</small>
                                        {t("modules.navbar.action.change_restaurant.title")}
                                    </div>
                                    <ArrowRightIco/>
                                </Link>
                            </li>
                        </ul>

                    </>
                )}
                {UserUtils.isLoyaltyRegistration(props.default_config?.loyalty) && (
                    <>
                        {loginModal && (
                            <LoginUserModal onHide={() => setLoginModal(false)} show={true}/>
                        )}
                        {registrationModal && (
                            <LoginUserModal page={"REGISTER"} onHide={() => setRegistrationModal(false)}
                                            show={true}/>
                        )}
                        {userModal && (
                            <UserModal onHide={() => setUserModal(false)} show={true}/>
                        )}
                        <ul className="navbar-nav justify-content-end flex-grow-1">
                            <h4>{t("modules.navbar.field.account.title")}</h4>
                            {(props.user && props.user.name) ? (
                                <li className={"nav-item"}>
                                    <button onClick={() => setUserModal(true)} className="nav-link">
                                        <span>{t("modules.navbar.field.your_account.title")}</span>
                                        <ArrowRightIco/>
                                    </button>
                                </li>
                            ) : (
                                <>
                                    <li className={"nav-item"}>
                                        <button className="nav-link" onClick={() => setLoginModal(true)}>
                                            <span>{t("common.action.login")}</span>
                                            <ArrowRightIco/>
                                        </button>
                                    </li>
                                    <li className={"nav-item"}>
                                        <button className="nav-link register-btn" onClick={() => setRegistrationModal(true)}>
                                            <RoundedIcon icon={<GiftIco/>} size="medium" className="me-3"/>
                                            <div className={"nav-link-multiline"}>
                                                {t("common.action.create_an_account")}
                                                <small>
                                                    {props.default_config?.loyalty?.registration?.banner_text ? props.default_config?.loyalty?.registration?.banner_text : t("modules.navbar.field.registration_banner.title")}
                                                </small>
                                            </div>
                                            <ArrowRightIco/>
                                        </button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </>
                )}

                {(!StoreUtils.isMultistore(props.config)) && (
                    <ul className="navbar-nav justify-content-end flex-grow-1">
                        <h4>{t("modules.navbar.field.restaurant.title")}</h4>
                        {addressFullString && (
                            <li className={"nav-item"}>
                                <a className="nav-link" href={`https://maps.google.com/?q=${addressFullString}`}>
                                    <div className={"nav-link-multiline"}>
                                        <small>{t("common.word.address")}</small>
                                        {addressFullString}
                                    </div>
                                    <ShareIco/>
                                </a>
                            </li>
                        )}
                        {props.config?.contact.phone && (
                            <li className={"nav-item"}>
                                <a className="nav-link" href={`tel:${props.config?.contact.phone}`}>
                                    <div className={"nav-link-multiline"}>
                                        <small>{t("common.word.phone_number")}</small>
                                        {props.config?.contact.phone}
                                    </div>
                                    <ShareIco/>
                                </a>
                            </li>
                        )}
                        {props.config?.contact.email && (
                            <li className={"nav-item"}>
                                <a className="nav-link" href={`tel:${props.config?.contact.email}`}>
                                    <div className={"nav-link-multiline"}>
                                        <small>{t("common.word.email")}</small>
                                        {props.config?.contact.email}
                                    </div>
                                    <ShareIco/>
                                </a>
                            </li>
                        )}
                        {(openHours && openHours.length > 0) && (
                            <li className={"nav-item"}>
                                <div className="nav-link"
                                     aria-expanded={collapseOpenHours}
                                     aria-controls="collapse_footer_hours"
                                     onClick={() => setCollapseOpenHours(!collapseOpenHours)}
                                >
                                    <div className={"nav-link-multiline"}>
                                        <small>{t("modules.navbar.field.opening_hours.title")}</small>
                                        {
                                            openHours.map((openHour: any, index: any) =>
                                                <span
                                                    key={index + "-" + openHour.hour_from + "-" + openHour.hour_to}>{openHour.hour_from} - {openHour.hour_to}</span>
                                            )
                                        }
                                    </div>
                                    <ArrowBottomIco/>
                                </div>
                                <Collapse in={collapseOpenHours}>
                                    <div id="collapse_footer_hours" className={"open-hours"}>
                                        <ul className="list-unstyled">
                                            {
                                                HoursHelper.sortOpenHours(props.config?.hours).map((openHour: any) => {

                                                        return (
                                                            <li key={"-" + openHour.day_from + "-" + openHour.day_to + "-" + openHour.hour_from + "-" + openHour.hour_to}>
                                                                {renderOpenHoursItem(openHour)}
                                                            </li>
                                                        )
                                                    }
                                                )
                                            }
                                        </ul>
                                    </div>
                                </Collapse>
                            </li>
                        )}
                    </ul>
                )}
                {(menuLinks && menuLinks.length > 0) && (
                    <ul className="navbar-nav justify-content-end flex-grow-1">
                        <h4>{t("modules.navbar.field.information.title")}</h4>
                        {
                            menuLinks.map((storeLink) =>
                                <li className="nav-item"
                                    key={`${storeLink.title}-${storeLink.link}-${storeLink.type}`}>
                                    {renderStoreLinkView(storeLink)}
                                </li>
                            )
                        }
                    </ul>
                )}
                <DrawerLanguages languages={props.config.languages} locale={props.locale} onHideDrawer={props.onHide}/>
                <FooterSocialMedia/>
            </Offcanvas.Body>
        </Offcanvas>
    )
}


const mapStateToProps = (state: RootState) => ({
    locale: selectLocale(state.session),
    user: getUser(state.user),
    default_config:getStoreDefaultConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(Drawer));