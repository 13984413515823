import {UserActionTypes, UserState} from "./types";
import { Reducer } from "redux";
import UserTokenContext from "./UserTokenContext";
import {UserActions} from "./actions";


const initialState : UserState = {
    pending: !!UserTokenContext.getToken(),
    fetch_status: UserTokenContext.getToken() ? "NEW" : "SUCCESS"
}

export const userReducer: Reducer<UserState, UserActions> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN: {
            return { ...state, ...action.payload, fetch_status: "SUCCESS", pending: false };
        }
        case UserActionTypes.UPDATE: {
            const data = {
                name: action.payload.name,
                phone_number: action.payload.phone_number
            };
            return { ...state, ...data };
        }
        case UserActionTypes.LOGOUT: {
            return { ...initialState, fetch_status: "SUCCESS", pending: false  };
        }
        case UserActionTypes.VOUCHERS: {
            let vouchers = action.vouchers;
            return {
                ...state,
                vouchers: vouchers
            }
        }
        case UserActionTypes.UPDATE_QRCODE:
            let qrcode = action.qrcode;
            return {
                ...state,
                qrcode: qrcode
            }
        default: {
            return state;
        }
    }
};

export default userReducer;
export const getUser = (state:UserState) => state;
export const getVouchers = (state:UserState) => state.vouchers;
