import React, {useCallback, useEffect, useRef, useState} from 'react';

import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {unstable_batchedUpdates} from "react-dom";
import {GoogleApiWrapper, IProvidedProps} from "google-maps-react-17";
import {ConfigApi} from "../../services/Api/types";
import PoweredByGoogle from '../../../images/logo/powered_by_google_on_white.png';
import {AsyncTypeahead, Menu, MenuItem} from "react-bootstrap-typeahead";
import {RootState} from "../../../reducers";
import ChangeLocationModal from "../Modal/ChangeLocationModal";
import StringHelper from "../../services/Common/StringHelper";
import Utils from "../../utils/Utils";

interface OwnProps {
    address: any,
    modalAddress?: boolean,
    config: ConfigApi,
    onChange: (value: any) => void
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & IProvidedProps;

const parseAddressToArray = (address: any) => {
    if (!address.description) {
        address.description = "dsa";
    }
    return [address];
}

const LocationSearch = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [addressModal, setAddressModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(parseAddressToArray(props.address));
    const {t} = useTranslation();
    const myRef = useRef<any>(null)
    useEffect(() => {
        if (props.address) {
            setSelectedValue([{...props.address, description: StringHelper.getAddressName(props.address)}])
        } else {
            setSelectedValue([])
        }
    }, [props.address, props.address?.description, props.address?.city, props.address?.street])
    let autocompleteService = new props.google.maps.places.AutocompleteService();


    const _renderMenu = (results: any, menuProps: any, state: any) => {
        let index = 0;
        // const regions = groupBy(results, 'region');
        const items = results.map((option: any, index: any) => (
            <MenuItem key={index} option={option} position={index}>
                {option.description}
            </MenuItem>
        ));

        return <Menu {...menuProps}>
            {items && items.length > 0 ? items : (
                <div className={"dropdown-item"}> {t("modules.search.field.search.placeholder")}</div>
            )}
            <div className="dropdown-divider"></div>
            <div className={"dropdown-footer"}>
                {props.modalAddress && (
                    <button type="button" className="dropdown-item" onClick={() => setAddressModal(true)}>
                        {t("modules.search.field.search.action.address_manual")}
                    </button>
                )}
                <div className="powered-by-google">
                    <img src={PoweredByGoogle}/>
                </div>
            </div>
        </Menu>;
    }
    const geolocate = () => {
    }
    const searchOnBlur = () => {
        // if(props.location.address !== null){
        //     setSelectedValue(props.location.address.fullAddres);
        //     // props.dispatch(updateSelectedValue(props.location.address.fullAddres));
        // }
    }
    const filterByCallback = (option: any, props: any) => {
        return true;
    }
    const handleSearch = useCallback((query: any) => {
        const {google} = props;
        if (query.length <= 1) {
            return;
        }
        setLoading(true);

        let codeCountries = ["pl"];
        if (props.config.ordering.countries) {
            codeCountries = Utils.countries(props.config.ordering.countries).map((x: any) => x.key);
        }
        let requestQuery = {
            input: query,
            types: ['geocode'],
            "componentRestrictions": {"country": codeCountries},
            'fields': ["address_component"]
        } as any;
        let coordinates = props.config.coordinates;
        if (coordinates && coordinates.latitude) {
            requestQuery.location = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);
            requestQuery.radius = 5000;
        }

        autocompleteService.getPlacePredictions(requestQuery, (predictions: any, status: any) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                unstable_batchedUpdates(() => {
                    setLoading(false);
                    setOptions([]);
                })
                return;
            }
            unstable_batchedUpdates(() => {
                setLoading(false);
                setOptions(predictions);
            })
        });
    }, []);
    const handleSearchOnChange = (selected: any) => {
        let value = myRef.current.getInput().value;
        if (!value) {
            props.onChange(undefined);
            return
        }
        if (selected === undefined || selected.length <= 0) {
            return;
        }
        let address = parseAddress(selected[0]);
        props.onChange(address);
    }

    const handleSearchValue = (newSelected: any) => {
        setSelectedValue([
            {
                ...selectedValue[0],
                "description": newSelected
            }
        ]);
        // props.dispatch(updateSelectedValue(newSelected));
    }

    const parseAddress = (selected: any) => {
        let description = selected.description;
        let descriptionSplit = description.split(",");
        let address = {
            street: '',
            city: '',
            country: ''
        } as any;
        if (descriptionSplit.length === 3) {
            let street = descriptionSplit[0];
            parseStreet(address, street);
            parseCity(address, descriptionSplit[1]);
            address.country = parseCountry(descriptionSplit[2]);
        }
        if (descriptionSplit.length == 2) {
            parseCity(address, descriptionSplit[0]);
            address.country = parseCountry(descriptionSplit[1]);
        }
        if (descriptionSplit.length === 1) {
            address.country = parseCountry(descriptionSplit[0]);
        }
        address.description = description;
        address.fullAddres = description;
        address.place_id = selected.place_id;
        return address;
    }
    const parseCountry = (text: string) => {
        return text ? text.trim() : text;
    }
    const parseStreet = (address: any, text: any) => {
        let textSplit = text.split(" ");
        let build_nr = textSplit[textSplit.length - 1];
        if (!isNaN(build_nr)) {
            text = textSplit.slice(0, textSplit.length - 1).join(" ");
            address.build_nr = build_nr;
        } else {
            let lastCharStreet = build_nr.slice(-1);
            if (isNaN(lastCharStreet)) {
                let buildNrToTest = build_nr.substring(0, build_nr.length - 1);
                if (!isNaN(buildNrToTest)) {
                    text = textSplit.slice(0, textSplit.length - 1).join(" ");
                    address.build_nr = build_nr;
                }
            }
        }
        address.street = text ? text.trim() : text;
    }
    const parseCity = (address: any, text: any) => {
        let textSplit = text.trimStart().split(" ");
        let zip_code = textSplit[0];
        let zip_codeSplit = zip_code.split("-");
        if (zip_codeSplit.length === 2 && !isNaN(zip_codeSplit[0]) && !isNaN(zip_codeSplit[1])) {
            text = textSplit.slice(1, textSplit.length).join(" ");
            address.zip_code = zip_code;
        }
        address.city = text ? text.trim() : text;
    }
    return (
        <div className="input-group search-input search-input-basic">
            <AsyncTypeahead
                isLoading={loading}
                options={options}
                clearButton
                renderMenu={_renderMenu}
                id="location-cart-search"
                filterBy={filterByCallback}
                onFocus={geolocate}
                onBlur={searchOnBlur}
                labelKey="description"
                useCache={true}
                delay={500}
                minLength={0}
                selected={selectedValue}
                // selected={[selected]}
                onSearch={handleSearch}
                onChange={handleSearchOnChange}
                onInputChange={handleSearchValue}
                placeholder={t("modules.search.field.enter_delivery_address.title")}
                ref={myRef}
                renderMenuItemChildren={(option: any, props: any) => (
                    <div>
                        {option.description}
                    </div>
                )}
            />
            <ChangeLocationModal showModal={addressModal} onHide={() => setAddressModal(false)}/>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({})
export default withTranslation()(connect(mapStateToProps)(GoogleApiWrapper({apiKey: (process.env.REACT_APP_API_GOOGLE_MAPS as any)})(LocationSearch)))