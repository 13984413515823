import React, {FC, useEffect, useState} from 'react';
import {RootState} from "../../reducers";
import {connect, DispatchProp} from "react-redux";
import {getStoreState, getStoreStateError, getStoreStatePending} from "../services/Store/reducer";
import {useLocation} from "react-router-dom";
import {ApiError, ConfigApi, StoreConfigAnalyticsApi} from "../services/Api/types";
import Pixel from "../services/Common/Analytics/Pixel";
import FirewallPage from "./FirewallPage";
import {getSessionInitialized} from "../services/Location/reducer";
import CustomHelmet from "../components/layout/CustomHelmet";
import Analytics from "../services/Common/Analytics/Analytics";
import StoreUtils from "../services/Store/StoreUtils";
import SingleStoreContextComponent from "./Contexts/SingleStoreContextComponent";
import MultistoreContextPage from "./Contexts/MultistoreContextPage";
import FetchStoreData from "../components/Fetch/FetchStoreData";
import {api} from "../services/Api/api";
import {initLocale} from "../services/languageUtils";
import AppConfig from "../services/AppConfig";
import {useLocationService} from "../services/Location/locationService";
import LoadingContainer from "../components/Common/LoadingContainer";
import {fetchStoreSuccessDefault} from "../services/Store/actions";
import {useAuth} from "../services/User/auth";
import ScrollToTop from "../utils/ScrollToTop";
import handleException from "../services/Api/handleException";
import ErrorView from "../components/Common/ErrorView";
import {CustomColorsLoader} from "../components/layout/CustomColorsLoader";
import {useTranslation} from "react-i18next";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

const Pages: FC<Props> = (props) => {
    const location = useLocation();
    const config = props.store_state.default_config;
    const [errors, setErrors] = useState<ApiError[] | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const locationService = useLocationService();
    const authService = useAuth();
    const colorsLoader = new CustomColorsLoader();
    const {t} = useTranslation();

    useEffect(() => {
        init()
    }, []);

    const initAnalytics = (configAnalytics: StoreConfigAnalyticsApi) => {
        let googleAnalyticsId = configAnalytics.google_analytics_id;
        if (googleAnalyticsId !== undefined && googleAnalyticsId) {
            Analytics.addGA(googleAnalyticsId);
            Analytics.pageView(location.pathname);
        }
        let facebookPixelId = configAnalytics.facebook_pixel_id;
        if (facebookPixelId) {
            Pixel.add(facebookPixelId);
            Pixel.pageView(location.pathname);
        }
    }

    const init = async () => {
        let locale = initLocale();
        setLoading(true);
        try {
            let response = await api.getConfig(locale);
            if(response.old_settings.old_store_redirect){
                if(response.domain && response.domain.includes(".goorder.pl")){
                    const currentUrl = window.location.href;
                    if(currentUrl.includes(".goorder.pl")){
                        let redirectUrl = currentUrl.replace(".goorder.pl", ".goorder.io");
                        window.location.replace(redirectUrl);
                    }
                }
            }
            if (response.layout.color) {
                colorsLoader.updateColors(response.layout.color);
            }
            AppConfig.setTemplate(response.layout.template);
            AppConfig.setMultistore(StoreUtils.isMultistore(response));
            const sessionState = await locationService.init(locale, response);
            try {
                await authService.refresh();
            } catch (err) {
                console.log("auth init error: ", err)
            }
            if (response?.analytics) {
                initAnalytics(response.analytics)
            }
            props.dispatch(fetchStoreSuccessDefault(response))
            setLoading(false);
        } catch (err) {
            console.log(err);
            setErrors(handleException(err))
            setLoading(false);
        }
    }

    useEffect(() => {
        // Log page views to Analytics and Pixel whenever the route changes
        Analytics.pageView(location.pathname);
        Pixel.pageView(location.pathname);
    }, [location]);


    if (loading) {
        return (<LoadingContainer/>)
    }

    if (errors) {
        return (
            <ErrorView>
                <p>
                    {t("constraints.internal.store_loading_failed")}
                </p>
                <button onClick={init} className={"btn btn-primary"}>{t("common.action.refresh")}</button>
            </ErrorView>
        )
    }

    if (StoreUtils.isMultistore(config)) {
        if (config === undefined) return null;
        return (
            <>
                <CustomHelmet config={config}/>
                <div className={"main"}>
                    <div className={"main-content"}>
                        <ScrollToTop/>
                        <MultistoreContextPage config={config}/>
                    </div>
                </div>
            </>
        )
    }
    const render = () => {
        if (config === undefined) return null;

        return (
            <>
                <CustomHelmet config={config}/>
                {StoreUtils.isMultistore(config) ?
                    (
                        <>
                            <MultistoreContextPage/>
                        </>
                    ) : (
                        <>
                            <SingleStoreContextComponent/>
                        </>
                    )}

                {/*<Routes>*/}
                {/*    <Route path={`cart`} element={<CartPage/>}/>*/}
                {/*    <Route path={`rules`} element={<RulesPage/>}/>*/}
                {/*    <Route path={`page`} element={<PagePage/>}/>*/}
                {/*    <Route path={`user/*`} element={<UserPage/>}/>*/}
                {/*    <Route path={`login/*`} element={<LoginLayout/>}/>*/}
                {/*    <Route path={`summary/:id/:token`} element={<SummaryPage/>}/>*/}
                {/*    <Route path={`DELIVERY`} element={<OrderTypeLayout><DeliveryOrderTypePage/></OrderTypeLayout>}/>*/}
                {/*    <Route path={`DINE_IN`} element={<OrderTypeLayout><DineInOrderTypePage/></OrderTypeLayout>}/>*/}
                {/*    <Route path={`ROOM_SERVICE`}*/}
                {/*           element={<OrderTypeLayout><RoomServiceOrderTypePage/></OrderTypeLayout>}/>*/}
                {/*    <Route path={`PICK_UP`} element={<OrderTypeLayout><PickUpOrderTypePage/></OrderTypeLayout>}/>*/}
                {/*    {StoreUtils.isMultistore(config) ?*/}
                {/*        (*/}
                {/*            <>*/}
                {/*                <Route path={`l/*`} element={<MultistorePages/>}/>*/}
                {/*                <Route path={``} element={<MultistoreHomePage/>}/>*/}
                {/*            </>*/}
                {/*        )*/}
                {/*        :*/}
                {/*        StoreUtils.isHomepageMenu(config) ? (*/}
                {/*            <Route path={`*`} element={<>*/}
                {/*                <Navbar config={props.store_state}/>*/}
                {/*                <Routes>*/}
                {/*                    <Route path={`menu/:menuId`} element={<MenuPage/>}/>*/}
                {/*                    <Route path={`*`} element={<MenuPage/>}/>*/}
                {/*                </Routes>*/}
                {/*                <div className={"body-container-cart"}>*/}
                {/*                    <Footer/>*/}
                {/*                </div>*/}
                {/*            </>}/>*/}
                {/*        ) : (*/}
                {/*            <>*/}
                {/*                <Route path={`menu/*`} element={<>*/}
                {/*                    <Navbar config={props.store_state}/>*/}
                {/*                    <Routes>*/}
                {/*                        <Route path={`menu/:menuId`} element={<MenuPage/>}/>*/}
                {/*                        <Route path={`*`} element={<MenuPage/>}/>*/}
                {/*                    </Routes>*/}
                {/*                    <div className={"body-container-cart"}>*/}
                {/*                        <Footer/>*/}
                {/*                    </div>*/}
                {/*                </>}/>*/}
                {/*                <Route path={`*`} element={<HomePage/>}/>*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*    <Route path={`/:pageId`} element={<PagePage/>}/>*/}

                {/*</Routes>*/}
            </>
        )
    }

    if (config?.firewall?.enabled) {
        return (
            <div className={"main"}>
                <div className={"main-content"}>
                    <FirewallPage config={config}>
                        <FetchStoreData config={config}>
                            {render()}
                        </FetchStoreData>
                    </FirewallPage>
                </div>
            </div>
        )
    }
    return (
        <div className={"main"}>
            <div className={"main-content"}>
                <FetchStoreData config={config}>
                    <>
                        <ScrollToTop/>
                        {render()}
                    </>
                </FetchStoreData>
            </div>
        </div>
    )
};

const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
    storePending: getStoreStatePending(state.config),
    storeError: getStoreStateError(state.config),
    sessionInitialized: getSessionInitialized(state.session)
})

const connector = connect(mapStateToProps);

export default connector(Pages);