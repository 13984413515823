import React, {useState, useRef, useEffect} from 'react';
import jsQR from 'jsqr';
import ErrorFormAlertView from "./ErrorFormAlertView";
import {useTranslation} from "react-i18next";

interface Props {
    onScan: (data: string) => void,
}

const QRCodeScannerComponent: React.FC<Props> = (props: Props) => {
    // State to track scanning mode and the QR code message
    const [scanning, setScanning] = useState<boolean>(false);
    const [leaveCamera, setLeaveCamera] = useState<boolean>(false);
    const [qrMessage, setQRMessage] = useState<string>('');
    const {t} = useTranslation();
    const [error, setError] = useState<string | undefined>(undefined);
    const isMounted = useRef(true);

    // References to the video and canvas elements
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    // References to the media stream and animation frame ID (for cleanup)
    const streamRef = useRef<MediaStream | null>(null);
    const animationRef = useRef<number | null>(null);

    /**
     * Starts the camera and begins scanning for a QR code.
     */
    const startCamera = async () => {
        setError(undefined);
        setQRMessage('');
        setScanning(true);
        try {
            // Request the camera stream (using the environment/back-facing camera)
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {facingMode: 'environment'},
            });
            streamRef.current = stream;

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                // Required for iOS to avoid fullscreen issues
                videoRef.current.setAttribute('playsinline', 'true');
                await videoRef.current.play();

                // Start scanning frames
                scanFrame();
            }
            if (!isMounted.current) {
                stopCamera();
                return;
            }
        } catch (error) {
            if (error instanceof Error) {
                if(error.message && (error.message.toLowerCase().includes("permission denied") || error.message.toLowerCase().includes("denied permission"))){
                    setError(t("constraints.internal.camera_permission_denied"))
                }else{
                    setError(`${t("constraints.internal.camera_unkown_error")}: ${error.message}`);
                }
            } else {
                setError(`${t("constraints.internal.camera_unkown_error")}`);
            }
            setScanning(false);
        }
    };

    /**
     * Captures a video frame, scans for a QR code, and schedules the next scan.
     */
    const scanFrame = () => {
        if (!videoRef.current || !canvasRef.current) return;
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (!context) return;

        // Set the canvas dimensions to match the video dimensions
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Get image data from the canvas for processing
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

        // Scan the image data for a QR code
        const code = jsQR(imageData.data, canvas.width, canvas.height, {
            inversionAttempts: 'dontInvert',
        });

        if (code) {
            // QR code detected – show its data and stop scanning
            // setQRMessage(code.data);
            props.onScan(code.data);
            // stopCamera();
            return;
        }

        // Schedule the next scan
        animationRef.current = requestAnimationFrame(scanFrame);
    };

    /**
     * Stops the camera stream and cancels further scanning.
     */
    const stopCamera = () => {
        setScanning(false);
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
        }
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
        }
    };

    // Clean up the camera stream when the component unmounts
    useEffect(() => {
        startCamera()
        return () => {
            isMounted.current = false;
            setLeaveCamera(true);
            stopCamera();
        };
    }, []);

    return (
        <div style={{textAlign: 'center', padding: '1rem'}}>

            {/* Video element displays the camera feed when scanning */}
            {scanning && (
                <div>
                    <video
                        ref={videoRef}
                        style={{
                            width: '100%',
                            maxWidth: '600px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                        }}
                        autoPlay
                        playsInline
                    />
                    {/* Hidden canvas for processing video frames */}
                    <canvas ref={canvasRef} style={{display: 'none'}}/>
                </div>
            )}
            {error && (
                <>
                    <ErrorFormAlertView errors={[{message:error}]}/>
                </>
            )}

            {/* Display the QR code message if available */}
            {qrMessage && (
                <div style={{marginTop: '1rem', background: '#f0f0f0', padding: '1rem', borderRadius: '4px'}}>
                    <h2>QR Code Message:</h2>
                    <p>{qrMessage}</p>
                </div>
            )}
        </div>
    );
};

export default QRCodeScannerComponent;