import React from 'react';
import {useTranslation} from "react-i18next";

interface Props {
    errors: any[],
    field: string,
    show_message?: boolean
}

const ErrorFormView = (data: Props) => {
    const {t} = useTranslation();

    let errors = data.errors;
    let field = data.field;
    let showMessage = data.show_message;
    let fieldErrors = [] as any[];
    errors.map((error: any) => {
        if (error === undefined || !error) {
            return;
        }
        let errorField = error.field;
        let code = error.code;
        let message = error.message;

        if (errorField === field) {
            fieldErrors.push({
                code: code,
                message: message
            });
        }
    });
    return (
        <React.Fragment>
            {
                fieldErrors.map((item) =>
                    <div className="invalid-feedback" key={item.code}>
                        {showMessage ? item.message : t("constraints." + item.code)}
                        {/*{trans("errors." + item.code)}*/}
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ErrorFormView;
