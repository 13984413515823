import React, { useLayoutEffect, useRef, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as BogoIco} from "../../../../../images/icons/bogo.svg";
import {RootState} from "../../../../../reducers";
import Money from "../../../../components/Common/Money";
import {getItems} from "../../../../services/Menu/reducer";
import {getCartTotal} from "../../../../services/Cart/reducer";
import {getUser, getVouchers} from "../../../../services/User/reducer";
import PromotionInfoModalComponent from "../../../../components/Cart/components/PromotionInfoModalComponent";
import ScrollableTabs from "../../../../components/Common/ScrollableTabs";

interface OwnProps {
    promotions: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuPromotionsComponent = (props: Props) => {
    const {t} = useTranslation();
    const cartTotal = props.cartTotal;
    const promotions = props.promotions;
    const [promotionToShow, setPromotionToShow] = useState<any>();
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [navigationLeftButtonVisible, setNavigationLeftButtonVisible ] = useState(false);
    const [navigationRightButtonVisible, setNavigationRightButtonVisible ] = useState(true);

    useLayoutEffect(() => {
        if(scrollContainerRef.current) {
            const {clientWidth, scrollWidth} = scrollContainerRef.current;
            setNavigationRightButtonVisible(scrollWidth > clientWidth)
        }
    }, [scrollContainerRef.current?.scrollWidth]);

    if ((promotions === undefined || !promotions || promotions.length === 0)) {
        return null;
    }

    const totalPriceAmount = cartTotal ? cartTotal.amount : 0;
    // const promotionsToGet = promotions.filter((x: any) => x.min_basket_money && x.min_basket_money.amount > totalPriceAmount);
    const promotionsToGet = promotions;

    const handleScroll = (direction: "left" | "right") => {
        if (scrollContainerRef.current) {
            if(direction === "left") {
                scrollContainerRef.current.scrollLeft -= 100;
                setNavigationLeftButtonVisible(scrollContainerRef.current.scrollLeft > 0);
                setNavigationRightButtonVisible(true);
            }
            else {
                const {scrollLeft, clientWidth, scrollWidth} = scrollContainerRef.current;
                scrollContainerRef.current.scrollLeft += 100;
                if(scrollLeft + 100  + clientWidth >= scrollWidth) {
                    setNavigationRightButtonVisible(false);
                }
                else setNavigationRightButtonVisible(scrollLeft + clientWidth < scrollWidth - 1);
                setNavigationLeftButtonVisible(true)
            }
        }
    };

    return (
        <div className={"container promotions"}>
            <ScrollableTabs hideScrollbars={false} scrollStep={200}>
                <>
                    {
                        promotionsToGet.map((promotion: any) => {
                            return (
                                <button key={promotion.id} className={`btn btn-promotion ${promotionsToGet.length === 1 ? "single" : ""}`} onClick={() => setPromotionToShow(promotion)}>
                                    <div className={"icon-container"}>
                                        <BogoIco />
                                    </div>
                                    <div className={"content"}>
                                        {promotion.name}
                                        <small>
                                            {`${t("modules.menu.field.min_cart_value.title")}: `} <Money value={promotion.min_basket_money}/>
                                        </small>
                                    </div>
                                </button>
                            )
                        })
                    }
                </>
            </ScrollableTabs>
            {promotionToShow && (
                <PromotionInfoModalComponent name={promotionToShow.name}
                                             promotionId={promotionToShow.id}
                                             onHide={() => setPromotionToShow(undefined)} />
            )}
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    user: getUser(state.user),
    vouchers: getVouchers(state.user),
    cartTotal: getCartTotal(state.cart)
})

const connector = connect(mapStateToProps);
export default  withTranslation()(connector(MenuPromotionsComponent));