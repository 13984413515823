import React, {useEffect, useState} from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import {getAvailabilities, getItems, getModifierGroups, getPromotions} from "../../../../services/Menu/reducer";
import AgeRestrictedContext from "../../../../services/Common/AgeRestrictedContext";
import {RootState} from "../../../../../reducers";
import StateItemHelper from "../../../../services/Menu/services/State/StateItemHelper";
import {getLocation, getSession} from "../../../../services/Location/reducer";
import MenuShowItemGroupComponent from "./MenuShowItemGroupComponent";
import MenuShowItemModalComponent from "./MenuShowItemModalComponent";
import MenuEditItemVariantComponent from "./MenuEditItemVariantComponent";
import MenuItemBaseModalComponent from "./MenuItemBaseModalComponent";
import MenuModifierGroupsPickOneComponent from "./MenuModifierGroupsPickOneComponent";
import AgeRestrictedAddItemComponent from "../AgeRestricted/AgeRestrictedAddItemComponent";
import {useCart} from "../../../../services/Cart/cartService";
import {getCart} from "../../../../services/Cart/reducer";
import handleException from "../../../../services/Api/handleException";


interface OwnProps {
    stateItem: any,
    itemId: any,
    itemGroup: any,
    parent: any,
    onUpdate: (item: any, menuId: any) => void,
    orderItemId: any,
    onHide: () => void,
    menu: any,
    locale: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuShowItemSingleComponent = (props: Props) => {
    const [errors, setErrors] = useState<any[]>([]);
    const [stateItem, setStateItem] = useState(props.stateItem ? JSON.parse(JSON.stringify(props.stateItem)) : StateItemHelper.create(props.itemId, props.items, props.modifier_groups, props.session.order_type, undefined));
    const [page, setPage] = useState<any | undefined>(undefined);
    const [variant, setVariant] = useState(ItemHelper.getItem(props.items, props.itemId));
    const [showAgeRestricted, setShowAgeRestricted] = useState(variant.alcohol || variant.energy_drink);
    let cartService = useCart();
    const cart = props.cart;

    useEffect(() => {
        StateItemHelper.refreshItemPrice(stateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
        let copyItem = Object.assign({}, stateItem);
        setStateItem(copyItem);
        let ageRestrictedAccepted = AgeRestrictedContext.isAccepted();
        if (showAgeRestricted && ageRestrictedAccepted) {
            setShowAgeRestricted(false)
        }
    }, []);

    let itemGroup = props.itemGroup;
    let itemId = stateItem.item_id;

    const update = (newStateItem: any) => {
        StateItemHelper.refreshItemPrice(newStateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
        let copyItem = Object.assign({}, newStateItem);
        setStateItem(copyItem);
        if (page) changePage(undefined)
    }

    const changePage = (name: any, data?: any, render?: any) => {
        if (!name) {
            setPage(undefined);
            return;
        }
        switch (name) {
            case "PICK_ONE":
                setPage({name: name, data: data, render: render})
                break;
            default:
                setPage({name: name, data: data, render: render})
                break;
        }
    }
    const renderPage = () => {
        if (page === undefined) return null;
        let pageData = page.data;
        let image = pageData.image;
        switch (page.name) {
            case "PICK_ONE":
                let modifierGroup = pageData.modifier_group;
                return (
                    <MenuItemBaseModalComponent image={image}>
                        <MenuModifierGroupsPickOneComponent modal={true} modifierGroupId={modifierGroup.id}
                                                            id={pageData.id}
                                                            stateItem={stateItem} onUpdate={update}
                                                            onChangePage={changePage}/>
                    </MenuItemBaseModalComponent>
                )
            case "MODAL_EDIT":
                return (
                    <>
                        <MenuItemBaseModalComponent image={image}>
                            {page.render()}
                        </MenuItemBaseModalComponent>
                    </>
                );
                break;
            default:
                return page.render();
        }
        return null;
    }
    const addItemInternal = async (menuId: any, newItem: any) => {
        if (props.parent) {
            props.onUpdate(newItem, menuId);
            return;
        }
        setErrors([]);

        if (props.orderItemId) {
            newItem.id = props.orderItemId;
        }
        try {
            await cartService.addItem(newItem);
            props.onUpdate(newItem, menuId);
        } catch (resError: any) {
            setErrors(handleException(resError));
        }
    }
    const renderItemGroup = () => {
        if (!itemGroup) return null;
        return (
            <MenuShowItemModalComponent title={itemGroup.translation ? itemGroup.translation.name : itemGroup.name}
                                        onHide={props.onHide}>
                <MenuEditItemVariantComponent parent={props.parent} onChangePage={changePage}
                                              changedQuantityVisibility={true}
                                              errors={errors} id={itemId}
                                              menuId={props.menu ? props.menu.id : undefined}
                                              addItem={addItemInternal} onUpdate={update} stateItem={stateItem}>
                    {itemGroup && (
                        <MenuShowItemGroupComponent onUpdate={update} stateItem={stateItem} itemGroup={itemGroup}/>
                    )}
                </MenuEditItemVariantComponent>
            </MenuShowItemModalComponent>
        )
    }
    const renderItem = () => {
        return (
            <MenuShowItemModalComponent title={variant.translation.name} onHide={props.onHide} back={props.parent}>
                <MenuEditItemVariantComponent parent={props.parent} onChangePage={changePage}
                                              changedQuantityVisibility={true}
                                              errors={errors} id={itemId}
                                              menuId={props.menu ? props.menu.id : undefined}
                                              addItem={addItemInternal} onUpdate={update} stateItem={stateItem}/>
            </MenuShowItemModalComponent>
        )
    }
    if (showAgeRestricted) {
        return (
            <>
                <AgeRestrictedAddItemComponent onHide={props.onHide} onSave={() => setShowAgeRestricted(false)}/>
            </>
        )
    }
    return (
        <>
            <>
                {itemGroup ? (
                    <>{renderItemGroup()}</>
                ) : (
                    <>{renderItem()}</>
                )}
            </>
            {page !== undefined && (
                <>
                    {renderPage()}
                </>
            )}
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    modifier_groups: getModifierGroups(state.menu),
    promotions: getPromotions(state.menu),
    availabilities: getAvailabilities(state.menu),
    session: getSession(state.session),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuShowItemSingleComponent));