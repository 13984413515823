import React, {useState} from 'react';
import {ReactComponent as GiftIco} from "../../../images/icons/gift.svg";
import {RootState} from "../../../reducers";
import {getStoreState} from "../../services/Store/reducer";
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import DrawerVouchers from "./DrawerVouchers";

type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

const NavbarVouchers = ({ config }: Props) => {
    const [openVouchersDrawer, setOpenVouchersDrawer] = useState(false);

    if(!config.default_config?.loyalty?.points?.enabled) return <></>;

	return (
		<>
            <button className={"btn btn-icon"} onClick={() => setOpenVouchersDrawer(true)}>
                <GiftIco/>
            </button>
            <DrawerVouchers show={openVouchersDrawer} onHide={() => setOpenVouchersDrawer(false)} />
        </>
	)
}

const mapStateToProps = (state: RootState) => ({
    config: getStoreState(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarVouchers));