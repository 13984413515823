import {useDispatch, useSelector} from "react-redux";
import {api} from "../Api/api";
import AppConfig from "../AppConfig";
import {SessionState} from "../Location/types";
import {selectSession} from "../Location/reducer";
import StorageStore from "../Common/Storage/storageStore";
import UserTokenContext from "../User/UserTokenContext";
import {clearBasket, orderError, orderErrorNotValid, orderPending, refreshBasket} from "./actions";
import {addFlash} from "../Notifications/actions";
import CartHelper from "./Helper/CartHelper";
import {ForbiddenException, NotFoundException, UnprocessableEntity} from "../Api/exceptions";
import Analytics from "../Common/Analytics/Analytics";
import CartContext from "./CartContext";
import Utils from "../../utils/Utils";
import handleException from "../Api/handleException";
import {ApiError} from "../Api/types";
import CartUtils from "./CartUtils";


interface CartService {
    addItem: (data: any) => Promise<any>;
    updateItem: (orderItemId: number, data: any) => Promise<any>;
    update: (data: any, notValidate?: boolean) => Promise<any>;
    pay: (data: any) => Promise<any>;
    init: (session: SessionState) => Promise<any>;
    refresh: () => Promise<any>;
    updateOrderCoupon: (data: any) => Promise<any>;
    addOrderVoucher: (couponCode: any) => Promise<any>;
    removePromotion: (id: number) => Promise<any>;
    refreshUser: () => Promise<any>;
    copyOrder: (id: number, token: string) => Promise<any>;
    fixOrder: () => Promise<any>;
    clean: () => void;
}

export const useCart = (name?: string): CartService => {
    const dispatch = useDispatch();
    const sessionState = useSelector(selectSession);

    const isAccessDeniedToken = (errors: ApiError[]) => {
        if (errors.some(x => x.code == "order_token_wrong" || x.code == "order_token_not_found")) {
            return true;
        }
        return false;
    }
    const parseCartToData = (locationState: SessionState) => {
        let data = {
            number: CartUtils.getCartNumber(locationState),
            pickup_at: locationState.pickup_at,
            address: locationState.address,
            contact: locationState.contact,
            type: locationState.order_type
        } as any
        if (AppConfig.isNativeFullMobile() || AppConfig.isNativeMobile()) {
            data.source = {
                name: "GoOrderApp"
            }
        }

        return data;
    }
    const init = async (session: SessionState) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            dispatch(refreshBasket(undefined, api.version()));
            return
        }
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let response = await api.getOrder(orderId, CartContext.getOrderToken())
            if (response.status !== 'OPEN' && !CartHelper.isConfirmed(response)) {
                clean();
                // dispatch(orderErrorNotValid([{code: "order_not_opened", message: "Order not open"}], version));
                return response;
            }
            // if (response.status !== "OPEN") {
            //     dispatch(orderErrorNotValid([{code: "order_not_opened", message: "Order not open"}], version));
            //     return response;
            //     // throw new Error();
            // }
            if (response.pickup_at !== session.pickup_at
                || response.type !== session.order_type
                || (response.number !== session.table_number && response.number !== session.room_number)
                || JSON.stringify(response.address ?? null) !== JSON.stringify(session.address ?? null)
            ) {
                let data = {
                    pickup_at: session.pickup_at,
                    type: session.order_type,
                    number: CartUtils.getCartNumber(session),
                    address: session.address
                }
                version = api.version();
                let responseUpdate = await update(data, true);
                // dispatch(refreshBasket(responseUpdate, version));
                return responseUpdate;
            } else {
                dispatch(refreshBasket(response, version));
                return response;
            }
        } catch (err) {
            if (err instanceof ForbiddenException) {
                if (err.data && err.data.errors && isAccessDeniedToken(err.data.errors)) {
                    dispatch(orderErrorNotValid(err.data.errors, version));
                    clean();
                    throw err;
                }
            }
            let errors = handleException(err);
            dispatch(orderErrorNotValid(errors, version));
            if (err instanceof NotFoundException) {
                clean();
            }
            throw err;
        }
    }

    const refresh = async () => {
        const version = api.version();
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            dispatch(refreshBasket(undefined, version));
            return
        }
        dispatch(orderPending(version));

        try {
            let response = await api.getOrder(orderId, CartContext.getOrderToken())

            if (response.status === 'OPEN') {
                dispatch(refreshBasket(response, version));
            } else if (CartHelper.isConfirmed(response)) {
                dispatch(refreshBasket(response, version));
            } else {
                clean();
                dispatch(orderErrorNotValid([{code: "order_not_opened", message: "Order not open"}], version));
            }
            return response;
        } catch (err) {
            let errors = handleException(err);
            dispatch(orderErrorNotValid(errors, version));
            throw err;
        }
    }
    const create = async (data: any) => {
        dispatch(orderPending(api.version()));
        const version = api.version();
        try {
            let response = await api.createOrder(data, UserTokenContext.getToken());
            let orderId = response.id;
            CartContext.setOrderId(orderId);
            if (response.token) {
                CartContext.setOrderToken(response.token);
            }
            dispatch(refreshBasket(response, version));
            return response;
        } catch (err) {
            let errors = handleException(err);
            dispatch(orderError(errors, version));
            throw err;
        }
    }
    const updateItem = async (orderItemId: number, data: any) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            return;
        }
        dispatch(orderPending(api.version()));
        let version = api.version();
        if (data.quantity <= 0) {
            try {
                let response = await api.deleteOrderItem(orderId, orderItemId, CartContext.getOrderToken());
                dispatch(refreshBasket(response, version));
            } catch (err) {
                let errors = handleException(err);
                dispatch(orderError(errors, version));
                dispatch(addFlash('error', errors));
                throw err;
            }
            return;
        }
        version = api.version();
        try {
            let response = await api.updateOrderItem(orderId, orderItemId, data, CartContext.getOrderToken());
            dispatch(refreshBasket(response, version));
        } catch (err) {
            let errors = handleException(err);
            dispatch(orderError(errors, version));
            dispatch(addFlash('error', errors));
            throw err;
        }
    }

    const addItem = async (data: any) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            let orderData = parseCartToData(sessionState) as any;
            orderData.items = [];
            if (data) {
                orderData.items.push(data);
            }
            return create(orderData);
        }
        dispatch(orderPending(api.version()));
        let version = api.version();
        try {
            let response = await api.addOrderItem(orderId, data, CartContext.getOrderToken());
            Analytics.addToCart(data);
            dispatch(refreshBasket(response, version));
        } catch (err) {
            let errors = handleException(err);
            dispatch(orderError(errors, version));
            dispatch(addFlash('error', errors));
            throw err;
        }
    }
    const update = async (data: any, notValidate?: boolean) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) return;
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let order = await api.updateOrder(orderId, data, CartContext.getOrderToken(), UserTokenContext.getToken(), notValidate ? true : false);
            let contactCopy = {
                name: order.contact?.name,
                email: order.contact?.email,
                phone: order.contact?.phone
            }
            StorageStore.setItem('contact', JSON.stringify(contactCopy));
            dispatch(refreshBasket(order, version));
            return order
        } catch (err) {
            const errors = handleException(err);
            dispatch(orderError(errors, version));
            // dispatch(addFlash('error', error.errors));
            throw err;
        }
    }

    const clean = () => {
        CartContext.cleanOrder();
        dispatch(clearBasket());
    }
    const pay = async (data: any) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) return;
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let response = await api.payOrder(orderId, data, CartContext.getOrderToken());
            Analytics.payPurchase(response.data);
            await Utils.timeout(500);
            // clean();
            return response;
        } catch (err: any) {
            const errors = handleException(err);
            if (err instanceof UnprocessableEntity) {
                errors.map((err: any) => {
                    if (err.message && err.message.includes(" ||")) {
                        let splitErrorFirst = err.message.split(" ||")[0];
                        err.code = splitErrorFirst;
                    } else {
                        // if(err.field){
                        //     const newCode = `${err.field.toLowerCase()}_${err.code}`;
                        //     err.code = newCode;
                        // }
                    }
                    return err;
                })
                dispatch(orderError(errors, version));
                dispatch(addFlash('error', errors));
            } else {
                dispatch(orderError(errors, version));
                alert(errors[0].message);
            }
            throw err;
        }
    }
    const addOrderVoucher = async (couponCode: string) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            let orderData = parseCartToData(sessionState) as any;
            let orderResponse = await create(orderData);
            orderId = orderResponse.id;
        }
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let response = await api.addOrderVoucher(orderId, couponCode, CartContext.getOrderToken());
            dispatch(refreshBasket(response.data, version));
            return response;
        } catch (err) {
            dispatch(orderError(handleException(err), version));
            throw err;
        }
    }

    const updateOrderCoupon = async (data: any) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            let orderData = parseCartToData(sessionState) as any;
            let orderResponse = await create(orderData);
            orderId = orderResponse.id;
        }
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let response = await api.updateOrderCoupon(orderId, data, CartContext.getOrderToken());
            dispatch(refreshBasket(response.data, version));
            return response.data;
        } catch (err) {
            dispatch(orderError(handleException(err), version));
            throw err;
        }
    }

    const removePromotion = async (id: number) => {
        let orderId = CartContext.getOrderId();
        if (!orderId) return;
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let response = await api.removePromotion(orderId, id, CartContext.getOrderToken());
            dispatch(refreshBasket(response.data, version));
            return response;
        } catch (err: any) {
            dispatch(orderError(handleException(err), version));
            throw err;
        }
    }


    const copyOrder = async (id: number, token: string) => {
        dispatch(orderPending(api.version()));
        let version = api.version();
        try {
            let response = await api.copyOrder(id, token);
            let orderId = response.id;
            CartContext.setOrderId(orderId);
            if (response.token !== undefined) {
                CartContext.setOrderToken(response.token);
            }
            dispatch(refreshBasket(response, version));
            return response;
        } catch (err) {
            dispatch(orderError(handleException(err), version));
            throw err;
        }
    }

    const fixOrder = async () => {
        let orderId = CartContext.getOrderId();
        if (!orderId) return;
        dispatch(orderPending(api.version()));
        let version = api.version();
        try {
            let response = await api.fixOrder(orderId, CartContext.getOrderToken());
            dispatch(refreshBasket(response, version));
            return response;
        } catch (err: any) {
            const errors = handleException(err);
            dispatch(orderErrorNotValid(errors, version));
            dispatch(addFlash('error', errors));
            throw err;
        }
    }
    const refreshUser = async () => {
        let orderId = CartContext.getOrderId();
        if (!orderId) {
            let orderData = parseCartToData(sessionState) as any;
            let orderResponse = await create(orderData);
            orderId = orderResponse.id;
        }
        dispatch(orderPending(api.version()));

        let version = api.version();
        try {
            let response = await api.updateOrderContact(orderId, CartContext.getOrderToken(), UserTokenContext.getToken());
            dispatch(refreshBasket(response, version));
            return response;
        } catch (err) {
            dispatch(orderError(handleException(err), version));
            throw err;
        }
    }

    return {
        addOrderVoucher,
        update,
        refresh,
        init,
        updateItem,
        addItem,
        pay,
        updateOrderCoupon,
        removePromotion,
        copyOrder,
        fixOrder,
        clean,
        refreshUser
    };
};
