let init = false;
let firstScrollToTop = false;

class IframeWidget {
    static initialize() {

        let parentHeight = undefined;
        let top = undefined as any | undefined;

        function onElementHeightChange(elm: any, callback: any) {
            var lastHeight = elm.clientHeight, newHeight;
            (function run() {
                newHeight = elm.clientHeight;
                if (lastHeight != newHeight) {
                    callback(newHeight)
                }
                lastHeight = newHeight

                if (elm.onElementHeightChangeTimer)
                    clearTimeout(elm.onElementHeightChangeTimer)

                elm.onElementHeightChangeTimer = setTimeout(run, 200)
            })()
        }

        function fixFixedElements(top: any, bottom: any, parentHeight: any) {
            if (top < 0) {
                top = 0;
            }
            var stickyFixed = document.getElementsByClassName("sticky-fixed");
            Array.from(stickyFixed).forEach((el) => {
                (el as any).style.top = top + "px";
            });


            let modals = document.getElementsByClassName("modal");
            Array.from(modals).forEach((el) => {
                (el as any).style.top = top + "px";
                let modalDialogs = el.getElementsByClassName("modal-dialog");
                Array.from(modalDialogs).forEach((el1) => {
                    // console.log(el);
                    (el1 as any).style.height = parentHeight + "px";
                });
            });
            let fixedBottomBottom = bottom - parentHeight + 15;
            if (fixedBottomBottom < 15) {
                fixedBottomBottom = 15;
            }
            let fixedBottom = document.getElementsByClassName("fixed-bottom");
            Array.from(fixedBottom).forEach((el) => {
                (el as any).style.bottom = fixedBottomBottom + "px";
            });

            if (parentHeight > 0) {
                let fullHeight = document.getElementsByClassName("fixed-height-100");
                Array.from(fullHeight).forEach((el) => {
                    // console.log(el);
                    (el as any).style.height = parentHeight + "px";
                });
            }
        }

        if (window.top != window.self) {
            if (!init) {
                init = true;
                // window.document.body.classList.add("widget");
                onElementHeightChange(document.body, function (h: any) {
                    console.log("HEIGHT_CHANGED", h)
                    let message = {type: 'HEIGHT_CHANGED', height: h};
                    window.parent.postMessage(message, "*");
                    // console.log('Body height changed:', h)
                });

                // window.addEventListener('scroll', function(e) {
                //     console.log(e);
                // });
            }
        }
        window.addEventListener("message", function (data) {
            if (data.data === undefined) {
                return;
            }
            switch (data.data.type) {
                case "ENABLE_FULLSCREEN_OFFSET":
                    var widgetElement = document.getElementsByClassName("widget");
                    let iframeOffsetTop = data.data.iframeOffsetTop;
                    if (iframeOffsetTop < 0) {
                    }
                    Array.from(widgetElement).forEach((el) => {
                        (el as any).style.top = iframeOffsetTop + "px";
                    });
                    let stickyFixedTop = iframeOffsetTop < 0 ? 0 : iframeOffsetTop;
                    var stickyFixed = document.getElementsByClassName("sticky-fixed");
                    Array.from(stickyFixed).forEach((el) => {
                        // el.style.top =  iframeOffsetTop + "px";
                        (el as any).style.top = stickyFixedTop + "px";
                    });
                    break;
                case "DISABLE_FULLSCREEN_OFFSET":
                    window.dispatchEvent(new Event('scroll', {detail: top} as any));
                    window.dispatchEvent(new CustomEvent('scroll-iframe', {detail: top}));
                    top = data.data.iframeOffsetTop * -1;
                    var bottom = data.data.iframeOffsetBottom;
                    parentHeight = data.data.iframeParentHeight;
                    fixFixedElements(top, bottom, parentHeight);
                    var widgetElement = document.getElementsByClassName("widget");
                    Array.from(widgetElement).forEach((el) => {
                        (el as any).style.top = "";
                    });
                    break;
                case "PARENT_SCROLL":
                    window.dispatchEvent(new Event('scroll', {detail: top} as any));
                    window.dispatchEvent(new CustomEvent('scroll-iframe', {detail: top}));
                    top = data.data.iframeOffsetTop * -1;
                    var bottom = data.data.iframeOffsetBottom;
                    parentHeight = data.data.iframeParentHeight;
                    fixFixedElements(top, bottom, parentHeight);
                    break;
            }
        }, false);
    }

    static scrollToTop() {
        if (init) {
            let message = {type: 'HEIGHT_CHANGED', height: document.body.clientHeight, scroll_to_top: firstScrollToTop};
            window.parent.postMessage(message, "*");
            firstScrollToTop = true;
        }
    }

    static scrollTo(value: any) {
        if (init) {
            let message = {type: 'SCROLL_PARENT_TO_IFRAME_OFFSET', offset: value};
            window.parent.postMessage(message, "*");
        }
    }

    static openModal() {
        if (init) {
            let message = {type: 'SIZE_STRETCH'};
            window.parent.postMessage(message, "*");
        }
    }

    static closeModal() {
        if (init) {
            let message = {type: 'SIZE_DEFAULT'};
            window.parent.postMessage(message, "*");
        }
    }

    static onHide(onHide?:any) {
        if(onHide) onHide();
        IframeWidget.closeModal();
    }

    static onShow(onShow?:any) {
        if(onShow) onShow();
        IframeWidget.openModal();
    }
}

export default IframeWidget;
