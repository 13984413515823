import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import {ConfigApi, StoreConfigCoordinatesApi} from "../../../../services/Api/types";
import {getUser} from "../../../../services/User/reducer";
import NavbarMulti from "../../../../components/Navbar/NavbarMulti";
import {getSession} from "../../../../services/Location/reducer";
import FetchPlaces from "../../../../components/Fetch/FetchPlaces";
import {usePlaceContext} from "../../../../services/Place/PlaceContext";
import MultistoreListComponent from "./components/MultistoreListComponent";
import ResponsiveComponent from "../../../../components/Common/ResponsiveComponent";
import MultistoreMapListComponent from "../components/MultistoreMapListComponent";
import {useLocationService} from "../../../../services/Location/locationService";


interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreListPage = (props: Props) => {
    const {places, nearestPlace, updateCoordinates} = usePlaceContext();
    const locationService = useLocationService();
    const [filter, setFilter] = useState<any>({});

    const geolocation = async (coordinates: StoreConfigCoordinatesApi) => {
        await locationService.updateCoordinates({
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
        })
    }

    return (
        <>
            <NavbarMulti config={props.config}/>
            <FetchPlaces>

                <ResponsiveComponent
                    small={<MultistoreListComponent places={places} filter={filter}
                                                    onChangeCity={(value: string) => setFilter({
                                                        ...filter,
                                                        city: value
                                                    })} geolocation={geolocation}/>}
                    large={<MultistoreMapListComponent filter={filter} setFilter={setFilter}/>}
                />
            </FetchPlaces>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);

export default connector(MultistoreListPage);