import i18next from "i18next";
import CookiesStore from "./Common/Storage/cookiesStore";

const defaultLocale = "pl";
let selectedLocale: string | undefined = undefined;
const languages: Record<string, any> = {
    "en": {
        "locale": "en",
        "nativeName": "English",
        "englishName": "English"
    },
    "pl": {
        "locale": "pl",
        "nativeName": "Polski",
        "englishName": "Polish"
    },
};

export const getSupportedLanguages = () => {
    return languages;
}

export const getPossibleLanguages = (fetchedLanguages: string[]) => {
    return fetchedLanguages.filter(language => !!languages[language]).sort((a,b) => a.localeCompare(b));
}

export const getLangDisplayName = (locale?: string) => {
    if (!locale) return null;
    return languages[locale]?.nativeName;
}

export async function changeLocale(locale: string) {
    if (i18next && ((i18next.language && i18next.language !== locale) || !i18next.language)) {
        await i18next.changeLanguage(locale);
    }
    CookiesStore.setGlobal("language", locale);
}

export function initLocale(newLocale?: string) {
    const supportedLanguages = getSupportedLanguages() as any;
    let locale = selectedLocale;

    if (newLocale) {
        const language = supportedLanguages[newLocale];
        if (language) {
            locale = language.locale;
        }
    }

    if (!locale) {
        const localeFromCookiesStore = CookiesStore.get("language");
        if(localeFromCookiesStore) {
            locale = localeFromCookiesStore
        }
        else {
            const browserLanguage = navigator.language;
            locale = supportedLanguages[browserLanguage]?.locale ?? defaultLocale;
        }
    }

    if (selectedLocale === locale) {
        return locale ? locale : defaultLocale;
    }

    if (i18next && i18next.language && ((i18next.language && i18next.language !== locale) || !i18next.language)) {
        i18next.changeLanguage(locale);
    }

    CookiesStore.setGlobal("language", locale);
    selectedLocale = locale;
    return locale ? locale : defaultLocale;
}


const getTranslation = (locale: any, field: any, translations: any, defaultValue: any) => {
    if (translations === undefined) {
        return defaultValue;
    }
    let translation = translations.filter((translation: any) => translation.locale === locale)[0];
    if (translation && translation[field]) {
        return translation[field];
    }
    return defaultValue;
}

export const getElementTranslation = (locale: any, element: any, fields: any) => {
    let translation = {} as any;
    fields.forEach((field: any) => {
        translation[field] = getTranslation(locale, field, element.translations, element[field])
    })
    return translation;
}

export const getElementTranslations = (locale: any, elements: any, fields: any) => {
    return elements.map((element: any) => {
        element.translation = getElementTranslation(locale, element, fields);
        // fields.forEach(field => {
        //     element.translation[field] = getTranslation(locale, field, element.translations, element[field])
        // })
        return element;
    });
}