import React, {ReactNode, useEffect, useRef} from "react";
import AppConfig from "../services/AppConfig";

interface Props {
    children?: ReactNode,
    value?: string,
}
const MenuScrollSpy = (props: Props) => {
    const sectionsRef = useRef<NodeListOf<Element> | null>(null);
    const menuLinksRef = useRef<NodeListOf<Element> | null>(null);
    const currentActiveRef = useRef(0);

    const sectionMargin = AppConfig.isWidget() ? 110 : 40;

    const makeActive = (link: number) => {
        const menuLinks = menuLinksRef.current;
        const navScrollSpy = document.getElementById("nav-scrollspy");

        if (menuLinks && navScrollSpy) {
            const menuLink = menuLinks[link];
            if(menuLink){
                const navScrollSpyNav = navScrollSpy.getElementsByClassName("nav");
                menuLink.classList.add("active");
                if (navScrollSpyNav.length > 0) {
                    navScrollSpyNav[0].scrollTo({
                        left: (menuLink as HTMLElement).offsetLeft - 40,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    const removeActive = (link: number) => {
        const menuLinks = menuLinksRef.current;
        if (menuLinks) {
            menuLinks[link]?.classList.remove("active");
        }
    };

    const removeAllActive = () => {
        const sections = sectionsRef.current;
        if (sections) {
            Array.from(Array(sections.length).keys()).forEach((link) => removeActive(link));
        }
    };

    const scrollListener = (scrollY: number) => {
        const sections = sectionsRef.current;
        if (sections) {
            let current =
                sections.length -
                [...sections].reverse().findIndex((section) => {
                    const sectionTop = (section as HTMLElement).offsetTop;
                    return scrollY >= sectionTop - sectionMargin;
                }) -
                1;

            if (current > sections.length - 1) {
                current = 0;
            }

            if (current !== currentActiveRef.current) {
                removeAllActive();
                currentActiveRef.current = current;
                makeActive(current);
            }
        }
    };

    const handleScroll = () => {
        scrollListener(window.scrollY);
    };

    const handleScrollIframe = (e: CustomEvent) => {
        scrollListener(e.detail);
    };

    useEffect(() => {
        sectionsRef.current = document.querySelectorAll(".scrollspy-section");
        menuLinksRef.current = document.querySelectorAll(".parent-top-nav .nav-item");

        if (!sectionsRef.current?.length) {
            return;
        }

        makeActive(currentActiveRef.current);

        if (AppConfig.isWidget()) {
            window.addEventListener("scroll-iframe", handleScrollIframe as EventListener);
        } else {
            window.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (AppConfig.isWidget()) {
                window.removeEventListener("scroll-iframe", handleScrollIframe as EventListener);
            } else {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, [props.value]);

    return <>{props.children}</>;
};

export default MenuScrollSpy;