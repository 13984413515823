import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import AgeRestrictedContext from "../../../../services/Common/AgeRestrictedContext";


interface OwnProps {
    onHide: () => void,
    onSave: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const AgeRestrictedAddItemComponent = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const save = () => {
        AgeRestrictedContext.accept();
        props.onSave()
    }
    const hide = () => {
        props.onHide()
    }

    return (
        <React.Fragment>
            <div className={"p-4"}>
                <p>
                    {t("modules.menu.field.age_restriction.title")}
                </p>
                <div>
                    <button type="button" className={"btn btn-secondary" + (loading ? ' loading' : '')}
                            data-dismiss="modal" onClick={() => hide()}>
                        {t("modules.menu.field.underage.title")}
                    </button>
                    {" "}
                    <button type="button" className={"btn btn-primary" + (loading ? ' loading' : '')} onClick={() => {
                        save()
                    }}>
                        {t("modules.menu.field.sufficient_age.title")}
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(AgeRestrictedAddItemComponent));
