import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import {getSession} from "../../services/Location/reducer";
import LoadingContainer from "../Common/LoadingContainer";
import NavbarLocationForm from "../Navbar/NavbarLocationForm";
import {useLocationService} from "../../services/Location/locationService";
import {ConfigAddressApi} from "../../services/Api/types";
import IframeWidget from "../../utils/IframeWidget";


interface OwnProps {
    showModal: boolean,
    onHide: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ChangeLocationModal(props: Props) {
    const [errors, setErrors] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [address, setAddress] = useState(props.session.address);
    const {t} = useTranslation();
    const locationService = useLocationService();

    if (!props.config) return null;
    const save = async () => {
        setLoading(true);
        setErrors([]);


        let addressData = address ? {
            city: address.city,
            street: address.street,
            build_nr: address.build_nr,
            flat_nr: address.flat_nr,
            zip_code: address.zip_code,
            country: address.country ? address.country : props.session?.address?.country
        } : {} as ConfigAddressApi;
        try {
            let data = await locationService.updateLocation(addressData);
            setLoading(false);
            IframeWidget.onHide(onHide)
        } catch (err: any) {
            setLoading(false);
            setErrors(err.errors)
        }
    }
    const onShow = () => {
        setLoadingData(true);
        setAddress(props.session.address)
        setLoadingData(false);
    }
    const onHide = () => {
        setLoadingData(true);
        props.onHide();
    }

    return (

        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.showModal} onHide={() => IframeWidget.onHide(onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("modules.location_modal.header.title")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        {(!props.showModal || loadingData) ? (
                            <LoadingContainer/>
                        ) : (
                            <NavbarLocationForm config={props.config}
                                                onErrors={(addressErrors: any) => setErrors(addressErrors)}
                                                onChange={(newAddress: any) => setAddress(newAddress)} errors={errors}
                                                address={address}/>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn-spinner w-100 btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                            onClick={() => {
                                save()
                            }}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.save")}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ChangeLocationModal));