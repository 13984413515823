import Utils from "../utils/Utils";

class AppConfig {

    static widget = false;
    static darkMode = false;
    static grid = false;
    static nativeMobile = false;
    static nativeFullMobile = false;
    static template: string | undefined = undefined;
    static multistore = false;
    static placeId: string | undefined = undefined;

    static setPlaceId(placeId?: string) {
        this.placeId = placeId;
    }

    static version() {
        return this.placeId ? this.placeId : "";
    }

    static getLinkWithPrefix(value: string, prefix: string) {
        return `${prefix ? `/${prefix}` : ""}${value}`;
    }

    static setWidget(widget: boolean) {
        this.widget = widget;

        if (window.location.search) {
            let query = window.location.search.substring(1);
            let qp = Utils.getQueryParams(query) as any;
            if (qp.darkMode) {
                this.setDarkMode()
            }
            if (qp.grid) {
                this.grid = true;
            }
        }
    }

    static setDarkMode() {
        this.darkMode = true;
        var body = document.body;
        body.classList.add("dark-mode");
        body.setAttribute('data-bs-theme', 'dark');
    }


    static isMultistore() {
        return this.multistore;
    }

    static setMultistore(value: boolean) {
        this.multistore = value;
    }

    static setTemplate(template: string) {
        this.template = template;
        var body = document.body;
        if (template === undefined) {
            body.classList.add("design-v2");
            body.classList.add("design-v3");
            return;
        }
        switch (template) {
            case "LIGHT":
                body.classList.add("design-v2");
                body.classList.add("design-v3");
                break;
            case "DARK":
                body.classList.add("design-v2");
                body.classList.add("design-v3");
                AppConfig.setDarkMode();
                break;
            case "V2":
            case "V3":
                body.classList.add("design-v2");
                body.classList.add("design-v3");
                break;
        }
    }

    static isWidget() {
        return this.widget;
    }

    static setNativeMobile() {
        this.nativeMobile = true;
    }

    static setNativeFullMobile() {
        this.nativeFullMobile = true;
    }

    static isNativeFullMobile() {
        return this.nativeFullMobile;
    }

    static isNativeMobile() {
        return this.nativeMobile;
    }

    static isDarkMode() {
        return this.darkMode;
    }

    static isShowFreeInfo() {
        return false;
    }

}

export default AppConfig;