import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import ErrorFormView from "../../Common/ErrorFormView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import ErrorFormAlertView from "../../Common/ErrorFormAlertView";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import {ApiError} from "../../../services/Api/types";
import handleException from "../../../services/Api/handleException";
import {UnprocessableEntity} from "../../../services/Api/exceptions";

interface OwnProps {
    onSuccess: () => void,
    setPage: (page: string) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function LoginUserComponent(props: Props) {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {t} = useTranslation();
    const authService = useAuth();

    const addClient = async () => {
        setLoading(true);
        try {
            const response = await authService.login(email, password);
            setLoading(false);
            props.onSuccess()
        } catch (e: any) {
            if(e instanceof UnprocessableEntity){
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": t("constraints.wrong_login")
                    }
                ])
            }else{
                setErrors(handleException(e));
            }
            setLoading(false);
        }
    }

    return (
        <>
            <div className={"login-box-login"}>
                <h4>
                    {t("common.action.login")}
                </h4>
                <ErrorFormAlertView errors={errors}/>
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email}
                           className={"form-control" + ErrorFormHelper(errors, "username")}
                           placeholder={t("common.word.email")} aria-label="Recipient's username"
                           aria-describedby="cart-username"/>
                    <label htmlFor="cart-username">{t("common.word.email")}</label>
                    <ErrorFormView errors={errors} field="username"/>
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                           className={"form-control" + ErrorFormHelper(errors, "password")}
                           placeholder={t("common.word.password")} aria-label="Recipient's username"
                           aria-describedby="cart-password"
                    />
                    <label htmlFor="cart-password">{t("common.word.password")}</label>
                    <ErrorFormView errors={errors} field="password"/>
                </div>
            </div>
            <a className={"login-box-lost-password"} href={"#"}
               onClick={(e) => {
                   e.preventDefault();
                   props.setPage("LOST_PASSWORD")
               }}>
                {t("modules.user.field.forget_password.title")}
            </a>
            <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                    onClick={() => {
                        addClient()
                    }}>
                <span className="left spinner-border spinner-border-sm"></span>
                {t("common.action.login")}
            </button>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LoginUserComponent));
