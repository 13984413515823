import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ConfigAddressApi, ConfigApi, StoreConfigCoordinatesApi} from "../../../services/Api/types";
import {RootState} from "../../../../reducers";
import LoadingContainer from "../../../components/Common/LoadingContainer";
import NavbarLocationForm from "../../../components/Navbar/NavbarLocationForm";
import {useLocationService} from "../../../services/Location/locationService";
import {getSession} from "../../../services/Location/reducer";
import {getStoreDefaultConfig} from "../../../services/Store/reducer";
import {ReactComponent as MarkerSVG} from "../../../../images/icons/marker.svg";
import {ReactComponent as LocationSVG} from "../../../../images/icons/location.svg";
import IframeWidget from "../../../utils/IframeWidget";


interface OwnProps {
    config: ConfigApi,
    showModal: boolean,
    onHide: () => void,
    onChange: (address: ConfigAddressApi, coordinates: StoreConfigCoordinatesApi) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ChangeLocationMultistoreModal(props: Props) {
    const [errors, setErrors] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [address, setAddress] = useState(props.session.address);
    const {t} = useTranslation();
    const locationService = useLocationService();

    if (!props.default_config) return null;
    const geolocation = async () => {
        console.log("geolocation");
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("geolocationgood");
            locationService.updateCoordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
            props.onChange(props.session.address, {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
            setLoadingLocation(false);
        }, (err) => {
            console.log("geolocationwrong");
            if (err && err.message === "User denied Geolocation") {
                alert(t("constraints.internal.user_blocked_geolocation"));
            } else {
                alert(t("constraints.internal.location_fetching_failed"));
            }
            setLoadingLocation(false);
        });
    }
    const save = async () => {
        setLoading(true);
        setErrors([]);

        let addressData = address ? {
            city: address.city,
            street: address.street,
            build_nr: address.build_nr,
            flat_nr: address.flat_nr,
            zip_code: address.zip_code,
            country: address.country ? address.country : props.session?.address?.country
        } : {} as ConfigAddressApi;
        try {
            let data = await locationService.updateLocation(addressData);
            setLoading(false);
            props.onChange(data?.address, data.coordinates);
        } catch (err: any) {
            setLoading(false);
            setErrors(err.errors)
        }
    }
    const onShow = () => {
        setLoadingData(true);
        setAddress(props.session.address)
        setLoadingData(false);
    }
    const onHide = () => {
        setLoadingData(true);
        props.onHide();
    }

    return (

        <React.Fragment>
            <Modal dialogClassName="modal-full modal-new" show={props.showModal} onHide={() => IframeWidget.onHide(onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <button onClick={() => IframeWidget.onHide(onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"modal-info"}>
                        <div className={"icon-square mb-4 mt-4"}>
                            <MarkerSVG/>
                        </div>
                        <div className={"modal-info-content"}>
                            <h4>
                                {t("modules.multistore.field.check_delivery_options.title")}
                            </h4>
                            <div>
                                {t("modules.multistore.field.locate_your_address.title")}
                            </div>
                        </div>
                    </div>
                    <button
                        className={`btn btn-outline-primary btn-lg w-100 btn-flex btn-spinner${loadingLocation ? " loading" : ""}`}
                        onClick={geolocation}>
                        <span className="left spinner-border spinner-border-sm"/>
                        {t("modules.multistore.field.locate_my_address.title")} <LocationSVG/>
                    </button>

                    <div className="horizontal-divider">
                        <span>{t("modules.multistore.field.or_provide_data.title")}</span>
                    </div>
                    <div>
                        {(!props.showModal || loadingData) ? (
                            <LoadingContainer/>
                        ) : (
                            <NavbarLocationForm config={props.default_config}
                                                skipDeliveryAreaErrors={true}
                                                onErrors={(addressErrors: any) => setErrors(addressErrors)}
                                                onChange={(newAddress: any) => setAddress(newAddress)} errors={errors}
                                                address={address}/>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn-spinner w-100 btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                            onClick={() => {
                                save()
                            }}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.save")}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    default_config: getStoreDefaultConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ChangeLocationMultistoreModal));