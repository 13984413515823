import React from 'react';
import {useTranslation} from "react-i18next";


interface Props {
    value: any,
    currency?: any
}

const Money = (props: Props) => {
    const {t, i18n} = useTranslation();
    let money = undefined;
    if (!props.value) {
        money = {
            amount: 0,
            currency: "PLN"
        }
    } else {
        if (typeof props.value === 'object') {
            money = props.value
        } else {
            money = {
                amount: props.value,
                currency: props.currency !== undefined && props.currency ? props.currency : 'PLN'
            };
        }
    }
    return (
        <>{Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: money.currency
        }).format(money.amount)}</>
    );
}

export default Money;