class CustomFieldHelper {

    static isCustomFieldExist(customFields: any, orderType: any, orderCustomFields: any, section?: any) {
        if (customFields === undefined || !customFields || customFields.length <= 0) {
            return false;
        }
        if (orderCustomFields === undefined || !orderCustomFields || !orderCustomFields.some((customField: any) => {
            let customFieldTemplate = customFields.filter((x: any) => x.slug === customField.slug)[0];
            if (customFieldTemplate === undefined) {
                return false;
            }
            return orderType === customField.type && (customField.visibility === "VISIBLE" || customField.visibility === "REQUIRED") &&
                (
                    customField.section === section || ((!customField.section || customField.section === undefined) && (!section || section === undefined))
                )
                ;
        })) {
            return false;
        }
        return true;
    }
    static getVisible(name: any, orderCustomFields: any, type: any) {
        let orderCustomField = orderCustomFields.filter((orderCustomField: any) => orderCustomField.slug === name && orderCustomField.type === type)[0];
        if (orderCustomField) {
            return orderCustomField.visibility;
        }
        switch (name) {
            case "name":
                return "REQUIRED"
            case "phone":
            case "email":
                switch (type) {
                    case "DELIVERY":
                    case "PICK_UP":
                        return "REQUIRED"
                }
                return "VISIBLE"
            // case "taxIdNo":
                // return "HIDDEN"
            case "acceptMarketing":
                return "HIDDEN"
            // return "VISIBLE"
        }
        return "VISIBLE";
    }

    static isRequired(value: string) {
        return value === "REQUIRED" ? true : false;
    }

    static isVisible(value: string) {
        return value === "VISIBLE" || this.isRequired(value);
    }
}

export default CustomFieldHelper;
