import React from 'react';

import 'leaflet/dist/leaflet.css';
import {RootState} from "../../../../../../reducers";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {PlaceApi} from "../../../../../services/Api/types";
import PlaceItemMultistoreComponent from "../../../components/PlaceItemMultistoreComponent";


interface OwnProps {
    places: PlaceApi[],
    hoverPlace: (place?: string) => void
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreListContentComponent = (props: Props) => {
    const {t} = useTranslation();

    return (
        <>
            {props.places ? (
                <>
                    {props.places.map(place => {
                        return (
                            <div key={place.domain}
                                 onMouseEnter={() => props.hoverPlace(place.domain)}
                                 onMouseLeave={() => props.hoverPlace(undefined)}>
                                <PlaceItemMultistoreComponent place={place}/>
                            </div>
                        )
                    })}
                </>
            ) : (
                <>
                    {t("modules.multistore.field.no_restaurants.title")}
                </>
            )}
        </>
    );
};


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MultistoreListContentComponent));

