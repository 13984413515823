export enum CartActionTypes {
    REFRESH_BASKET = "@@cart/REFRESH_BASKET",
    CLEAR_BASKET = "@@cart/CLEAR_BASKET",
    ORDER_ERROR = "@@cart/ORDER_ERROR",
    ACCEPT_RULES = "@@cart/ACCEPT_RULES",
    ORDER_INVALID = "@@cart/ORDER_INVALID",
    ORDER_PENDING = "@@cart/ORDER_PENDING",
    CLEAN = "@@cart/CLEAN"
}

export type CartState = {
    id?: number;
    payment_method_id?: number;
    payment_method_name?: string;
    total_paid: any;
    contact: any;
    tax_id_no: string;
    custom_fields: any;
    number: string;
    items: any[];
    promotions: any[];
    estimated_preparation_at: string;
    pickup_at: string;
    type?: string;
    total?: any;
    discount_money?: any;
    status?: string;
    payment_status?: string;
    sub_total: any;
    address: any;
    coupon: any;
    coupon_id?: string;
    comment?: string;
    delivery_fee?: any;
    sub_total_delivery_fee?: any;
    discount_delivery_fee?: any;
    accepted_rules: string[]
    errors: any[];
    isValid: boolean;
    pending: boolean;
    initialized: boolean;
    fetch_status: string;
    payment_settings?: any;
    version?: string;
};