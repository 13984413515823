import {useLocation} from 'react-router-dom';
import {useEffect} from "react";
import IframeWidget from "./IframeWidget";

let oldPathname: string | undefined = undefined;

function ScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        const excludedRoutes = ["/menu/"]; // List of routes where scroll to top should be disabled
        const excludedOldRoutes = ["/menu/"]; // List of routes where scroll to top should be disabled
        const isExcluded = excludedRoutes.some(route => pathname.includes(route));
        const isExcludedOldPathname = excludedOldRoutes.some(route => oldPathname && oldPathname.includes(route));
        if (!isExcluded && !isExcludedOldPathname) {
            window.scrollTo(0, 0);
            IframeWidget.scrollToTop();
        }
        oldPathname = pathname;
    }, [pathname]);

    return null;
}

export default ScrollToTop;