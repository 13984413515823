import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as ArrowLeftSVG} from "../../../images/icons/arrow-left.svg";
import {ReactComponent as ArrowRightSVG} from "../../../images/icons/arrow-right.svg";
import ScrollContainer from "react-indiana-drag-scroll";

interface Props {
    children: React.ReactNode;
    hideScrollbars?: boolean;
    className?: string;
    scrollStep?: number;
}

const ScrollableTabs = ({children, hideScrollbars, className, scrollStep}: Props) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const buttonsWrapperContainer = useRef<HTMLDivElement | null>(null);
    const [navigationLeftButtonVisible, setNavigationLeftButtonVisible] = useState(false);
    const [navigationRightButtonVisible, setNavigationRightButtonVisible] = useState(true);

    useEffect(() => {
        handleScroll();
        window.addEventListener("resize", handleScroll);
        return () => window.removeEventListener("resize", handleScroll);
    }, []);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener("scroll", handleScroll);
            }
        }

    }, [scrollContainerRef.current]);

    const handleNavigate = (direction: "left" | "right") => {
        if (scrollContainerRef.current) {
            const {scrollLeft,} = scrollContainerRef.current;
            const finalScrollStep = scrollStep ?? 100;

            if (direction === "left") {
                scrollContainerRef.current.scrollTo({left: scrollLeft - finalScrollStep, behavior: "smooth"});
            } else {
                scrollContainerRef.current.scrollTo({left: scrollLeft + finalScrollStep, behavior: "smooth"});
            }
        }
    };

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const {scrollLeft, scrollWidth, clientWidth} = scrollContainerRef.current;
            setNavigationLeftButtonVisible(scrollLeft > 0);
            setNavigationRightButtonVisible(scrollLeft + clientWidth < scrollWidth - 1);
        }
    };

    return (
        <div className={`nav-scrolling-buttons-wrapper ${className ?? ""}`} ref={buttonsWrapperContainer}>
            {navigationLeftButtonVisible && (
                <div className={"navigate-left"}>
                    <button className={"btn btn-default btn-icon"} onClick={() => handleNavigate("left")}>
                        <ArrowLeftSVG/>
                    </button>
                </div>
            )}
            {
                navigationRightButtonVisible && (
                    <div className={"navigate-right"}>
                        <button className={"btn btn-default btn-icon"} onClick={() => handleNavigate("right")}>
                            <ArrowRightSVG/>
                        </button>
                    </div>
                )
            }
            <ScrollContainer innerRef={scrollContainerRef} className="nav nav-tabs-top" hideScrollbars={hideScrollbars}>
                {children}
            </ScrollContainer>
        </div>
    )
}

export default ScrollableTabs;