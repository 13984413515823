import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {getAvailabilities, getCustomFields} from "../../services/Menu/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import {getSession} from "../../services/Location/reducer";
import LocationSearchWithSave from "../Search/LocationSearchWithSave";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import ErrorFormView from "../Common/ErrorFormView";
import ChangeTableNumberModal from "../Modal/ChangeTableNumberModal";
import ChangeRoomServiceNumberModal from "../Modal/ChangeRoomServiceNumberModal";
import CitySearchComponent from "../Location/CitySearchComponent";
import StreetSearchComponent from "../Location/StreetSearchComponent";
import ErrorFormAlertView from "../Common/ErrorFormAlertView";
import {useLocationService} from "../../services/Location/locationService";
import {updateSessionLocation} from "../../services/Location/actions";
import {ConfigAddressApi} from "../../services/Api/types";
import {getCart} from "../../services/Cart/reducer";
import {ReactComponent as CarSVG} from "../../../images/icons/car.svg";
import {ReactComponent as PickUpSVG} from "../../../images/icons/pick-up.svg";
import {ReactComponent as DineInSVG} from "../../../images/icons/dine-in.svg";
import {ReactComponent as RoomServiceSVG} from "../../../images/icons/room-service.svg";
import {ReactComponent as BarcodeSVG} from "../../../images/icons/barcode.svg";
import {ReactComponent as EditSVG} from "../../../images/icons/edit.svg";
import StoreUtils from "../../services/Store/StoreUtils";


interface OwnProps {
    errors: any[],
    onChange: (cart: any) => void,
    onChangeCustomField: (slug: any, value: any) => void
    data: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartTypeComponent = (props: Props) => {
    const cart = props.cart;
    const [pickDateModal, setPickDateModal] = useState(false);
    const [tableNumberModal, setTableNumberModal] = useState(false);
    const [roomNumberModal, setRoomNumberModal] = useState(false);
    const [loading, setLoading] = useState(false);
    // const cartType = cart.type;
    // const cartNumber = cart.number;
    const orderType = props.session.order_type;
    const tableNumber = props.session.table_number;
    const roomNumber = props.session.room_number;
    const {t} = useTranslation();
    const locationService = useLocationService();
    const handleChangeCity = (value: any, zip: any) => {
        let address = {...props.session.address};
        address.city = value;
        address.zip_code = zip;
        props.dispatch(updateSessionLocation(address, true));
    }
    const handleChangeStreet = (value: any) => {
        let address = {...props.session.address};
        address.street = value;
        props.dispatch(updateSessionLocation(address, true));
    }
    const handleChangeNumber = (event: any) => {
        let address = {...props.session.address};
        address.build_nr = event.target.value;
        props.dispatch(updateSessionLocation(address, true));
    }
    const handleChangeFlatNumber = (event: any) => {
        let address = {...props.session.address};
        address.flat_nr = event.target.value;
        props.dispatch(updateSessionLocation(address, true));
    }

    const handleChangeType = async (event: any) => {
        let type = event.target.value;
        setLoading(true);
        switch (type) {
            case "DINE_IN":
                if (props.config?.ordering.table && !StoreUtils.isOrderingPreview(props.config)) {
                    setTableNumberModal(true)
                } else {
                    let sessionData = {
                        ...props.session,
                        order_type: type
                    }
                    try {
                        await locationService.update(sessionData);
                    } catch (error) {
                    }
                }
                break;
            case "ROOM_SERVICE":
                if (!StoreUtils.isOrderingPreview(props.config)) {
                    setRoomNumberModal(true)
                } else {
                    let sessionData = {
                        ...props.session,
                        order_type: type
                    }
                    try {
                        await locationService.update(sessionData);
                    } catch (error) {
                    }
                }
                break;
            default:
                let sessionData = {
                    ...props.session,
                    order_type: type
                }
                try {
                    await locationService.update(sessionData);
                } catch (error) {
                }
                break;
        }
        setLoading(false);
    }

    useEffect(() => {
        refreshLocation();
    }, [props.session.address.city, props.session.address.street, props.session.address.build_nr]);
    const onBlur = async () => {
    }
    const refreshLocation = async () => {
        let addressData = props.session.address ? {
            city: props.session.address.city,
            street: props.session.address.street,
            build_nr: props.session.address.build_nr,
            flat_nr: props.session.address.flat_nr,
            zip_code: props.session.address.zip_code,
            country: props.session.address.country ? props.session.address.country : props.session?.address?.country
        } : {} as ConfigAddressApi;
        try {
            let data = await locationService.updateLocation(addressData);
        } catch (err: any) {
            console.log(err);
        }
    }

    let isSelectCities = true;
    if (props.config?.ordering.cities_radius) {
        isSelectCities = true;
    }
    let address = props.session.address;
    let isDeliveryAreaView = undefined;
    if (orderType === "DELIVERY") {
        if (!props.session.in_delivery_area) {
            let errorMEssage = [t("constraints.internal.store_address_beyond_delivery")];
            isDeliveryAreaView = (
                <ErrorFormAlertView errors={errorMEssage}/>
            );
        }
    }
    let deliveryOptionView = null;
    let pickupOptionView = null;
    let dineInOptionView = null;
    let roomServiceOptionView = null;
    if (props.config?.ordering.delivery || orderType === 'DELIVERY') {
        deliveryOptionView = (
            <label
                className={`btn btn-default btn-lg btn-check-full btn-spinner${(props.cart.pending || loading) ? " loading" : ""}${orderType === "DELIVERY" ? " active" : ""}`}>
                <CarSVG/>
                <span className="mx-2">{t("enums.order.type.DELIVERY")}</span>
                <input className="btn-check1" type="radio" name="type" value="DELIVERY" autoComplete="off"
                       checked={orderType === "DELIVERY"} onChange={handleChangeType}/>
                <div className="spinner-border spinner-border-sm"></div>
            </label>
        );
    }
    if (props.config?.ordering.takeaway || orderType === 'PICK_UP') {
        pickupOptionView = (
            <label
                className={`btn btn-default btn-lg btn-check-full btn-spinner${(props.cart.pending || loading) ? " loading" : ""}${orderType === "PICK_UP" ? " active" : ""}`}>
                <PickUpSVG/>
                <span className="mx-2">{t("enums.order.type.PICK_UP")}</span>
                <input className="btn-check1" type="radio" name="type" value="PICK_UP" autoComplete="off"
                       checked={orderType === "PICK_UP"} onChange={handleChangeType}/>
                <div className="spinner-border spinner-border-sm"></div>
            </label>
        );
    }

    if (props.config?.ordering.dine_in || orderType === 'DINE_IN') {
        dineInOptionView = (
            <label
                className={`btn btn-default btn-lg btn-check-full btn-spinner${(props.cart.pending || loading) ? " loading" : ""}${orderType === "DINE_IN" ? " active" : ""}`}>
                <DineInSVG/>
                <span className="mx-2">{t("enums.order.type.DINE_IN")}</span>
                <input className="btn-check1" type="radio" name="type" value="DINE_IN" autoComplete="off"
                       checked={orderType === "DINE_IN"} onChange={handleChangeType}/>
                <div className="spinner-border spinner-border-sm"></div>
                <ChangeTableNumberModal tableName={tableNumber} show={tableNumberModal}
                                        onSave={() => setTableNumberModal(false)}
                                        onHide={() => setTableNumberModal(false)}/>
            </label>
        );
    }

    if (props.config?.ordering.room_service || orderType === 'ROOM_SERVICE') {
        roomServiceOptionView = (
            <label
                className={`btn btn-default btn-lg btn-check-full btn-spinner${(props.cart.pending || loading) ? " loading" : ""}${orderType === "ROOM_SERVICE" ? " active" : ""}`}>
                <RoomServiceSVG/>
                <span className="mx-2">{t("enums.order.type.ROOM_SERVICE")}</span>
                <input className="btn-check1" type="radio" name="type" value="ROOM_SERVICE" autoComplete="off"
                       checked={orderType === "ROOM_SERVICE"}
                       onChange={handleChangeType}/>
                <div className="spinner-border spinner-border-sm"></div>
                <ChangeRoomServiceNumberModal roomNumber={roomNumber} show={roomNumberModal}
                                              onSave={() => setRoomNumberModal(false)}
                                              onHide={() => setRoomNumberModal(false)}/>
            </label>
        );
    }

    return (
        <React.Fragment>
            <div className="card-cart">
                <div className={"container-fluid"}>
                    <h5 className={"card-cart-header"}>
                        {t("modules.cart.shipment.header.title")}
                    </h5>
                    <div className={"card-cart-body"}>
                        <div>
                            <div className="btn-groups-columns">
                                <div className="btn-group1 btn-group-toggle btn-checks" data-toggle="buttons">
                                    {deliveryOptionView}
                                    {pickupOptionView}
                                    {dineInOptionView}
                                    {roomServiceOptionView}
                                </div>
                            </div>
                            {/*{CustomFieldHelper.isCustomFieldExist(props.custom_fields, cart.type, orderCustomFields, "ADDRESS") && (*/}
                            {/*    <div className="form-row row">*/}
                            {/*        <CartCustomFieldsComponent availabilities={props.availabilities} section={"ADDRESS"}*/}
                            {/*                                   orderCustomFields={orderCustomFields}*/}
                            {/*                                   orderType={cart.type}*/}
                            {/*                                   customFields={props.custom_fields}*/}
                            {/*                                   valueCustomFields={cart.custom_fields}*/}
                            {/*                                   errors={props.errors}*/}
                            {/*                                   onChange={props.onChangeCustomField}/>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/*{pickDateOptionView}*/}
                        </div>
                    </div>
                </div>
            </div>
            {!StoreUtils.isOrderingPreview(props.config) ? (
                <>

                    {orderType === "DELIVERY" && (
                        <>
                            <div className="card-cart">
                                <div className={"container-fluid"}>
                                    <h5 className={"card-cart-header"}>
                                        {t("modules.cart.field.delivery_address.title")}
                                    </h5>
                                    <div className={"card-cart-body"}>
                                        <div>
                                            {isDeliveryAreaView}
                                            <div className="form-row row">
                                                {isSelectCities ? (
                                                    <>
                                                        <div
                                                            className={`form-group col-lg-4  col-sm-6${address.city ? " focus" : ""}${ErrorFormHelper(props.errors, "address.city")}`}>
                                                            <CitySearchComponent
                                                                placeholder={t("common.address.city") + "*"}
                                                                className={ErrorFormHelper(props.errors, "address.city")}
                                                                onBlur={onBlur} value={address.city}
                                                                zip={address.zip_code}
                                                                onChange={(value: any, zip: any) => handleChangeCity(value, zip)}/>
                                                            <label htmlFor="city">{t("common.address.city")}</label>
                                                            <ErrorFormView errors={props.errors} field="address.city"/>
                                                        </div>
                                                        <div
                                                            className={`form-group col-lg-4  col-sm-6${address.street ? " focus" : ""}${ErrorFormHelper(props.errors, "address.street")}`}>
                                                            <StreetSearchComponent city={address.city}
                                                                                   className={ErrorFormHelper(props.errors, "address.street")}
                                                                                   placeholder={t("common.address.street") + "*"}
                                                                                   onBlur={onBlur}
                                                                                   value={address.street}
                                                                                   onChange={(value: any) => handleChangeStreet(value)}/>
                                                            <label htmlFor="street">{t("common.address.street")}</label>
                                                            <ErrorFormView errors={props.errors}
                                                                           field="address.street"/>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="form-group col-lg-8 col-sm-6">
                                                        <LocationSearchWithSave/>
                                                    </div>
                                                )}
                                                <div className="form-group col-lg-2 col-sm-6">
                                                    <input type="text" value={address.build_nr} onBlur={onBlur}
                                                           onChange={handleChangeNumber}
                                                           className={"form-control" + ErrorFormHelper(props.errors, "address.buildNr")}
                                                           id="build_nr"
                                                           placeholder={t("common.address.number") + "*"}/>
                                                    <label htmlFor="build_nr">{t("common.address.number")}</label>
                                                    <ErrorFormView errors={props.errors} field="address.buildNr"/>
                                                </div>
                                                <div className="form-group col-lg-2  col-sm-6">
                                                    <input type="text" value={address.flat_nr}
                                                           onChange={handleChangeFlatNumber}
                                                           onBlur={onBlur}
                                                           className={"form-control" + ErrorFormHelper(props.errors, "address.flat_nr")}
                                                           id="flat_nr" placeholder={t("common.address.flat_nr")}/>
                                                    <label htmlFor="flat_nr">{t("common.address.flat_nr")}</label>
                                                    <ErrorFormView errors={props.errors} field="address.flat_nr"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {orderType === "DINE_IN" && (
                        <>
                            {props.config?.ordering.table ? (
                                <div className="card-cart">
                                    <div className={"container-fluid"}>
                                        <h5 className={"card-cart-header"}>
                                            {t("common.word.table")}
                                            <small>
                                                {t("modules.cart.field.scan_table_qr_code.title")}
                                            </small>
                                        </h5>
                                        <ErrorFormAlertView field={"number"} errors={props.errors}/>
                                        <div className={"card-cart-body"}>
                                            <button className={"btn btn-outline-primary w-100 btn-lg"}
                                                    onClick={() => setTableNumberModal(true)}>
                                                {props.cart.number ? (
                                                    <>
                                                        {`${t("modules.cart.field.table_nr.title", {number: props.cart.number})} `}
                                                        <EditSVG/>
                                                    </>
                                                ) : (
                                                    <>
                                                        {`${t("modules.cart.action.scan_table_qr_code.title")} `}
                                                        <BarcodeSVG/>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="card-cart">
                                    <div className={"container-fluid"}>
                                        <h5 className={"card-cart-header"}>
                                            {t("modules.cart.field.self_collection.title")}
                                            <small>
                                                {t("modules.cart.field.self_collection.description")}
                                            </small>
                                        </h5>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {orderType === "ROOM_SERVICE" && (
                        <>
                            <div className="card-cart">
                                <div className={"container-fluid"}>
                                    <h5 className={"card-cart-header"}>
                                        {t("enums.order.type.ROOM_SERVICE")}
                                        <small>
                                            {t("modules.cart.field.scan_room_qr_code.title")}
                                        </small>
                                    </h5>
                                    <ErrorFormAlertView field={"number"} errors={props.errors}/>
                                    <div className={"card-cart-body"}>
                                        <button className={"btn btn-outline-primary w-100 btn-lg"}
                                                onClick={() => setRoomNumberModal(true)}>
                                            {props.cart.number ? (
                                                <>
                                                    {`${t("modules.cart.field.room_nr.title", {number: props.cart.number})} `}
                                                    <EditSVG/>
                                                </>
                                            ) : (
                                                <>
                                                    {`${t("modules.cart.action.scan_table_qr_code.title")} `}
                                                    <BarcodeSVG/>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    config: getStoreConfig(state.config),
    custom_fields: getCustomFields(state.menu),
    availabilities: getAvailabilities(state.menu),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartTypeComponent));
