import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {useParams} from "react-router-dom";
import {ApiError, PageApi} from "../../services/Api/types";
import Hero from "./components/Hero/Hero";
import Navbar from "../../components/Navbar/Navbar";
import {getStoreConfig, getStoreConfigLoyalty} from "../../services/Store/reducer";
import Footer from "../../components/layout/Footer/Footer";
import HomePageMenusComponent from "./components/HomePageMenusComponent";
import AdvertUserComponent from "../../components/User/components/AdvertUser";
import UserUtils from "../../services/User/UserUtils";
import {getUser} from "../../services/User/reducer";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;
const HomePage = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<PageApi | undefined>(undefined);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const {t} = useTranslation();
    const {pageId} = useParams();

    return (
        <>
            <Navbar/>
            <Hero config={props.config}/>
            <HomePageMenusComponent/>
            {(UserUtils.isLoyaltyRegistration(props.config_loyalty) && (!props.user || !props.user.name)) && (
                <div className={"homepage-advert-user"}>
                    <AdvertUserComponent/>
                </div>
            )}
            <Footer config={props.config}/>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    user: getUser(state.user),
    config_loyalty: getStoreConfigLoyalty(state.config)
})

const connector = connect(mapStateToProps);

export default connector(HomePage);