import React, {useEffect, useState} from 'react';
import {ConfigApi} from "../../../services/Api/types";
import StringHelper from "../../../services/Common/StringHelper";
import {useTranslation} from "react-i18next";

interface Props {
    config: ConfigApi,
    paymentMethods: any
}

const Rules2021Component = (props: Props) => {
    const company = props.config.company;
    let companyName = null;
    let email = null;
    let phone = null;
    let addressView = null;
    let taxIdNoView = null;
    const paymentProviders: Record<string, string>[] = [];
    const {t} = useTranslation();


    if (company) {
     companyName = company.legal_name;
         if (company.address) {
             addressView = StringHelper.getAddressName(company.address);
         }
     taxIdNoView = company.tax_id_no;
     email = props.config.contact.email;
     phone = props.config.contact.phone;
    }

    if (props.paymentMethods.some((paymentMethod: any) => paymentMethod.gateway == 'BLUEMEDIA')) {
        paymentProviders.push({
            "name": "Autopay S.A.",
            "full_name": " Autopay S.A. z siedzibą w Sopocie przy ulicy Powstańców Warszawy 6, kod 81-718. Spółka została zarejestrowana w Sądzie Rejonowym Gdańsk-Północ VIII Wydział Gospodarczy KRS pod nr 0000320590, NIP 585-13-51-185, REGON 191781561.",
            "img": "autopay.png"
        });
    }

    if (props.paymentMethods.some((paymentMethod: any) => paymentMethod.gateway == 'TPAY')) {
        paymentProviders.push({
            "name": "Krajowy Integrator Płatności spółka akcyjna",
            "full_name": " Krajowy Integrator Płatności spółka akcyjna z siedzibą w Poznaniu, plac Andersa 3, 17 piętro, 61-894 Poznań, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, VIII Wydział Krajowego Rejestru Sądowego pod numerem KRS 0000412357, NIP 7773061579, REGON 300878437.",
            "img": "tpay.svg"
        });
    }

    const renderMultilineText = (text: string) => {
        return text.split("\n")
            .map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))
    };

    return (
        <div className='page rules-component'>
            <div className='header-container'>
                <h1>{t("modules.rules.header.title")}</h1>
            </div>

            <h4>
                {t("modules.rules.field.company_data.title")}
            </h4>

            <p>
                {companyName}<br/>
                {addressView}<br/>
                {`${t("common.word.tax_id_no")}: ${taxIdNoView ?? "-"}`}<br/>
                {`${t("common.word.phone_number")}: ${phone ?? "-"}`}<br/>
                {`${t("common.word.email")}: ${email ?? "-"}`}<br/>
            </p>
            <h4>
                {t("modules.rules.field.terms_of_service.title")}
            </h4>

            <p>
                {renderMultilineText(t("modules.rules.field.terms_of_service.description"))}
            </p>

            <h4>
                {t("modules.rules.field.offer.title")}
            </h4>

            <p>
                {renderMultilineText(t("modules.rules.field.offer.description"))} <a href={"https://gopos.pl/tos/privacy-policy"}>https://gopos.pl/tos/privacy-policy</a>
            </p>

            <h4>
                {t("modules.rules.field.payment.title")}
            </h4>

            <p>
                {renderMultilineText(t("modules.rules.field.payment.description"))}
                {
                    paymentProviders.map((pamentProvider) => pamentProvider.name)
                } <br/>
                {renderMultilineText(t("modules.rules.field.online_payments.title"))}
                {

                    paymentProviders.map((paymentProvider: any) =>
                        <React.Fragment key={paymentProvider}>
                            - {paymentProvider.full_name} <br/>
                            {paymentProvider.img ? (
                                <img style={{padding: 20}} className={"w-100"}
                                     src={"/images/providers/" + paymentProvider.img}/>
                            ) : ""}
                        </React.Fragment>
                    )
                }
                {renderMultilineText(t("modules.rules.field.refund.title"))}
            </p>

            <h4>
                {t("modules.rules.field.complaint.title")}
            </h4>

            <p>
                {renderMultilineText(t("modules.rules.field.complaint.description"))}
            </p>
        </div>
    )
}

export default Rules2021Component;