import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getStoreConfig} from "../../../../services/Store/reducer";
import {getSession} from "../../../../services/Location/reducer";
import {RootState} from "../../../../../reducers";
import {ReactComponent as SearchSVG} from "../../../../../images/icons/search.svg";


interface OwnProps {
    onSearch: (value?: string) => void,
    value: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuSearchFilterComponent = (props: Props) => {
    const {t} = useTranslation();

    if (!props.config) return null;

    const search = (value?: string) => {
        props.onSearch(value);
    }

    return (
        <div className={"container-fluid"}>
            <div className="input-search menu-filters">
                <input type="text" className="form-control" placeholder={t("modules.menu.field.search_product.title")} value={props.value}
                       onChange={(e) => search(e.target.value)}/>
                <SearchSVG className={"left"}/>
                {/*<div className={"right"} onClick={clearSearch}>*/}
                {/*    <SearchSVG/>*/}
                {/*</div>*/}
            </div>
        </div>
    )

}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuSearchFilterComponent));