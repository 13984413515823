import React, {FC, useEffect, useRef, useState} from 'react';
import {connect, DispatchProp} from 'react-redux'
import {RootState} from "../../../reducers";
import {useParams} from "react-router-dom";
import Analytics from "../../services/Common/Analytics/Analytics";
import Pixel from "../../services/Common/Analytics/Pixel";
import LoadingContainer from "../../components/Common/LoadingContainer";
import NavbarCart from "../../components/Navbar/NavbarCart";
import {useTranslation} from "react-i18next";
import {getItems, getModifierGroups} from "../../services/Menu/reducer";
import {getStoreConfig} from "../../services/Store/reducer";
import SummarySummaryComponent from "./components/SummarySummaryComponent";
import SummaryContactComponent from "./components/SummaryContactComponent";
import SummaryItemsComponent from "./components/SummaryItemsComponent";
import ErrorView from "../../components/Common/ErrorView";
import {api} from "../../services/Api/api";
import SummaryOrderDetailsComponent from "./components/SummaryOrderDetailsComponent";
import SummaryOrderDataComponent from "./components/SummaryOrderDataComponent";
import SummaryStatusComponent from "./components/SummaryStatusComponent";
import FetchData from "../../components/Fetch/FetchData";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

const SummaryPage: FC<Props> = (props) => {
    const [pending, setPending] = useState(true);
    const [order, setOrder] = useState<any>(undefined);
    const [error, setError] = useState<any>(undefined);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const {t} = useTranslation();

    const {id, token} = useParams();
    const refreshOrder = async (id: any, token: any, withoutPending?: any, withRefresh?: any) => {

        if (!shouldRefreshing()) {
            setPending(false);
            return;
        }
        try {
            let options = {} as any;
            if (withRefresh) options.refresh = "1";
            const data = await api.getOrder(id, token, options);
            if (!withoutPending) {
                Analytics.purchase(data);
                Pixel.purchase(data);
            }
            setOrder(data);
            setPending(false);
            if (data.status === 'CONFIRMED' && data.payment_status === 'PROGRESS') {
                withRefresh = true;
            }
            timeoutRef.current = setTimeout(() => {
                refreshOrder(id, token, true, withRefresh);
            }, 5000);

        } catch (error) {
            alert(error);
            console.log(error);
            setError(error);
            setPending(false);
        }
    }

    useEffect(() => {
        setPending(true);
        refreshOrder(id, token);
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
    }, []);

    const shouldRefreshing = () => {
        if (!order) {
            return true;
        }
        if (order.status == 'OPEN' || order.status == 'CONFIRMED' || order.status == 'WAITING_FOR_ACCEPTED') {
            return true;
        }
        return false;
    }

    const shouldComponentRender = () => {
        if (pending === false) return true;
        return false;
    }


    if (!shouldComponentRender()) return (
        <div>
            <LoadingContainer/>
        </div>
    );
    if (error) {
        return (
            <div>
                <ErrorView>
                    {error}
                </ErrorView>
            </div>
        );
    }

    return (
        <FetchData menus={true} >
            <div className="summary-container">
                <NavbarCart/>
                <div className="cart">
                    <SummaryStatusComponent onUpdate={(order) => setOrder(order)} order={order} config={props.config}/>

                    <div className="cart cart-container">
                        <div className={"card-cart"}>
                            <div className={"container-fluid"}>
                                <h3 className={"card-cart-header"}>{t("modules.summary.field.products.title")}<span
                                    className="badge badge-light">{order.items.length}</span></h3>
                                <div className={"card-cart-body"}>
                                    <SummaryItemsComponent orderItems={order.items} items={props.items}
                                                           promotions={order.promotions}
                                                           modifierGroups={props.modifierGroups}/>
                                </div>
                            </div>
                        </div>
                        <SummaryOrderDetailsComponent order={order}></SummaryOrderDetailsComponent>
                        <SummaryContactComponent order={order}></SummaryContactComponent>
                        <SummaryOrderDataComponent order={order}></SummaryOrderDataComponent>
                        <SummarySummaryComponent order={order}></SummarySummaryComponent>
                    </div>
                </div>
            </div>
        </FetchData>
    )
}

const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    modifierGroups: getModifierGroups(state.menu),
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);

export default connector(SummaryPage);