import {getWeekDays, isTimeAllDay, setTimeByString} from "../../../Common/Date/DateHelper";
import StringHelper from "../../../Common/StringHelper";

class HoursHelper {

    static getTime = (i: any) => {
        return (i >= 24) ? "00" : (i < 10) ? "0" + i : i;
    }
    static sortOpenHours = (hours: any) => {
        let weekDays = getWeekDays();
        return hours.sort((a: any, b: any) => weekDays.indexOf(a.day_from.toLowerCase()) - weekDays.indexOf(b.day_from.toLowerCase()));
    }
    static isMinuteAvailable = (i: any, minHours: any, maxHours: any, minMinutes: any, maxMinutes: any, time: any) => {
        if (i !== minHours && i !== maxHours) {
            return true;
        }
        if (i === minHours && minMinutes > time) {
            return false;
        }
        if (i === maxHours && maxMinutes < time) {
            return false;
        }
        return true;
    }
    static getDayOfWeekNumber = (dayOfWeek: any) => {
        let weekday = StringHelper.getWeekday();
        var index = weekday.indexOf(dayOfWeek.toLowerCase());
        return index;
    }
    static getOpenHours = (hours: any, date: any) => {
        if (hours === undefined) {
            return undefined;
        }
        var d = date;
        let dayOfWeekIndex = d.getDay();
        let currentHours = [] as any[];
        hours.forEach((hourItem: any) => {
            let dayFromIndex = this.getDayOfWeekNumber(hourItem.day_from);
            let dayToIndex = this.getDayOfWeekNumber(hourItem.day_to);
            let hourFrom = hourItem.hour_from;
            let hourTo = hourItem.hour_to;
            if (hourFrom > hourTo) {
                if (dayOfWeekIndex === 0) {
                    if (dayToIndex === 7) {
                        let newHourTo = "00:00";
                        if (newHourTo != hourTo) {
                            currentHours.push({
                                hour_from: newHourTo,
                                hour_to: hourTo,
                            });
                        }
                    }
                    // hourTo = "24:00";
                } else {
                    if (dayToIndex === dayOfWeekIndex - 1) {
                        let newHourTo = "00:00";
                        if (newHourTo != hourTo) {
                            currentHours.push({
                                hour_from: newHourTo,
                                hour_to: hourTo,
                            })
                        }
                    }
                    // hourTo = "24:00";
                }
            }
            if (dayOfWeekIndex >= dayFromIndex && dayOfWeekIndex <= dayToIndex) {
                currentHours.push({
                    hour_from: hourFrom,
                    hour_to: hourTo,
                })
            }
        });
        return currentHours
    }
    static isOpen = (openHours: any, date: any) => {
        let dayOpenHours = this.getOpenHours(openHours, date);
        if (!dayOpenHours) return false;
        openHours = dayOpenHours.filter(
            openHour => {
                let hourFrom = openHour.hour_from;
                let hourTo = openHour.hour_to;
                if (isTimeAllDay(hourFrom) && isTimeAllDay(hourTo)) {
                    return true;
                }

                var hourFormD = new Date(date.getTime());
                setTimeByString(hourFormD, hourFrom);
                // hourFormD.setHours(hourFrom.substring(0, 2), hourFrom.substring(3, 5),0); // 5.30 pm
                var hourToD = new Date(date.getTime());
                if (hourFrom > hourTo) {
                    hourToD.setDate(hourToD.getDate() + 1);
                }
                setTimeByString(hourToD, hourTo);
                // hourToD.setHours(hourTo.substring(0, 2), hourTo.substring(3, 5),0); // 6.30 pm

                if (date >= hourFormD && date < hourToD) {
                    return true;
                } else {
                    return false;
                }
            }
        );
        if (openHours.length === 0) {
            return false;
        }
        return true;
    }

    static closetOpenHours = (openHours: any, date: any) => {
        let dayOpenHours = this.getOpenHours(openHours, date);
        if (!dayOpenHours) return undefined;
        const currentHour = date.getHours();
        const currentMinute = date.getMinutes();

        var hours = dayOpenHours.map(x => x.hour_from);

        // Convert each hour string to a number of minutes since midnight
        const timesInMinutes = hours.map(time => {
            const [hour, minute] = time.split(':').map(Number);
            return hour * 60 + minute;
        });

        // Convert the current time to minutes since midnight
        const currentTimeInMinutes = currentHour * 60 + currentMinute;

        // Filter times to keep only those smaller than the current time
        const smallerTimes = timesInMinutes.filter(time => time > currentTimeInMinutes);

        if (smallerTimes.length === 0) {
            return undefined; // No smaller times found
        }

        // Find the closest smaller time (maximum of the filtered array)
        const closestTimeInMinutes = Math.max(...smallerTimes);

        // Convert the closest time back to "HH:mm" format
        const closestHour = Math.floor(closestTimeInMinutes / 60);
        const closestMinute = closestTimeInMinutes % 60;

        return `${closestHour.toString().padStart(2, '0')}:${closestMinute.toString().padStart(2, '0')}`;
    }
}

export default HoursHelper;