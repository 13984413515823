import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {getCart} from '../../services/Cart/reducer';
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import Money from "../Common/Money";
import {getSession} from "../../services/Location/reducer";

interface OwnProps {
    goToCart: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartSummaryDataComponent = (props: Props) => {
    const cart = props.cart;
    const numberItems = cart.items.length;
    const {t} = useTranslation();


    const renderDiscountMoney = () => {
        if (!cart || !cart.discount_money || cart.discount_money.amount === 0) {
            return null;
        }
        return (
            <dl>
                <dt>
                    {t("common.word.discount")}
                </dt>
                <dd>
                    -<Money value={cart.discount_money}/>
                </dd>
            </dl>
        )
    }

    let deliveryView = null;

    if (cart.sub_total_delivery_fee && cart.sub_total_delivery_fee.amount > 0 && cart.discount_delivery_fee && cart.discount_delivery_fee.amount > 0) {
        deliveryView = (
            <dl>
                <dt>
                    {t("common.word.delivery")}
                </dt>
                <dd>
                        <span className="text-danger">
                            <del>
                            <Money
                                value={cart.sub_total_delivery_fee}
                                currency='PLN'/>
                            </del>
                        </span>
                    <Money
                        value={cart.delivery_fee}
                        currency='PLN'/>
                </dd>
            </dl>
        )
    } else {
        if (cart.delivery_fee && cart.delivery_fee.amount > 0) {
            deliveryView = (
                <dl>
                    <dt>
                        {t("common.word.delivery")}
                    </dt>
                    <dd>
                        <Money value={cart.delivery_fee}/>
                    </dd>
                </dl>
            )
        }
    }

    return (
        <div className={"cart-summary"}>
            <dl>
                <dt>
                    {t("common.word.summary_items")}
                </dt>
                <dd>
                    <Money value={cart.sub_total}/>
                </dd>
            </dl>
            {renderDiscountMoney()}
            {deliveryView}
            <div className="separator"></div>
            <dl className={"total"}>
                <dt>{t("common.word.sum")}</dt>
                <dd>
                    <Money value={cart.total}/>
                </dd>
            </dl>
            {/*{renderDiscountMoney()}*/}
            {/*<div className="d-flex justify-content-between summary-total-price">*/}
            {/*    <div>*/}
            {/*        <strong>{t("common.word.sum")}</strong>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <strong>*/}
            {/*            <Money value={cart.total}/>*/}
            {/*        </strong>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartSummaryDataComponent));