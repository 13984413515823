import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import CartButtonComponent from "./CartButtonComponent";
import CartSummaryDataComponent from "./CartSummaryDataComponent";
import CartItemsComponent from "./CartItemsComponent";
import {ReactComponent as BasketIco} from "../../../images/icons/basket.svg";
import {getSession} from "../../services/Location/reducer";
import Money from "../Common/Money";
import {getCart} from "../../services/Cart/reducer";
import CartCashbackComponent from "./CartCashbackComponent";
import CartVouchers from "./components/CartVouchers";

interface OwnProps {
    goToCart: () => void;
    showVouchers?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartStickyComponent = (props: Props) => {
    const {t} = useTranslation();

    const renderDeliverInfo = () => {
        let minPriceView = null;

        if (props.session.order_type !== 'ROOM_SERVICE' && props.session.order_type !== 'DELIVERY') {
            if (props.session?.default_min_order_price_money) {
                minPriceView = (
                    <p className='mb-0'>
                        {t("common.word.min_order_price")}: <strong><Money
                        value={props.session?.default_min_order_price_money}/></strong>
                    </p>
                );
            }
        }
        if (props.session.order_type === 'ROOM_SERVICE') {
            if (props.session?.default_min_order_price_money) {
                minPriceView = (
                    <p className='mb-0'>
                        {t("common.word.min_order_price")}: <strong><Money
                        value={props.session?.default_min_order_price_money}/></strong>
                    </p>
                );
            }
        }
        if (props.session.order_type === 'DELIVERY') {
            if (props.session.min_order_price_money) {
                minPriceView = (
                    <p className='mb-0'>
                        {t("common.word.min_order_price")}: <strong><Money
                        value={props.session?.min_order_price_money}/></strong>
                    </p>
                )
            }
        }
        return minPriceView
    }

    const minPrice = renderDeliverInfo();

    return (
        <div className="cart-sticky sticky-fixed  sticky-fixed-cart fixed-height-100">
            <div className={"cart-sticky-container"}>
                <div className="cart-sticky-header">
                    <h3>
                        <BasketIco/>
                        {t("modules.cart.header.title")}
                        <span className="badge badge-light">{props.cart.items.length}</span>
                    </h3>
                </div>
                <div className={"cart-sticky-body position-relative"}>
                    <CartItemsComponent/>
                    <CartCashbackComponent/>
                    {props.cart.items.length > 0 && <CartSummaryDataComponent/>}
                </div>
                <div className={"cart-sticky-footer"}>
                    {props.showVouchers && <CartVouchers/>}
                    {props.goToCart !== undefined && <CartButtonComponent goToCart={props.goToCart}/>}
                    {minPrice && (
                        <div className={"text-center mt-3"}>
                            {minPrice}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartStickyComponent));