import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {getStoreConfig} from "../../../../services/Store/reducer";
import {RootState} from "../../../../../reducers";
import {api} from "../../../../services/Api/api";
import LoadingContainer from "../../../../components/Common/LoadingContainer";
import SingleStoreContextComponent from "../../../Contexts/SingleStoreContextComponent";
import FetchStoreData from "../../../../components/Fetch/FetchStoreData";
import AppConfig from "../../../../services/AppConfig";
import {cleanStore} from "../../../../services/Store/actions";
import {PrefixContext} from "../../../../services/Prefix";
import {cleanMenus} from "../../../../services/Menu/actions";
import {cleanCart} from "../../../../services/Cart/actions";
import {useTranslation} from "react-i18next";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;
const SingleStoreMultistorePage = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [prefix, setPrefix] = useState<any>(undefined);
    const {storeId} = useParams();
    const {t} = useTranslation();


    const init = async () => {
        AppConfig.setPlaceId(storeId);
        api.setPlaceId(storeId);
        props.dispatch(cleanCart(api.version()));
        props.dispatch(cleanMenus());
        props.dispatch(cleanStore());
        setPrefix(storeId)
        setLoading(false)
    }
    useEffect(() => {
        if (storeId) {
            init();
        }
        return () => {
            AppConfig.setPlaceId(undefined);
            api.setPlaceId(undefined);
        }
    }, []);
    if (!storeId) return null;

    if (loading) {
        return (
            <LoadingContainer/>
        )
    }
    return (
        <PrefixContext.Provider value={prefix}>
            <FetchStoreData errorView={<>
                <div className={"text-center mt-2"}>
                    <Link to={"/"} className={"btn btn-primary"}>{t("modules.multistore.action.back_to_home.title")}</Link>
                </div>
            </>}>
                <SingleStoreContextComponent/>
            </FetchStoreData>
        </PrefixContext.Provider>
    )
}

const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);

export default connector(SingleStoreMultistorePage);