import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "./reducers";
import LoadingContainer from "./New/components/Common/LoadingContainer";
import {I18nextProvider} from "react-i18next";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AppConfig from "./New/services/AppConfig";
import StorageStore from "./New/services/Common/Storage/storageStore";
import {HelmetProvider} from "react-helmet-async";
import './App.scss';
import AppInitializer from "./New/utils/AppInitializer";
import CookiesAlert from "./New/components/Common/CookiesAlert";
import FlashMessages from "./New/components/Notification/FlashMessages";
import initI18n from "./vendor/i18n";
import {initLocale} from "./New/services/languageUtils";
import Pages from "./New/pages/Pages";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

AppInitializer.initConfigBaseOnQueryParams();
const i18n = initI18n(initLocale())

function App(props: Props) {
    let baseNameWithPrefix = `${StorageStore.getBaseName()}${StorageStore.getPrefix() ? `/${StorageStore.getPrefix()}` : ""}`;

    let appClassName = "";
    let appClassNamePreview = "";
    if (AppConfig.isWidget()) {
        appClassName = " widget";
    }
    if (AppConfig.isNativeMobile()) {
        appClassName = " widget-native-mobile";
    }
    if (AppConfig.isNativeFullMobile()) {
        appClassName = " widget-native-full-mobile";
    }

    return (
        <React.Suspense fallback={(<LoadingContainer/>)}>
            <HelmetProvider>
                <I18nextProvider i18n={i18n}>
                    <React.Fragment>
                        <div className={"App" + appClassName + appClassNamePreview}>
                            <FlashMessages/>
                            <Router basename={baseNameWithPrefix}>
                                <Routes>
                                    <Route path={"/*"} element={<Pages/>}/>
                                </Routes>
                                <CookiesAlert path={baseNameWithPrefix}/>
                            </Router>
                        </div>
                    </React.Fragment>
                </I18nextProvider>
            </HelmetProvider>
        </React.Suspense>
    );
}

const mapStateToProps = (state: RootState) => ({})

// Connect component to Redux store
const connector = connect(mapStateToProps);
// Export connected component
export default connector(App);
