import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import Utils from "../../../../utils/Utils";
import Money from "../../../../components/Common/Money";
import AppConfig from "../../../../services/AppConfig";
import {ReactComponent as MenuItemDarkIco} from "../../../../../images/icons/menus/menu-item-dark.svg";
import {ReactComponent as MenuItemIco} from "../../../../../images/icons/menus/menu-item.svg";


interface OwnProps {
    addItem: (id: any, categoryId: any, menuId: any) => void,
    product: any,
    menuId: any,
    categoryId: any,
    promotions: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuProductListItemComponent = (props: Props) => {
    const {t} = useTranslation();
    const {product, menuId, categoryId} = props;
    let item = product;
    let itemName = item.name;
    let itemDisabled = item.disabled;
    if (product.type && product.type === "ITEM_GROUP") {
        item = product.item;
        itemName = product.name;
        itemDisabled = false;
    }

    const addItem = (itemId: any, categoryId: any, menuId: any, e: any) => {
        e.stopPropagation();
        e.preventDefault();
        props.addItem(itemId, categoryId, menuId);
    }

    const renderDescription = (item: any) => {
        if (item.description !== undefined && item.description) {
            return item.description;
        }
        if (!item.selected_items || item.selected_items.length <= 0) {
            return null;
        }
        let descriptions = [] as any[];
        item.selected_items.forEach(function (selectedItem: any) {
            descriptions.push(selectedItem.quantity > 1 ? selectedItem.quantity + "x" + selectedItem.translation.name : selectedItem.translation.name);
        });
        return descriptions.join(", ");
    }
    const renderTags = (item: any) => {
        let promotions = PromotionHelper.getForItem(props.promotions, item.id);
        if (promotions === undefined || promotions.length <= 0) return null;
        return (
            <div className={"tags"}>
                {promotions.map((promotion: any) => {
                    return (
                        <span key={promotion.id} className="badge bg-success">{promotion.name}</span>
                    )
                })}
            </div>
        )
    }
    let productImgView = null;
    if (item.image_link) {
        productImgView = (
            <div className="card-img">
                <img src={item.image_link.small}/>
            </div>
        )
    }
    return (
        <a href="#" className="menu-item" onClick={(e) => addItem(item.id, categoryId, menuId, e)}>
            <div className={"card" + (itemDisabled ? ' disabled' : '')}>
                {productImgView ? productImgView : (
                    <>
                        <div className="card-img">
                            {AppConfig.isDarkMode() ? <MenuItemDarkIco/> : <MenuItemIco/>}
                        </div>
                    </>
                )}
                <div className="card-body">
                    <h5 className="card-title">{itemName}</h5>
                    <p className="card-text"
                       dangerouslySetInnerHTML={{__html: Utils.nl2brString(renderDescription(item))}}></p>
                    {renderTags(item)}
                    {item.discount_price ? (
                        <div className={"prices price-bottom"}>
                                     <span className="price price-discount">
                                         <Money
                                             value={item.price}
                                             currency='PLN'/>
                                     </span>
                            <span className="price">
                                         <Money
                                             value={item.discount_price}
                                             currency='PLN'/>
                                     </span>
                        </div>
                    ) : (item.price && item.price > 0) ? (
                        <span className="price price-bottom">
                            <Money
                                value={item.price}
                                currency='PLN'/>
                        </span>
                    ) : null}
                </div>
            </div>
        </a>
    )
}


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuProductListItemComponent));