import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {useCart} from "../../services/Cart/cartService";
import handleException from "../../services/Api/handleException";
import {ApiError} from "../../services/Api/types";
import {getCart} from "../../services/Cart/reducer";
import {ReactComponent as RemoveSVG} from "../../../images/icons/remove.svg";
import {ReactComponent as PlusSVG} from "../../../images/icons/plus.svg";
import {ReactComponent as CashbackSVG} from "../../../images/icons/cashback.svg";
import {getUser} from "../../services/User/reducer";
import Money from "../Common/Money";


interface OwnProps {
    errors: any[],
    updateErrors: (errors: any) => void,
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartCashbackComponent = (props: Props) => {
    const {t} = useTranslation();
    const cart = props.cart;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ApiError[]>([]);
    let cartService = useCart();
    const cashbackPromotion = cart.promotions.filter(x => x.type === "CREDIT")[0];

    const removeCashback = async () => {
        if (!cashbackPromotion) return;
        setLoading(true)
        try {
            await cartService.removePromotion(cashbackPromotion.id);
            setErrors([])
            setLoading(false)
        } catch (error: any) {
            const errors = handleException(error);
            setErrors(errors);
            setLoading(false)
        }

    }

    const addCashback = async () => {
        setLoading(true)
        try {
            await cartService.refreshUser();
            setErrors([])
            setLoading(false)
        } catch (error: any) {
            const errors = handleException(error);
            setErrors(errors);
            setLoading(false)
        }

    }
    const usedCashback = !!cashbackPromotion;
    const useCashback = async () => {
        if (usedCashback) {
            await removeCashback();
        } else {
            await addCashback()
        }
    }
    if (!props.user.promotional_credits) {
        return null;
    }

    let restCashback = props.user.promotional_credits;
    if (cashbackPromotion && cashbackPromotion.value_money) {
        restCashback = restCashback - cashbackPromotion.value_money.amount;
    }

    return (
        <React.Fragment>
            <div className={"btn btn-default btn-nav btn-lg mb-3"}>
                <CashbackSVG className={"text-success"}/>
                <span>
                    {cashbackPromotion ? (
                        <>
                            <Money value={cashbackPromotion.value_money}/> {t("modules.cart.field.rest_of_cashback.title")}
                            <small>
                                {`${t("modules.cart.field.amount_remain_on_account.title")}: `}
                                <Money value={restCashback}/>
                            </small>
                        </>
                    ) : (
                        <>
                            {`${t("modules.cart.field.on_account.title")}: `}
                            <Money value={restCashback}/>
                        </>
                    )}
                        </span>
                {usedCashback ? (
                    <button className={`btn btn-outline-danger btn-icon btn-spinner${loading ? " loading" : ""}`}
                            disabled={loading}
                            onClick={useCashback}>
                        {/*<div>*/}
                            <span className="left spinner-border spinner-border-sm"></span>
                        {/*</div>*/}
                        <RemoveSVG/>
                    </button>
                ) : (
                    <button className={`btn btn-outline-success btn-icon btn-spinner${loading ? " loading" : ""}`}
                            disabled={loading}
                            onClick={useCashback}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        <PlusSVG/>
                    </button>
                )}
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    cart: getCart(state.cart),
    user:
        getUser(state.user),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartCashbackComponent));