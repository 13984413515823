import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {Helmet} from "react-helmet-async";
import {RootState} from "../../../reducers";
import {useTranslation} from "react-i18next";
import {ConfigApi} from "../../services/Api/types";

interface OwnProps {
    noindex?: boolean;
    config?: ConfigApi;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function CustomHelmet(props: Props) {
    const finalConfig = props.config;
    const noindex = !!finalConfig?.firewall.enabled;
    const {t} = useTranslation();

    if (!finalConfig) {
        return null;
    }

    const configLayout = finalConfig.layout;
    let faviconView = null;
    let urlCss = (configLayout.css_url) ? configLayout.css_url : undefined;

    if (configLayout.ico_link) {
        faviconView = (
            <link href={configLayout.ico_link.small} rel="shortcut icon" type="image/png"/>
        )
    }

    if (urlCss !== undefined && urlCss && !urlCss.includes(".css")) urlCss = undefined;

    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{`${finalConfig.name} - ${t("modules.helmet.field.order_online.title")}`}</title>
            {noindex && (
                <meta name="robots" content="noindex"/>
            )}
            {urlCss && (
                <link rel="stylesheet" type="text/css" href={urlCss}/>
            )}
            {faviconView}
        </Helmet>
    )
}


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default connector(CustomHelmet);