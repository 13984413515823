import AppInitializer from "../../../utils/AppInitializer";

class StorageStore {

    static storeDomain = "";
    static baseName = "/";
    static prefix = "/";
    static items = {} as any;
    static localStorageAvailable: boolean | undefined = undefined;

    static init(storeDomain: string, baseName: string, prefix: string) {
        this.localStorageAvailable = undefined;
        this.storeDomain = storeDomain;
        this.baseName = baseName.endsWith('/') ? baseName.slice(0, -1) : baseName;
        this.prefix = prefix;
    }

    static setItem(key:string, value?:string) {
        if(!value){
            this.removeItem(key);
            return;
        }
        if (this.isLocalStorageAvailable()) {
            localStorage.setItem(this.prepareKey(key), value)
        } else {
            this.items[this.prepareKey(key)] = value;
        }
    }

    static getItem(key:string) {
        if (this.isLocalStorageAvailable()) {
            return localStorage.getItem(this.prepareKey(key))
        } else {
            if (this.prepareKey(key) in this.items) {
                return this.items[this.prepareKey(key)];
            }
            return undefined;
        }
    }

    static removeItem(key:string) {
        if (this.isLocalStorageAvailable()) {
            localStorage.removeItem(this.prepareKey(key))
        } else {
            delete this.items[this.prepareKey(key)];
        }
    }

    static prepareKey(key:string) {
        AppInitializer.initConfigBaseOnQueryParams();
        return this.storeDomain + "-" + key;
    }

    static isLocalStorageAvailable() {
        if (this.localStorageAvailable === undefined) {
            try {
                let len = localStorage.length;
                if (typeof window.localStorage !== 'undefined') {
                    this.localStorageAvailable = true;
                } else {
                    this.localStorageAvailable = false;
                }
            } catch (e) {
                this.localStorageAvailable = false;
            }
        }
        return this.localStorageAvailable;
    }

    static getBaseName() {
        return this.baseName;
    }

    static getPrefix() {
        return this.prefix;
    }

    static getStoreDomain() {
        return this.storeDomain;
    }
}

export default StorageStore;