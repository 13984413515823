import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import LoginUserModal from "../LoginUserModal";
import {ReactComponent as GiftSVG} from "../../../../images/icons/gift-solid.svg";
import UserContextOld from "../../../services/User/UserContextOld";
import {RootState} from "../../../../reducers";
import {getUser} from "../../../services/User/reducer";
import UserUtils from "../../../services/User/UserUtils";
import {getStoreConfig, getStoreConfigLoyalty} from "../../../services/Store/reducer";
import AppConfig from "../../../services/AppConfig";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
function AdvertUserComponent(props:Props) {
    const [loginModal, setLoginModal] = useState(false);
    const {t} = useTranslation();

    if (props.user && props.user.name) return null;
    if(UserContextOld.getToken()) return null;
    if(!UserUtils.isLoyaltyRegistration(props.config_loyalty)) return null;
    return (
        <>
            {loginModal && (
                <LoginUserModal page={"REGISTER"} onHide={() => setLoginModal(false)} show={true}/>
            )}
            <div className={"container-fluid"}>
                <div className={"card card-advert"}>
                    <div className={"card-body"}>
                        <div>
                            <GiftSVG/>
                        </div>
                        <div>
                            <p>
                                {props.config_loyalty?.registration?.banner_text ? (
                                    <>
                                        {props.config_loyalty?.registration?.banner_text}
                                    </>
                                ) : (
                                    <>
                                        {t("modules.user.field.create_an_account.title")}
                                        <strong>{t("modules.user.field.unique_offers.title")}</strong>
                                        {t("modules.user.field.for_registered_users.title")}
                                    </>
                                )}
                            </p>
                            <div>
                                <a href={"#"} className={"btn btn-primary stretched-link"} onClick={(e) => {
                                    e.preventDefault();
                                    setLoginModal(true)
                                }}>
                                    {t("common.action.create_an_account")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config_loyalty: getStoreConfigLoyalty(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(AdvertUserComponent));