import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import {withTranslation} from "react-i18next";
import MenuProductListItemComponent from "./MenuProductListItemComponent";
import Utils from "../../../../utils/Utils";


interface OwnProps {
    menu: any,
    categories: any,
    promotions: any,
    addItem: (id: any, categoryId: any, menuId: any) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuItemsGridComponent = (props: Props) => {
    return (
        <div className={"menu-items-wrapper"}>
            {
                props.categories.map((category: any) =>
                    <section className="menu-category container-fluid scrollspy-section" id={`category-${props.menu.id}-${category.id}`}
                             key={category.id}>
                        <h4>{category.name}</h4>
                        {category.description && (
                            <p dangerouslySetInnerHTML={{__html: Utils.nl2brString(category.description)}}></p>
                        )}
                        <div className="menu-items menu-items-grid">
                            {
                                [...category.entities].sort((a: any, b: any) => a.position - b.position || a.name.localeCompare(b.name)).map((itemEntity: any) =>
                                    <MenuProductListItemComponent promotions={props.promotions}
                                                         menuId={props.menu.id} addItem={props.addItem}
                                                         categoryId={category.id} product={itemEntity}
                                                         key={`item-${props.menu.id}-${category.id}-${itemEntity.type}-${itemEntity.id}`} />
                                )
                            }
                        </div>
                    </section>
                )
            }
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuItemsGridComponent));
