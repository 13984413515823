class PromotionHelper {

    static get(promotions: any, id: any) {
        let obj = promotions.filter((x: any) => x.id === id);
        if (obj.length > 0) {
            return obj[0];
        }
        return undefined;
    }

    static getForItem(promotions: any, itemId: any) {
        if (promotions === undefined) return []
        let obj = promotions.filter((x: any) => x.entities.some((y: any) => y.type == "ITEM" && y.entity_id == itemId));
        return obj;
    }

    static getSuggest(promotions: any, cart: any) {
        if (cart === undefined || cart.total === undefined) return [];
        let activePromotions = this.getActivePromotionsForCategory(promotions, cart.total);
        let usedPromotions = cart.promotions.filter((x: any) => x.value_money && x.value_money.amount);
        let suggestPromotions = activePromotions.filter((x: any) => !usedPromotions.some((y: any) => y.promotion_id == x.id));
        let suggestPromotions1 = [] as any[];
        suggestPromotions.forEach((promotion: any) => {
            switch (promotion.type) {
                case "BOGO":
                    promotion.entities.forEach((promotionItem: any) => {
                        let orderItem = cart.items.filter((item: any) => item.item_id == promotionItem.entity_id)[0];
                        if (orderItem !== undefined) {
                            suggestPromotions1.push(
                                {
                                    "name": promotion.name,
                                    "type": promotion.type,
                                    "info": {
                                        "order_item_id": orderItem.id
                                    },
                                    "item_name": orderItem.name
                                }
                            )
                        }
                    })
                    break;
            }
        });
        return suggestPromotions1;
    }

    static getForItemWithCart(promotions: any, itemId: any, cart: any) {
        if (promotions === undefined) return []
        let activePromotions = promotions.filter((x: any) => x.entities.some((y: any) => y.type == "ITEM" && y.entity_id == itemId));
        if (cart === undefined || cart.total === undefined) return activePromotions;
        let priceForPromotionItems = cart.items.filter((x: any) => x.item_id == itemId).reduce((prev: any, next: any) => prev + next.total_money.amount, 0);
        let totalAmount = cart.total.amount;
        let priceToCheck = cart.total.amount - priceForPromotionItems;
        return activePromotions.filter((x: any) =>
                !x.min_basket_money || (
                    (x.type == "BOGO" && x.min_basket_money.amount <= totalAmount) ||
                    (x.type == "ITEM_DISCOUNT" && x.min_basket_money.amount <= totalAmount) ||
                    (x.type == "ITEM_FIXED_PRICE" && x.min_basket_money.amount <= priceToCheck)
                )
        );
    }

    static getActivePromotionsForCategory(promotions: any, totalPrice: any) {
        let totalPriceAmount = (totalPrice !== undefined && totalPrice) ? totalPrice.amount : 0;
        return promotions.filter((x: any) => (!x.min_basket_money || x.min_basket_money.amount <= totalPriceAmount) && (x.type == "BOGO" || x.type == "ITEM_FIXED_PRICE" || x.type == "ITEM_DISCOUNT"));
    }

    static getList(promotions: any, ids: any) {
        let obj = promotions.filter((x: any) => ids.includes(x.id));
        return obj;
    }
}


export default PromotionHelper;