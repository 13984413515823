import {buildI18n} from "go-core/i18nUtils";

const version = "1.0.0";
const cdnUrlTranslation = null;
const cdns = {
	translation: cdnUrlTranslation,
};
// let i18n = buildI18n(version, cdns, undefined, false, ["pl", "en", "de"]);
// export default i18n;

const initI18n = (locale: string) => {
	let i18n = buildI18n(version, cdns, undefined, false, ["pl", "en", "de"], locale);
	return i18n;
}
export default initI18n;
