import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as CheckCircleSVG} from "../../../../../images/icons/check-circle.svg";
import {ReactComponent as NoImageSVG} from "../../../../../images/icons/no-image.svg";
import {RootState} from "../../../../../reducers";
import MenuEditItemViewHelper
    from "../../../../services/Menu/services/ItemView/MenuEditItemViewHelper";
import Money from "../../../../components/Common/Money";
import AppConfig from "../../../../services/AppConfig";
import {ReactComponent as PlusSVG} from "../../../../../images/icons/plus.svg";
import {ReactComponent as MinusSVG} from "../../../../../images/icons/minus.svg";


interface OwnProps {
    disableAdd: any,
    singlePick: any,
    errors: any,
    onEdit: (optionStateItem: any) => void
    stateItem: any,
    modifierGroupId: any,
    optionItemView: any,
    onUpdate: (modifierGroupId: any, optionItemView: any, quantity: any) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuItemOptionWithImageComponent = (props: Props) => {
    let optionStateItem = props.stateItem;
    let modifierGroupId = props.modifierGroupId;
    let optionItemView = props.optionItemView;
    let price = optionItemView.price;
    let disableAdd = props.disableAdd ? props.disableAdd : false;
    let singlePick = props.singlePick;
    let quantity = optionStateItem ? optionStateItem.quantity : 0;
    let errors = props.errors;
    const {t} = useTranslation();

    let onClickItem = (e: any) => {

        if (disableAdd) return;
        if (!singlePick) {
            props.onUpdate(modifierGroupId, optionItemView, quantity + 1);
            return;
        }
        if (optionStateItem) {
            props.onUpdate(modifierGroupId, optionItemView, 0);
        } else {
            props.onUpdate(modifierGroupId, optionItemView, 1);
        }
    }

    const subQuantityItem = () => {
        props.onUpdate(modifierGroupId, optionItemView, quantity - 1);
    }
    const addQuantityItem = () => {
        props.onUpdate(modifierGroupId, optionItemView, quantity + 1);
    }
    const renderItemInfo = (optionStateItem: any, optionItemView: any) => {
        let nameOptions = [] as any[];
        if (!optionStateItem.modifier_groups) return null;
        optionStateItem.modifier_groups.forEach((mg: any) => {
            let modifierGroupView = optionItemView.modifier_groups.filter((x: any) => x.id === mg.modifier_group_id)[0];
            mg.selected_items.forEach((mgo: any) => {
                if (mgo.quantity > 0) {
                    let itemView = modifierGroupView.options.filter((x: any) => x.id === mgo.item_id)[0];
                    nameOptions.push(`${mgo.quantity}x${itemView.name}`);
                }
            })
        })
        if (nameOptions.length === 0) return null;
        return (
            <p>
                {nameOptions.join(", ")}
            </p>
        );
    }
    const renderErrors = (errors: any) => {
        return (
            <>
                {errors.map((error: any) => {
                        return (
                            <p className="product-item-modifiers-errors"
                               key={`${modifierGroupId}-${optionItemView.id}-${error.message}-${error.resource ? error.resource : ""}`}>{error.message} {error.resource ? error.resource : ""}</p>
                        )
                    }
                )}
            </>
        )
    }
    const renderMultilevelOption = () => {
        if (!optionStateItem) return null;
        let errors = MenuEditItemViewHelper.getErrors(optionItemView);
        const itemInfo = renderItemInfo(optionStateItem, optionItemView);
        if(errors.length === 0 && !optionItemView.is_customize && itemInfo === null) return null;
        return (
            <div className={"product-option-multilevel"}>
                {itemInfo}
                {renderErrors(errors)}
                {optionItemView.is_customize && (
                    <>
                        {errors.length > 0 ? (
                            <button onClick={() => props.onEdit(optionStateItem)}
                                    className={"btn btn-primary w-100"}>
                                {t("modules.menu.action.choose_position.title")}
                            </button>
                        ) : (
                            <button onClick={() => props.onEdit(optionStateItem)}
                                    className={"btn btn-primary w-100"}>
                                {t("modules.menu.action.adjust_position.title")}
                            </button>
                        )}
                    </>
                )}
            </div>
        )
    }
    return (
        <div className="product-modifier-option1" key={modifierGroupId + "-" + optionItemView.id}>
            <>
                <div
                    className={`product-option-with-image${optionStateItem ? " active" : ""}${errors !== undefined && errors.length > 0 ? " error" : ""}`}>
                    <div className={"check-circle"}>
                        <CheckCircleSVG/>
                    </div>
                    <div className={"product-option-body"} onClick={onClickItem}>
                        <div className={"card-img"}>
                            {optionItemView.image_link ? (
                                <img
                                    src={optionItemView.image_link.default}/>
                            ) : (
                                <NoImageSVG/>
                            )}
                        </div>
                        <div className={"product-option-content"}>
                            <h4>
                                {optionItemView.name}
                            </h4>
                            {optionItemView.description && (
                                <p>{optionItemView.description}</p>
                            )}
                            <div className={"product-option-actions"}>
                                <div className={"price"}>
                                    {optionStateItem ? (
                                        <>
                                            +<Money
                                            value={optionStateItem.total_price}
                                            currency='PLN'/>
                                            {(quantity > 1 || (AppConfig.isShowFreeInfo() && optionStateItem.quantity_free > 0)) && (
                                                <small>
                                                    {" "}
                                                    (
                                                    {quantity > 1 && (
                                                        <>
                                                            <Money
                                                                value={price}
                                                                currency='PLN'/> {t("modules.menu.field.per_one_item.title")}
                                                        </>
                                                    )}
                                                    {(AppConfig.isShowFreeInfo() && optionStateItem.quantity_free > 0) && (
                                                        <>
                                                            {quantity > 1 && ", "}
                                                            {optionStateItem.quantity_free} {t("modules.menu.field.charge_above.title").toLowerCase()}
                                                        </>
                                                    )}
                                                    )
                                                </small>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Money
                                                value={price}
                                                currency='PLN'/>
                                        </>
                                    )}
                                </div>
                                {!singlePick && (
                                    <div className={"product-option-quantity"} onClick={e => e.stopPropagation()}>
                                        <div className="input-group input-group-sm input-group-quantity">
                                            <div className="input-group-prepend">
                                                <button disabled={quantity <=0} className={`btn btn-default btn-sm btn-icon`} type="button"
                                                        id="button-addon1" onClick={(e: any) => subQuantityItem()}>
                                                    <MinusSVG/>
                                                </button>
                                            </div>
                                            <span className={"input-group-text"}>{quantity}</span>
                                            <div className="input-group-append">
                                                <button disabled={disableAdd} className="btn btn-default btn-icon btn-sm"
                                                        type="button" id="button-addon1"
                                                        onClick={(e) => addQuantityItem()}>
                                                    <PlusSVG/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {(errors !== undefined && errors.length > 0) && (
                        <div className={"product-option-errors"}>
                            {
                                errors.map((error: any) =>
                                    <div className="text-danger error"
                                         key={`${modifierGroupId}-${optionItemView.id}-${error.message}`}>{error.message}</div>
                                )
                            }
                        </div>
                    )}
                    {optionItemView.disabled && optionItemView.quantity === 0 && (
                        <div className={"product-option-errors"}>
                                <div className="text-danger error">{t("constraints.internal.item_not_available")}</div>
                        </div>
                    )}
                    {renderMultilevelOption()}
                </div>
            </>
        </div>
    )

}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuItemOptionWithImageComponent));