import {ConfigAddressApi, StoreConfigCoordinatesApi} from "../Api/types";

export enum LocationActionTypes {
	UPDATE_LOCATION = "@@session/UPDATE_LOCAtiON",
	UPDATE_COORDINATES = "@@session/UPDATE_COORDINATES",
	UPDATE = "@@session/UPDATE",
	CHECK = "@@session/CHECK",
	UPDATE_LOCALE = "@@session/UPDATE_LOCALE",
}

export type SessionState = {
	is_exist: boolean;
	in_delivery_area: boolean;
	full_address: string;
	address: ConfigAddressApi;
	address_dirty: boolean;
	contact?: any;
	locale?: string;
	order_type?: "DINE_IN"|"PICK_UP"|"DELIVERY"|"ROOM_SERVICE"|string;
	pickup_at?: string;
	free_delivery_price_money?: any;
	delivery_price_money?: any;
	min_order_price_money?: any;
	estimated_preparation_duration?: number;
	estimated_delivery_duration?: number;
	default_min_order_price_money?: any,
	default_delivery_price_money?: any,
	initialized: boolean,
	coordinates?: StoreConfigCoordinatesApi,
	table_number?: string;
	room_number?: string;
	scan_time?: string
};