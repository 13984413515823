import {useLanguageService} from "../../../services/Language/languageService";
import {getLangDisplayName, getPossibleLanguages} from "../../../services/languageUtils";
import {withTranslation} from "react-i18next";
import React, {useState} from "react";
import {ReactComponent as GlobeIco} from "../../../../images/icons/globe.svg";
import {ReactComponent as ArrowBottomIco} from "../../../../images/icons/arrow-bottom.svg";
import {ReactComponent as ArrowTopIco} from "../../../../images/icons/arrow-top.svg";

interface Props {
    locale: string;
    languages: string[];
    renderListItem: (icon: JSX.Element, title: string, value: string, shareIcon: JSX.Element, link?: string, action?: any) => JSX.Element;
}

const FooterLanguages = ({ languages, locale, renderListItem }: Props) => {
    const languageService = useLanguageService();
    const [expandLanguages, setExpandLanguages] = useState<boolean>(false);
    const possibleLanguages = getPossibleLanguages(languages);
    const [loadingIndex, setLoadingIndex] = useState(-1);

    if (possibleLanguages.length === 0) return null;

    const changeLocaleInternal = async (newLocale: string, index: number) => {
        if(newLocale !== locale ) {
            setLoadingIndex(index);
            await languageService.change(newLocale);
            setExpandLanguages(false);
            setLoadingIndex(-1);
        }
    }

    return (
        <li className='footer-languages'>
            {renderListItem((<GlobeIco/>), '',
                getLangDisplayName(locale),
                (expandLanguages ? <ArrowTopIco/> : <ArrowBottomIco/>),
                undefined,
                () => setExpandLanguages((prev) => !prev),
            )}
            {expandLanguages && (
                <div className={`d-flex flex-column languages-container collapse`}>
                    {possibleLanguages.map((language, index) => (
                        <p key={language} className={`${language === locale ? 'active' : ''}`}
                           onClick={() => changeLocaleInternal(language, index)}>
                            {getLangDisplayName(language)}
                            {loadingIndex === index && <span className="left spinner-border spinner-border-sm ms-2"/>}
                        </p>
                    ))}
                </div>
            )}
        </li>
    )
}

export default withTranslation()(FooterLanguages)