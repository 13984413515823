import React, {FC, ReactNode, useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import LoadingContainer from "../Common/LoadingContainer";
import {useCart} from "../../services/Cart/cartService";
import {getMenuState} from "../../services/Menu/reducer";
import ErrorView from "../Common/ErrorView";
import {getSession, getSessionInitialized} from "../../services/Location/reducer";
import {initLocale} from "../../services/languageUtils";
import {useLocationService} from "../../services/Location/locationService";
import {useAuth} from "../../services/User/auth";
import {useStoreService} from "../../services/Store/storeService";
import {isErrorCodeExist} from "../../services/Api/handleException";
import {getStoreState, getStoreStateError, getStoreStatePending} from "../../services/Store/reducer";
import {ConfigApi} from "../../services/Api/types";
import {getUser} from "../../services/User/reducer";
import {getCart} from "../../services/Cart/reducer";
import {useTranslation} from "react-i18next";


interface OwnProps {
    children?: ReactNode,
    config?: ConfigApi,
    errorView?: ReactNode
}

type Props = ReturnType<typeof mapStateToProps> & DispatchProp & OwnProps;

const FetchStoreData: FC<Props> = (props) => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cartService = useCart();
    const store_state = props.store_state;
    const config = store_state.config;
    const locationService = useLocationService();
    const authService = useAuth();
    const storeService = useStoreService();
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const initStore = async () => {
        let locale = initLocale();
        try {
            const response = await storeService.init(props.config, locale, signal);
            await initSession(response);
            return response;
        } catch (error) {
            console.log("initStore error", error);
        }
    }


    const initUser = async () => {
        if (props.user.name) return;
        try {
            await authService.refresh();
        } catch (err) {
        }
    }
    const initSession = async (config: ConfigApi) => {
        if (!config) {
            return;
        }
        let newSession = {...props.session};
        newSession.default_delivery_price_money = config.ordering.delivery_price_money;
        newSession.default_min_order_price_money = config.ordering.min_order_price_money;
        const sessionState = await locationService.update(newSession, true);
        try {
            return await cartService.init(sessionState)
        } catch (err) {
            console.log("cart err", err);
        }
    }


    const refresh = async () => {
        await initStore();
        initUser();
    }

    useEffect(() => {
        initStore();
        initUser();
        return () => {
            controller.abort(); // Step 3: Cancel the request on unmount
        };
    }, []);

    if (props.storePending) {
        return (<LoadingContainer/>)
    }

    if (props.storeError) {
        if (isErrorCodeExist(props.storeError, "not_found")) {
            return (
                <>
                    <ErrorView message={t("constraints.internal.store_not_exist")}/>
                    {props.errorView && (
                        <>{props.errorView}</>
                    )}
                </>
            )
        } else {
            return (
                <ErrorView>
                    <button className={"btn btn-primary"} onClick={() => refresh()}>
                        {t("common.action.try_again")}
                    </button>
                </ErrorView>
            )
        }
    }
    if (!props.sessionInitialized) {
        return (<LoadingContainer/>)
    }
    if (!config) {
        return (<LoadingContainer/>)
    }


    if (loading) {
        return (
            <LoadingContainer/>
        )
    }

    return (
        <>
            {props.children}
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
    storePending: getStoreStatePending(state.config),
    storeError: getStoreStateError(state.config),
    menu_state: getMenuState(state.menu),
    session: getSession(state.session),
    sessionInitialized: getSessionInitialized(state.session),
    cart: getCart(state.cart),
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default connector(FetchStoreData);
