import AppConfig from "../services/AppConfig";
import IframeWidget from "./IframeWidget";
import StorageStore from "../services/Common/Storage/storageStore";
import {api} from "../services/Api/api";
import Utils from "./Utils";
import {ConfigAddressApi} from "../services/Api/types";
import UserTokenContext from "../services/User/UserTokenContext";
import CookiesStore from "../services/Common/Storage/cookiesStore";
import CartContext from "../services/Cart/CartContext";

let goorderAnalyticsId = 'G-JYCDCN61ZX';
let prefix = '';
let initialized = false;
let orderWithPath = false;

// Analytics.initGA(goorderAnalyticsId);

class AppInitializer {

    static isInitialized() {
        return initialized;
    }

    static initQueryParams() {
        let query = window.location.search.substring(1);
        let qp = Utils.getQueryParams(query);
        if (qp.city || qp.street) {
            let newAddress = {
                city: qp.city,
                street: qp.street,
                build_nr: qp.build_nr
            } as ConfigAddressApi
            if (qp.flat_nr) newAddress.flat_nr = qp.flat_nr;
            StorageStore.setItem('location', JSON.stringify(newAddress));
            Utils.removeQueryParam(["city", "street", "build_nr"]);
        }
        if (qp.user_token) {
            UserTokenContext.setToken(qp.user_token);
            Utils.removeQueryParam(["user_token"]);
        }
        if (qp.ac) {
            StorageStore.setItem('authorization_code', qp.ac);
            Utils.removeQueryParam(["ac"]);
        }
        if (qp.order_type) {
            StorageStore.setItem('order_type', qp.order_type);
            Utils.removeQueryParam(["order_type"]);
        }
        if (qp.dark_mode) {
            AppConfig.setDarkMode()
        }
        let lang = qp.lang;
        if (lang) {
            CookiesStore.setGlobal("language", lang);
            Utils.removeQueryParam(["lang"]);
        }
        return qp;

    }

    static initConfigBaseOnQueryParams() {
        if (initialized) return;
        let baseNameTmp = '/';
        let pathname = window.location.pathname;
        var body = document.body;
        body.classList.add("body");
        if (pathname.includes("widget_native_full")) {
            prefix = "widget_native_full";
            AppConfig.setNativeFullMobile();
        } else if (pathname.includes("widget_native")) {
            prefix = "widget_native";
            AppConfig.setNativeMobile();
        } else if (pathname.includes("widget")) {
            prefix = "widget";
            AppConfig.setWidget(true);
            IframeWidget.initialize();
        }
        if (pathname.includes("order/")) {
            CartContext.initialize();
            prefix += "order/" + CartContext.getOrderId() + "/" + CartContext.getOrderToken() + "/";
            orderWithPath = true;
        }
        let storeDomain = '';
        if (pathname.includes("inner")) {
            let pathnameSplit = pathname.split("/");
            if (pathnameSplit.length >= 2) {
                storeDomain = pathnameSplit[2];
                baseNameTmp = "/inner/" + storeDomain + "/";
            }
        }
        let hostname = window.location.hostname;
        if (hostname === "store2.goorder.pl") {
            storeDomain = storeDomain + ".goorder.pl" //TODO REMOVE if wants to use new domain
        }
        StorageStore.init(storeDomain, baseNameTmp + "", prefix);
        CookiesStore.init(baseNameTmp + "");
        api.setBaseUrl(StorageStore.getStoreDomain());
        initialized = true;
        AppInitializer.initQueryParams();
    }

}

export default AppInitializer;
