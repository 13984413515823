import React from 'react';
import {useTranslation} from "react-i18next";

interface Props {
    order: any
}

const SummaryContactComponent = (props: Props) => {
    const {t} = useTranslation();
    return (
        <div className={"card-cart"}>
            <div className={"container-fluid"}>
                <h3 className={"card-cart-header"}>{t("common.word.your_data")}</h3>
                <div className={"card-cart-body"}>

                    <div className={"cart-card-info"}>
                        <dl>
                            <dt>{t("common.word.full_name")}:</dt>
                            <dd>{props.order.contact.name}</dd>
                        </dl>
                        <dl>
                            <dt>{t("common.word.email")}:</dt>
                            <dd>{props.order.contact.email}</dd>
                        </dl>
                        <dl>
                            <dt>{t("common.word.phone_number")}:</dt>
                            <dd>{props.order.contact.phone}</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
    // return (
    //     <div className="card card-cart card-shipment">
    //         <div className="card-body">
    //             <h3>
    //                 {t("modules.summary.contact.header.title")}
    //             </h3>
    //
    //             <dl>
    //                 <dt>{props.order.contact.name} ({props.order.contact.email} / {props.order.contact.phone})</dt>
    //                 <dd>{t("modules.summary.contact.field.contact_data.title")}</dd>
    //                 {props.order.comment && (
    //                     <>
    //                         <dt>{props.order.comment}</dt>
    //                         <dd>{t("modules.summary.contact.field.comment.title")}</dd>
    //                     </>
    //                 )}
    //             </dl>
    //         </div>
    //
    //     </div>
    // )
}
export default SummaryContactComponent;