import {combineReducers} from 'redux';
import menuReducer from './New/services/Menu/reducer'
import cartReducer from './New/services/Cart/reducer'
import thunk from 'redux-thunk';
import storeConfigReducer from "./New/services/Store/reducer";
import sessionReducer from "./New/services/Location/reducer";
import userReducer from "./New/services/User/reducer";
import notificationReducer from "./New/services/Notifications/reducer";
import {configureStore} from "@reduxjs/toolkit";

const rootReducer = combineReducers({
    cart: cartReducer,
    menu: menuReducer,
    notification: notificationReducer,
    session: sessionReducer,
    config: storeConfigReducer,
    user: userReducer
});

export default rootReducer;
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    preloadedState: undefined, // Equivalent to initialState
    // devTools: {
    //     serialize: false,
    //     trace: true,      // Enable tracing for actions
    //     traceLimit: 25    // Limit the number of actions to trace
    // }
});

export type RootState = ReturnType<typeof rootReducer>;