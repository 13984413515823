import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {Link} from "react-router-dom";
import {RootState} from "../../../../reducers";
import {getStoreConfig, getStoreConfigLoyalty, getStoreDefaultConfig} from "../../../services/Store/reducer";
import {ApiError, StoreConfigRulesApi} from "../../../services/Api/types";
import Utils from "../../../utils/Utils";
import ErrorFormView from "../../Common/ErrorFormView";
import ErrorFormAlertView from "../../Common/ErrorFormAlertView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import StringHelper from "../../../services/Common/StringHelper";
import handleException from "../../../services/Api/handleException";
import {RegisterCustomFieldComponent} from "./RegisterCustomFieldComponent";

interface OwnProps {
    setPage: (page: string) => void,
    onSuccess: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function RegisterUserComponent(props: Props) {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [acceptRules, setAcceptRules] = useState<string[]>([]);
    const [cardCode, setCardCode] = useState<string | undefined>(undefined);
    const rules = props.config_loyalty?.rules;
    const authService = useAuth();
    const [customFields, setCustomFields] = useState<Record<string, string | undefined>>({});
    let configCustomFields = props.config_loyalty?.custom_fields ? props.config_loyalty.custom_fields : [];
    const {t} = useTranslation();

    const updateAcceptRules = (name: string, value: boolean) => {
        if (value) {
            if (!acceptRules.includes(name)) {
                setAcceptRules([...acceptRules, name])
            }
        } else {
            setAcceptRules(acceptRules.filter(x => x != name))
        }
    }

    let defaultMarketingContent = () => {
        if(props.config?.old_settings?.rules_marketing_text) {
            return props.config.old_settings.rules_marketing_text
        }

        let company = props.config?.company;
        let companyName = null;
        let addressView = null;
        if (company) {
            companyName = company?.legal_name;
            if (company.address) {
                addressView = StringHelper.getAddressName(company.address);
            }
        }
        return t("modules.user.field.accept_rules.title", {companyName, address: addressView})
    }


    let defaultRulesContent = () => {
        return (
            <>
                {`${t("modules.user.field.rules_read.title")} `}
                <Link to={"rules"} target="_blank">
                    {t("modules.user.field.policy_privacy.title")}
                </Link>
            </>
        )
    }
    const renderRules = (rule: StoreConfigRulesApi) => {
        const type = rule.type;
        let value = acceptRules.includes(rule.slug)
        let field = "custom_rules_" + rule.slug;
        let content = undefined as undefined | any;
        switch (type) {
            case "rules_and_privacy":
                field = "accept_rules";
                if (!rule.content) {
                    content = defaultRulesContent();
                }
                break;
            case "marketing":
                field = "accept_marketing_rules";
                if (!rule.content) {
                    content = defaultMarketingContent();
                }
                break;
        }
        return (
            <div className="form-group">
                <div className={"form-check"}>
                    <input id={`registration-${rule.slug}`} name={"accept_rules"} type="checkbox"
                           onChange={(e) => updateAcceptRules(rule.slug, !value)} value={1} checked={value}
                           className={"form-check-input" + ErrorFormHelper(errors, field)}
                           aria-label="Akceptacja regulaminu" aria-describedby={`registration-${rule.slug}`}/>
                    {content ? (
                        <label className={"form-check-label"}
                               htmlFor={`registration-${rule.slug}`}>{content}</label>
                    ) : (
                        <label className={"form-check-label"}
                               htmlFor={`registration-${rule.slug}`}
                               dangerouslySetInnerHTML={{__html: Utils.nl2brString(rule.content)}}
                        ></label>
                    )}
                    <ErrorFormView errors={errors} field={field}/>
                </div>
            </div>
        );
    }

    const register = async () => {
        setLoading(true);
        const acceptMarketingRules = acceptRules.includes("marketing");
        const acceptRulesAndPrivacy = acceptRules.includes("rules_and_privacy");
        const newCustomFields = {...customFields}
        newCustomFields.registration_source = props.config?.name;
        try {
            const response = await authService.register(email, name, phone, password, confirmPassword, acceptRulesAndPrivacy, acceptMarketingRules, cardCode, newCustomFields);
            setLoading(false);
            props.onSuccess()
        } catch (e: any) {
            const errors = handleException(e);
            setErrors(errors);
            setLoading(false);
        }

    }

    const onChangeCustomField = (slug: string, value?: string) => {
        setCustomFields((prevFields) => {
            const updatedFields = {...prevFields}; // Create a copy of the previous state
            if (value) {
                updatedFields[slug] = value; // Add or update the field
            } else {
                updatedFields[slug] = undefined; // Add or update the field
                // delete updatedFields[slug]; // Remove the field if value is falsy
            }
            return updatedFields; // Return the updated object
        });
    };
    return (
        <>
            <div>
                <h4>
                    {t("common.action.create_an_account")}
                </h4>
                <ErrorFormAlertView show_message={true} errors={errors}/>
                <div className="form-group">
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name}
                           className={"form-control" + ErrorFormHelper(errors, "name")} placeholder={t("common.word.full_name")}
                           aria-label="Recipient's username" aria-describedby="cart-name"/>
                    <label htmlFor="cart-name">{t("common.word.full_name")}</label>
                    <ErrorFormView errors={errors} field="name"/>
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone}
                           className={"form-control" + ErrorFormHelper(errors, "phone")} placeholder={t("common.word.phone_number")}
                           aria-label="Recipient's username" aria-describedby="cart-phone"/>
                    <label htmlFor="cart-phone">{t("common.word.phone_number")}</label>
                    <ErrorFormView errors={errors} field="phone"/>
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email}
                           className={"form-control" + ErrorFormHelper(errors, "email")} placeholder={t("common.word.email")}
                           aria-label="Recipient's username" aria-describedby="cart-username"/>
                    <label htmlFor="cart-username">{t("common.word.email")}</label>
                    <ErrorFormView errors={errors} field="username"/>
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                           className={"form-control" + ErrorFormHelper(errors, "password")} placeholder={t("common.word.password")}
                           aria-label="Recipient's username" aria-describedby="cart-password"/>
                    <label htmlFor="cart-password">{t("common.word.password")}</label>
                    <ErrorFormView errors={errors} field="password"/>
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}
                           className={"form-control" + ErrorFormHelper(errors, "confirm_password")}
                           placeholder={t("modules.user.field.confirm_password.title")} aria-label="Recipient's username"
                           aria-describedby="cart-confirm-password"/>
                    <label htmlFor="cart-confirm-password">{t("modules.user.field.confirm_password.title")}</label>
                    <ErrorFormView errors={errors} field="confirm_password"/>
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setCardCode(e.target.value)} value={cardCode}
                           className={"form-control" + ErrorFormHelper(errors, "cardCode")} placeholder={t("modules.user.field.promotional_code.title")}
                           aria-label="Recipient's username" aria-describedby="cart-name"/>
                    <label htmlFor="cart-cardCode">{t("modules.user.field.promotional_code.title")}</label>
                    <ErrorFormView errors={errors} field="name"/>
                </div>
                {(configCustomFields && configCustomFields.length > 0) && (
                    <>
                        <hr/>
                        {configCustomFields.map(customField => {
                            return (
                                <>
                                    <RegisterCustomFieldComponent custom_field={customField} values={customFields}
                                                                  errors={errors} onChange={onChangeCustomField}/>
                                </>
                            )
                        })}
                    </>
                )}
                {rules && (
                    <>
                        {rules.map(rule => {
                            return renderRules(rule);
                        })}
                    </>
                )}
            </div>
            <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                    onClick={() => {
                        register()
                    }}>
                <span className="left spinner-border spinner-border-sm"></span>
                {t("common.action.create_an_account")}
            </button>

            <div className={"login-box-register-info"}>
                <hr/>
                <div className={"text-center"}>
                    {t("modules.user.field.have_account_already.title")}
                    <a className="ms-1" href={"#"} onClick={(e) => {
                    e.preventDefault();
                    props.setPage("LOGIN")
                }}>
                    {t("common.action.login")}
                </a>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config: getStoreDefaultConfig(state.config),
    config_loyalty: getStoreConfigLoyalty(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(RegisterUserComponent));
