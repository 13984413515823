import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import {PlaceApi} from "../../../services/Api/types";
import {ReactComponent as MarkerSVG} from "../../../../images/icons/marker.svg";
import {ReactComponent as ClockSVG} from "../../../../images/icons/clock.svg";
import {ReactComponent as MenuSVG} from "../../../../images/icons/menu.svg";
import {ReactComponent as PhoneSVG} from "../../../../images/icons/phone.svg";
import {ReactComponent as ArrowRightSVG} from "../../../../images/icons/arrow-right.svg";
import StorageStore from "../../../services/Common/Storage/storageStore";
import {FormatAddressString} from "go-core/components/Formatters/FormatAddress";
import {Link} from "react-router-dom";
import OpenStoreModal from "./OpenStoreModal";
import {useTranslation} from "react-i18next";
import FormattedPhone from "../../../components/Common/FormattedPhone";

interface OwnProps {
    place: PlaceApi
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const PlaceItemMultistoreComponent = (props: Props) => {
    const [requiredLocationModal, setRequiredLocationModal] = useState<PlaceApi | undefined>(undefined);
    const {t} = useTranslation();
    let link = props.place.domain.replace(`${StorageStore.storeDomain}/`, "/");

    if (!link.startsWith('/')) {
        link = '/' + link;
    }

    let renderDistance = (distance: number | undefined) => {
        if(!distance) return "~";
        distance = Math.round((distance + Number.EPSILON) * 100) / 100
        return `${distance} km`;
    }

    const renderTypes = (place: PlaceApi) => {
        const types = place.types;
        if(types === undefined) return null;
        let nameTypes = types.map(type => {
            if(type == 'DELIVERY') return `${t("enums.order.type.DELIVERY")} ${place.delivery?.delivery_price ? `: ${place.delivery.delivery_price}` : ""}`;
            if(type == 'PICK_UP') return t("enums.order.type.PICK_UP");
            if(type == 'DINE_IN') return t("enums.order.type.DINE_IN")
        })
        return (
            <ul className={"list-inline"}>
                {nameTypes.map(typeString => {
                    return (
                        <li key={typeString}>{typeString}</li>
                    )
                })}
            </ul>
        )
    }
    const openStore = () => {
        setRequiredLocationModal(props.place)
    }
    return (
        <>
            {requiredLocationModal && (
                <>
                    <OpenStoreModal storeUrl={link} openStore={openStore} place={requiredLocationModal} onHide={() => setRequiredLocationModal(undefined)} />
                </>
            )}
            <div className={"card-place"}>
                <div className={"card-place-wrapper"}>
                    <div className={"card-header"} onClick={(e) => {
                        e.preventDefault();
                        openStore();
                    }}>
                        <div className={"icon-square"}>
                            <MarkerSVG/>
                        </div>
                        <div className={"card-header-text"}>
                            <h3>{props.place.name}</h3>
                            <span>{FormatAddressString(props.place.address)}</span>
                        </div>
                    </div>
                    <div className={"card-body"} onClick={(e) => {
                        e.preventDefault();
                        openStore();
                    }}>
                        <ul className="icon-list">
                            {/*<li><ClockSVG/> Otwarte do 23:00</li>*/}
                            <li><MenuSVG/> {renderTypes(props.place)}</li>
                            {!props.place.in_delivery_zone && (
                                <li className={"text-danger"}><MarkerSVG/> {t("modules.multistore.field.your_address_is_not_available.title")}</li>
                            )}
                        </ul>
                    </div>
                    <Link to={link} className={"btn btn-default"} onClick={(e) => {
                        e.preventDefault();
                        openStore();
                    }}>Menu</Link>{" "}
                    <Link to={link} className={"btn btn-default"} onClick={(e) => {
                        e.preventDefault();
                        openStore();
                    }}>{renderDistance(props.place.distance)}</Link>{" "}
                    {props.place.phone_number !== undefined && (
                        <a className={"btn btn-default phone"} href={`tel:${props.place.phone_number}`}><PhoneSVG/><span><FormattedPhone value={props.place.phone_number}/></span></a>
                    )}
                </div>
                <Link className={"card-place-navigate"} to={link} onClick={(e) => {
                    e.preventDefault();
                    openStore();
                }}>
                    <ArrowRightSVG/>
                </Link>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);

export default connector(PlaceItemMultistoreComponent);