import React from 'react';

import {Link} from 'react-router-dom'
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreState} from "../../services/Store/reducer";
import AppConfig from "../../services/AppConfig";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function NavbarCart(props: Props) {
    const {store_state} = props;
    let {t} = useTranslation();
    let config = store_state.config;
    if (!config) return null;
    let configLayout = config.layout;
    if (!configLayout) return null;

    if (!props.store_state) {
        return (
            <nav className="navbar navbar-light">
                <Link to="/menu" className="navbar-brand">
                    {t("common.word.loading_inprogress")}
                </Link>
            </nav>
        );
    }
    let logoView = null;
    if(props.store_state.config?.layout.image_link){
        logoView = (
            <img alt="Logo location" src={props.store_state.config?.layout.image_link.default}/>
        )
    } else {
        logoView = props.store_state.config?.name;
    }
    if(AppConfig.isWidget()){
        return null;
    }

    return (
        <nav className="navbar navbar-light navbar-cart">
            <div className="navbar-brand-container">
                <Link to="/" className="navbar-brand">
                    {logoView}
                </Link>
            </div>
        </nav>
    )
}


const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarCart));