import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import {RootState} from "../../../../reducers";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import AppConfig from "../../../services/AppConfig";
import {usePrefix} from "../../../services/Prefix";

interface OwnProps {
    onHide: () => void,
    setPage: (page?: string) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
function UserDeleteComponent(props: Props) {
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([] as any[]);
    const navigate = useNavigate();
    const authService = useAuth();
    const prefix = usePrefix();
    const {t} = useTranslation();

    const deleteAccount = async () => {
        setLoading(true);
        try {
            await authService.deleteUser(password);
            setLoading(false);
            props.onHide();
            // navigate(AppConfig.getLinkWithPrefix("/", prefix));
        } catch (e) {
            setErrors([{
                message: t("constraints.internal.account_removal_failed")
            }]);
            setLoading(false);
        }

    }
    return (

        <>
            <div className={"user"}>
                <div className={"user-header-top top"}>
                        <button onClick={() => props.setPage(undefined)} className={"btn-back"}>
                            <BackSVG/>
                        </button>
                        <h5 className="">
                            {t("modules.user.action.remove_account.title")}
                        </h5>
                        <button onClick={props.onHide} className="btn-close ms-auto me-3" data-bs-dismiss="modal"
                                aria-label="Close"/>

                </div>
                <div className={"container my-2"}>
                    <p>
                        {t("modules.user.field.remove_account_confirmation.title")}

                    </p>
                    <div>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                                   className={"form-control" + ErrorFormHelper(errors, "password")}
                                   placeholder={t("modules.user.field.your_password.title")}
                                   aria-label="Recipient's username" aria-describedby="cart-password"/>
                            <label htmlFor="cart-username">
                                {t("modules.user.field.your_password.title")}
                            </label>
                            <ErrorFormView errors={errors} field="password"/>
                        </div>
                    </div>
                    <button
                        className={"btn-spinner btn-block btn-primary btn w-100 btn-submit" + (loading ? ' loading' : '')}
                        onClick={deleteAccount}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("modules.user.action.remove_account.title")}
                    </button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserDeleteComponent));