import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import {RootState} from "../../../../reducers";
import {getStoreConfig} from "../../../services/Store/reducer";
import LoadingContainer from "../../../components/Common/LoadingContainer";
import {getUser, getVouchers} from "../../../services/User/reducer";
import {api} from "../../../services/Api/api";
import VoucherModal from "./VoucherModal";
import VoucherProductModal from "./VoucherProductModal";
import {getCart} from "../../../services/Cart/reducer";
import VoucherUtils from "../../../services/User/VoucherUtils";
import VoucherSingleComponent from "../components/Voucher/VoucherSingleComponent";
import UserUtils from "../../../services/User/UserUtils";
import VoucherProductComponent from "../components/Voucher/VoucherProductComponent";
import {VoucherApi} from "../../../services/Api/types";

interface OwnProps {
    onHide: () => void,
    setPage: (page?: string) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function UserVouchersComponent(props: Props) {
    const [voucher, setVoucher] = useState(undefined);
    const [product, setProduct] = useState(undefined);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [products, setProducts] = useState([]);
    const {t} = useTranslation();
    let activeVouchers = VoucherUtils.getVoucherWithCarts(props.vouchers, props.cart);

    useEffect(() => {
        getProducts();
    }, []);
    const isAvailableVoucher = (voucher: any) => {
        return true;
        // return !props.orderPromotions.some(orderPromotion => voucher.promotion_id == orderPromotion.promotion_id && orderPromotion.reference_id == voucher.id);
        // return true;
    }
    const showVoucher = (voucher: any) => {
        setProduct(undefined)
        setVoucher(voucher)
    }
    const showProduct = (product: any) => {
        setVoucher(undefined)
        setProduct(product)
    }

    const getProducts = async () => {
        let data = await api.getProducts();
        setProducts(data);
        setLoadingProducts(false)
    }

    const handleSuccessBuyVoucher = async (voucher: VoucherApi) => {
        showVoucher(voucher);
    }

    return (
        <div className='user-vouchers'>
            {voucher && (
                <>
                    <VoucherModal onHide={() => setVoucher(undefined)} show={true} voucher={voucher}/>
                </>
            )}
            {product && (
                <>
                    <VoucherProductModal onSuccess={handleSuccessBuyVoucher} onHide={() => setProduct(undefined)} show={true}
                                         product={product}/>
                </>
            )}
            <div className={"user"}>
                <div className={"user-header-top top"}>
                    <button onClick={() => props.setPage(undefined)} className={"btn-back"}>
                        <BackSVG/>
                    </button>
                    <h5 className="">
                        {t("modules.user.field.vouchers.title")}
                    </h5>
                    <button onClick={props.onHide} className="btn-close ms-auto me-3" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div className={"container-fluid pt-3"}>

                    {(activeVouchers.length > 0) && (
                        <div className={"mb-4"}>
                            <h5 className={"mb-3"}>
                                {t("modules.user.field.your_vouchers.title")}
                            </h5>
                            <p>
                                {t("modules.user.field.your_vouchers.description")}
                            </p>
                            <div className="row row-cols-1 g-3">
                                {
                                    activeVouchers.sort((a: any, b: any) => a.voucher.name.localeCompare(b.voucher.name)).map((voucher: any) => {
                                            return (
                                                <React.Fragment key={voucher.voucher.id}>
                                                    <div className={"col d-flex"}>
                                                        <VoucherSingleComponent voucher={voucher.voucher}
                                                                                promotion_id={voucher.promotion_id}/>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    )}


                    <div className={"mb-4"}>
                        <h5 className={"d-flex justify-content-between mb-3"}>
                            {t("modules.user.field.to_buy.title")}

                            {UserUtils.isLogged(props.user) && (
                                <span className={"badge badge-success-light"}>
                                    {t("modules.user.field.your_points.description", {amount: props.user.points})}
                                </span>
                            )}
                        </h5>
                        <div>
                            {loadingProducts ? (
                                <>
                                    <LoadingContainer/>
                                </>
                            ) : (
                                <>
                                    {products ? (
                                        <div className="row row-cols-1 g-3">
                                            {
                                                products.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((product: any) => {
                                                        return (
                                                            <React.Fragment key={product.id}>
                                                                <div className={"col d-flex"}>
                                                                    <VoucherProductComponent onSuccess={handleSuccessBuyVoucher} product={product}/>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <>
                                            {t("modules.user.field.no_vouchers_to_buy.description")}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config: getStoreConfig(state.config),
    vouchers: getVouchers(state.user),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserVouchersComponent));

