import React from 'react';
import Loading from "./Loading";

const LoadingContainer = (value:any)=>{
    return(
        <div className="loading-container">
            <Loading>{value.children}</Loading>
        </div>
    )
}

export default LoadingContainer;
