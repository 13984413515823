import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from 'react-redux'
import {Link, useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useTranslation, withTranslation} from "react-i18next";
import {Button, Navbar} from "react-bootstrap";
import {ReactComponent as LeftIco} from "../../../images/icons/arrow-left.svg";
import {RootState} from "../../../reducers";
import {getSession} from "../../services/Location/reducer";
import {getStoreConfig} from "../../services/Store/reducer";
import IframeUtils from "../../utils/IframeUtils";
import CartStickyComponent from "../../components/Cart/CartStickyComponent";
import CartItemsComponent from "../../components/Cart/CartItemsComponent";
import CartSummaryDataComponent from "../../components/Cart/CartSummaryDataComponent";
import FetchData from "../../components/Fetch/FetchData";
import {getPaymentMethods} from "../../services/Menu/reducer";
import CartRulesComponent from "../../components/Cart/CartRulesComponent";
import CartClientDataComponent from "../../components/Cart/CartClientDataComponent";
import CartPaymentComponent from "../../components/Cart/CartPaymentComponent";
import CartSubmitComponent from "../../components/Cart/CartSubmitComponent";
import CartCouponComponent from "../../components/Cart/CartCouponComponent";
import CartCustomFieldComponent from "../../components/Cart/CartCustomFieldComponent";
import {useCart} from "../../services/Cart/cartService";
import Analytics from "../../services/Common/Analytics/Analytics";
import StringHelper from "../../services/Common/StringHelper";
import CartHelper from "../../services/Cart/Helper/CartHelper";
import AppConfig from "../../services/AppConfig";
import {usePrefix} from "../../services/Prefix";
import {getCart} from "../../services/Cart/reducer";
import CartDeliveryInfoComponent from "./components/CartDeliveryInfoComponent";
import CartTypeComponent from "../../components/Cart/CartTypeComponent";
import CartPickDateComponent from "../../components/Cart/CartPickDateComponent";
import CartRegisterComponent from "../../components/Cart/CartRegisterComponent";
import CartCommentComponent from "../../components/Cart/components/CartCommentComponent";
import {getUser} from "../../services/User/reducer";
import CartCashbackComponent from "../../components/Cart/CartCashbackComponent";
import {CartState} from "../../services/Cart/types";
import CartStickyContextPage from "../Contexts/CartStickyContextPage";
import handleException from "../../services/Api/handleException";
import LoadingContainer from "../../components/Common/LoadingContainer";
import CartUtils from "../../services/Cart/CartUtils";
import StoreUtils from "../../services/Store/StoreUtils";
import {ApiError} from "../../services/Api/types";
import {addFlash} from "../../services/Notifications/actions";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartPage = (props: Props) => {
    const [errors, setErrors] = useState<any[]>([]);
    const cart = props.cart;
    const [data, setData] = useState<CartState>(props.cart);
    const navigate = useNavigate();
    const {t} = useTranslation();
    let cartService = useCart();
    const prefix = usePrefix();

    const saveOrder = async () => {
        let availablePaymentMethods = CartHelper.getAvailablePaymentMethods(props.config?.old_store.payment_methods ? props.config?.old_store.payment_methods : [], props.paymentMethods, cart.type);
        // let paymentMethodId = CartHelper.getDefaultPaymentMethodId(cart.payment_method_id, availablePaymentMethods);
        let paymentMethodId = CartHelper.getDefaultPaymentMethodId(data.payment_method_id, availablePaymentMethods);

        let total = cart.total;
        let orderData = {
            address: props.session.address,
            contact: {
                name: data.contact.name,
                email: data.contact.email,
                phone: data.contact.phone,
            },
            comment: StringHelper.emptyOrValue(data.comment),
            tax_id_no: data.tax_id_no,
            custom_fields: data.custom_fields,
            payment_method_id: paymentMethodId,
            type: cart.type,
            number: CartUtils.getCartNumber(props.session),
            pickup_at: cart.pickup_at,
            accept_marketing: cart.accepted_rules.includes('acceptMarketing') ? true : false
        }

        try {
            const newData = await cartService.update(orderData) as any;
            if (total.amount !== newData.total_money.amount) {
                props.dispatch(addFlash('error', [{
                    code: t("constraints.internal.order_changed_price")
                }] as ApiError[]));
                return;
            }
            await pay(paymentMethodId, data.payment_settings, cart.accepted_rules);
        } catch (error: any) {
            const errors = handleException(error);
            setErrors(errors);
            scrollToErrorSection();
        }
    }
    const scrollToErrorSection = () => {
        const headerOffset = 80; // Adjust this value based on your desired offset
        const cartElement = document.querySelectorAll('.cart');
        if (cartElement.length > 0) {
            const lastModal = cartElement[cartElement.length - 1];
            let errorElement = lastModal.querySelector('.is-invalid');
            if (!errorElement) {
                errorElement = lastModal.querySelector('.alert-danger');
            }
            if (errorElement) {
                const targetTop = errorElement.getBoundingClientRect().top + window.scrollY - headerOffset;
                window.scrollTo({
                    top: targetTop,
                    behavior: 'smooth'
                });
            }
        }
    };
    const pay = async (paymentMethodId: any, paymentSettings: any, acceptedRules: any) => {

        let data = {...paymentSettings};
        data.payment_method_id = paymentMethodId;
        data.accepted_rules = acceptedRules;
        const response = await cartService.pay(data) as any;
        if (response.data && response.data.status === "WAITING_FOR_ACCEPTED") {
            await cartService.clean();
            navigate(AppConfig.getLinkWithPrefix(`/summary/${response.data.id}/${response.data.token}`, prefix));
        } else {
            if (response.redirect_url !== undefined && response.redirect_url) {
                IframeUtils.redirect(response.redirect_url);
            }
        }
    }
    useEffect(() => {
        if (cart.id) {
            Analytics.beginCheckout(cart);
            setData(props.cart);
        }
    }, [cart.id])
    if (!props.cart.initialized) return (
        <LoadingContainer/>
    );
    const updateCustomFieldValue = (slug: any, value: any) => {
        if (cart.custom_fields === undefined) {
            cart.custom_fields = {};
        }
        cart.custom_fields[slug] = value;
        setData({
            ...data,
            custom_fields: {
                ...data.custom_fields,
                [slug]: value
            }
        });
    }

    const backToHome = () => {
        navigate(AppConfig.getLinkWithPrefix("/", prefix), {replace: true});
    }

    const renderAfterFetch = () => {

        return (
            <CartStickyContextPage>
                <Navbar className={"sticky-top navbar-back"}>
                    <div className={"navbar-brand-container"}>
                        <Link className={"btn btn-back"} to={AppConfig.getLinkWithPrefix("/", prefix)}>
                            <LeftIco/> {t("common.action.back")}
                        </Link>
                    </div>

                </Navbar>
                <div>
                    <CartStickyComponent></CartStickyComponent>

                    <div className={classNames('body-container body-container-cart')}>

                        <div className="cart cart-container">
                            <div className={"container-fluid"}>
                                <CartDeliveryInfoComponent/>
                            </div>
                            {cart.id ? (
                                <>
                                    <>
                                        <div className={"card-cart card-cart-items"}>
                                            <div className={"container-fluid"}>
                                                <h3 className={"card-cart-header"}>
                                                    {t("modules.cart.field.your_cart.title")}
                                                    <span
                                                        className="badge badge-light">{cart.items.length}</span></h3>
                                                <div className={"card-cart-body"}>
                                                    <CartItemsComponent
                                                        items={cart.items}></CartItemsComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                    {(!AppConfig.isNativeMobile()) && (
                                        <>
                                            <CartRegisterComponent errors={errors}></CartRegisterComponent>
                                        </>
                                    )}
                                    {!StoreUtils.isOrderingPreview(props.config) && (
                                        <CartClientDataComponent data={data} onChange={setData}
                                                                 errors={errors}></CartClientDataComponent>
                                    )}

                                    {!StoreUtils.isOrderingPreview(props.config) && (
                                        <CartCustomFieldComponent data={data}
                                                                  onChange={setData}
                                                                  errors={errors}></CartCustomFieldComponent>
                                    )}

                                    <CartTypeComponent errors={errors}
                                                       onChangeCustomField={updateCustomFieldValue}></CartTypeComponent>

                                    <CartPickDateComponent errors={errors}
                                                           onChangeCustomField={updateCustomFieldValue}></CartPickDateComponent>

                                    {!StoreUtils.isOrderingPreview(props.config) && (
                                        <>
                                            <CartPaymentComponent errors={errors} onChange={setData}
                                                                  data={data}></CartPaymentComponent>
                                            <CartCommentComponent data={data} onChange={setData}
                                                                  onChangeCustomField={updateCustomFieldValue}
                                                                  errors={errors}/>
                                            <CartCouponComponent updateErrors={setErrors}
                                                                 errors={errors}></CartCouponComponent>
                                        </>
                                    )}
                                    <div className={"card-cart"}>

                                        <div className={"container-fluid"}>
                                            <h5 className={"card-cart-header"}>
                                                {t("modules.cart.field.summary.title")}
                                            </h5>

                                            <CartCashbackComponent/>
                                            <div className={"card-cart-body"}>
                                                <div className={"mb-3"}>
                                                    <CartSummaryDataComponent/>
                                                </div>
                                                {!StoreUtils.isOrderingPreview(props.config) && (
                                                    <div className="cart-rules mb-3">
                                                        <CartRulesComponent errors={errors}/>
                                                    </div>
                                                )}
                                                {!StoreUtils.isOrderingPreview(props.config) ? (
                                                    <div className="btn-pay-container mb-3">
                                                        <CartSubmitComponent saveOrder={saveOrder}/>
                                                    </div>
                                                ) : (
                                                    <button
                                                        className={"w-100 btn btn-spinner btn-primary btn-submit"}
                                                        type="button"
                                                        disabled={true}>
                                                        {t("modules.cart.action.call_waiter_to_order")}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className={"container-fluid"}>
                                    <p>
                                        {t("modules.cart.field.empty_cart.title")}
                                    </p>
                                    <Button className={"btn btn-primary"} onClick={backToHome}>
                                        {t("modules.cart.action.back_to_home.title")}
                                    </Button>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </CartStickyContextPage>
        )
    }
    return (
        <FetchData menus={true} order={true}>
            {renderAfterFetch()}
        </FetchData>
    )
}


const mapStateToProps = (state: RootState) => ({
    paymentMethods: getPaymentMethods(state.menu),
    session: getSession(state.session),
    config: getStoreConfig(state.config),
    cart: getCart(state.cart),
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartPage));
