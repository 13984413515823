import {CartActionTypes} from "./types";
import {ApiError} from "../Api/types";

export const acceptRules = (acceptRules: any) => {
    return {
        type: CartActionTypes.ACCEPT_RULES,
        accepted_rules: acceptRules
    };
}

export const refreshBasket = (data: any, version: string) => {
    return {
        type: CartActionTypes.REFRESH_BASKET,
        order: data,
        version: version
    };
}

export const clearBasket = () => {
    return {
        type: CartActionTypes.CLEAR_BASKET
    };
}

export function orderPending(version: string) {
    return {
        type: CartActionTypes.ORDER_PENDING,
        version: version
    }
}

export function orderError(errors: ApiError[], version: string) {
    return {
        type: CartActionTypes.ORDER_ERROR,
        errors: errors,
        version: version
    }
}

export function orderErrorNotValid(errors: ApiError[], version: string) {
    return {
        type: CartActionTypes.ORDER_INVALID,
        errors: errors,
        version: version
    }
}

export function cleanCart(version: string) {
    return {
        type: CartActionTypes.CLEAN,
        version: version
    }
}