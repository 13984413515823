import React, {useState} from 'react';
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ConfigAddressApi, ConfigApi} from "../../services/Api/types";
import {RootState} from "../../../reducers";
import ErrorFormView from "../Common/ErrorFormView";
import CitySearchComponent from "../Location/CitySearchComponent";
import ErrorFormAlertView from "../Common/ErrorFormAlertView";
import StreetSearchComponent from "../Location/StreetSearchComponent";
import {api} from "../../services/Api/api";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import Utils from "../../utils/Utils";

interface OwnProps {
    address: ConfigAddressApi,
    errors: any,
    config: ConfigApi,
    onChange: (value: any) => void,
    onErrors: (value: any) => void,
    skipDeliveryAreaErrors?: boolean
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps;

function NavbarLocationForm(props: Props) {
    const {t} = useTranslation();
    const [address, setAddress] = useState(props.address);
    const [errors, setErrors] = useState(props.errors);

    const handleChangeStreet = (value: any, check?: any) => {
        let newAddress = {...address, street: value};
        setAddress(newAddress)
        props.onChange(newAddress)
        if (check) {
            onCheckLocationAddress(newAddress);
        }
    }
    const handleChangeCity = (value: any, zip?: any, check?: any) => {
        let newAddress = {...address, city: value, zip_code: zip};
        setAddress(newAddress)
        props.onChange(newAddress)
        if (check) {
            onCheckLocationAddress(newAddress);
        }
    }
    const handleChangeBuildNr = (value: any) => {
        let newAddress = {...address, build_nr: value};
        setAddress(newAddress)
        props.onChange(newAddress)
    }
    const handleChangeFlatNr = (value: any) => {
        let newAddress = {...address, flat_nr: value};
        setAddress(newAddress)
        props.onChange(newAddress)
    }
    const onCheckLocationAddress = async (addressToCheck: any) => {
        if (!addressToCheck.city || !addressToCheck.build_nr) return;
        let addressData = {
            city: addressToCheck.city,
            street: addressToCheck.street,
            build_nr: addressToCheck.build_nr,
            flat_nr: addressToCheck.flat_nr,
            country: addressToCheck.country,
            zip_code: addressToCheck.zip_code,
        }
        try {
            let data = await api.getLocationCheck(addressData);
            if (!data.coordinates) {
                let errors = [{
                    "field": "address",
                    "code": "coordinates_not_exist"
                }];
                setErrors(errors);
                props.onErrors(errors);
            } else if (!data.in_delivery_area && !props.skipDeliveryAreaErrors) {
                let errors = [{
                    "field": "address",
                    "code": "not_in_delivery_area"
                }];
                setErrors(errors);
                props.onErrors(errors);
            } else {
                setErrors([]);
                props.onErrors([]);
            }
        }catch (e){
            setErrors([{
                "field": "address",
                "code": "connection_problem"
            }]);
        }

    }
    const onCheckLocation = async () => {
        onCheckLocationAddress(address)
    }
    const handleChangeCountry = (value: any) => {
        let newAddress = {...address, country: value};
        setAddress(newAddress)
        props.onChange(newAddress)
        onCheckLocationAddress(newAddress)
    }
    let countries = [];
    if (props.config.ordering.countries) {
        countries = Utils.countries(props.config.ordering.countries);
    }
    let isSelectCities = false;
    if (props.config.ordering.search_type === "SEARCH") {
        isSelectCities = true;
    }

    return (
        <>
            <ErrorFormAlertView errors={errors} field="address"/>
            {(countries && countries.length > 1) ?
                <>
                    <div className="form-row row">
                        <div className="form-group col-3">
                            <select value={address.country} onChange={(e) => handleChangeCountry(e.currentTarget.value)}
                                    className={"form-control" + ErrorFormHelper(errors, "address.country")}
                                    id="country">
                                {countries.map((country: any) => (
                                    <option value={country.key} key={country.key}>{country.value}</option>
                                ))}
                            </select>
                            <label htmlFor="country">{t("common.address.country")}</label>
                            <ErrorFormView errors={errors} field="address.country"/>
                        </div>
                        <div className={`form-group col-9${address.city ? " focus" : ""}`}>
                            {isSelectCities ?
                                <CitySearchComponent placeholder={t("common.address.city") + "*"}
                                                     onBlur={(value: any, zip: any) => handleChangeCity(value, zip, true)}
                                                     value={address.city} zip={address.zip_code}
                                                     onChange={(value: any, zip: any) => handleChangeCity(value, zip)}/> :
                                <input type="text" value={address.city} onBlur={onCheckLocation}
                                       onChange={(e) => handleChangeCity(e.currentTarget.value)}
                                       className={"form-control" + ErrorFormHelper(errors, "address.city")} id="city"
                                       placeholder={t("common.address.city") + "*"}/>
                            }
                            <label htmlFor="city">{t("common.address.city")}</label>
                            <ErrorFormView errors={errors} field="address.city"/>
                        </div>
                    </div>
                </> :
                <>
                    <div className={`form-group${address.city ? " focus" : ""}`}>
                        {isSelectCities ?
                            <CitySearchComponent placeholder={t("common.address.city") + "*"}
                                                 onBlur={(value: any, zip: any) => handleChangeCity(value, zip, true)}
                                                 value={address.city} zip={address.zip_code}
                                                 onChange={(value: any, zip: any) => handleChangeCity(value, zip)}/> :
                            <input type="text" value={address.city} onBlur={onCheckLocation}
                                   onChange={(e) => handleChangeCity(e.currentTarget.value)}
                                   className={"form-control" + ErrorFormHelper(errors, "address.city")} id="city"
                                   placeholder={t("common.address.city") + "*"}/>
                        }
                        <label htmlFor="city">{t("common.address.city")}</label>
                        <ErrorFormView errors={errors} field="address.city"/>
                    </div>
                </>
            }
            <div className="form-row row">
                <div className={`form-group col-6${address.street ? " focus" : ""}`}>
                    {isSelectCities ?
                        <StreetSearchComponent placeholder={t("common.address.street") + "*"} city={address.city}
                                               onBlur={(value: any) => handleChangeStreet(value, true)}
                                               value={address.street}
                                               onChange={(value: any) => handleChangeStreet(value)}/> :
                        <input type="text" value={address.street} onBlur={onCheckLocation}
                               onChange={(e) => handleChangeStreet(e.currentTarget.value)}
                               className={"form-control" + ErrorFormHelper(errors, "address.street")} id="street"
                               placeholder={t("common.address.street") + "*"}/>
                    }
                    <label htmlFor="street">{t("common.address.street")}</label>
                    <ErrorFormView errors={errors} field="address.street"/>
                </div>
                <div className="form-group col-3">
                    <input type="text" value={address.build_nr} onBlur={onCheckLocation}
                           onChange={(e) => handleChangeBuildNr(e.currentTarget.value)}
                           className={"form-control" + ErrorFormHelper(errors, "address.build_nr")} id="build_nr"
                           placeholder={t("common.address.number") + "*"}/>
                    <label htmlFor="build_nr">{t("common.address.number")}</label>
                    <ErrorFormView errors={errors} field="address.build_nr"/>
                </div>
                <div className="form-group col-3">
                    <input type="text" value={address.flat_nr}
                           onChange={(e) => handleChangeFlatNr(e.currentTarget.value)}
                           className={"form-control" + ErrorFormHelper(errors, "address.flat_nr")} id="build_nr"
                           placeholder={t("common.address.flat_nr") + ""}/>
                    <label htmlFor="build_nr">{t("common.address.flat_nr")}</label>
                    <ErrorFormView errors={errors} field="address.flat_nr"/>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    return {}
}
export default withTranslation()(connect(mapStateToProps)(NavbarLocationForm))
