import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getStoreConfig} from "../../../../services/Store/reducer";
import {getSession} from "../../../../services/Location/reducer";
import {RootState} from "../../../../../reducers";
import {useLocationService} from "../../../../services/Location/locationService";
import {ReactComponent as MarkerSVG} from "../../../../../images/icons/marker.svg";
import ChangeLocationModal from "../../../../components/Modal/ChangeLocationModal";
import {FormatAddressString} from "go-core/components/Formatters/FormatAddress";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuLocationFilterComponent = (props: Props) => {
    const [addressModal, setAddressModal] = useState(false);
    const locationService = useLocationService();
    const {t} = useTranslation();

    if (!props.config) return null;

    return (
        <div className={"container-fluid"}>
            <button className={"btn btn-outline-primary btn-flex w-100"} onClick={() => setAddressModal(true)}>
                {(props.session.address && props.session.address.city) ? (
                    <>
                        {FormatAddressString(props.session.address)} <MarkerSVG/>
                    </>
                ) : (
                    <>
                        {t("common.action.select_location")} <MarkerSVG/>
                    </>
                )}
            </button>

            <ChangeLocationModal showModal={addressModal}
                                 onHide={() => setAddressModal(false)}/>
        </div>
    )

}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuLocationFilterComponent));