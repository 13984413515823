import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import AgeRestrictedContext from "../../../../services/Common/AgeRestrictedContext";
import IframeWidget from "../../../../utils/IframeWidget";


interface OwnProps {
    onHide: () => void,
    onSave: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const AgeRestrictedModalComponent = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(true);
    let {t} = useTranslation();

    const onShow = () => {
    }

    const save = () => {
        AgeRestrictedContext.accept();
        props.onSave()
    }
    const onHide = () => {
        setModal(false);
    }

    return (
        <React.Fragment>
            <div className={"alert alert-warning"}>
                <p>
                    {t("modules.menu.field.alcohol_advert.title")}
                </p>
                {!modal && (
                    <button type="button" className={"btn btn-primary" + (loading ? ' loading' : '')}
                            onClick={() => setModal(true)}>
                        {t("modules.menu.action.accept.title")}
                    </button>
                )}
            </div>
            <Modal dialogClassName="modal-full" show={modal} onHide={() => IframeWidget.onHide(onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("modules.menu.field.age_confirmation.title")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        {t("modules.menu.field.alcohol_advert.title")}
                        {t("modules.menu.field.are_you_an_adult.title")}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className={"btn btn-secondary" + (loading ? ' loading' : '')}
                            data-dismiss="modal" onClick={() => onHide()}>
                        {t("modules.menu.field.no.title")}
                    </button>
                    <button type="button" className={"btn btn-primary" + (loading ? ' loading' : '')} onClick={() => {
                        save()
                    }}>
                        {t("modules.menu.field.yes.title")}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(AgeRestrictedModalComponent));