export class CustomColorsLoader {
    private hexToRgb(hex: string) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return {r, g, b};
    }

    private rgbToHex(r: number, g: number, b: number) {
        return '#' + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase();
    }

    private adjustColor(color: string, amount: number) {
        let {r, g, b} = this.hexToRgb(color);

        r = Math.min(255, Math.max(0, r + amount));
        g = Math.min(255, Math.max(0, g + amount));
        b = Math.min(255, Math.max(0, b + amount));

        return this.rgbToHex(r, g, b);
    }

    private hexToHSL(hex: string) {
        // Remove the leading '#' if it exists
        if (hex[0] === '#') {
            hex = hex.slice(1);
        }

        // Parse the hex color string to R, G, B values
        let r = parseInt(hex.substring(0, 2), 16) / 255;
        let g = parseInt(hex.substring(2, 4), 16) / 255;
        let b = parseInt(hex.substring(4, 6), 16) / 255;

        // Get the minimum and maximum values of r, g, and b
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let delta = max - min;

        // Calculate hue
        let h = 0;
        if (delta !== 0) {
            if (max === r) {
                h = (g - b) / delta;
            } else if (max === g) {
                h = (b - r) / delta + 2;
            } else {
                h = (r - g) / delta + 4;
            }
            h *= 60;
            if (h < 0) h += 360;
        }

        // Calculate lightness
        let l = (max + min) / 2;

        // Calculate saturation
        let s = 0;
        if (delta !== 0) {
            s = delta / (1 - Math.abs(2 * l - 1));
        }

        // Return the HSL values
        return {
            h: Math.round(h),
            s: Math.round(s * 100),  // Convert to percentage
            l: Math.round(l * 100)   // Convert to percentage
        };
    }

    private generateColorPalette(baseColor: string) {
        // Ensure base color is in the right format
        const base = (baseColor.startsWith('#') ? baseColor : `#${baseColor}`).toUpperCase();

        // Create a scale from dark to light
        let scale = [];
        const step = 22; // Amount to lighten/darken for each step
        const colorLightness = this.hexToHSL(baseColor).l;

        for (let i = -10; i <= 10; i++) {
            const adjustedColor = this.adjustColor(base, step * i);
            scale.push(adjustedColor);
        }

        const firstDifferentIndex = scale.findIndex((color, index, colors) => {
            if (colorLightness >= 50) {
                return (colors[index - 1] !== color);
            } else {
                return (colors[index + 1] !== color);
            }
        });

        const lastDifferentIndex = scale.length - [...scale].reverse().findIndex((color, index, colors) => {
            if (colorLightness >= 50) {
                return (colors[index + 1] !== color);
            } else {
                return (colors[index - 1] !== color);
            }
        }) - 1;

        const indexDifference = lastDifferentIndex - firstDifferentIndex;
        const centerIndex = firstDifferentIndex + Math.floor(indexDifference / 2);
        scale = scale.slice(centerIndex - 5, centerIndex + 6)
        scale.reverse();

        // Map the generated colors to the 50-950 scale
        return {
            "--brand-50": scale[0],    // Lightest shade
            "--brand-100": scale[1],
            "--brand-200": scale[2],
            "--brand-300": scale[3],
            "--brand-400": scale[4],
            "--brand-500": scale[5],   // Base color (500)
            "--brand-600": scale[6],
            "--brand-700": scale[7],
            "--brand-800": scale[8],
            "--brand-900": scale[9],
            "--brand-950": scale[10]   // Darkest shade
        };
    }

    public updateColors(baseColor: string) {
        const colorPalette = this.generateColorPalette(baseColor);

        for (const [key, colorValue] of Object.entries(colorPalette)) {
            document.documentElement.style.setProperty(key, colorValue);
        }
    }
}