import React from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import StateItemHelper from "../../../../services/Menu/services/State/StateItemHelper";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import {getAvailabilities, getItems, getModifierGroups, getPromotions} from "../../../../services/Menu/reducer";
import Money from "../../../../components/Common/Money";
import {getCart} from "../../../../services/Cart/reducer";
import {getSession} from "../../../../services/Location/reducer";
import ItemViewHelper from "../../../../services/Menu/services/Item/ItemViewHelper";


interface OwnProps {
    stateItem: any,
    itemGroup: any,
    onUpdate: (stateItem: any) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuShowItemGroupComponent = (props: Props) => {
    const cart = props.cart;
    let stateItem = props.stateItem;
    let itemGroup = props.itemGroup;
    let selectedItemId = stateItem.item_id;
    const {t} = useTranslation();

    const handleChangeRadio = (itemId: any, e: any) => {
        let newCurrentStateItem = StateItemHelper.create(itemId, props.items, props.modifier_groups, props.session.order_type);
        StateItemHelper.refreshItemPrice(newCurrentStateItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), cart);
        props.onUpdate(newCurrentStateItem);
    }
    const renderItemGroupTitle = (itemGroup: any, item: any) => {
        let itemGroupTitle = itemGroup.translation.name;
        let itemTitle = item.translation.name;
        if (itemTitle.includes(itemGroupTitle)) {
            let regex = new RegExp('^' + itemGroupTitle);
            itemTitle = itemTitle.replace(regex, '').trim();
        }
        return itemTitle;
    }
    return (
        <div className="product-item-variant product-item-modifiers">
            <fieldset className="form-group">
                <legend>
                    <h5>
                        {t("modules.menu.field.choose_variant.title")}
                    </h5>
                </legend>
                <div className={"row row-cols-2 gx-2"}>
                    {
                        [...itemGroup.items].sort((a: any, b: any) => a.position - b.position || (a.name && b.name && a.name.localeCompare(b.name))).map((itemGroupItem: any) => {
                            let item = ItemHelper.getItem(props.items, itemGroupItem.item_id)
                            if (!item) return null;
                            let checked = selectedItemId === itemGroupItem.item_id;
                            let itemPrice = (item.price) ? item.price.amount : 0;
                            let itemView = ItemViewHelper.parseItem(item, props.availabilities, [], props.session.pickup_at, undefined, props.cart, props.session.order_type);
                            return (
                                <div className={"product-modifier-option-pick"}
                                     key={`${itemGroup.reference_id}-${itemGroupItem.item_id}`}>
                                    <input className={"btn-check"} type="radio" checked={checked}
                                           onChange={(e) => handleChangeRadio(itemGroupItem.item_id, e)}
                                           name={'variant'}
                                           id={`variant-${itemGroup.reference_id}-${itemGroupItem.item_id}`}
                                           value={itemGroupItem.item_id}/>
                                    <label className={`input-single${itemView.disabled ? " disabled" : ""}`}
                                           htmlFor={`variant-${itemGroup.reference_id}-${itemGroupItem.item_id}`}>
                                        {renderItemGroupTitle(itemGroup, item)}
                                        {(itemPrice > 0) && (
                                            <span className="product-modifier-option-price ml-auto price"><Money
                                                value={itemPrice}
                                                currency='PLN'/></span>
                                        )}
                                        {itemView.disabled && (
                                            <div className={"product-option-errors"}>
                                                <div className="text-danger error">{t("constraints.internal.item_not_available")}</div>
                                            </div>
                                        )}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </fieldset>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    modifier_groups: getModifierGroups(state.menu),
    promotions: getPromotions(state.menu),
    cart: getCart(state.cart),
    availabilities: getAvailabilities(state.menu),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuShowItemGroupComponent));