import React from 'react';
import {useTranslation} from "react-i18next";
import {ReactComponent as BasketFastSVG} from "../../../../images/icons/basket-fast.svg";
import FormatAddress from "go-core/components/Formatters/FormatAddress";
import FormattedTime from "../../../components/Common/FormattedTime";
import {parseDate} from "../../../services/Common/Date/DateHelper";
import {ReactComponent as CarSVG} from "../../../../images/icons/car.svg";
import {ReactComponent as PickUpSVG} from "../../../../images/icons/pick-up.svg";
import {ReactComponent as DineInSVG} from "../../../../images/icons/dine-in.svg";
import {ReactComponent as RoomServiceSVG} from "../../../../images/icons/room-service.svg";
import {ReactComponent as CashSVG} from "../../../../images/icons/cash.svg";


interface Props {
    order: any
}

const SummaryOrderDetailsComponent = (props: Props) => {
    const {t} = useTranslation();

    const orderTypeBox = () => {
        if (props.order.type === 'DELIVERY') {
            return (
                <div className="btn btn-lg btn-default btn-nav w-100">
                    <CarSVG/>
                    <span>{t("modules.summary.field.delivery_to_door.title")}<small><FormatAddress
                        address={props.order.address}/></small></span>
                </div>
            )
        }
        if (props.order.type === 'DINE_IN') {
            return (
                <div className="btn btn-lg btn-default btn-nav w-100">
                    <DineInSVG/>
                    <span>{t("enums.order.type.DINE_IN")}<small>{props.order.number ? `${t("modules.summary.field.delivery_to_table.title")}: ${props.order.number}` : t("modules.summary.field.pick_up_by_yourself.title")}</small></span>
                </div>
            )
        }
        if (props.order.type === 'PICK_UP') {
            return (
                <div className="btn btn-lg btn-default btn-nav w-100">
                    <PickUpSVG/>
                    <span>{t("enums.order.type.PICK_UP")}<small>{t("modules.summary.field.pick_up_by_yourself.title")}</small></span>
                </div>
            )
        }
        if (props.order.type === 'ROOM_SERVICE') {
            return (
                <div className="btn btn-lg btn-default btn-nav w-100">
                    <RoomServiceSVG/>
                    <span>{t("enums.order.type.ROOM_SERVICE")}<small>{t("modules.summary.field.delivery_to_room.title")}: {props.order.number}</small></span>
                </div>
            )
        }
        return null;
    }
    const timeToDelivery = () => {
        return (
            <div className="btn btn-lg btn-default btn-nav w-100">
                <BasketFastSVG/>
                {props.order.pickup_at ? (
                    <span>
                        {t("modules.summary.field.another_date.title")}
                        <small>
                                <FormattedTime
                                    value={parseDate(props.order.pickup_at)}
                                    day="numeric"
                                    month="long"/>
                        </small>
                    </span>
                ) : (
                    <span>
                        {t("common.word.as_soon_as_possible")}
                    </span>
                )}
            </div>
        )
    }

    const payment = () => {
        return (
            <div className="btn btn-lg btn-default btn-nav w-100">
                <span>
                    {props.order.payment_method_name}
                    {props.order.payment_status === "FAILURE" ? (
                        <small className={"text-danger"}>
                            {t("enums.order.payment_status." + props.order.payment_status)}
                        </small>
                    ) : props.order.payment_status === "PROGRESS" ? (
                        <small className={"text-warning"}>
                            {t("enums.order.payment_status." + props.order.payment_status)}
                        </small>
                    ) : (
                        <small className={"text-success"}>
                            {t("enums.order.payment_status." + props.order.payment_status)}
                        </small>
                    )}
                </span>
                <CashSVG/>
            </div>
        )
    }
    return (
        <>
            <div className={"card-cart"}>
                <div className={"container-fluid"}>
                    <h3 className={"card-cart-header"}>
                        {t("modules.summary.field.order_details.title")}
                        <small className="mt-2">#{props.order.id}</small>
                    </h3>
                    <div className={"card-cart-body"}>

                        <div className={"btn-groups-columns"}>

                            {/*{props.order.type === 'DELIVERY' && (*/}
                            {/*    <>*/}
                            {/*        {orderTypeBox()}*/}
                            {/*    </>*/}
                            {/*)}*/}
                            {orderTypeBox()}
                            {timeToDelivery()}
                            {payment()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryOrderDetailsComponent;