import {ADD_FLASH, REMOVE_FLASH} from './actions';
import {Reducer} from "redux";

const initialState = {
    messages: []
}
const notificationReducer: Reducer<any> = (state = initialState, action:any) => {
    switch (action.type) {
        case ADD_FLASH:
            let messages = action.messages.map((x: any) => {
                x.id = '_' + Math.random().toString(36).substr(2, 9);
                return x;
            });

            return {
                ...state,
                messages: state.messages.concat(messages)
            }
        case REMOVE_FLASH:
            return {
                ...state,
                messages: state.messages.filter((item: any) => item.id !== action.message.id)
            }
        default:
            return state;
    }
}

export default notificationReducer;
export const getMessages = (state: any) => state.messages;
