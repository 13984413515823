import CookiesStore from "../Common/Storage/cookiesStore";

class UserContextOld {
    static token = null;

    static setToken(token: any) {
        this.token = token;
        if (token !== undefined && token) {
            CookiesStore.set("utk", token);
        } else {
            CookiesStore.remove("utk");
        }
    }

    static getToken() {
        if (this.token) {
            return this.token;
        }
        const token = CookiesStore.get("utk");
        if (token) return token;
        return null;
    }
}

export default UserContextOld;
