import React from 'react';

import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import LocationSearch from "../../../New/components/Search/LocationSearch";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import {useLocationService} from "../../services/Location/locationService";
import {getSession} from "../../services/Location/reducer";


interface OwnProps {
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const LocationSearchWithSave = (props: Props) => {
    const locationService = useLocationService();

    if (!props.config) return null;
    const onChangeFromSearch = async (newAddress: any) => {
        await locationService.updateLocation(newAddress);
    }
    return (
        <LocationSearch onChange={onChangeFromSearch} config={props.config}
                        address={props.session.address}/>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LocationSearchWithSave));
