import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {ReactComponent as VoucherSVG} from "../../../../images/icons/voucher.svg";
import {RootState} from "../../../../reducers";
import ErrorFormAlertView from "../../Common/ErrorFormAlertView";
import {getLocation, selectLocale} from "../../../services/Location/reducer";
import {useAuth} from "../../../services/User/auth";
import handleException from "../../../services/Api/handleException";
import {VoucherApi} from "../../../services/Api/types";
import IframeWidget from "../../../utils/IframeWidget";

interface OwnProps {
    product: any,
    onSuccess: (voucher?: VoucherApi) => void
    onHide: () => void,
    show: boolean
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function VoucherProductModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);
    const product = props.product;
    const auth = useAuth();

    const buy = async (product: any) => {
        setLoading(true);
        try {
            const response = await auth.buyVoucher(product.id);
            const vouchers = await auth.syncVouchers();
            await auth.refresh();
            if (vouchers && response) {
                const userVoucher = vouchers.filter(x => x.id === response?.id)[0];
                props.onSuccess(userVoucher);
            } else {
                props.onSuccess(undefined);
            }
            setLoading(false);
        } catch (e: any) {
            const errors = handleException(e);
            setErrors(errors);
            
            setLoading(false);
        }

    }
    const onShow = () => {
    }


    return (
        <>
            <Modal dialogClassName="modal-sm modal-login" show={props.show} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"voucher-full"}>

                        <div className={"voucher-full-img"}>
                            {product.image ? (
                                <img src={product.image}/>
                            ) : (
                                <div className={"voucher-full-img-color"} style={{backgroundColor: product.color}}>
                                    <VoucherSVG/>
                                </div>
                            )}
                            {product.cost ? (
                                <span className={"points"}>{product.cost} pkt</span>
                            ) : (
                                <span className={"points"}>Darmowy</span>
                            )}
                            {/*<span className={"valid-time"}>Ważny do: 12.01.25</span>*/}
                        </div>
                        <h5>
                            {product.name}
                        </h5>
                        <p className="card-text">{product.description}</p>
                        <ErrorFormAlertView errors={errors}/>

                        <Button
                            className={`btn btn-spinner btn-primary w-100 ${loading ? " loading disabled" : ""}`}
                            onClick={() => buy(product)}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Odbierz voucher za {product.cost ? `${product.cost} pkt` : "darmo"}
                        </Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    location: getLocation(state.session),
    locale: selectLocale(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(VoucherProductModal));
