import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {getPaymentMethods} from "../../services/Menu/reducer";
import {useTranslation} from "react-i18next";
import {getStoreState} from "../../services/Store/reducer";
import Rules2021Component from "./components/Rules2021Component";
import {RootState} from "../../../reducers";
import PageLayout from "../../components/layout/PageLayout";
import {ConfigApi} from "../../services/Api/types";

interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const RulesPage = (props: Props) => {
    const {t} = useTranslation();
    let isDefaultRules = false;
    const config = props.config;
    if (config?.ordering.rules.some(rule => rule.link === "rules")) {
        isDefaultRules = true;
    }

    // if (!isDefaultRules) {
    //     return (<PageLayout>{t("constraints.internal.page_not_exist")}</PageLayout>);
    // }

    return (
        <PageLayout>
            {config && (
                <div className="menu-page">
                    <div className="container">
                        <Rules2021Component paymentMethods={props.paymentMethods} config={config}/>
                    </div>
                </div>
            )}
        </PageLayout>
    )
}

const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
    paymentMethods: getPaymentMethods(state.menu)
})

const connector = connect(mapStateToProps);

export default connector(RulesPage);