import React from 'react';
import {withTranslation} from "react-i18next";
import BaseSearchComponent from "./BaseSearchComponent";
import {api} from "../../services/Api/api";
import {CityApi} from "../../services/Api/types";


interface Props {
    onChange: (value: any, zip: any) => void
    onBlur: (value: any, zip: any) => void,
    value: any,
    zip: any,
    placeholder: any,
    className?: string
}

const getCityName = (city: CityApi) =>{
    return city.city_name ? city.city_name : city.cityName;
}
const getPostalCode = (city: CityApi) =>{
    return city.postal_code ? city.postal_code : city.postalCode;
}
const CitySearchComponent = (props: Props) => {
    const fetch = async (query: any) => {
        let response = await api.getCities(query);
        return response.map((c: any) => {
            let isOnlyCity = !getPostalCode(c) || response.some((x: CityApi) => getCityName(x) == getCityName(c));
            if (isOnlyCity) {
                return {
                    description: getCityName(c),
                    postalCode: getPostalCode(c)
                }
            }
            return {
                description: getCityName(c),
                postalCode: getPostalCode(c),
                renderText: () => {
                    return (
                        <>
                            {getCityName(c)}
                            {getPostalCode(c) && (
                                <small>
                                    , {getPostalCode(c)}
                                </small>
                            )}
                        </>
                    )
                }
            }
        })
    }
    const onChange = (value: any) => {
        props.onChange(value.description, value.postalCode);
    }
    const onBlur = (value: any) => {
        props.onBlur(value.description, value.postalCode);
    }


    return (
        <BaseSearchComponent className={props.className} fetch={fetch} value={{description: props.value, postalCode: props.zip}} onBlur={onBlur}
                             onChange={onChange} placeholder={props.placeholder}/>
    )
}

export default withTranslation()(CitySearchComponent)