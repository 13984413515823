import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import {RootState} from "../../../../reducers";
import ErrorFormView from "../../Common/ErrorFormView";
import ErrorFormAlertView from "../../Common/ErrorFormAlertView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import {getUser} from "../../../services/User/reducer";
import {api} from "../../../services/Api/api";
import {ApiError} from "../../../services/Api/types";
import {UnprocessableEntity} from "../../../services/Api/exceptions";
import handleException from "../../../services/Api/handleException";

interface OwnProps {
    setPage: (page: string) => void,
    onSuccess: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function LostPasswordUserComponent(props: Props) {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const {t} = useTranslation();

    const resetPassword = async () => {
        setLoading(true);
        try {
            await api.resetPassword(email)
            setLoading(false);
            setSuccess(true);
        } catch (e) {
            if (e instanceof UnprocessableEntity) {
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": t("constraints.internal.could_not_reset_password")
                    }
                ])
            } else {
                setErrors(handleException(e));
            }
            setLoading(false);
        }
    }

    return (
        <>
            <div className={"login-box-login"}>
                <h4>
                    {t("modules.user.field.password_reset.title")}
                </h4>
                {success ? (
                    <>
                        <div className="alert alert-light" role="alert">
                            <small>
                                <InfoSVG className={"icon me-2"}/>
                                {t("modules.user.field.reset_password_message.title")}
                            </small>
                        </div>
                    </>
                ) : (
                    <>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="text" onChange={(e) => setEmail(e.target.value)} value={email}
                                   className={"form-control" + ErrorFormHelper(errors, "username")}
                                   placeholder={t("common.word.email")}
                                   aria-label="Recipient's username"
                                   aria-describedby="cart-username"/>
                            <label
                                htmlFor="cart-username">{t("common.word.email")}</label>
                            <ErrorFormView errors={errors} show_message={true} field="username"/>
                        </div>
                        <button
                            className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                            onClick={() => {
                                resetPassword()
                            }}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {t("modules.user.action.reset_password.title")}
                        </button>
                    </>
                )}
            </div>
            <div className={"login-box-register-info"}>
                <hr/>
                <div className={"text-center"}>
                    {t("modules.user.field.remember_password.title")}
                    <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    props.setPage("LOGIN");
                }}>
                    {t("common.action.login")}
                </a>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LostPasswordUserComponent));
