import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {getStoreConfig} from "../../services/Store/reducer";
import {RootState} from "../../../reducers";
import StringHelper from "../../services/Common/StringHelper";
import {Link} from "react-router-dom";
import AppConfig from "../../services/AppConfig";
import {ReactComponent as BackSVG} from "../../../images/icons/arrow-left.svg";


interface OwnProps {
    backUrl?: string,
    multiStore?: boolean
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function NavbarMultiStore(props: Props) {
    let portalUrl = props.backUrl;
    if (!props.config) return null;

    let addressName = StringHelper.getAddressName(props.config?.address);
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light sticky-top navbar-back">
                <div className="navbar-brand-container">

                    <Link to={"/"} className={"btn btn-back btn-lg"}>
                        <BackSVG/>
                    </Link>
                    <div className={"navbar-back-header"}>
                        <h5>
                            {props.config.name}
                        </h5>
                        <small>
                            {addressName}
                        </small>
                    </div>
                </div>
            </nav>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarMultiStore));
