import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as PlusRemoveIco} from "../../../images/icons/plus-remove.svg";
import {RootState} from "../../../reducers";
import Money from "../Common/Money";
import PromotionInfoModalComponent from "./components/PromotionInfoModalComponent";
import {useCart} from "../../services/Cart/cartService";
import {getCart} from "../../services/Cart/reducer";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartPromotionsComponent = (props: Props) => {
    const {t} = useTranslation();
    let cartService = useCart();
    const cart = props.cart;

    const [loading, setLoading] = useState(false);
    const [promotionInfoModal, setPromotionInfoModal] = useState<any | undefined>(undefined);

    const remove = async (orderPromotion: any) => {

        setLoading(true);
        try {
            await cartService.removePromotion(orderPromotion.id);
        } catch (e) {
            console.log(e);
        }
        setLoading(false)

    }

    const showPromotionInfo = async (promotion: any) => {
        setPromotionInfoModal(promotion);
    }
    let sortedPromotions = [...cart.promotions].filter(x => x.type !== "CREDIT").sort((a: any, b: any) => a.name.localeCompare(b.name))
    return (
        <>
            {promotionInfoModal && (
                <PromotionInfoModalComponent name={promotionInfoModal.name}
                                             promotionId={promotionInfoModal.promotion_id}
                                             onHide={() => setPromotionInfoModal(undefined)} onShow={() => {
                }}/>
            )}
            <div className={"container cart-promotions"}>
                {sortedPromotions.map((promotion: any) => {
                    if (promotion.type == "TIP") return null;
                    let isActive = promotion.value_money !== undefined && promotion.value_money.amount > 0;
                    return (
                        <div
                            className={"btn btn-promotion btn-spinner" + (loading ? ' loading' : '') + (!isActive ? ' btn-danger' : '')}
                            key={promotion.id}>
                            <div className={"content"} onClick={(e) => {
                                e.preventDefault();
                                if ((e.target as HTMLElement).classList.contains('icon')) {
                                    remove(promotion);
                                } else {
                                    showPromotionInfo(promotion);
                                }
                            }}>
                                {promotion.type == "CREDIT" ? (
                                    <>{t("modules.promotion.field.customer_wallet.title")}</>
                                ) : (
                                    <>{promotion.name}</>
                                )}

                                <small>
                                    {isActive ? (
                                        <>
                                            <Money value={promotion.value_money}/>
                                            {promotion.max_value_money && (
                                                <>, {t("modules.promotion.field.available.title")}: <Money
                                                    value={promotion.max_value_money}/></>
                                            )}
                                        </>
                                    ) : (
                                        <>

                                            <div className={"text-danger"}>
                                                {t("modules.promotion.field.not_meet_promotion_condition.title")}
                                            </div>
                                            <a className={"text-success"}>
                                                {t("modules.promotion.action.learn_more.title")}
                                            </a>
                                        </>
                                    )}
                                </small>
                            </div>
                            <div className={"icon"} onClick={(e) => {
                                e.preventDefault();
                                remove(promotion);
                            }}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                <PlusRemoveIco/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )

}


const mapStateToProps = (state: RootState) => ({
    cart: getCart(state.cart)

})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartPromotionsComponent));