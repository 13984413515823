import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getAvailabilities, getCustomFields} from "../../services/Menu/reducer";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import CustomFieldHelper from "../../services/Cart/Helper/CustomFieldHelper";
import {getCart} from "../../services/Cart/reducer";
import {CartCustomFieldsComponent} from "./CartCustomFieldsComponent";
import {Collapse} from "react-bootstrap";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import ErrorFormView from "../Common/ErrorFormView";

const getDefaultCustomFields = (orderCustomFields: any) => {
    let customFields = []
    for (var key in orderCustomFields) {
        var value = orderCustomFields[key];
        customFields.push({
            slug: key,
            value: value
        })
    }
    return customFields
}

interface OwnProps {
    data: any,
    onChange: (cart: any) => void,
    // onChange: (slug: any, value: any) => void,
    errors: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const CartCustomFieldComponent = (props: Props) => {
    const [showInvoice, setShowInvoice] = useState(!!props.cart.tax_id_no);
    const customFields = getDefaultCustomFields(props.data);
    // const updateCustomFieldValue = (slug: any, value: any) => {
    //     props.onChange(slug, value);
    // }
    // const cart = props.cart;
    const {t} = useTranslation();
    const orderCustomFields = props.config?.old_store.order_custom_fields ? props.config?.old_store.order_custom_fields : [];
    const updateCustomFieldValue = (slug: any, value: any) => {
        let customFieldsToUpdate = props.data.custom_fields === undefined ? {} : {...props.data.custom_fields};
        customFieldsToUpdate[slug] = value;
        props.onChange({
            ...props.data,
            custom_fields: {
                ...props.data.custom_fields,
                [slug]: value
            }
        });
    }
    const handleChangeTaxIdNo = (value: any) => {
        props.onChange({
            ...props.data,
            tax_id_no: value
        })
    }
    const openTaxIdNo = () => {
        if(showInvoice){
            props.onChange({
                ...props.data,
                tax_id_no: ""
            })
        }
        setShowInvoice(!showInvoice)
    }

    const {custom_fields} = props;
    let taxIdNoVisible = CustomFieldHelper.getVisible("taxIdNo", orderCustomFields, props.cart.type);
    if (!CustomFieldHelper.isVisible(taxIdNoVisible) && !CustomFieldHelper.isCustomFieldExist(props.custom_fields, props.cart.type, orderCustomFields)) {
        return null;
    }
    return (
        <div className="card-cart">
            <div className={"container-fluid"}>
                <h5 className={"card-cart-header"}>
                    {t("modules.cart.order_info.header.title")}
                </h5>
                <div className={"card-cart-body"}>
                    <CartCustomFieldsComponent availabilities={props.availabilities}
                                               orderCustomFields={orderCustomFields} orderType={props.cart.type}
                                               customFields={custom_fields} valueCustomFields={props.data.custom_fields}
                                               errors={props.errors}
                                               onChange={updateCustomFieldValue}/>
                    {CustomFieldHelper.isVisible(taxIdNoVisible) && (
                        <>
                            <div className={"form-group"}>
                                <div className="form-check">
                                    <input checked={showInvoice}
                                           onChange={openTaxIdNo}
                                           className={"form-check-input"}
                                           type="checkbox" value="1"
                                           id={"cart_invoice_open"}/>
                                    <label className="form-check-label" htmlFor={"cart_invoice_open"}>
                                        {t("modules.cart.field.invoice_needed.title")}
                                    </label>
                                </div>
                            </div>
                            <Collapse in={showInvoice}>
                                <div id="collapse-content">

                                    <div className="form-group">
                                        <input type="text" value={props.data.tax_id_no}
                                               onChange={(e) => handleChangeTaxIdNo(e.target.value)}
                                               className={"form-control" + ErrorFormHelper(props.errors, "taxIdNo")}
                                               id="taxIdNo"
                                               placeholder={t("common.word.tax_id_no") + (CustomFieldHelper.isRequired(taxIdNoVisible) ? "*" : "")}/>
                                        <label
                                            htmlFor="phone">{t("common.word.tax_id_no")}{CustomFieldHelper.isRequired(taxIdNoVisible) && "*"}</label>
                                        <ErrorFormView errors={props.errors} field="taxIdNo"/>
                                    </div>
                                </div>
                            </Collapse>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    custom_fields: getCustomFields(state.menu),
    availabilities: getAvailabilities(state.menu),
    config: getStoreConfig(state.config),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartCustomFieldComponent));