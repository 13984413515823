import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreConfigLoyalty} from "../../services/Store/reducer";
import AdvertUserComponent from "../User/components/AdvertUser";
import UserUtils from "../../services/User/UserUtils";
import {getUser, getVouchers} from "../../services/User/reducer";
import {ReactComponent as ArrowRightSVG} from "../../../images/icons/arrow-right.svg";
import LoginUserModal from "../User/LoginUserModal";


interface OwnProps {
    errors: any[]
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartRegisterComponent = (props: Props) => {
    const [loginModal, setLoginModal] = useState(false);
    const {user} = props;
    const {t} = useTranslation();

    if (!UserUtils.isLoyaltyEnabled(props.config_loyalty)) {
        return null;
    }
    if (UserUtils.isLogged(user)) {
        return null;
    }

    return (
        <>
            <div className={"card-cart"}>
                {UserUtils.isLoyaltyRegistration(props.config_loyalty) && (
                    <AdvertUserComponent/>
                )}
                <div className={"container-fluid mt-2"}>
                    <button onClick={() => setLoginModal(true)} className={"btn btn-lg btn-default btn-nav w-100"}>
                            <span>
                                {t("common.action.login")}
                                <small>
                                    {t("modules.cart.field.collect_points.title")}
                                </small>
                            </span>
                        <ArrowRightSVG/>
                    </button>
                </div>
                {loginModal && (
                    <LoginUserModal onHide={() => setLoginModal(false)} show={true}/>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    vouchers: getVouchers(state.user),
    config_loyalty: getStoreConfigLoyalty(state.config),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartRegisterComponent));
