import {ConfigApi, StoreConfigLoyaltyApi} from "../Api/types";
import {UserState} from "./types";

class UserUtils {
    static isLogged(user: UserState) {
        return !!user?.id;
    }
    static isLoyaltyEnabled(configLoyalty?: StoreConfigLoyaltyApi) {
        return configLoyalty?.registration?.enabled;
    }
    static isLoyaltyRegistration(config?: StoreConfigLoyaltyApi) {
        return config?.registration?.enabled;
    }
    static isAnyLoyaltyProgramEnabled(config?: StoreConfigLoyaltyApi) {
        return this.isLoyaltyPointsEnabled(config) || this.isLoyaltyGroupsEnabled(config) || this.isLoyaltyVoucherEnabled(config) || this.isLoyaltyCashbackEnabled(config);
    }
    static isLoyaltyVoucherEnabled(config?: StoreConfigLoyaltyApi) {
        return config?.vouchers?.enabled;
    }

    static isLoyaltyGroupsEnabled(config?: StoreConfigLoyaltyApi) {
        return config?.groups?.enabled;
    }

    static isLoyaltyCashbackEnabled(config?: StoreConfigLoyaltyApi) {
        return config?.cashback?.enabled;
    }

    static isLoyaltyPointsEnabled(config?: StoreConfigLoyaltyApi) {
        return config?.points?.enabled;
    }
    static isLoyaltyQrcodeEnabled(config?: StoreConfigLoyaltyApi) {
        return config?.qrcode;
    }

}

export default UserUtils;
