import {Reducer} from "redux";
import {LocationActionTypes, SessionState} from "./types";
import {ConfigAddressApi, LocationCheckApi} from "../Api/types";
import {RootState} from "../../../reducers";
import StringHelper from "../Common/StringHelper";

const initialState: SessionState = {
    full_address: "",
    is_exist: false,
    in_delivery_area: false,
    address: {} as ConfigAddressApi,
    initialized: false,
    address_dirty: false
}

const sessionReducer: Reducer<SessionState> = (state = initialState, action: any) => {
    switch (action.type) {
        case LocationActionTypes.CHECK:
            let locationCheck = action.check as LocationCheckApi;
            return {
                ...state,
                in_delivery_area: locationCheck.in_delivery_area,
                estimated_delivery_duration: locationCheck.estimated_delivery_duration,
                estimated_preparation_duration: locationCheck.estimated_preparation_duration,
                delivery_price_money: locationCheck.delivery_price_money,
                free_delivery_price_money: locationCheck.free_delivery_price_money,
                min_order_price_money: locationCheck.min_order_price_money,
                coordinates: locationCheck.coordinates
            }
        case LocationActionTypes.UPDATE:
            let session = action.session as SessionState;
            var newAddress = session.address ? session.address : state.address;
            var fullAddress = StringHelper.getAddressName(newAddress);
            return {
                ...state,
                pickup_at: session.pickup_at,
                order_type: session.order_type,
                room_number: session.room_number,
                table_number: session.table_number,
                scan_time: session.scan_time,
                address: newAddress,
                contact: session.contact,
                is_exist: !!fullAddress,
                full_address: fullAddress,
                default_min_order_price_money: session.default_min_order_price_money,
                default_delivery_price_money: session.default_delivery_price_money,
                initialized: true,
                address_dirty: false,
                locale: session.locale,
                coordinates: session.coordinates
            }
        case LocationActionTypes.UPDATE_LOCATION:
            var fullAddress = state.full_address;

            var actionAddress = action.location;
            var newAddress = {...initialState.address};
            if (!newAddress) {
                newAddress = {} as ConfigAddressApi
            }
            let is_exist = false;
            if (actionAddress == undefined) {
            } else {
                newAddress.city = actionAddress.city;
                newAddress.street = actionAddress.street;
                newAddress.build_nr = actionAddress.build_nr;
                newAddress.zip_code = actionAddress.zip_code;
                newAddress.country = actionAddress.country != undefined ? actionAddress.country : state.address ? state.address.country : "";
                newAddress.flat_nr = actionAddress.flat_nr != undefined ? actionAddress.flat_nr : state.address ? state.address.flat_nr : "";
                is_exist = true;
                fullAddress = StringHelper.getAddressName(newAddress);
            }

            return {
                ...state,
                is_exist: is_exist,
                full_address: fullAddress,
                description: fullAddress,
                address: newAddress,
                address_dirty: action.dirty ? action.dirty : false
            }

        case LocationActionTypes.UPDATE_COORDINATES:
            var actionCoordinates = action.coordinates;

            return {
                ...state,
                coordinates: actionCoordinates
            }
        case LocationActionTypes.UPDATE_LOCALE:
            var locale = action.locale;
            return {
                ...state,
                locale: locale
            }
        default:
            return state;
    }
}

export default sessionReducer;
export const getLocation = (state: SessionState) => state;
export const getSession = (state: SessionState) => state;
export const getSessionInitialized = (state: SessionState) => state.initialized;

export const selectSession = (state: RootState) => {
    return state.session;
};
export const selectLocale = (state: SessionState) => {
    return state.locale;
};

