import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import {ConfigApi, StoreConfigCoordinatesApi} from "../../../../services/Api/types";
import {getStoreConfigLoyalty} from "../../../../services/Store/reducer";
import {getUser} from "../../../../services/User/reducer";
import NavbarMulti from "../../../../components/Navbar/NavbarMulti";
import {getSession} from "../../../../services/Location/reducer";
import FetchPlaces from "../../../../components/Fetch/FetchPlaces";
import {usePlaceContext} from "../../../../services/Place/PlaceContext";
import {useLocationService} from "../../../../services/Location/locationService";
import MultistoreMapListComponent from "../components/MultistoreMapListComponent";
import ResponsiveComponent from "../../../../components/Common/ResponsiveComponent";

const MultistoreMapComponent = React.lazy(() => import('./components/MultistoreMapComponent'));

interface OwnProps {
    config: ConfigApi
}

// let defaultCenter: [number, number] = [
//     51.9194, 19.1451
// ]

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreMapPage = (props: Props) => {
    const {places, nearestPlace, updateCoordinates} = usePlaceContext();
    const locationService = useLocationService();
    const [filter, setFilter] = useState<any>({});

    const geolocation = async (coordinates:StoreConfigCoordinatesApi) => {
        await locationService.updateCoordinates({
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
        })
    }

    useEffect(() => {
        updateCoordinates(props.session.coordinates);
    }, [props.session.coordinates]);

    return (
        <>
            <NavbarMulti config={props.config}/>
            <FetchPlaces>
                <ResponsiveComponent small={<div className={"multistore-map"}>
                    <MultistoreMapComponent places={places} nearestPlace={nearestPlace}
                                            coordinates={props.session.coordinates}
                                            zoom={8} geolocation={geolocation}
                                            onChangeCity={(value:string) => setFilter({...filter, city: value})}/>
                </div>} large={<MultistoreMapListComponent filter={filter} setFilter={setFilter}/>}/>

            </FetchPlaces>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);

export default connector(MultistoreMapPage);