import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import AvailableHelper from "../../../../services/Menu/services/Availability/AvailableHelper";
import DateService from "../../../../services/Common/Date/DateService";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import {RootState} from "../../../../../reducers";
import {getSession} from "../../../../services/Location/reducer";
import {getAvailabilities} from "../../../../services/Menu/reducer";
import MenuAvailabilityDateButton from "./MenuAvailabilityDateButton";
import {getStoreConfig} from "../../../../services/Store/reducer";

interface OwnProps {
    menu: any,
    preview: boolean
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuAvailability = ({menu, session, preview, availabilities, config}: Props) => {
    const {t} = useTranslation();
    let showChangePickDateButton = !!config?.ordering.pick_date;
    const availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id);

    const renderWrapper = (children: JSX.Element) => {
        return (
            <div className="container-fluid">
                <div className="alert alert-warning">
                    {children}
                </div>
            </div>
        )
    }

    const render = () => {
        if (session.pickup_at && !menu.pick_date) {
            return renderWrapper(<>
                <div className="d-flex flex-column">
                    {t("constraints.internal.unavailable_pickup_date")}
                    <MenuAvailabilityDateButton menu={menu}/>
                </div>
            </>)
        }

        if (!preview) {
            if (menu.pick_date) {
                showChangePickDateButton = true;
            }
        }

        if (!AvailableHelper.isAvailabileDate(availability, DateService.emptyOrNow(session.pickup_at))) {
            let pickDateText = "";
            if (menu.pick_date) {
                pickDateText = session.pickup_at ? t("constraints.internal.unavailable_pickup_date") : t("constraints.internal.unavailable_today_pickup_date");
            } else {
                pickDateText = session.pickup_at ? t("constraints.internal.unavailable_pickup_date") : t("constraints.internal.unavailable_today");
            }

            return renderWrapper(
                <div className="d-flex flex-column">
                    {pickDateText}
                    {showChangePickDateButton && <MenuAvailabilityDateButton menu={menu}/>}
                </div>
            )
        } else if (!AvailableHelper.isAvailabileHours(availability, DateService.emptyOrNow(session.pickup_at))) {
            const todayOpenHours = ItemHelper.getTodayOpenHoursString(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(session.pickup_at));
            let todayOpenHoursText = '';
            if (todayOpenHours) {
                todayOpenHoursText = session.pickup_at ? t("constraints.internal.unavailable_with_openhours", {openHours: todayOpenHours}) : t("constraints.internal.available_time") + ": " + todayOpenHours
            } else {
                todayOpenHoursText = session.pickup_at ? t("constraints.internal.unavailable_pickup_date") : t("constraints.internal.unavailable_today");
            }

            return renderWrapper(
                <div className="d-flex flex-column">
                    {todayOpenHoursText}
                    {showChangePickDateButton && <MenuAvailabilityDateButton menu={menu}/>}
                </div>
            )
        }

        return <></>;
    }

    return render()
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    config: getStoreConfig(state.config),
    availabilities: getAvailabilities(state.menu),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuAvailability));