import React, {createContext, ReactNode, useContext, useState} from 'react';
import {PlaceApi, StoreConfigCoordinatesApi} from "../Api/types";
import Utils from "../../utils/Utils";


// Define the context type
type PlaceContextType = {
    places?: PlaceApi[];
    nearestPlace?: PlaceApi;
    addPlace: (place: PlaceApi) => void;
    updatePlaces: (places: PlaceApi[], coordinates?: StoreConfigCoordinatesApi) => void;
    updateCoordinates: (coordinates?: StoreConfigCoordinatesApi) => void,
    removePlace: (id: string) => void;
    clearPlaces: () => void;
};

// Create the context with a default value
const PlaceContext = createContext<PlaceContextType | undefined>(undefined);

// Provider component
export const PlaceProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [places, setPlaces] = useState<PlaceApi[] | undefined>(undefined);
    const [nearestPlace, setNearestPlace] = useState<PlaceApi | undefined>(undefined);

    const addPlace = (place: PlaceApi) => setPlaces((prev) => prev ? [...prev, place] : [place]);
    const updatePlaces = (places: PlaceApi[], coordinates?: StoreConfigCoordinatesApi) => {
        places = refreshCoordinates(places, coordinates);
        setPlaces(places)
        refreshNearestPlace(places);
    };

    const removePlace = (id: string) =>
        setPlaces((prev) => prev ? prev.filter((place) => place.domain !== id) : []);

    const clearPlaces = () => setPlaces([]);
    const refreshCoordinates = (places: PlaceApi[], coordinates?: StoreConfigCoordinatesApi) => {
        if(!places) return places;
        let sortedProfiles = [...places];

        if (coordinates !== undefined) {
            sortedProfiles.forEach(x => {
                if (coordinates === undefined || x.coordinates === undefined) {
                    x.distance = undefined;
                    return;
                }
                x.distance = Utils.calculateDistance(coordinates?.latitude, coordinates?.longitude, x.coordinates.latitude, x.coordinates.longitude);
            });
            sortedProfiles = sortedProfiles.sort((a, b) => {
                if (a.in_delivery_zone && !b.in_delivery_zone) return -1;
                if (!a.in_delivery_zone && b.in_delivery_zone) return 1;
                if (a.distance == undefined) return 1;
                if (b.distance == undefined) return -1;
                if (a.distance > b.distance) return 1;
                if (a.distance < b.distance) return -1;
                return 0;
            });
        } else {
            sortedProfiles = sortedProfiles.sort((a, b) => a.name.localeCompare(b.name));
        }
        return sortedProfiles;
    }
    const updateCoordinates = (coordinates?: StoreConfigCoordinatesApi) => {
        if (!places) return;
        let sortedProfiles = refreshCoordinates(places, coordinates);
        setPlaces(sortedProfiles);
        refreshNearestPlace(sortedProfiles);
    }
    const refreshNearestPlace = (places:PlaceApi[]) => {
        let sortedProfilesByDistance;
        sortedProfilesByDistance = places.sort((a, b) => {
            if (a.in_delivery_zone && !b.in_delivery_zone) return -1;
            if (!a.in_delivery_zone && b.in_delivery_zone) return 1;
            if (a.distance === undefined) return 1;
            if (b.distance === undefined) return -1;
            return a.distance - b.distance;
        });
        let nearestProfile = sortedProfilesByDistance.length > 0 && sortedProfilesByDistance[0].distance !== undefined ? sortedProfilesByDistance[0] : undefined;
        setNearestPlace(nearestProfile);
    }
    return (
        <PlaceContext.Provider value={{places, nearestPlace, addPlace, removePlace, clearPlaces, updatePlaces, updateCoordinates}}>
            {children}
        </PlaceContext.Provider>
    );
};

// Custom hook to use the Place context
export const usePlaceContext = (): PlaceContextType => {
    const context = useContext(PlaceContext);
    if (!context) {
        throw new Error('usePlaceContext must be used within a PlaceProvider');
    }
    return context;
};