import {CartState} from "../Cart/types";
import {VoucherApi} from "../Api/types";

class VoucherUtils {
    static getVoucherWithCarts(vouchers?: VoucherApi[], cart?: CartState) {
        if (!vouchers) return [];
        return vouchers.map(x => {
            const orderPromotion = cart ? cart.promotions.filter(y => y.reference_id === x.id)[0] : undefined;
            return {
                voucher: x,
                promotion_id: orderPromotion ? orderPromotion.id : undefined
            };
        });
    }
}

export default VoucherUtils;
