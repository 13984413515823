import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import StringHelper from "../../services/Common/StringHelper";
import {ConfigApi} from "../../services/Api/types";
import {ReactComponent as ArrowBottomSVG} from "../../../images/icons/arrow-bottom.svg";
import {Link} from "react-router-dom";


interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function NavbarPlaces(props: Props) {

    let addressName = StringHelper.getAddressName(props.config.address);

    return (
        <>
            <Link to={"/map"} className={"btn btn-choose-place btn-flex"}>
                <div className={"multiline"}>
                    <h5>
                        {props.config.name}
                    </h5>
                    {addressName && (
                        <small>
                            {addressName}
                        </small>
                    )}
                </div>
                <ArrowBottomSVG/>
            </Link>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarPlaces));