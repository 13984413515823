import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getItems, getModifierGroups, getPromotions} from "../../services/Menu/reducer";
import PromotionHelper from "../../services/Menu/services/Promotion/PromotionHelper";
import {ReactComponent as PlusCircleIco} from "../../../images/icons/plus-circle.svg";
import {RootState} from "../../../reducers";
import Money from "../Common/Money";
import MenuEditItemModalComponent from "../../pages/Menu/components/Edit/MenuEditItemModalComponent";
import {useCart} from "../../services/Cart/cartService";
import Analytics from "../../services/Common/Analytics/Analytics";
import {getCart} from "../../services/Cart/reducer";
import ItemHelper from "../../services/Menu/services/Item/ItemHelper";
import {parseDate} from "../../services/Common/Date/DateHelper";
import CartItemsNewComponent from "./components/CartItemsNewComponent";
import {ReactComponent as PlusSVG} from "../../../images/icons/plus.svg";
import {ReactComponent as MinusSVG} from "../../../images/icons/minus.svg";
import {ReactComponent as MenuSVG} from "../../../images/icons/menu.svg";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartItemsComponent = (props: Props) => {
    const {items, modifierGroups, promotions} = props;
    const cart = props.cart;
    const orderItems = cart.items;
    const {t} = useTranslation();
    const visibileModifierGroupName = false;
    const [modalEditItem, setModalEditItem] = useState(undefined);
    let cartService = useCart();

    let cartPromotions = cart.promotions;
    let suggestPromotions = PromotionHelper.getSuggest(promotions, cart);

    const changeQuantity = async (orderItem: any, value: any) => {
        try {
            const id = orderItem.id;
            await cartService.updateItem(id, {
                ...orderItem,
                quantity: value
            });
            if (Number(value) === 0) {
                let orderItemToRemove = orderItems.filter((x: any) => x.id == id)[0];
                if (orderItemToRemove) {
                    Analytics.removeFromCart(orderItemToRemove);
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
    const addPromotion = async (promotionId: any, orderItemId: any) => {
        let orderItem = cart.items.filter((orderItem: any) => orderItem.id == orderItemId)[0];
        if (orderItem === undefined) return;
        try {
            await cartService.updateItem(orderItem.id, orderItem.quantity + 1);
        } catch (err) {
            console.log(err)
        }
    }

    const renderItemPrice = (item: any) => {
        if (item.discount_money !== undefined && item.discount_money.amount !== 0) {
            let subTotalPrice = item.total_money.amount + item.discount_money.amount;
            return (
                <React.Fragment>
                    <span className="text-danger">
                        <del>
                        <Money
                            value={subTotalPrice}
                            currency='PLN'/>
                        </del>
                    </span>
                    <Money
                        value={item.total_money.amount}
                        currency='PLN'/>
                </React.Fragment>
            )
        }
        return (
            <Money
                value={item.total_money.amount}
                currency='PLN'/>
        )
    }
    const getPromotionItem = (item: any, promotions: any) => {
        let valuePromotions = promotions.filter((x: any) => x.value_money && x.value_money.amount);
        let promotion = valuePromotions.filter((x: any) => x.items.some((y: any) => y.order_item_id == item.id))[0];
        if (promotion === undefined) return null;
        return promotion;
    }
    let tipOrderItems = cartPromotions.filter(x => x.type == "TIP").flatMap(x => x.items).map(x => x.order_item_uid);

    const sortedOrderItems = [...props.cart.items].sort((a: any, b: any) => parseDate(a.created_at).getTime() - parseDate(b.created_at).getTime());

    return (
        <div>
            {modalEditItem && (
                <MenuEditItemModalComponent orderItem={modalEditItem} onHide={() => setModalEditItem(undefined)}/>
            )}
            <>
                {sortedOrderItems.length > 0 ? (
                    <div className="cart-items-body">
                        {
                            sortedOrderItems.map((orderItem: any) => {
                                    let item = ItemHelper.getItem(items, orderItem.item_id)
                                    let promotionItem = getPromotionItem(orderItem, cartPromotions);
                                    return (
                                        <div key={orderItem.id} className={"cart-item"}>
                                            {(item && item.image_link?.small) && (
                                                <div className={"cart-item-img"}
                                                     onClick={() => setModalEditItem(orderItem)}>
                                                    <img src={item.image_link.small} alt={item.name}/>
                                                </div>
                                            )}
                                            <div className={"cart-item-body"}>
                                                <h3 onClick={() => setModalEditItem(orderItem)}>
                                                    {item ? item.translation.name : orderItem.name}
                                                </h3>
                                                <div className={"cart-item-description"}
                                                     onClick={() => setModalEditItem(orderItem)}>
                                                    <CartItemsNewComponent orderItem={orderItem}/>
                                                    {orderItem.comment && (
                                                        <div className="order-comment mt-2 rounded-2 p-2">
                                                            <p className="mb-0">{orderItem.comment}</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={"cart-item-bottom"}>
                                                    <div>
                                                        {!tipOrderItems.includes(orderItem.uid) && (
                                                            <div
                                                                className="input-group input-group-sm input-group-quantity">
                                                                <div className="input-group-prepend">
                                                                    <button type="button"
                                                                            className="btn btn-default btn-icon"
                                                                            onClick={() => changeQuantity(orderItem, orderItem.quantity - 1)}>
                                                                        <MinusSVG/>
                                                                    </button>
                                                                </div>
                                                                <span
                                                                    className="input-group-text">{orderItem.quantity}</span>
                                                                <div className="input-group-append">
                                                                    <button type="button"
                                                                            className="btn btn-default btn-icon"
                                                                            onClick={() => changeQuantity(orderItem, orderItem.quantity + 1)}>
                                                                        <PlusSVG/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={"cart-item-prices"}>
                                                        {renderItemPrice(orderItem)}
                                                    </div>
                                                </div>
                                                {promotionItem && (
                                                    <div className={"cart-item-promotion"}>
                                                        {promotionItem.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                ) : (
                    <div className='cart-no-items-body'>
                        <div className="circle-container">
                            <div className={"center"}>
                                <div className="circle circle1"></div>
                            </div>
                            <div className={"center"}>
                                <div className="circle circle2"></div>
                            </div>
                            <div className={"center"}>
                                <div className="circle circle3"></div>
                            </div>
                            <div className={"center"}>
                                <div className="circle circle4"></div>
                            </div>
                            <div className={"center"}>
                                <div className={"center-svg"}>
                                    <MenuSVG/>
                                </div>
                            </div>
                        </div>
                       <p className={"text-center"}>
                           {t("modules.cart.constraints.not_items")}
                       </p>
                    </div>
                )}
            </>

            {/*<div className={"container cart-promotions"}>*/}
            {/*    {suggestPromotions.map(promotion => {*/}
            {/*        return (*/}
            {/*            <button key={promotion.id}*/}
            {/*                    className={"btn btn-promotion btn-spinner" + (cart.pending ? ' loading' : '')}*/}
            {/*                    onClick={() => addPromotion(promotion.id, promotion.info.order_item_id)}>*/}
            {/*                <div className={"content"}>*/}
            {/*                    {promotion.item_name}*/}
            {/*                    <small>*/}
            {/*                        {promotion.name}*/}
            {/*                    </small>*/}
            {/*                </div>*/}
            {/*                <div className={"icon"}>*/}
            {/*                    <span className="left spinner-border spinner-border-sm"></span>*/}
            {/*                    <PlusCircleIco/>*/}
            {/*                </div>*/}
            {/*            </button>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    promotions: getPromotions(state.menu),
    items: getItems(state.menu),
    modifierGroups: getModifierGroups(state.menu),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartItemsComponent));