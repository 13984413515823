import React, {FC, useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {Modal} from "react-bootstrap";
import {RootState} from "../../../../../reducers";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import IframeWidget from "../../../../utils/IframeWidget";
import {getItemGroups, getItems, getMenus} from "../../../../services/Menu/reducer";
import MenuShowItemSingleComponent from "../../components/Item/MenuShowItemSingleComponent";
import Analytics from "../../../../services/Common/Analytics/Analytics";
import AppConfig from "../../../../services/AppConfig";
import {usePrefix} from "../../../../services/Prefix";

type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

const MenuAddItemPage: FC<Props> = (props) => {
    const [modal, setModal] = useState(true);
    const {items, menus} = props;
    const {menuId: paramMenuId, id: paramId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const prefix = usePrefix();
    useEffect(() => {
        let item = ItemHelper.getItem(items, id);
        if (item) {
            if (location.state && location.state.back) {
                Analytics.selectItem(item);
            }
            Analytics.viewItem(item);
        }
    }, []);
    if (!paramId || !paramMenuId) return null;
    let id = parseInt(paramId);
    let menuId = parseInt(paramMenuId);

    const addItem = (menuId: any, item: any) => {
        hideModal();
    }
    const hideModal = () => {
        IframeWidget.closeModal();
        setModal(false);
        if (location.state && location.state.back) {
            navigate(-1);
        } else {
            navigate(AppConfig.getLinkWithPrefix(`/menu/${menuId}`, prefix), {replace: true, state: {action: 'stay'}});
        }
    };

    let menusById = menus.filter((menu: any) => menu.id === menuId);
    let menu = undefined;
    if (menusById.length > 0) {
        menu = menusById[0];
    }
    let item = ItemHelper.getItem(items, id);
    if (item === undefined) {
        return null;
    }
    let productImgView = null;
    if (item.image_link) {
        productImgView = (
            <div className="product-item-img">
                <img src={item.image_link.default}/>
            </div>
        )
    }
    let itemGroup = ItemHelper.getItemGroup(props.itemGroups, item.id);
    return (
        <Modal dialogClassName={"modal-full modal-product modal-product-full"} show={modal}
               onHide={() => IframeWidget.onHide(hideModal)} onShow={() => IframeWidget.onShow()}>
            <div className={"modal-product-content"}>
                {productImgView}
                <div className={"modal-product-full-right"}>
                    <div className={"container-fluid"}>
                        <MenuShowItemSingleComponent itemId={item.id} itemGroup={itemGroup} onUpdate={addItem}
                                                     menu={menu} onHide={() => IframeWidget.onHide(hideModal)}/>
                    </div>
                </div>
            </div>
            {/*   <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {productImgView}
                        </div>
                        <div className={"col-md-6 modal-product-full-right"}>
                            <MenuShowItemSingleComponent itemId={item.id} itemGroup={itemGroup} onUpdate={addItem}
                                                         menu={menu} onHide={hideModal}/>
                        </div>
                    </div>
                </div>*/}
        </Modal>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    itemGroups: getItemGroups(state.menu),
    menus: getMenus(state.menu),
})

const connector = connect(mapStateToProps);

export default connector(MenuAddItemPage);