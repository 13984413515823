import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import {RootState} from "../../../../reducers";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import ErrorFormView from "../../../components/Common/ErrorFormView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import {usePrefix} from "../../../services/Prefix";

interface OwnProps {
    setPage: (page?: string) => void;
    onHide: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
function UserEditComponent(props: Props) {
    const [phoneNumber, setPhoneNumber] = useState(props.user.phone_number);
    const [name, setName] = useState(props.user.name);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([] as any[]);
    const navigate = useNavigate();
    const authService = useAuth();
    const prefix = usePrefix();
    const {t} = useTranslation();

    const save = async () => {
        setLoading(true);
        try {
            await authService.updateUser({
                phone_number: phoneNumber,
                name: name
            });
            setLoading(false);
            props.setPage(undefined);
            // navigate(AppConfig.getLinkWithPrefix("/", prefix));
        } catch (e) {
            setErrors([{
                message: t("constraints.internal.account_removal_failed")
            }]);
            setLoading(false);
        }

    }
    return (

        <>
            <div className={"user"}>
                <div className={"user-header-top top"}>
                    <button onClick={() => props.setPage(undefined)} className={"btn-back"}>
                        <BackSVG/>
                    </button>
                    <h5>
                        {t("common.word.your_data")}
                    </h5>
                    <button onClick={props.onHide} className="btn-close ms-auto me-3" data-bs-dismiss="modal" aria-label="Close"/>
                </div>
                <div className={"container my-2"}>
                    <div>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="text" onChange={(e) => setName(e.target.value)} value={name}
                                   className={"form-control" + ErrorFormHelper(errors, "name")}
                                   placeholder={t("common.word.full_name")}
                                   aria-label="Imię i nazwisko" aria-describedby="account-name"/>
                            <label htmlFor="account-name">{t("common.word.full_name")}</label>
                            <ErrorFormView errors={errors} field="name"/>
                        </div>
                    </div>
                    <div>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="text" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                                   className={"form-control" + ErrorFormHelper(errors, "phone")}
                                   placeholder={t("common.word.phone_number")}
                                   aria-label="Numer telefonu" aria-describedby="account-phone"/>
                            <label htmlFor="account-phone">{t("common.word.phone_number")}</label>
                            <ErrorFormView errors={errors} field="phone"/>
                        </div>
                    </div>
                    <button
                        className={"btn-spinner btn-block btn-primary btn w-100 btn-submit" + (loading ? ' loading' : '')}
                        onClick={save}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.save")}
                    </button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserEditComponent));