import React, {FC, ReactNode, useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import LoadingContainer from "../Common/LoadingContainer";
import {usePlaceContext} from "../../services/Place/PlaceContext";
import {api} from "../../services/Api/api";
import handleException from "../../services/Api/handleException";
import {getSession} from "../../services/Location/reducer";


interface OwnProps {
    children?: ReactNode,
    menus: boolean,
    order: boolean
}

type Props = ReturnType<typeof mapStateToProps> & DispatchProp & OwnProps;

const FetchPlaces: FC<Props> = (props) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const {places, addPlace, removePlace, updatePlaces} = usePlaceContext();
    const [loading, setLoading] = useState(true);

    const fetch = async () => {
        try {
            let response = await api.getPlaces(props.session.coordinates);
            updatePlaces(response, props.session.coordinates);
            setLoading(false);
            // setErrors([]);
        } catch (err) {
            const exceptionErrors = handleException(err);
            // setErrors(exceptionErrors);
            setLoading(false);
        }
    }


    const init = async () => {
        if (!places) {
            await fetch();
        } else {
            setLoading(false);
        }
    }

    const refresh = async () => {
        if(places){
            try {
                let response = await api.getPlaces(props.session.coordinates);
                updatePlaces(response, props.session.coordinates);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        init()
        return () => {
            controller.abort(); // Step 3: Cancel the request on unmount
        };
    }, [])
    useEffect(() => {
        refresh();
    }, [props.session.coordinates])

    if (loading) {
        return (
            <LoadingContainer/>
        )
    }

    return (
        <>
            {props.children}
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default connector(FetchPlaces);
