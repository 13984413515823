import {createContext, useContext} from "react";

// 1. Define types for local store data and context
interface PrefixData {
    prefix?: string;
}


interface PrefixContextType {
    prefix: PrefixData;
}
export const PrefixContext = createContext<string | undefined>(undefined);


// 3. Create a custom hook to use the context
export function usePrefix(): string {
    const context = useContext(PrefixContext);
    // if (!context) {
    //     throw new Error('useLocalStore must be used within a LocalStoreProvider');
    // }
    return context ? context : "";
}
