import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../reducers";
import {ReactComponent as FacebookIco} from "../../../../images/icons/social/dark/facebook.svg";
import {ReactComponent as InstagramIco} from "../../../../images/icons/social/dark/instagram.svg";
import {ReactComponent as LinkedinIco} from "../../../../images/icons/social/dark/linkedin.svg";
import {ReactComponent as XIco} from "../../../../images/icons/social/dark/x.svg";
import {ConfigApi} from "../../../services/Api/types";


interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const FooterSocialMedia = (props: Props) => {
    const {t} = useTranslation();
    if (!props.config) return null;

    let renderSocialIco = (name: any) => {
        switch (name) {
            case "facebook":
                return <FacebookIco className="icon"/>
            case "instagram":
                return <InstagramIco className="icon"/>
            case "linkedin":
                return <LinkedinIco className="icon"/>
            case "twitter":
                return <XIco className="icon"/>
        }
    }
    let renderSocialLink = (name: any, value: any) => {
        switch (name) {
            case "facebook":
                return "https://facebook.com/" + value;
            case "instagram":
                return "https://instagram.com/" + value;
            case "linkedin":
                return "https://linkedin.com/company/" + value;
            case "twitter":
                return "https://x.com/" + value;
        }
    }


    let socialPortals = ["facebook", "instagram", "linkedin", "twitter"];
    let socialList = [];
    if (props.config.old_settings) {
        for (const [key, value] of Object.entries(props.config.old_settings)) {
            if (socialPortals.includes(key) && value) {
                socialList.push({"key": key, "value": value});
            }
        }
    }
    return (

        <ul className={"footer-social"}>
            {socialList.map(socialItem => {
                return (
                    <li key={`socia-media-${socialItem.key}`}>
                        <a href={renderSocialLink(socialItem.key, socialItem.value)} target={"_blank"}>
                            {renderSocialIco(socialItem.key)}
                        </a>
                    </li>
                )
            })}
        </ul>
    )
}


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(FooterSocialMedia));
