import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import Money from "../../../../components/Common/Money";
import {useTranslation, withTranslation} from "react-i18next";


interface OwnProps {
    stateItem: any,
    modifierGroupId: any,
    optionItemView: any,
    errors: any,
    onUpdate: (modifierGroupId: any, optionItemView: any, quantity: any) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuItemOptionBasicComponent = (props: Props) => {
    let optionStateItem = props.stateItem;
    let modifierGroupId = props.modifierGroupId;
    let optionItemView = props.optionItemView;
    let price = optionItemView.price;
    let errors = props.errors;
    const {t} = useTranslation();

    let onClickItem = (e: any) => {
        if (optionStateItem) {
            props.onUpdate(modifierGroupId, optionItemView, 0);
        } else {
            props.onUpdate(modifierGroupId, optionItemView, 1);
        }
    }
    let id = `modifierGroupItemId-${modifierGroupId}-${optionItemView.id}`;
    let checked = optionStateItem ? true : false;
    return (
        <div className="product-modifier-option-pick" key={id}>
            <input className={"btn-check"} type="radio" checked={checked} onChange={onClickItem}
                   name={'modifierGroup[' + modifierGroupId + '].options'} id={id} value={optionItemView.id}/>
            <label className={`input-single${optionItemView.disabled ? " disabled" : ""}`} htmlFor={id}>
                {optionItemView.name}
                {price ? (
                    <span className="product-modifier-option-price ml-auto price"><Money
                        value={price}
                        currency='PLN'/></span>
                ) : null}
                {(errors !== undefined && errors.length > 0) && (
                    <div className={"product-option-errors"}>
                        {
                            errors.map((error: any) =>
                                <div className="text-danger error"
                                     key={`${modifierGroupId}-${optionItemView.id}-${error.message}`}>{error.message}</div>
                            )
                        }
                    </div>
                )}
                {optionItemView.disabled && optionItemView.quantity === 0 && (
                    <div className={"product-option-errors"}>
                        <div className="text-danger error">{t("constraints.internal.item_not_available")}</div>
                    </div>
                )}
            </label>
        </div>
    )

}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuItemOptionBasicComponent));