import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getStoreConfig} from "../../../../services/Store/reducer";
import {getSession} from "../../../../services/Location/reducer";
import {RootState} from "../../../../../reducers";
import {useLocationService} from "../../../../services/Location/locationService";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuTypesFilterComponent = (props: Props) => {
    const locationService = useLocationService();
    const {t} = useTranslation();

    if (!props.config) return null;
    const orderType = props.session.order_type;

    const changeType = async (type: any) => {

        let sessionData = {
            ...props.session,
            order_type: type
        }
        try {
            await locationService.update(sessionData);
        } catch (error) {
        }
    }

    let types = [] as string[];
    if (props.config.ordering.dine_in) types.push("DINE_IN");
    if (props.config.ordering.takeaway) types.push("PICK_UP");
    if (props.config.ordering.delivery) types.push("DELIVERY");
    if (props.config.ordering.room_service) types.push("ROOM_SERVICE");

    return (
        <div className={"container-fluid"}>
            <div className="list-nowraps menu-filters">
                {types.map((type: string) => {
                    let nameType = t("enums.order.type." + type)
                    return (
                        <button key={type} className={"btn btn-default me-2" + (orderType == type ? ' active' : '')}
                                onClick={() => changeType(type)}>{nameType}</button>
                    )
                })}
            </div>
        </div>
    )

}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuTypesFilterComponent));