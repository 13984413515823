import {StoreActionTypes} from "./types";
import {ApiError, ConfigApi, VoucherApi} from "../Api/types";
import {Action} from "redux";
import {UserActionTypes, UserState} from "../User/types";


interface ErrorAction extends Action<StoreActionTypes.ERROR> {
    errors: any[];
}

interface PendingAction extends Action<StoreActionTypes.PENDING> {}

interface SuccessAction extends Action<StoreActionTypes.SUCCESS> {
    payload: ConfigApi;
}
interface SuccessDefaultAction extends Action<StoreActionTypes.SUCCESS_DEFAULT> {
    payload: ConfigApi;
}
interface CleanAction extends Action<StoreActionTypes.CLEAN> {}


export type StoreActions = ErrorAction | PendingAction | SuccessAction | SuccessDefaultAction | CleanAction;

export function fetchStorePending() {
    return {
        type: StoreActionTypes.PENDING
    }
}

export function fetchStoreError(errors:ApiError[]) {
    return {
        type: StoreActionTypes.ERROR,
        errors: errors
    }
}

export function fetchStoreSuccess(data:ConfigApi) {
    return {
        type: StoreActionTypes.SUCCESS,
        payload: data
    }
}

export function fetchStoreSuccessDefault(data:ConfigApi) {
    return {
        type: StoreActionTypes.SUCCESS_DEFAULT,
        payload: data
    }
}
export function cleanStore() {
    return {
        type: StoreActionTypes.CLEAN
    }
}