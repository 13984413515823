import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import CartHelper from "../../../services/Cart/Helper/CartHelper";
import {getItems, getModifierGroups} from "../../../services/Menu/reducer";
import {getCart} from "../../../services/Cart/reducer";
import {RootState} from "../../../../reducers";


interface OwnProps {
    orderItem: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartItemsNewComponent = (props: Props) => {
    const orderItem = props.orderItem;
    const visibileModifierGroupName = false;

    const renderSubItems = (itemModifierGroups: any, modifierGroups: any, items: any, lvl?: any) => {
        if (!itemModifierGroups || itemModifierGroups.length <= 0) return null;
        if (!lvl) lvl = 0;
        if (visibileModifierGroupName) {
            return (
                <>
                    {
                        itemModifierGroups.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((itemModifierGroup: any) => {
                                return (
                                    <ul className="list-inline" key={itemModifierGroup.id}>
                                        <h5>
                                            {CartHelper.getItemName(modifierGroups, itemModifierGroup.modifier_group_id, itemModifierGroup.name)}
                                        </h5>
                                        {
                                            [...itemModifierGroup.selected_items].sort((a: any, b: any) => a.name.localeCompare(b.name)).map((selectedItem: any) =>
                                                <li className={`${isModifiers(selectedItem) ? "list-inline-item list-inline-item-multi" : "list-inline-item"}`}
                                                    key={selectedItem.id}>
                                                    {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                                                    {renderSubItems(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                                </li>
                                            )
                                        }
                                    </ul>
                                )
                            }
                        )
                    }
                </>
            )
        }
        return (
            <ul className="list-inline">
                {
                    itemModifierGroups.sort((a: any, b: any) => a.name.localeCompare(b.name)).flatMap((x: any) => x.selected_items).sort((a: any, b: any) => a.name.localeCompare(b.name)).map((selectedItem: any) =>
                        <li className={`${isModifiers(selectedItem) ? "list-inline-item list-inline-item-multi" : "list-inline-item"}`}
                            key={selectedItem.id}>
                            {isModifiers(selectedItem) ? (
                                <h5>
                                    {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                                </h5>
                            ) : (
                                <>
                                    {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                                </>
                            )}
                            {renderSubItems(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                        </li>
                    )
                }
            </ul>
        )
    }
    const renderMultilevel = (selectedItem: any) => {
        return (
            <div className={"cart-item-multilevel"}>
                <h5>
                    {CartHelper.getItemName(props.items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                </h5>
                <div>
                    {renderSubItemsString(selectedItem, selectedItem.modifier_groups, props.modifierGroups, props.items, 0 + 1)}
                </div>
            </div>
        )
    }
    const renderSubItemsString = (orderItem: any, itemModifierGroups: any, modifierGroups: any, items: any, lvl?: any) => {
        if (!itemModifierGroups || itemModifierGroups.length <= 0) return null;
        if (!lvl) lvl = 0;
        const item = props.items.filter((x: any) => x.id === orderItem.item_id)[0];
        let sortedItemModifierGroups = [...itemModifierGroups]
        if (item.modifier_groups) {
            const positionMap = new Map(
                item.modifier_groups.map((itemModifierGroup: any) => [itemModifierGroup.modifier_group_id as number, itemModifierGroup.position as number])
            );
            sortedItemModifierGroups = sortedItemModifierGroups.sort((a: any, b: any) => {
                const posA = (positionMap.get(a.modifier_group_id) ?? Infinity) as number;
                const posB = (positionMap.get(b.modifier_group_id) ?? Infinity) as number;
                return posA - posB;
            });
        } else {
            sortedItemModifierGroups = sortedItemModifierGroups.sort((a: any, b: any) => a.name.localeCompare(b.name));
        }
        return (
            <>
                {
                    sortedItemModifierGroups.map((x: any) => {
                        let itemsCount = x.selected_items.length;
                        let newLine = false;
                        let isAnyMultilevel = false;
                        let viewItems = [...x.selected_items].sort((a: any, b: any) => a.name.localeCompare(b.name)).map((selectedItem: any, key: any) => {
                            let existModifiers = isModifiers(selectedItem);
                            if (existModifiers) {
                                isAnyMultilevel = true;
                                return (
                                    <React.Fragment
                                        key={selectedItem.id}>
                                        {renderMultilevel(selectedItem)}
                                    </React.Fragment>
                                );
                            }
                            newLine = !existModifiers;
                            return (
                                <React.Fragment
                                    key={selectedItem.id}>
                                    {existModifiers ? (
                                        <div style={{paddingLeft: 15 * lvl}}>
                                            <strong>
                                                {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}: {` `}
                                            </strong>
                                            <div style={{paddingLeft: 5 * (lvl + 1)}}>
                                                {renderSubItemsString(selectedItem, selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {x.selected_items.length <= 1 ? (
                                                <>
                                                    {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount - 1 > key && (<>, </>)}
                                                </>
                                            ) : (
                                                <>
                                                    {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount - 1 > key && (<>, </>)}
                                                </>
                                            )}
                                            {/*, {` `}*/}
                                            {renderSubItemsString(selectedItem, selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                        </>
                                    )}
                                </React.Fragment>
                            )
                        });
                        return (
                            <React.Fragment key={x.id}>
                                {isAnyMultilevel ? (
                                    <></>
                                ) : (
                                    <>
                                        {x.selected_items.length > 1 ? (
                                            <strong>
                                                {CartHelper.getItemName(props.modifierGroups, x.modifier_group_id, x.name)}:{` `}
                                            </strong>
                                        ) : (
                                            <strong>
                                                {CartHelper.getItemName(props.modifierGroups, x.modifier_group_id, x.name)}:{` `}
                                            </strong>
                                        )}
                                    </>
                                )}
                                {viewItems}
                                {/*{[...x.selected_items].sort((a: any, b: any) => a.name.localeCompare(b.name)).map((selectedItem: any, key: any) => {*/}
                                {/*    let existModifiers = isModifiers(selectedItem);*/}
                                {/*    if(existModifiers){*/}
                                {/*        return renderMultilevel(selectedItem);*/}
                                {/*    }*/}
                                {/*    newLine = !existModifiers;*/}
                                {/*    return (*/}
                                {/*        <React.Fragment*/}
                                {/*            key={selectedItem.id}>*/}
                                {/*            {existModifiers ? (*/}
                                {/*                <div style={{paddingLeft: 15 * lvl}}>*/}
                                {/*                    <strong>*/}
                                {/*                        {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}: {` `}*/}
                                {/*                    </strong>*/}
                                {/*                    <div style={{paddingLeft: 5 * (lvl + 1)}}>*/}
                                {/*                        {renderSubItemsString(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            ) : (*/}
                                {/*                <>*/}
                                {/*                    {x.selected_items.length <= 1 ? (*/}
                                {/*                        <strong>*/}
                                {/*                            {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount - 1 > key && (<>, </>)}*/}
                                {/*                        </strong>*/}
                                {/*                    ) : (*/}
                                {/*                        <>*/}
                                {/*                            {CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount - 1 > key && (<>, </>)}*/}
                                {/*                        </>*/}
                                {/*                    )}*/}
                                {/*                    /!*, {` `}*!/*/}
                                {/*                    {renderSubItemsString(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}*/}
                                {/*                </>*/}
                                {/*            )}*/}
                                {/*        </React.Fragment>*/}
                                {/*    )*/}
                                {/*})}*/}
                                {newLine && <br/>}
                            </React.Fragment>
                        )
                    })
                }
            </>
        )
    }
    const isModifiers = (item: any) => {
        return item.modifier_groups && item.modifier_groups.length > 0;
    }

    return (
        <>
            {(isModifiers(orderItem)) && (
                <>
                    {renderSubItemsString(orderItem, orderItem.modifier_groups, props.modifierGroups, props.items)}
                </>
            )}
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    modifierGroups: getModifierGroups(state.menu),
    cart: getCart(state.cart),
    items: getItems(state.menu)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartItemsNewComponent));