import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import {RootState} from "../../../../reducers";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import {ApiError} from "../../../services/Api/types";
import {UnprocessableEntity} from "../../../services/Api/exceptions";
import handleException from "../../../services/Api/handleException";


interface OwnProps {
    setPage: (page: string) => void,
    onHide: () => void,
    onSuccess: () => void,
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function VerificationUserComponent(props: Props) {
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [loading, setLoading] = useState(false);
    const user = props.user;
    const authService = useAuth();
    const {t} = useTranslation();

    useEffect(() => {
    }, [user.verified])
    const logout = async () => {
        try {
            await authService.logout();
            props.setPage("LOGIN");
        } catch (err) {
            props.setPage("LOGIN");
            props.onHide();
        }

    }
    const retry = async () => {
        setLoading(true);
        try {
            await authService.sendActivation(user.email)
            setLoading(false);
            props.onSuccess()
        } catch (e: any) {
            if(e instanceof UnprocessableEntity){
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": t("constraints.internal.user_verification_failed")
                    }
                ])
            }else{
                setErrors(handleException(e));
            }
            setLoading(false);
        }

    }
    return (
        <>
            <div>
                <h4>
                    {t("modules.user.field.account_verification.title")}
                </h4>
            </div>
            <div className="alert alert-light" role="alert">
                <small>
                    <InfoSVG className={"icon me-2"}/>
                    {t("modules.user.field.account_verification.description")}
                </small>
            </div>
            <div className={"login-box-register-info"}>
                <hr/>
                <div className={"text-center"}>
                    {t("modules.user.field.message_not_arrived.title")}{` `}
                    <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    retry()
                }}>
                    {t("modules.user.action.send_again.title")}
                </a>
                </div>
                <div className={"text-center"}>
                    {`${t("modules.user.field.login_again.title")} `}
                     <a href={"#"} onClick={logout}>
                    {t("modules.user.action.logout.title")}
                </a>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(VerificationUserComponent));
