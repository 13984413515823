import {LocationActionTypes, SessionState} from "./types";
import {ConfigAddressApi, LocationCheckApi, StoreConfigCoordinatesApi} from "../Api/types";

export function updateSessionLocation(location:ConfigAddressApi, dirty?: boolean) {
    return {
        type: LocationActionTypes.UPDATE_LOCATION,
        location: location,
        dirty: dirty
    }
}
export function updateSession(session:SessionState) {
    return {
        type: LocationActionTypes.UPDATE,
        session: session
    }
}

export function updateSessionCoordinates(coordinates:StoreConfigCoordinatesApi) {
    return {
        type: LocationActionTypes.UPDATE_COORDINATES,
        coordinates: coordinates
    }
}
export function updateLocationCheck(locationCheck:LocationCheckApi) {
    return {
        type: LocationActionTypes.CHECK,
        check: locationCheck
    }
}

export function updateLocale(locale:string) {
    return {
        type: LocationActionTypes.UPDATE_LOCALE,
        locale: locale
    }
}