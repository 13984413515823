import AvailableHelper from "./Availability/AvailableHelper";
import DateService from "../../Common/Date/DateService";

class MenuHelper {

    static getMenuErrors(menu: any, orderType: any, pickDate: any, availabilities: any) {
        let errors = [];
        if (orderType === "DELIVERY") {
            if (!menu.delivery) {
                errors.push("unavailable_type");
            }
        } else if (orderType === "PICK_UP") {
            if (!menu.takeaway) {
                errors.push("unavailable_type");
            }
        } else if (orderType === "DINE_IN") {
            if (!menu.dine_in) {
                errors.push("unavailable_type");
            }
        }
        if (pickDate && !menu.pick_date) {
            errors.push("unavailable_pickup_date");
        } else {
            let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate)) as any;

            if (openHours === undefined || openHours.length <= 0) {
                errors.push("unavailable_date");
            } else {
                if (!AvailableHelper.isAvailabile(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate))) {
                    errors.push("unavailable_time");
                }
            }
        }
        return errors;
    }

    static isMenuAvailable(menu: any, orderType: any, pickDate: any, availabilities: any) {
        if (orderType === "DELIVERY") {
            if (!menu.delivery) {
                return false;
            }
        } else if (orderType === "PICK_UP") {
            if (!menu.takeaway) {
                return false;
            }
        } else if (orderType === "DINE_IN") {
            if (!menu.dine_in) {
                return false;
            }
        }

        if (pickDate && !menu.pick_date) {
            return false;
        } else {
            let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate)) as any;

            if (openHours === undefined || openHours.length <= 0) {
                return false;
            } else {
                if (!AvailableHelper.isAvailabile(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pickDate))) {
                    return false;
                }
            }
        }
        return true;
    }

}


export default MenuHelper;