import {SessionState} from "../Location/types";

class CartUtils {
    static getCartNumber(session: SessionState) {
        if (session.order_type === "ROOM_SERVICE") return session.room_number;
        if (session.order_type === "DINE_IN") return session.table_number;
        return undefined;
    }
}

export default CartUtils;
