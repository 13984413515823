import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import {RootState} from "../../../../reducers";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import ErrorFormView from "../../../components/Common/ErrorFormView";
import {useLocation} from "react-router-dom";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const UserResetPasswordPage = (props: Props) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const location = useLocation();
    const authService = useAuth();
    const {t} = useTranslation();

    const queryParams = new URLSearchParams(location.search);
    const getQueryParam = (param: any) => {
        const value = queryParams.get(param);
        return value ? value : undefined;
    };

    const code = getQueryParam('code');
    const email = getQueryParam('email');

    const changePassword = async () => {
        setLoading(true);
        try {
            await authService.changePassword(email, code, password, confirmPassword)
            setLoading(false);
            setSuccess(true);
        } catch (e) {
            setErrors([{
                message: t("constraints.internal.cannot_change_password"),
            }]);
            setLoading(false);
        }
    }
    if (!code || !email) {
        return (
            <>
                <div className="alert alert-danger" role="alert">
                    <small>
                        <InfoSVG className={"icon me-2"}/>
                        {t("modules.login.field.improper_link_to_password_reset.title")}
                    </small>
                </div>
            </>
        )
    }

    return (

        <>
            <div className={"login-box"}>
                <>
                    <div>
                        <h4>
                            {t("modules.login.field.set_new_password.title")}

                        </h4>
                    </div>
                    {success ? (
                        <>
                            <div className="alert alert-success" role="alert">
                                <small>
                                    {t("modules.login.field.new_password_set.title")} <strong>{email}</strong>. {t("modules.login.field.login_with_new_data.title")}
                                </small>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="alert alert-light" role="alert">
                                <small>
                                    <InfoSVG className={"icon me-2"}/>
                                    {t("modules.login.field.password_reset.alert.one")} <strong>{email}</strong>, {t("modules.login.field.password_reset.alert.two")}
                                </small>
                            </div>
                            <>
                                <ErrorFormAlertView errors={errors}/>

                                <div className="form-group">
                                    <input type="password" onChange={(e) => setPassword(e.target.value)}
                                           value={password}
                                           className={"form-control" + ErrorFormHelper(errors, "password")}
                                           placeholder={t("common.word.password")}
                                           aria-label="Recipient's username" aria-describedby="cart-password"/>
                                    <label htmlFor="cart-password">{t("common.word.password")}</label>
                                    <ErrorFormView errors={errors} show_message={true} field="password"/>
                                </div>
                                <div className="form-group">
                                    <input type="password" onChange={(e) => setConfirmPassword(e.target.value)}
                                           value={confirmPassword}
                                           className={"form-control" + ErrorFormHelper(errors, "confirm_password")}
                                           placeholder={t("modules.login.field.confirm_password.title")} aria-label="Recipient's username"
                                           aria-describedby="cart-confirm-password"/>
                                    <label htmlFor="cart-confirm-password">{t("modules.login.field.confirm_password.title")}</label>
                                    <ErrorFormView errors={errors} show_message={true} field="confirm_password"/>
                                </div>
                                <button
                                    className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                                    onClick={() => {
                                        changePassword()
                                    }}>
                                    <span className="left spinner-border spinner-border-sm"></span>
                                    {t("modules.login.action.set_password.title")}
                                </button>
                            </>
                        </>
                    )}
                </>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserResetPasswordPage));
