import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import {getSession} from "../../../../services/Location/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import ChangePickDateModal from "../../../../components/Modal/ChangePickDateModal";
import React, {useState} from "react";
import {ReactComponent as CalendarIco} from "../../../../../images/icons/calendar.svg";

interface OwnProps {
    menu: any,
    preview: boolean
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuAvailabilityDateButton = ({menu, session}: Props) => {
    const {t} = useTranslation();
    const [showPickDateModal, setShowPickDateModal] = useState(false);

    return (
        <div className='mt-2'>
            <button type="button" className="btn btn-default" onClick={() => setShowPickDateModal(true)}>
                {t("modules.menu.action.change_pickup_date.title")}
                <CalendarIco className="ms-1"/>
            </button>
            <ChangePickDateModal menuId={menu.id} pickDate={session.pickup_at}
                                 showModal={showPickDateModal}
                                 onHide={() => setShowPickDateModal(false)}/>
    </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuAvailabilityDateButton));