import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as GiftSVG} from "../../../../../images/icons/gift-solid.svg";
import {ReactComponent as CalendarSVG} from "../../../../../images/icons/calendar.svg";
import {ReactComponent as PlusSVG} from "../../../../../images/icons/plus.svg";
import {ReactComponent as RemoveSVG} from "../../../../../images/icons/remove.svg";
import {RootState} from "../../../../../reducers";
import handleException from "../../../../services/Api/handleException";
import {useCart} from "../../../../services/Cart/cartService";
import VoucherModal from "../../Logged/VoucherModal";

interface OwnProps {
    setPage: (page?: string) => void,
    voucher: any,
    promotion_id?: number,
    onSuccess: (voucher?: any) => void,
    options?: string[]
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function VoucherSingleComponent(props: Props) {
    const [modal, setModal] = useState(false);
    const voucher = props.voucher;
    const product = voucher.product;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);
    let cartService = useCart();
    const isLocationSelected = true;
    const {t} = useTranslation();

    const addVoucher = async () => {
        setLoading(true);
        if (!isLocationSelected) {
            setLoading(false);
            setErrors([{
                message: "Wybierz najpierw lokal aby dodać voucher do zamówienia"
            }]);
            return;
        }
        try {
            await cartService.addOrderVoucher(voucher.code);
            setLoading(false);
            if (props.onSuccess) props.onSuccess();
            // navigate(AppConfig.getLinkWithPrefix("/", prefix));
        } catch (e) {
            console.log(e);
            setErrors(handleException(e));
            setLoading(false);
        }
    }

    const removeVoucher = async () => {
        if (!props.promotion_id) return;
        setLoading(true);
        try {
            await cartService.removePromotion(props.promotion_id);
            setLoading(false);
            if (props.onSuccess) props.onSuccess();
        } catch (e) {
            console.log(e);
            setErrors(handleException(e));
            setLoading(false);
        }
    }
    const isAvailableVoucher = (voucher: any) => {
        return true;
    }

    const useVoucher = async () => {

        if (props.promotion_id) {
            await removeVoucher();
        } else {
            if(!props.options){
                setModal(true);
                return;
            }
            await addVoucher();
        }
    }

    return (
        <>
            {modal && (
                <>
                    <VoucherModal onHide={() => setModal(false)} show={true} voucher={voucher}/>
                </>
            )}
            <div
                className={"voucher-card success flex-fill " + (!isAvailableVoucher(voucher) ? ' disabled' : '')}
                onClick={useVoucher}>

                <div className={"card-content"}>
                    <div className="card-body">
                        <div className={"card-img"}>
                            {product.image ? (
                                <img src={product.image}
                                     className="card-img-top" alt="..."/>
                            ) : (
                                <GiftSVG/>
                            )}
                            {/*{product.cost ? (*/}
                            {/*    <span className={"points"}>{product.cost} pkt</span>*/}
                            {/*) : (*/}
                            {/*    <span className={"points"}>Darmowy</span>*/}
                            {/*)}*/}
                        </div>
                        <div className={"card-text-content"}>
                            <h5 className="card-title">{product.name}</h5>
                            <p className="card-text">{product.description}</p>
                        </div>
                    </div>
                    <div className="card-footer">
                        <span className={"valid-time"}>
                            {/*<CalendarSVG/> Ważny do: 12.01.25*/}
                        </span>
                        {props.options ? (
                            <>
                                {props.promotion_id ? (
                                    <button className={`btn btn-outline-danger btn-spinner${loading ? " loading" : ""}`}>
                                        <span className="left spinner-border spinner-border-sm"></span>
                                        {t("modules.voucher.action.remove_from_cart.title")}
                                       <RemoveSVG/>
                                    </button>
                                ) : (
                                    <button className={`btn btn-outline-success btn-spinner${loading ? " loading" : ""}`}>
                                        <span className="left spinner-border spinner-border-sm"></span>
                                        {t("modules.voucher.action.to_cart.title")}
                                        <PlusSVG/>
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                {props.promotion_id ? (
                                    <button className={`btn btn-outline-danger btn-spinner${loading ? " loading" : ""}`}>
                                        <span className="left spinner-border spinner-border-sm"></span>
                                        {t("modules.voucher.action.remove_from_cart.title")}
                                        <RemoveSVG/>
                                    </button>
                                ) : (
                                    <button className={`btn btn-outline-success btn-spinner${loading ? " loading" : ""}`}>
                                        <span className="left spinner-border spinner-border-sm"></span>
                                        {t("modules.voucher.action.use.title")}
                                        <PlusSVG/>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(VoucherSingleComponent));

