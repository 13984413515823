import React from 'react';
import Money from "../../../components/Common/Money";
import CartHelper from "../../../services/Cart/Helper/CartHelper";
import {parseDate} from "../../../services/Common/Date/DateHelper";
import ItemHelper from "../../../services/Menu/services/Item/ItemHelper";
import SummaryItem from "./SummaryItem";


interface Props {
    orderItems: any;
    items: any;
    promotions: any[];
    modifierGroups: any[];
}

const SummaryItemsComponent = ({ orderItems, items, promotions, modifierGroups }: Props) => {
    const renderItemPrice = function (item: any) {
        if (item.discount_money !== undefined && item.discount_money.amount !== 0) {
            let subTotalPrice = item.total_money.amount + item.discount_money.amount;
            return (
                <React.Fragment>
                    <span className="text-danger me-2">
                        <del>
                        <Money
                            value={subTotalPrice}
                            currency='PLN'/>
                        </del>
                    </span>
                    <Money
                        value={item.total_money.amount}
                        currency='PLN'/>
                </React.Fragment>
            )
        }
        return (
            <Money
                value={item.total_money.amount}
                currency='PLN'/>
        )
    }

    const getSubItemsToRender = (itemModifierGroups: any, modifierGroups: any, items: any, lvl: number) => {
        const itemsToRender: string[] = [];

         [...itemModifierGroups].forEach((itemModifierGroup: any) => {
            [...itemModifierGroup.selected_items].sort((a: any, b: any) => a.name.localeCompare(b.name)).map((selectedItem: any) => {
                itemsToRender.push(`${selectedItem.quantity}x ${CartHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}`);
                const nextSubItems = getSubItemsToRender(selectedItem.modifier_groups, modifierGroups, items, lvl + 1);

                nextSubItems.forEach(subItem => {
                    // itemsToRender.push(`${subItem.quantity}x ${CartHelper.getItemName(items, subItem.item_id, subItem.name)}`);
                    itemsToRender.push(subItem)
                })
            })
        })

        return itemsToRender;
    }

    const getPromotionItem = (item: any, promotions: any) => {
        let valuePromotions = promotions.filter((x: any) => x.value_money && x.value_money.amount);
        let promotion = valuePromotions.filter((x: any) => x.items.some((y: any) => y.order_item_id == item.id))[0];
        if (promotion === undefined) return null;
        return promotion;
    }

    const getSortedOrderItems = () => {
        return  [...orderItems].sort((a: any, b: any) =>{
            const dateA = parseDate(a.created_at).getTime();
            const dateB = parseDate(b.created_at).getTime();

            if (dateA !== dateB) {
                return dateA - dateB;
            }

            return a.id - b.id;
        })
    }

    return (
        <div>
            <div className={"cart-items-body"}>
                {
                    getSortedOrderItems().map((orderItem: any) => {
                            const item = ItemHelper.getItem(items, orderItem.item_id)
                            const promotionItem = getPromotionItem(orderItem, promotions);
                            return (
                                <div key={orderItem.id} className={"cart-item"}>
                                    {(item && item.image_link?.small) && (
                                        <div className={"cart-item-img"}>
                                            <img src={item.image_link.small} alt={item.name}/>
                                        </div>
                                    )}
                                    <div className={"cart-item-body"}>
                                        <h3>
                                            {`${item && item.translation?.name ?  item.translation.name : orderItem.name}${orderItem.quantity && orderItem.quantity > 1 ? ` (x${orderItem.quantity})` : ""}`}
                                        </h3>
                                        <div className={"cart-item-description"}>
                                            <SummaryItem items={items} orderItem={orderItem} modifierGroups={modifierGroups}/>
                                            {orderItem.comment && (
                                                <div className="order-comment mt-2 rounded-2 p-2">
                                                    <p className="mb-0">{orderItem.comment}</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className={"cart-item-bottom"}>
                                            <div className={"cart-item-prices"}>
                                                {renderItemPrice(orderItem)}
                                            </div>
                                        </div>
                                        {promotionItem && (
                                            <div className={"cart-item-promotion"}>
                                                {promotionItem.name}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}
export default SummaryItemsComponent;