import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import CustomFieldHelper from "../../../services/Cart/Helper/CustomFieldHelper";
import ErrorFormView from "../../Common/ErrorFormView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import {RootState} from "../../../../reducers";
import {getStoreConfig} from "../../../services/Store/reducer";
import {getCart} from "../../../services/Cart/reducer";


interface OwnProps {
    errors: any[],
    onChange: (cart: any) => void,
    onChangeCustomField: (slug: any, value: any) => void
    data: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartCommentComponent = (props: Props) => {
    const cart = props.cart;
    const {t} = useTranslation();
    const orderCustomFields = props.config?.old_store.order_custom_fields;

    const handleChangeComments = (value: any) => {
        props.onChange({
            ...props.data,
            comment: value
        })
    }

    let commentVisible = CustomFieldHelper.getVisible("comment", orderCustomFields, cart.type);

    return (
        <div className="card-cart">
            <div className={"container-fluid"}>
                <h5 className={"card-cart-header"}>
                    {t("modules.cart.field.order_comments.title")}
                </h5>
                <div className={"card-cart-body"}>
                    <div className="form-row row">
                        {CustomFieldHelper.isVisible(commentVisible) && (
                            <div className="form-group col-lg-6">
                                <textarea rows={3} value={props.data.comment}
                                          onChange={(e) => handleChangeComments(e.target.value)}
                                          className={"form-control" + ErrorFormHelper(props.errors, "comment")}
                                          placeholder={t("modules.cart.field.comment.title") + (CustomFieldHelper.isRequired(commentVisible) ? "*" : "")}
                                          id="comment"></textarea>
                                <label
                                    htmlFor="comment">{t("modules.cart.field.comment.title")}{CustomFieldHelper.isRequired(commentVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="comment"/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartCommentComponent));
