import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import {useCart} from "../../services/Cart/cartService";
import handleException from "../../services/Api/handleException";
import {ApiError} from "../../services/Api/types";
import {getCart} from "../../services/Cart/reducer";
import CartPromotionsModalComponent from "./components/CartPromotionsModalComponent";
import {ReactComponent as PlusSVG} from "../../../images/icons/plus.svg";
import {ReactComponent as RemoveSVG} from "../../../images/icons/remove.svg";
import CartPromotionsComponent from "./CartPromotionsComponent";
import Money from "../Common/Money";


interface OwnProps {
    errors: any[],
    updateErrors: (errors: any) => void,
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartCouponComponent = (props: Props) => {
    const {t} = useTranslation();
    const cart = props.cart;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [promotionInfoModal, setPromotionInfoModal] = useState<any | undefined>(undefined);
    const [promotionsModal, setPromotionsModal] = useState(false);
    let cartService = useCart();

    const deleteCoupon = async () => {
        setLoading(true)
        try {
            await cartService.updateOrderCoupon({
                code: ""
            });
            updateErrors([]);
            setErrors([])
            setLoading(false)
        } catch (error: any) {
            const errors = handleException(error);
            updateErrors(errors);
            setErrors(errors);
            setLoading(false)
        }

    }

    const updateErrors = (errors: any) => {
        errors.forEach((y: any) => {
            y.field = "coupon"
        });
        props.updateErrors(errors);
    }

    let couponId = cart.coupon_id;
    let couponPromotionVoucher = (cart.coupon && !couponId) ? cart.promotions.filter((x: any) => x.metadata && x.metadata.toLocaleUpperCase().includes(`CODE_${cart.coupon.toLocaleUpperCase()}`))[0] : undefined;
    let couponPromotion = cart.promotions.filter((x: any) => x.type === "COUPON")[0];
    let couponError = (couponPromotionVoucher === undefined || !cart.coupon || (cart.discount_money && cart.discount_money.amount > 0)) ? false : true;
    return (
        <React.Fragment>
            <div className={"card-cart"}>

                <div className={"container-fluid"}>
                    <h5 className={"card-cart-header"}>
                        {t("modules.cart.field.discounts_and_vouchers.title")}
                    </h5>
                    <div className={"card-cart-body"}>
                        {promotionsModal && (
                            <CartPromotionsModalComponent onHide={() => setPromotionsModal(false)}/>
                        )}
                        {cart.coupon && (
                            <div className={"form-group mb-3"}>
                                <div
                                    className={"form-group-buttons"}>
                                    <input type="text" disabled={true} value={cart.coupon}
                                           className={"form-control" + ErrorFormHelper(errors, "code")}
                                           placeholder={t("modules.cart.field.coupon_code.title")}
                                           aria-label="Recipient's username" aria-describedby="cart-coupon"/>
                                    <label htmlFor="cart-coupon">{t("modules.cart.field.coupon_code.title")}</label>
                                    <div className={"form-group-button"}>
                                        <button className={"btn btn-icon btn-outline-danger"} onClick={deleteCoupon}>
                                            <RemoveSVG/>
                                        </button>
                                    </div>
                                </div>
                                {couponPromotion && (
                                    <small className={"text-success"}>
                                        {couponPromotion.name} <Money value={couponPromotion.value_money}/> {t("modules.cart.field.amount_of_discount.title")}
                                    </small>
                                )}
                            </div>
                        )}
                        <button className={"btn btn-outline-primary w-100 btn-lg mb-3"}
                                onClick={() => setPromotionsModal(true)}>
                            <PlusSVG/> {t("modules.cart.action.discount_or_activate_voucher.title")}
                        </button>
                        <CartPromotionsComponent/>
                        {/*<CartUserVouchersComponent/>*/}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartCouponComponent));