import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {RootState} from "../../../reducers";
import {getStoreState} from "../../services/Store/reducer";
import NavbarMultiStore from "./NavbarMultiStore";
import NavbarMenus from "./NavbarMenus";
import NavbarUser from "./NavbarUser";
import {ReactComponent as BarsIco} from "../../../images/icons/bars.svg";
import {ReactComponent as MapIco} from "../../../images/icons/map.svg";
import Drawer from "./Drawer";
import {selectLocale} from "../../services/Location/reducer";
import NavbarHours from "./NavbarHours";
import StoreUtils from "../../services/Store/StoreUtils";
import NavbarPlaces from "./NavbarPlaces";
import AppConfig from "../../services/AppConfig";
import NavbarVouchers from "./NavbarVouchers";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function Navbar(props: Props) {
    const {store_state} = props;
    let {t} = useTranslation();
    const [pagesCollapse, setPagesCollapse] = useState(false);
    const [drawer, setDrawer] = useState(false);
    let config = props.store_state.config;
    if (!config) return null;
    let configLayout = config.layout;
    if (!configLayout) return null;

    let baseBackUrl = config.old_multistore?.back_url;
    let backUrlNative = config.old_multistore?.back_url_native;

    let isMultiStore = config.old_multistore?.enabled;
    let backUrl = store_state.native_mobile && baseBackUrl ? backUrlNative : baseBackUrl;

    const renderLogoView = () => {
        if (configLayout?.image_link) {
            return (
                <img alt="Logo location" src={configLayout.image_link.default}/>
            )
        } else {
            return (
                <div className={"logo-text"}>
                    {config?.name}
                </div>
            );
        }
    }

    let anyMenuLinks = configLayout?.links?.some(x => x.type === "MENU");

    if(AppConfig.isNativeMobile()) return null;
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light sticky-top">
                <div className="navbar-brand-container">
                    <Link to="/" className="navbar-brand">
                        {renderLogoView()}
                    </Link>
                    {StoreUtils.isMultistore(props.store_state.default_config) ? (
                        <NavbarPlaces config={props.store_state.config}/>
                    ) : null}
                    <div className="navbar-options">
                        <NavbarHours/>
                        <NavbarVouchers/>
                        <NavbarUser/>
                        {/*<NavbarLanguages languages={config.languages} locale={props.locale}/>*/}
                        <button className="navbar-toggler" type="button"
                                onClick={() => setDrawer(!drawer)}>
                            <BarsIco/>
                        </button>
                    </div>
                    {(backUrl || store_state.native_mobile) ? (
                        <>
                            <div className="navbar-actions-container">
                                <NavbarMultiStore backUrl={backUrl} multiStore={isMultiStore}/>
                            </div>
                            <div className={"navbar-actions-container-desktop"}>
                                <NavbarMultiStore backUrl={backUrl} multiStore={isMultiStore}/>
                            </div>
                        </>
                    ) : (
                        <>
                            {/*<div className={"navbar-actions-container"}>*/}
                            {/*    <NavbarSearch/>*/}
                            {/*</div>*/}
                            {/*<div className={"navbar-actions-container-desktop"}>*/}
                            {/*    <NavbarSearch modal={true}/>*/}
                            {/*</div>*/}
                        </>
                    )}
                    {(anyMenuLinks) && (
                        <div className={classNames(`navbar-menus collapse navbar-collapse`, {
                            'show': pagesCollapse
                        })} id="navbarNav">
                            {anyMenuLinks && (
                                <NavbarMenus links={configLayout?.links}/>
                            )}
                        </div>
                    )}
                </div>
            </nav>
            <Drawer config={props.store_state.config} onHide={() => setDrawer(false)} show={drawer}/>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
    locale: selectLocale(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(Navbar));