import React, {FC, ReactNode, useEffect} from 'react';


interface Props {
    children?: ReactNode;
}


const bodyClass = "body-cart-sticky"
const CartStickyContextPage: FC<Props> = (props) => {
    useEffect(() => {
        if (bodyClass) {
            document.body.classList.add(bodyClass);
        }

        return () => {
            if (bodyClass) {
                document.body.classList.remove(bodyClass);
            }
        };
    }, [bodyClass]);

    return (
        <>{props.children}</>
    )
};

export default CartStickyContextPage;
