
export enum MenusActionTypes {
    PENDING = "@@menus/PENDING",
    SUCCESS = "@@menus/SUCCESS",
    ERROR = "@@menus/ERROR",
    CLEAN = "@@menus/CLEAN"
}


export type MenuState = {
    menus: any[],
    categories: any[],
    item_groups: any[],
    items: any[],
    modifier_groups: any[],
    payment_methods: any[],
    promotions: any[],
    custom_fields: any[],
    availabilities: any[],
    initialized: boolean,
    pending: boolean,
    error?: string,
    fetch_status: string
};