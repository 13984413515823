import React from 'react';
import {useTranslation} from "react-i18next";

const Loading = (value:any)=> {
    const {t} = useTranslation();

    let text =  (
        <p>{t("common.word.loading")}</p>
    );

    if(value.children) {
        text = value.children;
    }

    return(
        <div className="loading-app text-center">
            <div className="loader"/>
            {text}
        </div>
    )
}

export default Loading;
