import React from 'react';
import Alert from './Alert';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {withTranslation} from "react-i18next";
import {getMessages} from "../../services/Notifications/reducer";
import {removeFlash} from "../../services/Notifications/actions";

interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const FlashMessages = (props: Props) => {
    const alerts = props.messages.map((message: any) =>
        <Alert key={message.id} message={message}
               onClose={() => props.dispatch(removeFlash(message))}/>
    );

    if (alerts === undefined || alerts.length <= 0) {
        return null;
    }
    return (
        <div className="alerts sticky-fixed">{alerts}</div>
    );
}


const mapStateToProps = (state: RootState) => ({
    messages: getMessages(state.notification)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(FlashMessages));
