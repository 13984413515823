import {ApiError, Exception} from "./types";
import {
	BadRequestException,
	ForbiddenException,
	NotAllowedException, NotFoundException,
	OrganizationAccessException,
	TooManyRequestsException, UnauthorizedException,
	UnprocessableEntity
} from "./exceptions";
import {CanceledError} from "axios";


export default function handleException(exception: Exception | unknown): Array<ApiError> {


	if (!(exception instanceof Error)) {
		throw exception;
	}
	if (exception instanceof BadRequestException) {
		return [
			{
				message: "wrong_data",
				code: "wrong_data",
			},
		];
	}

	if (exception instanceof NotFoundException) {
		return [
			{
				message: "not_found",
				code: "not_found",
			},
		];
	}
	if (exception instanceof ForbiddenException) {
		return [
			{
				message: "access_forbidden",
				code: "access_forbidden"
			},
		];
	}
	if (exception instanceof UnauthorizedException) {
		return [
			{
				message: "unauthorized",
				code: "unauthorized",
			},
		];
	}

	if (exception instanceof NotAllowedException) {
		return [
			{
				message: "not_allowed",
				code: "not_allowed",
			},
		];
	}

	if (exception instanceof OrganizationAccessException) {
		return exception.errors;
	}

	if (exception instanceof TooManyRequestsException) {
		return [
			{
				message: "too_many_requests",
				code: "too_many_requests",
			},
		];
	}

	if (exception instanceof UnprocessableEntity) {
		return exception.errors;
		// return exception.errors.map(error => {
		// 	let newCode = error.code;
		// 	if(error.field){
		// 		newCode = newCode?.replaceAll(`${error.field.toLowerCase()}_`, "")
		// 	}
		// 	return {
		// 		...error,
		// 		code: newCode
		// 	}
		// })
	}

	if (exception instanceof CanceledError) {
		return [exception];
	}

	const error = exception as Exception;
	if (!error?.errors) {
		console.log(error);
	}

	return error?.errors
		? error.errors
		: [
			{
				message: "unknown_error",
				code: "unknown_error",
			},
		];
}

export function isErrorCodeExist(errors: ApiError[], code: string): boolean {
	return errors.some(x => x.code === code);
}