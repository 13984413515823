import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {getPaymentMethods} from "../../services/Menu/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import LoadingContainer from "../Common/LoadingContainer";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import ErrorFormAlertView from "../Common/ErrorFormAlertView";
import TpayBankTransferPaymentMethodComponent
    from "./components/PaymentMethod/tpay/TpayBankTransferPaymentMethodComponent";
import BluemediaBankTransferPaymentMethodComponent
    from "./components/PaymentMethod/bluemedia/BluemediaBankTransferPaymentMethodComponent";
import BluemediaCardPaymentMethodComponent
    from "./components/PaymentMethod/bluemedia/BluemediaCardPaymentMethodComponent";
import CartHelper from "../../services/Cart/Helper/CartHelper";
import {getCart} from "../../services/Cart/reducer";
import ChangeRoomServiceNumberModal from "../Modal/ChangeRoomServiceNumberModal";
import {CartState} from "../../services/Cart/types";

interface OwnProps {
    onChange: (cart: any) => void,
    data: CartState,
    errors: any[]
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartPaymentComponent = (props: Props) => {
    const cart = props.cart;
    const [paymentMethodId, setPaymentMethodId] = useState(CartHelper.getDefaultPaymentMethodId(cart.payment_method_id, CartHelper.getAvailablePaymentMethods(props.config?.old_store.payment_methods ? props.config?.old_store.payment_methods : [], props.paymentMethods, cart.type)));
    const {t} = useTranslation();

    const {paymentMethods} = props;

    let availablePaymentMethods = CartHelper.getAvailablePaymentMethods(props.config?.old_store.payment_methods ? props.config?.old_store.payment_methods : [], paymentMethods, cart.type);

    const setPaymentMethod = (paymentMethod: any) => {
        setPaymentMethodId(paymentMethod.id);
        props.onChange({
            ...props.data,
            payment_method_id: paymentMethod.id
        })
    }

    const updatePaymentSettings = (settings: any) => {
        props.onChange({
            ...props.data,
            payment_settings: settings
        })
    }

    const getPaymentMethod = (paymentMethodId: any, paymentMethods: any) => {
        if (!paymentMethodId) {
            return undefined;
        }

        let paymentMethod = paymentMethods.filter((paymentMethod: any) => {
            return paymentMethod.id === paymentMethodId;
        })[0];
        if (paymentMethod !== undefined && paymentMethod) {
            return paymentMethod;
        }
        return undefined;
    }
    let selectedPaymentMethod = getPaymentMethod(paymentMethodId, availablePaymentMethods);

    return (
        <>
            <div className="card-cart">
                <div className={"container-fluid"}>
                    <h5 className={"card-cart-header"}>
                        {t("modules.cart.payment.header.title")}
                    </h5>
                    <div className={"card-cart-body"}>
                        <div
                            className={"" + ErrorFormHelper(props.errors, "paymentMethodId,paymentMethod")}>
                            <ErrorFormAlertView errors={props.errors} field="paymentMethodId"/>
                            <ErrorFormAlertView errors={props.errors} field="paymentMethod"/>
                            <div className={"btn-groups-columns"}>
                                {
                                    availablePaymentMethods.map((paymentMethod: any) =>
                                        <React.Fragment key={paymentMethod.id}>
                                            <label
                                                className={`btn btn-default btn-lg btn-check-full${paymentMethodId === paymentMethod.id ? " active" : ""}`}>
                                                <span>
                                                    {paymentMethod.name}
                                                    <small>
                                                        {paymentMethod.description}
                                                    </small>
                                                </span>
                                                <input className="btn-check1" type="radio" name="paymentMethodId"
                                                       value={paymentMethod.id}
                                                       autoComplete="off"
                                                       checked={paymentMethodId === paymentMethod.id}
                                                       onChange={() => setPaymentMethod(paymentMethod)}/>
                                            </label>
                                            {(selectedPaymentMethod && selectedPaymentMethod.id === paymentMethod.id) && (
                                                 <>
                                                     {selectedPaymentMethod.gateway === "BLUEMEDIA" && (
                                                         <>
                                                             <BluemediaBankTransferPaymentMethodComponent
                                                                 updatePaymentSettings={updatePaymentSettings}
                                                                 paymentMethod={paymentMethod}/>
                                                         </>
                                                     )}
                                                     {(selectedPaymentMethod.gateway === "TPAY") && (
                                                         <>
                                                             <TpayBankTransferPaymentMethodComponent
                                                                 updatePaymentSettings={updatePaymentSettings}
                                                                 paymentMethod={paymentMethod}/>
                                                         </>
                                                     )}
                                                 </>
                                            )}
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    paymentMethods: getPaymentMethods(state.menu),
    config: getStoreConfig(state.config),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartPaymentComponent));