import {ApiError, ConfigApi, ConfigLayoutApi, StoreConfigLoyaltyApi} from "../Api/types";

export enum StoreActionTypes {
	PENDING = "@@store/PENDING",
	SUCCESS = "@@store/SUCCESS",
	SUCCESS_DEFAULT = "@@store/SUCCESS_DEFAULT",
	ERROR = "@@store/ERROR",
	CLEAN = "@@store/CLEAN"
}

export type StoreState = {
	pending?: boolean;
	errors?: ApiError[];
	native_mobile?: boolean,
	config?: ConfigApi
	default_config?: ConfigApi
};


// export type StoreConfigState = {
// 	id?: number;
// 	name?: string;
// 	native_mobile?: boolean,
// 	firewall?: StoreConfigFirewallState,
// 	analytics?: StoreConfigAnalyticsState,
// 	old_multistore?: ConfigStoreMultistoreOldState,
// 	layout?: ConfigLayoutApi,
// 	languages: string[]
// };
//
// export type StoreConfigFirewallState = {
// 	password?: string;
// 	enabled?: boolean
// };
//
// export type StoreConfigAnalyticsState = {
// 	google_analytics_id?: string;
// 	facebook_pixel_id?: boolean
// };
//
// export type ConfigStoreMultistoreOldState = {
// 	back_url?: string;
// 	back_url_native?: string,
// 	enabled?: boolean
// };
