import React from 'react';
import {useTranslation} from "react-i18next";
import {ApiError, StoreConfigLoyaltyCustomFieldApi} from "../../../services/Api/types";
import ErrorFormView from "../../Common/ErrorFormView";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";


interface Props {
    custom_field: StoreConfigLoyaltyCustomFieldApi,
    errors: ApiError[],
    values: Record<string, string | undefined>,
    onChange: (slug: string, value?: string) => void
}

export function RegisterCustomFieldComponent(props: Props) {
    const {t} = useTranslation();
    let customField = props.custom_field;
    let valueCustomFields = props.values;
    let errors = props.errors;
    let name = customField.name ? customField.name : customField.slug;
    if (customField.required) {
        name += "*";
    }
    let fieldName = `customFields[${customField.slug}]`;
    let fieldvalue = valueCustomFields !== undefined ? valueCustomFields[customField.slug] : undefined;
    if (fieldvalue === undefined) fieldvalue = "";

    let fieldId = "custom_field_" + customField.slug;


    if (customField.type.toUpperCase() === 'DATE') {
        return (
            <div className="form-group" key={fieldId}>
                <input type="date" onChange={(e) => props.onChange(customField.slug, e.target.value)}
                       value={valueCustomFields[customField.slug]}
                       className={"form-control" + ErrorFormHelper(errors, fieldId)}
                       placeholder={customField.slug}
                />
                <label htmlFor="cart-cardCode">{customField.slug}</label>
                <ErrorFormView errors={errors} show_message={true}
                               field={fieldId}/>
            </div>
        )
    }
    if (customField.options !== undefined && customField.options.length > 0) {
        return (
            <div className="form-group" key={fieldId}>
                <select value={fieldvalue} onChange={(e) => props.onChange(customField.slug, e.target.value)}
                        className={"form-control" + ErrorFormHelper(errors, fieldName)} id={fieldId} placeholder={name}>
                    <option>{t("common.action.choose")} {customField.name}</option>
                    {customField.options.map(customFieldOption => {
                        return (
                            <option key={customFieldOption}>{customFieldOption}</option>
                        );
                    })}
                </select>
                <label htmlFor={fieldId}>{name}</label>
                <ErrorFormView errors={errors} field={fieldName}/>
            </div>
        )
    }
    return (
        <div className="form-group" key={fieldId}>

            <input type="text" onChange={(e) => props.onChange(customField.slug, e.target.value)}
                   value={valueCustomFields[customField.slug]}
                   className={"form-control" + ErrorFormHelper(errors, fieldId)}
                   placeholder={customField.slug}
            />
            <label htmlFor="cart-cardCode">{customField.slug}</label>
            <ErrorFormView errors={errors} show_message={true}
                           field={fieldId}/>
        </div>
    )
}