import React from 'react';
import {useTranslation} from "react-i18next";
import Money from "../../../components/Common/Money";

interface Props {
    order: any
}

const SummarySummaryComponent = (props: Props) => {
    const {t} = useTranslation();
    const renderDiscountMoney = () => {
        if (props.order === undefined || props.order.discount_money === undefined || props.order.discount_money.amount === 0) {
            return null;
        }
        return (
            <dl>
                <dt>
                    {t("common.word.discount")}
                </dt>
                <dd>
                    -<Money value={props.order.discount_money}/>
                </dd>
            </dl>
        )
    }
    let deliveryView = null;
    let order = props.order;
    if (order.type === 'DELIVERY' && order.delivery_fee_money) {
        deliveryView = (
            <dl>
                <dt>
                    {t("common.word.delivery")}
                </dt>
                <dd>
                    <Money value={props.order.delivery_fee_money}/>
                </dd>
            </dl>
        )
    }
    return (
        <div className={"card-cart"}>
            <div className={"container-fluid"}>
                <h5 className={"card-cart-header"}>
                    {t("modules.summary.header.title")}
                </h5>
                <div className={"card-cart-body"}>
                    <div className={"cart-summary"}>
                        <dl>
                            <dt>
                                {t("common.word.summary_items")}
                            </dt>
                            <dd>
                                <Money value={props.order.sub_total_money}/>
                            </dd>
                        </dl>
                        {deliveryView}
                        {renderDiscountMoney()}
                        <div className="separator"></div>
                        <dl className={"total"}>
                            <dt>{t("common.word.sum")}</dt>
                            <dd>
                                <Money value={props.order.total_money}/>
                            </dd>
                        </dl>
                    </div>
                </div>

            </div>

        </div>
    )
}
export default SummarySummaryComponent;