import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {getAvailabilities, getMenus} from "../../../../services/Menu/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import {getStoreConfig} from "../../../../services/Store/reducer";
import StoreInfoModal from "../../../../components/Modal/StoreInfoModal";

interface OwnProps {
    className?: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const HeroInfoHours = (props: Props) => {
    const [modal, setModal] = useState(false);
    const {config} = props;
    let {t} = useTranslation();
    if (!config) return null;
    return (

        <React.Fragment>
            <a href="#" className={`${props.className ? props.className : "link"}`} onClick={() => setModal(true)}>
                {t("common.action.more_information")}
            </a>
            {modal && (
                <StoreInfoModal onHide={() => setModal(false)}/>
            )}
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    menus: getMenus(state.menu),
    availabilities: getAvailabilities(state.menu)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(HeroInfoHours));