import {ReactNode, useEffect, useState} from "react";

interface Props {
    small: ReactNode; // Content for smaller screens
    large: ReactNode; // Content for larger screens
    breakpoint?: number;
}

const parseBreakpoint = (breakpoint?: number) => {
    return breakpoint ? breakpoint : 992
};

const ResponsiveComponent = (props: Props) => {
    const [isWideScreen, setIsWideScreen] = useState<boolean>(window.innerWidth >= parseBreakpoint(props.breakpoint));
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= parseBreakpoint(props.breakpoint));
        };

        // Attach the resize event listener
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [props.breakpoint]);

    return <>{isWideScreen ? props.large : props.small}</>;
}
export default ResponsiveComponent;