import React, {FC, ReactNode} from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {getStoreDefaultConfig, getStoreState} from "../../services/Store/reducer";
import Navbar from "../Navbar/Navbar";
import FooterLong from "./Footer/Footer";


interface OwnProps {
    children?: ReactNode
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const PageLayout: FC<Props> = (props) => {
    return (
        <>
            <Navbar />
            <main>
                {props.children}
            </main>
            <FooterLong/>
            {/*<Footer/>*/}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    default_config: getStoreDefaultConfig(state.config),
})

const connector = connect(mapStateToProps);

export default connector(PageLayout);