import React, {FC, useState} from "react";
import {Modal} from "react-bootstrap";
import {ConfigAddressApi, PlaceApi, StoreConfigCoordinatesApi} from "../../../services/Api/types";
import {useNavigate} from "react-router-dom";
import ChangeLocationMultistoreModal from "./ChangeLocationMultistoreModal";
import {RootState} from "../../../../reducers";
import {getSession} from "../../../services/Location/reducer";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as AlertErrorSVG} from "../../../../images/icons/alert-error.svg";
import {api} from "../../../services/Api/api";
import LoadingContainer from "../../../components/Common/LoadingContainer";
import IframeWidget from "../../../utils/IframeWidget";


interface OwnProps {
    onHide: () => void;
    place?: PlaceApi | undefined,
    openStore: (place: PlaceApi) => void,
    storeUrl: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

const RequiredLocationModalComponnent: FC<Props> = (props) => {
    const [orderType, setOrderType] = useState<string | undefined>(undefined);
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState("ORDER_TYPE");
    const navigate = useNavigate();
    const {t} = useTranslation();
    const types = props.place?.types;

    const onShow = () => {
    }
    const updateOrderType = (value: string) => {
        setOrderType(value);
        if (value == "PICK_UP") {
            navigate(`${props.storeUrl}/PICK_UP`);
            return;
        }
        if (value == "DINE_IN") {
            navigate(`${props.storeUrl}/DINE_IN`);
            return;
        }
        if (value == "DELIVERY") {
            if (props.place?.in_delivery_zone) {
                navigate(`${props.storeUrl}/DELIVERY`);
                return;
            }
            if (!props.session.coordinates) {
                setView("LOCATION_REQUIRED")
            } else {
                setView("NOT_DELIVERY_ZONE")
            }
        }
    }
    const renderChooseType = () => {
        return (
            <>
                <div className={"text-center mb-4"}>
                    <h5 style={{fontWeight: 300}}>
                        {t("modules.multistore.field.choose_order_type.title")}
                    </h5>
                </div>
                {(types && types.length > 0) ? (
                    <>

                        {types.includes("DELIVERY") && (
                            <button type="button"
                                    className={`btn w-100 mb-2 btn-outline-primary`}
                                    onClick={() => updateOrderType("DELIVERY")}>
                                {t("enums.order.type.DELIVERY")}
                            </button>
                        )}
                        {types.includes("PICK_UP") && (
                            <button type="button"
                                    className={`btn w-100 mb-2 btn-outline-primary`}
                                    onClick={() => updateOrderType("PICK_UP")}>
                                {t("enums.order.type.PICK_UP")}
                            </button>
                        )}

                        {types.includes("DINE_IN") && (
                            <button type="button"
                                    className={`btn w-100 mb-2 btn-outline-primary`}
                                    onClick={() => updateOrderType("DINE_IN")}>
                                {t("enums.order.type.DINE_IN")}
                            </button>
                        )}
                    </>
                ) : (
                    <div className={"alert alert-danger"}>
                        {t("constraints.internal.place_with_not_types")}
                    </div>
                )}

            </>
        )
    }
    const onChangeLocation = async (address: ConfigAddressApi, coordinates: StoreConfigCoordinatesApi) => {
        setLoading(true);
        const places = await api.getPlaces(coordinates)
        const currentPlace = places.filter(x => x.domain === props.place?.domain)[0];
        if (!currentPlace || !currentPlace.in_delivery_zone) {
            setView("NOT_DELIVERY_ZONE")
            setLoading(false);
            return;
        }
        navigate(props.storeUrl);
    }
    if (view === "LOCATION_REQUIRED") {
        return (
            <>
                <ChangeLocationMultistoreModal showModal={true} onHide={props.onHide} onChange={onChangeLocation}/>
            </>
        )
    }
    if (loading) {
        return (
            <>
                <Modal dialogClassName="modal-dialog-centered" show={show} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                    <Modal.Body>
                        <div className={"modal-info"} style={{minHeight: 4}}>
                            <LoadingContainer>
                                {t("modules.multistore.field.check_address_pending.title")}
                            </LoadingContainer>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    if (view === "NOT_DELIVERY_ZONE") {
        return (
            <>
                <Modal dialogClassName="modal-dialog-centered" show={show} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                    <Modal.Body>
                        <div className={"modal-info"} style={{minHeight: 4}}>
                            <div className={"icon-square text-danger mb-4 mt-4"}>
                                <AlertErrorSVG/>
                            </div>
                            <div className={"modal-info-content"}>
                                <h4>
                                    {t("modules.multistore.field.delivery_not_available.title")}
                                </h4>
                                <div>
                                    {t("modules.multistore.field.delivery_not_available.description")}
                                </div>
                            </div>
                            <div className={"modal-info-actions"}>
                                <button className={"btn btn-default btn-lg"} onClick={() => IframeWidget.onHide(props.onHide)}>
                                    {t("modules.multistore.action.change_restaurant.title")}
                                </button>
                                {" "}
                                <button className={"btn btn-default btn-lg"}
                                        onClick={() => setView("LOCATION_REQUIRED")}>
                                    {t("modules.multistore.action.change_address.title")}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    return (
        <>
            <Modal dialogClassName="modal-full1 modal-dialog-centered" show={show} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <Modal.Body>
                    <div className={""} style={{minHeight: 4}}>
                        {renderChooseType()}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
};


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(RequiredLocationModalComponnent));
