import AvailableHelper from "../Availability/AvailableHelper";
import {DateHelper} from "../../../Common/Date/DateHelper";
import HoursHelper from "../Availability/HoursHelper";

class ItemHelper {

    static getItemGroup(itemGroups: any, id: any) {
        return itemGroups.filter((x: any) => x.items.some((i: any) => i.item_id == id))[0];
    }

    static getItem(items: any, id: any) {
        return items.filter((item: any) => item.id === id)[0];
    }

    static getModifierGroup(modiferGroups: any, id: any) {
        let obj = modiferGroups.filter((modifierGroup: any) => modifierGroup.id === id);
        if (obj.length > 0) {
            return obj[0];
        }
        return undefined;
    }

    static getModifierGroupQuantityInfo(modifierGroup: any, parentItem: any) {
        let ovverides = parentItem.quantity_info_overrides;
        let id = modifierGroup.id;
        let quantityInfo = undefined as any;
        let modiferGroupOvverides = [];
        if (ovverides !== undefined) {
            modiferGroupOvverides = ovverides.filter((ovveride: any) => ovveride.context_type === 'MODIFIER_GROUP' && ovveride.context_id === id.toString());
        }
        if (modiferGroupOvverides.length > 0) {
            let modiferGroupOvveride = modiferGroupOvverides[0];
            quantityInfo = modiferGroupOvveride.quantity_info;
            return quantityInfo === undefined ? undefined : quantityInfo;
        } else {
            if (modifierGroup.quantity_info !== undefined) {
                quantityInfo = {}
                if (modifierGroup.quantity_info.max_permitted !== undefined) {
                    quantityInfo.max_permitted = modifierGroup.quantity_info.max_permitted;
                }
                if (modifierGroup.quantity_info.min_permitted !== undefined) {
                    quantityInfo.min_permitted = modifierGroup.quantity_info.min_permitted;
                }
                if (modifierGroup.quantity_info.charge_above !== undefined) {
                    quantityInfo.charge_above = modifierGroup.quantity_info.charge_above;
                }
                return quantityInfo;
            }
        }
        return undefined;
    }

    static getItemQuantityInfo(item: any, parentItem: any, modifierGroup: any) {
        let parentItemId = parentItem ? parentItem.id : undefined;
        let modifierGroupId = modifierGroup ? modifierGroup.id : undefined;

        let itemOvverides = undefined;
        if (parentItemId !== undefined && modifierGroupId !== undefined) {
            itemOvverides = parentItem.quantity_info_overrides.filter((parentItemQuantityInfoOvveride: any) => parentItemQuantityInfoOvveride.context_type === 'ITEM' && parentItemQuantityInfoOvveride.context_id === item.id.toString())[0];
            if (!itemOvverides) {
                itemOvverides = modifierGroup.quantity_info_overrides.filter((modifierGroupQuantityInfoOvveride: any) => modifierGroupQuantityInfoOvveride.context_type === 'ITEM' && modifierGroupQuantityInfoOvveride.context_id === item.id.toString())[0];
            }
            if (itemOvverides !== undefined && itemOvverides.quantity_info !== undefined) {
                return itemOvverides.quantity_info;
            }
        }
        return undefined;
    }

    static getPriceOvveride(item: any, parentItem: any, modifierGroup: any) {
        let parentItemId = parentItem !== undefined ? parentItem.id : undefined;
        let modifierGroupId = modifierGroup !== undefined ? modifierGroup.id : undefined;
        let itemPriceInfoOverride = undefined;
        let price = undefined;
        if (parentItemId !== undefined && modifierGroupId !== undefined) {
            itemPriceInfoOverride = item.price_info_overrides.filter((priceInfoOverride: any) => priceInfoOverride.context_type === 'ITEM' && priceInfoOverride.context_id === parentItemId.toString())[0];
            if (itemPriceInfoOverride === undefined) {
                itemPriceInfoOverride = item.price_info_overrides.filter((priceInfoOverride: any) => priceInfoOverride.context_type === 'MODIFIER_GROUP' && priceInfoOverride.context_id === modifierGroupId.toString())[0];
            }
            if (itemPriceInfoOverride !== undefined) {
                if (itemPriceInfoOverride.price !== undefined) {
                    price = itemPriceInfoOverride.price;
                }
            }
        }
        if (price === undefined) {
            price = item.price !== undefined ? item.price : undefined;
        }
        if (price !== undefined) {
            price = price.amount;
        }
        return price;
    }

    static isAvailabile(item: any, availabilities: any, date: any, orderType?: string | undefined) {
        if (item.status !== 'ENABLED') {
            return false;
        }
        if (item.availability_id !== undefined) {
            let availability = AvailableHelper.getAvailability(availabilities, item.availability_id);

            if (availability.hours.length > 0 && !HoursHelper.isOpen(availability.hours, date)) {
                return false;
            }
        }
        if (item.suspension !== undefined && (item.suspension.suspend_until !== undefined || item.suspension.reason)) {
            const suspendUntilDate =  item.suspension.suspend_until !== undefined ? DateHelper.fromString(item.suspension.suspend_until) : undefined;
            if (!suspendUntilDate || suspendUntilDate > date) {
                const isAnyLimited = (item.suspension.order_types && item.suspension.order_types.length > 0) || (item.suspension.sources && item.suspension.sources.length > 0)
                if (!isAnyLimited) return false;
                const suspensionForOrderTypeExist = item.suspension.order_types && item.suspension.order_types.length > 0;
                const suspensionForSourcesExist = item.suspension.sources && item.suspension.sources.length > 0;
                const suspensionForOrderType = suspensionForOrderTypeExist && item.suspension.order_types.includes(orderType);
                const suspensionForSources = suspensionForSourcesExist && item.suspension.sources.includes("GOORDER");
                if(suspensionForOrderTypeExist && suspensionForSourcesExist){
                    return !(suspensionForOrderType && suspensionForSources);
                }
                return !(suspensionForOrderType || suspensionForSources);
            }
        }
        return true;
    }

    static isMenuAvailabile(menu: any, date: any) {
        if (menu.availability !== undefined) {
            if (!HoursHelper.isOpen(menu.availability.hours, date)) {
                return false;
            }
        }
        return true;
    }

    static getTodayOpenHours(availability: any, date: any) {
        if (availability === undefined) {
            return undefined;
        }

        let dayOpenHours = HoursHelper.getOpenHours(availability.hours, date) as any;
        if (dayOpenHours.length == 0) {
            return undefined;
        }
        return dayOpenHours;
    }

    static getTodayOpenHoursString(availability: any, date: any) {
        let openHours = this.getTodayOpenHours(availability, date);
        if (openHours === undefined) {
            return undefined;
        }
        openHours = openHours.sort((a: any, b: any) => {
            return a.hour_from < b.hour_from ? -1 : 1;
        });
        let openHourStrings = openHours.map((x: any) => x.hour_from + " - " + x.hour_to);
        return openHourStrings.join(", ");
    }
}


export default ItemHelper;