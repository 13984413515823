import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {RootState} from "../../../reducers";
import {getStoreDefaultConfig} from "../../services/Store/reducer";
import NavbarMenus from "./NavbarMenus";
import NavbarUser from "./NavbarUser";
import {ReactComponent as BarsIco} from "../../../images/icons/bars.svg";
import {ReactComponent as MapIco} from "../../../images/icons/map.svg";
import Drawer from "./Drawer";
import {selectLocale} from "../../services/Location/reducer";
import {ConfigApi} from "../../services/Api/types";
import NavbarVouchers from "./NavbarVouchers";

interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function NavbarMulti(props: Props) {
    let {t} = useTranslation();
    const [pagesCollapse, setPagesCollapse] = useState(false);
    const [drawer, setDrawer] = useState(false);
    let config = props.config;
    if (!config) return null;
    let configLayout = config.layout;
    if (!configLayout) return null;

    const renderLogoView = () => {
        if (configLayout?.image_link) {
            return (
                <img alt="Logo location" src={configLayout.image_link.default}/>
            )
        } else {
            if (props.default_config?.layout.image_link) {
                return (
                    <img alt="Logo location" src={props.default_config?.layout.image_link}/>
                )
            }
            return config?.name;
        }
    }

    let anyMenuLinks = configLayout?.links?.some(x => x.type === "MENU");
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light sticky-top">
                <div className="navbar-brand-container">
                    <Link to="/" className="navbar-brand">
                        {renderLogoView()}
                    </Link>
                    <div className="navbar-options">
                        <NavbarVouchers/>
                        <Link to='/map' className={"btn btn-icon"}>
                            <MapIco/>
                        </Link>
                        <NavbarUser/>
                        {/*<NavbarLanguages languages={config.languages} locale={props.locale}/>*/}
                        <button className="navbar-toggler" type="button"
                                onClick={() => setDrawer(!drawer)}>
                            <BarsIco/>
                        </button>
                    </div>
                    {(anyMenuLinks) && (
                        <div className={classNames(`navbar-menus collapse navbar-collapse`, {
                            'show': pagesCollapse
                        })} id="navbarNav">
                            {anyMenuLinks && (
                                <NavbarMenus links={configLayout?.links}/>
                            )}
                        </div>
                    )}
                </div>
            </nav>
            <Drawer config={props.default_config} onHide={() => setDrawer(false)} show={drawer}/>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    locale: selectLocale(state.session),
    default_config: getStoreDefaultConfig(state.config),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarMulti));