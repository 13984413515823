import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import {getStoreDefaultConfig} from "../../services/Store/reducer";
import {getUser} from "../../services/User/reducer";
import UserIndexComponent from "./Logged/UserIndexComponent";
import UserDeleteComponent from "./Logged/UserDeleteComponent";
import UserVouchersComponent from "./Logged/UserVouchersComponent";
import UserEditComponent from "./Logged/UserEditComponent";
import IframeWidget from "../../utils/IframeWidget";

interface OwnProps {
    page?: string,
    onHide: () => void,
    show: boolean
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function UserModal(props: Props) {
    const [page, setPage] = useState(props.page ? props.page : "LOGIN");
    const onShow = () => {
    }

    const renderView = () => {
        switch (page) {
            case "delete":
                return <UserDeleteComponent setPage={setPage} onHide={props.onHide}/>
            case "edit":
                return <UserEditComponent setPage={setPage} onHide={props.onHide}/>
            case "vouchers":
                return <UserVouchersComponent setPage={setPage} onHide={props.onHide}/>
            default:
                return <UserIndexComponent setPage={setPage} onHide={props.onHide}/>
        }
    }

    return (
        <>
            <Modal dialogClassName="modal-full modal-user" show={props.show}
                   onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <Modal.Body>
                    {renderView()}
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    default_config: getStoreDefaultConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserModal));
