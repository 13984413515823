import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {
    getAvailabilities,
    getCategories,
    getItemGroups,
    getItems,
    getMenus,
    getPromotions
} from "../../../services/Menu/reducer";
import MenuHelper from "../../../services/Menu/services/MenuHelper";
import {RootState} from "../../../../reducers";
import PromotionHelper from "../../../services/Menu/services/Promotion/PromotionHelper";
import ItemViewHelper from "../../../services/Menu/services/Item/ItemViewHelper";
import ItemHelper from "../../../services/Menu/services/Item/ItemHelper";
import {useNavigate} from "react-router-dom";
import MenuItemsGridComponent from "./List/MenuItemsGridComponent";
import MenuCategoryNavComponent from "./Menu/MenuCategoryNavComponent";
import Languages from "../../../components/Common/Languages";
import {getStoreConfig, isPreview} from "../../../services/Store/reducer";
import Sticky from "react-stickynode";
import {getSession} from "../../../services/Location/reducer";
import MenuMenusFilterComponent from "./Filter/MenuMenusFilterComponent";
import MenuSearchFilterComponent from "./Filter/MenuSearchFilterComponent";
import MenuTypesFilterComponent from "./Filter/MenuTypesFilterComponent";
import MenuLocationFilterComponent from "./Filter/MenuLocationFilterComponent";
import MenuPickDateFilterComponent from "./Filter/MenuPickDateFilterComponent";
import AppConfig from "../../../services/AppConfig";
import {usePrefix} from "../../../services/Prefix";
import {getCart} from "../../../services/Cart/reducer";
import MenuAvailability from "./MenuAvailability/MenuAvailability";

interface OwnProps {
    menuId?: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuComponent = (props: Props) => {
    const [search, setSearch] = useState<string>("");
    const navigate = useNavigate();
    const {menus} = props;
    const {t} = useTranslation();
    const cart = props.cart;
    const prefix = usePrefix();

    let availableMenus = menus ? [...menus] : [];
    let menuIdFinal = undefined as undefined | number;
    if (!props.menuId) {
        if (availableMenus.length > 0) {
            availableMenus = availableMenus.sort((a: any, b: any) => a.name.localeCompare(b.name));
            availableMenus.forEach((availableMenu: any) => {
                if (menuIdFinal) {
                    return;
                }
                if (MenuHelper.isMenuAvailable(availableMenu, props.session.order_type, props.session.pickup_at, props.availabilities)) {
                    menuIdFinal = availableMenu.id
                }
            });

            if (!menuIdFinal) {
                menuIdFinal = availableMenus[0].id
            }
        }
    } else {
        menuIdFinal = parseInt(props.menuId);
    }

    let menu = availableMenus.filter((menu: any) => menu.id === menuIdFinal)[0];
    if (menu === undefined || !menu) {
        return (
            <>
                <div className={"menu-filters-1"}>
                    {props.session.order_type === "DELIVERY" && (
                        <MenuLocationFilterComponent/>
                    )}
                    {props.config?.ordering.pick_date && (
                        <MenuPickDateFilterComponent/>
                    )}
                    <MenuTypesFilterComponent/>
                    <MenuMenusFilterComponent menus={availableMenus} menu_id={menuIdFinal}/>
                </div>
                <div className="error-loading">
                    {t("constraints.internal.menu_not_exist")}
                </div>
            </>
        );
    }


    const addItem = (id: any, categoryId: any, menuId: any) => {
        navigate(AppConfig.getLinkWithPrefix(`/menu/${menuId}/add/${id}`, prefix), {state: {back: true}});
    }

    const filterItems = (items: any) => {
        if (!search) return items;
        return items.filter((item: any) => item.translation.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }

    let filteredItems = filterItems(props.items);

    const parseCategories = (menu: any, categories: any, items: any, availabilities: any, pick_date: any, promotions: any) => {
        let availablePromotions = PromotionHelper.getActivePromotionsForCategory(promotions, cart.total);
        let menuCategories = [] as any[];
        menu.categories.forEach((menuCategoryItem: any) => {
            let category = categories.filter((categoryItem: any) => categoryItem.id === menuCategoryItem.category_id)[0];
            if (category === undefined) {
                return;
            }
            if (category.status !== 'ENABLED') {
                return;
            }
            let menuCategory = {
                id: category.id,
                position: menuCategoryItem.position === undefined ? 0 : menuCategoryItem.position,
                name: category.translation.name,
                image_link: category.image_link,
                description: category.translation.description,
                entities: [] as any[]
            }
            category.entities.forEach((entity: any) => {
                let entityItem = undefined;
                if (entity.type = "ITEM") {
                    let item = items.filter((item: any) => item.id === entity.entity_id)[0];
                    if (!item) {
                        return;
                    }
                    entityItem = ItemViewHelper.parseItem(item, availabilities, availablePromotions, pick_date, entity.position, cart, props.session.order_type);
                    menuCategory.entities.push(entityItem);
                }
            });
            if (props.itemGroups && props.itemGroups.length > 0) {
                let newMenuCategoryEntities = [] as any[];
                menuCategory.entities.forEach((entity: any) => {
                    let itemGroupForItem = ItemHelper.getItemGroup(props.itemGroups, entity.id);
                    if (!itemGroupForItem) {
                        newMenuCategoryEntities.push(entity);
                        return;
                    }
                    let itemGroupView = ItemViewHelper.parseItemGroup(itemGroupForItem, items, availabilities, availablePromotions, pick_date, entity.position, cart, props.session.order_type) as any;

                    let newMenuCategoryEntity = newMenuCategoryEntities.filter(x => x.reference_id === itemGroupView.reference_id)[0];
                    if (newMenuCategoryEntity !== undefined) {
                        if (entity.position < newMenuCategoryEntity.position) newMenuCategoryEntity.position = entity.position;
                        return;
                    }
                    if (itemGroupView.item) {
                        let itemEntity = menuCategory.entities.filter(x => itemGroupView.item && x.id === itemGroupView.item.id)[0];
                        if (itemEntity !== undefined) {
                            itemGroupView.item = itemEntity;
                        } else {
                            itemGroupView.item = ItemViewHelper.parseItem(itemGroupView.item, availabilities, availablePromotions, pick_date, entity.position, cart, props.session.order_type);
                        }
                    }
                    newMenuCategoryEntities.push(itemGroupView);
                })
                menuCategory.entities = newMenuCategoryEntities;
            }
            if (menuCategory.entities.length > 0) {
                menuCategories.push(menuCategory);
            }
        });
        menuCategories = menuCategories.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name));
        return menuCategories;
    }

    const renderMenuCategories = (menuCategories: any, menu: any) => {
        return (
            <MenuItemsGridComponent promotions={props.promotions} categories={menuCategories} menu={menu}
                                    addItem={addItem}/>
        )
    }

    const menuCategories = parseCategories(menu, props.categories, filteredItems, props.availabilities, props.session.pickup_at, props.promotions)
    return (
        <>
            <div className={"menu-filters-1"}>
                {props.session.order_type === "DELIVERY" && (
                    <MenuLocationFilterComponent/>
                )}
                {props.config?.ordering.pick_date && (
                    <MenuPickDateFilterComponent/>
                )}
                <MenuTypesFilterComponent/>
                <MenuMenusFilterComponent menus={availableMenus} menu_id={menuIdFinal}/>
            </div>
            <MenuSearchFilterComponent
                onSearch={(value?: string) => setSearch(value ? value : "")} value={search}/>
            <MenuAvailability menu={menu} preview={props.preview}/>
            <Languages size={"xl"}/>
            <Sticky top={0} bottomBoundary="#footer" innerZ={1021} activeClass="is-sticky">
                <header className="parent-top-nav sticky-fixed1 sticky-fixed-categories" id="nav-scrollspy">
                    <MenuCategoryNavComponent menu_id={menu.id} promotions={props.promotions}
                                              categories={menuCategories}/>
                </header>
            </Sticky>
            {/*<MenuPromotionsCategoryComponent menu={menu} addItem={addItem} promotions={props.promotions}/>*/}
            {renderMenuCategories(menuCategories, menu)}
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    menus: getMenus(state.menu),
    preview: isPreview(state.config),
    availabilities: getAvailabilities(state.menu),
    promotions: getPromotions(state.menu),
    categories: getCategories(state.menu),
    items: getItems(state.menu),
    itemGroups: getItemGroups(state.menu),
    session: getSession(state.session),
    config: getStoreConfig(state.config),
    cart: getCart(state.cart)

})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuComponent));