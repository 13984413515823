import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../../reducers";
import {withTranslation} from "react-i18next";
import ScrollMenuTo from "../../../../utils/ScrollMenuTo";
import MenuScrollSpy from "../../../../utils/MenuScrollSpy";
import {getCartTotal} from "../../../../services/Cart/reducer";
import ScrollableTabs from "../../../../components/Common/ScrollableTabs";

interface OwnProps {
    promotions: any[],
    categories: any[],
    menu_id: string
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuCategoryNavComponent = (props: Props) => {
    const menuCategories = props.categories;

    return (
        <div className="container-fluid">
            <ScrollableTabs hideScrollbars={false} className="nav-categories-wrapper" scrollStep={60}>
                <MenuScrollSpy value={props.menu_id}>
                    {
                        menuCategories.map((category, i) =>
                            <li className="nav-item" key={category.id}>
                                <ScrollMenuTo to={`category-${props.menu_id}-${category.id}`} isFirstElement={i === 0}>
                                    {category.name}
                                </ScrollMenuTo>
                            </li>
                        )
                    }
                </MenuScrollSpy>
            </ScrollableTabs>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    cartTotal: getCartTotal(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuCategoryNavComponent));
