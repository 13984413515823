import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import LoadingContainer from "../../Common/LoadingContainer";
import {getCategories, getItems} from "../../../services/Menu/reducer";
import {api} from "../../../services/Api/api";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import ErrorFormView from "../../Common/ErrorFormView";
import {getCart} from "../../../services/Cart/reducer";
import {ApiError} from "../../../services/Api/types";
import handleException from "../../../services/Api/handleException";
import {ReactComponent as RemoveSVG} from "../../../../images/icons/remove.svg";
import {useCart} from "../../../services/Cart/cartService";
import VoucherProductComponent from "../../User/components/Voucher/VoucherProductComponent";
import {getUser, getVouchers} from "../../../services/User/reducer";
import UserUtils from "../../../services/User/UserUtils";
import VoucherSingleComponent from "../../User/components/Voucher/VoucherSingleComponent";
import VoucherUtils from "../../../services/User/VoucherUtils";
import IframeWidget from "../../../utils/IframeWidget";


interface OwnProps {
    name: any,
    promotionId: any,
    onHide: () => void,
    onShow: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function CartPromotionsModalComponent(props: Props) {
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const [coupon, setCoupon] = useState(props.cart.coupon);
    const [products, setProducts] = useState([]);
    let {t} = useTranslation();
    let activeVouchers = VoucherUtils.getVoucherWithCarts(props.vouchers, props.cart);

    let cartService = useCart();

    const fetchPromotion = async (promotionId: any) => {
        let promotion = await api.getPromotion(promotionId);
        return promotion;
    }

    useEffect(() => {
        getProducts();
    }, [])

    let couponId = props.cart.coupon_id;
    let couponPromotion = (coupon && !couponId) ? props.cart.promotions.filter((x: any) => x.metadata && x.metadata.toLocaleUpperCase().includes(`CODE_${coupon.toLocaleUpperCase()}`))[0] : undefined;

    let couponError = errors.length > 0 || (couponPromotion === undefined || !props.cart.coupon || (props.cart.discount_money && props.cart.discount_money.amount > 0)) ? false : true;


    const handleChangeCoupon = (event: any) => {
        setCoupon(event.target.value)
    }
    const addChangeCoupon = async (event: any) => {
        setLoadingCoupon(true)
        try {
            const response = await cartService.updateOrderCoupon({
                code: coupon
            });
            setErrors([])
            setLoadingCoupon(false);
            if (response.promotions.some((x: any) => x.type === "COUPON")) {
                props.onHide();
            }
        } catch (error: any) {
            const errors = handleException(error);
            setErrors(errors.map(error => {
                return {
                    ...error,
                    field: "code"
                }
            }));
            setLoadingCoupon(false)
        }
    }
    const deleteCoupon = async () => {
        setLoadingCoupon(true)
        try {
            await cartService.updateOrderCoupon({
                code: ""
            });
            setCoupon("");
            setErrors([])
            setLoadingCoupon(false)
        } catch (error: any) {
            const errors = handleException(error);
            setErrors(errors.map(error => {
                return {
                    ...error,
                    field: "code"
                }
            }));
            setLoadingCoupon(false)
        }
    }

    const getProducts = async () => {
        setLoadingProducts(true);
        let data = await api.getProducts();
        setProducts(data);
        setLoadingProducts(false)
        // return data;
    }
    return (
        <>
            <Modal dialogClassName="modal-full" show={true} onHide={() => IframeWidget.onHide(props.onHide)}
                   onShow={() => IframeWidget.onShow(props.onShow)}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("modules.cart.field.add_discount_or_voucher.title")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"mb-4"}>
                        <h5 className={"mb-3"}>
                            {t("modules.cart.field.coupon_code.title")}
                        </h5>
                        {props.cart.coupon ? (
                            <>
                                <div
                                    className={"form-group form-group-buttons mb-3"}>
                                    <input type="text" disabled={true} value={props.cart.coupon}
                                           className={"form-control" + ErrorFormHelper(errors, "code")}
                                           placeholder={t("modules.cart.field.coupon_code.title")}
                                           aria-label="Recipient's username" aria-describedby="cart-coupon"/>
                                    <label htmlFor="cart-coupon">{t("modules.cart.field.coupon_code.title")}</label>
                                    <div className={"form-group-button"}>
                                        <button className={"btn btn-icon btn-outline-danger"} onClick={deleteCoupon}>
                                            <RemoveSVG/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={"form-group mb-3" + ErrorFormHelper(errors, "code") + (couponError ? " is-invalid" : "")}>
                                    <input type="text" onChange={handleChangeCoupon} value={coupon}
                                           className={"form-control" + ErrorFormHelper(errors, "code") + (couponError ? " is-invalid" : "")}
                                           placeholder={t("modules.cart.field.coupon_code.title")}
                                           aria-label="Recipient's username" aria-describedby="cart-coupon"/>
                                    <label htmlFor="cart-coupon">{t("modules.cart.field.coupon_code.title")}</label>
                                    <ErrorFormView errors={errors} field="code"/>
                                    {couponError && (
                                        <>
                                            {/*{couponPromotion ? (*/}
                                            {/*    <div className="invalid-feedback"*/}
                                            {/*         onClick={() => setPromotionInfoModal(couponPromotion)}>*/}
                                            {/*        Koszyk nie spełnia warunków promocji: {couponPromotion.name}. <a*/}
                                            {/*        className={"text-success"}>Dowiedz się więcej</a>*/}
                                            {/*    </div>*/}
                                            {/*) : (*/}
                                            {/*    <div className="invalid-feedback">*/}
                                            {/*        Koszyk nie spełnia warunków promocji*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                        </>
                                    )}
                                </div>
                                <button
                                    className={"w-100 btn btn-primary btn-spinner btn-lg" + (loadingCoupon ? ' loading' : '')}
                                    type="button" id="cart-coupon" disabled={loadingCoupon} onClick={addChangeCoupon}>
                                    <span className="left spinner-border spinner-border-sm"/>
                                    {t("common.action.add")}
                                </button>
                            </>
                        )}
                    </div>

                    {(activeVouchers.length > 0) && (
                        <div className={"mb-4"}>
                            <h5 className={"mb-3"}>
                                {t("modules.cart.field.your_vouchers.title")}
                            </h5>
                            <div className="row row-cols-1 g-3">
                                {
                                    activeVouchers.sort((a: any, b: any) => a.voucher.name.localeCompare(b.voucher.name)).map((voucher: any) => {
                                            return (
                                                <React.Fragment key={voucher.voucher.id}>
                                                    <div className={"col d-flex"}>
                                                        <VoucherSingleComponent options={["BASKET"]}
                                                                                voucher={voucher.voucher}
                                                                                promotion_id={voucher.promotion_id}/>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    )}
                    {(loadingProducts || products.length > 0) && (
                        <div className={"mb-4"}>
                            <h5 className={"d-flex justify-content-between mb-3"}>
                                {t('modules.cart.field.to_buy.title')}
                                {UserUtils.isLogged(props.user) && (
                                    <span className={"badge badge-success-light"}>
                                    {t('modules.cart.points_amount.title', {amount: props.user.points})}
                                </span>
                                )}
                            </h5>
                            <div>
                                {loadingProducts ? (
                                    <>
                                        <LoadingContainer/>
                                    </>
                                ) : (
                                    <>

                                        {products ? (
                                            <div className="row row-cols-1 g-3">
                                                {
                                                    products.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((product: any) => {
                                                            return (
                                                                <React.Fragment key={product.id}>
                                                                    <div className={"col d-flex"}>
                                                                        <VoucherProductComponent onSuccess={() => {
                                                                        }} product={product}/>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <>
                                                {t("modules.cart.field.no_vouchers.title")}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    categories: getCategories(state.menu),
    vouchers: getVouchers(state.user),
    cart: getCart(state.cart),
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartPromotionsModalComponent));
