class MenuEditItemStateHelper {

    static getStateModifierGroup = (modifierGroups: any, modifierGroupId: any) => {
        if (!modifierGroups) return undefined;
        return modifierGroups.filter((modifierGroup: any) => modifierGroup.modifier_group_id === modifierGroupId)[0];
    }
    static getStateModifierGroupSelectedItem = (modifierGroup: any, item_id: any) => {
        if (!modifierGroup) {
            return undefined;
        }
        return modifierGroup.selected_items.filter((selectedItem: any) => selectedItem.item_id === item_id)[0];
    }
    static getStateModifierGroupSelectedItems = (modifierGroup: any, item_id: any) => {
        if (!modifierGroup) {
            return [];
        }
        return modifierGroup.selected_items.filter((selectedItem: any) => selectedItem.item_id === item_id);
    }
    static parseOrderItemToStateItem = (orderItem: any, parentModifierGroupId?: any) => {
        let modifierGroups = [] as any[];
        if (orderItem.modifier_groups) {
            orderItem.modifier_groups.forEach((orderItemModifierGroup: any) => {
                let stateModifierGroup = {
                    modifier_group_id: orderItemModifierGroup.modifier_group_id,
                    selected_items: [] as any[]
                }
                orderItemModifierGroup.selected_items.forEach((selectedItem: any) => {
                    let stateSelectedItem = this.parseOrderItemToStateItem(selectedItem, orderItemModifierGroup.modifier_group_id);
                    stateModifierGroup.selected_items.push(stateSelectedItem);
                })
                modifierGroups.push(stateModifierGroup);
            })
        }
        let stateItem = {
            item_id: orderItem.item_id,
            modifier_groups: modifierGroups,
            name: orderItem.name,
            comment: orderItem.comment,
            price: orderItem.base_unit_price_money?.amount,
            quantity: orderItem.quantity,
            total_price: orderItem.total_money?.amount,
            unit_total_price: orderItem.unit_price_money?.amount
        } as any
        if (parentModifierGroupId) {
            stateItem.parent_modifier_group_id = parentModifierGroupId;
        }
        return stateItem;
    }
}

export default MenuEditItemStateHelper;