import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import Utils from "../../utils/Utils";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {RootState} from "../../../reducers";
import ChangeTableNumberModal from "../../components/Modal/ChangeTableNumberModal";
import LoadingContainer from "../../components/Common/LoadingContainer";
import {useLocationService} from "../../services/Location/locationService";
import {getSession} from "../../services/Location/reducer";
import AppConfig from "../../services/AppConfig";
import {usePrefix} from "../../services/Prefix";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

function DineInOrderTypePage(props: Props) {
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const orderType = "DINE_IN";
    const locationService = useLocationService();
    const prefix = usePrefix();

    const init = async () => {
        var number = undefined;
        if (location.search) {
            let query = location.search.substring(1);
            let qp = Utils.getQueryParams(query) as any;
            if (qp.table) {
                number = qp.table;
            } else if (qp.number) {
                number = qp.number;
            }
        }
        if (!number && show) {
            setLoading(false);
            return;
        }
        let sessionData = {
            ...props.session,
            order_type: orderType,
            table_number: number
        }
        try {
            await locationService.update(sessionData);
            setRedirect(true);
        } catch (error) {
            console.log(error);
            setShow(true);
        }
    }

    const onHideModal = async () => {
        let sessionData = {
            ...props.session,
            order_type: orderType,
            table_number: undefined
        }
        try {
            await locationService.update(sessionData);
            setRedirect(true);
        } catch (error) {
            console.log(error);
        }
        setShow(false);
    }
    useEffect(() => {

        init();
    }, []);

    var number = undefined;
    if (location.search) {
        let query = location.search.substring(1);
        let qp = Utils.getQueryParams(query) as any;
        if (qp.table) {
            number = qp.table;
        } else if (qp.number) {
            number = qp.number;
        }
    }

    if (!number) {
        const onSave = () => {
            navigate(AppConfig.getLinkWithPrefix("/", prefix));
        }
        if (show) {
            return (
                <ChangeTableNumberModal showLanguages={true} tableNumber={number} show={show}
                                        onSave={onSave} onHide={onHideModal}/>
            )
        }
    }

    if (redirect) {
        return (
            <Navigate to={AppConfig.getLinkWithPrefix("/", prefix)}/>
        )
    }
    if (loading) {
        return (
            <LoadingContainer/>
        );
    }
    return (
        <Navigate to={AppConfig.getLinkWithPrefix("/", prefix)}/>
    )
}

const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session)
})

const connector = connect(mapStateToProps);

export default connector(DineInOrderTypePage);
