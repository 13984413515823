import {MenusActionTypes} from "./types";

export function fetchMenuPending() {
    return {
        type: MenusActionTypes.PENDING
    }
}

export function fetchMenuError(error: any) {
    return {
        type: MenusActionTypes.ERROR,
        error: error
    }
}

export function fetchMenusSuccess(menus: any, locale?: string) {
    return {
        type: MenusActionTypes.SUCCESS,
        data: {
            menus: menus,
            locale: locale
        }
    }
}

export function cleanMenus() {
    return {
        type: MenusActionTypes.CLEAN
    }
}
