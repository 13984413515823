import React from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {ConfigLayoutMenuApi} from "../../services/Api/types";
import AppConfig from "../../services/AppConfig";

interface Props {
    links: ConfigLayoutMenuApi[]
}

const NavbarMenus = (props: Props) => {
    if (AppConfig.isWidget()) return null;

    const renderStoreLinkView = function (storeLink: ConfigLayoutMenuApi) {
        if (storeLink.link.includes("http") || storeLink.link.includes("www")) {
            return (
                <a className="nav-link" href={storeLink.link}>
                    {storeLink.title}
                </a>
            )
        }

        return (
            <Link className="nav-link" to={`/${storeLink.link}`}>
                {storeLink.title}
            </Link>
        )
    }

    return (
        <ul className="navbar-nav">
            {
              props.links.filter(x => x.type === "MENU").sort((a, b) => a.position - b.position || a.title.localeCompare(b.title)).map((storeLink) =>
                    <li className="nav-item" key={`${storeLink.title}-${storeLink.link}-${storeLink.type}`}>
                        {renderStoreLinkView(storeLink)}
                    </li>
                )
            }
        </ul>
    )
}
export default withTranslation()(NavbarMenus)