import TagManager from 'react-gtm-module'

let defaultCookieConsent = {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied',
};
let sendDefaultCookieConsent = false;
const ReactGtag = {

    initializeTagManager: function (id: string, cookieConsent: any) {
        const tagManagerArgs = {
            gtmId: id
        } as any
        if (cookieConsent) {
            tagManagerArgs.dataLayer = {
                event: 'default_consent',
                consent: cookieConsent,
            } as any
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push(tagManagerArgs.dataLayer);
        }
        (window as any).gtag = function () {
            (window as any).dataLayer.push(arguments);
        };
        if (defaultCookieConsent) {
            tagManagerArgs.dataLayer = {
                event: 'default_consent',
                consent: defaultCookieConsent,
            }
        }
        if (defaultCookieConsent && !sendDefaultCookieConsent) {
            (window as any).gtag('consent', 'default', defaultCookieConsent);
            if (cookieConsent) {
                (window as any).gtag('consent', 'update', cookieConsent);
            }
            sendDefaultCookieConsent = true;
        }
        TagManager.initialize(tagManagerArgs)
    },
    consent: function (cookieConsent: any) {
        if (typeof window === 'undefined') {
            return;
        }
        if (cookieConsent) {
            if ((window as any).gtag) {
                (window as any).gtag('consent', 'update', cookieConsent);
            }
        }
    },
    initialize: function (id: string, config: any, cookieConsent: any) {
        if (typeof window === 'undefined') {
            return;
        }
        var gtag = 'gtag';
        if (document.getElementById(gtag)) {
            ReactGtag.gtag('config', id, config);
            return;
        }
        if (!(window as any).dataLayer) {
            (window as any).dataLayer = [];
        }
        (window as any).gtag = function () {
            (window as any).dataLayer.push(arguments);
        };
        if (defaultCookieConsent) {
            (window as any).gtag('consent', 'default', defaultCookieConsent);
            if (cookieConsent) {
                (window as any).gtag('consent', 'update', cookieConsent);
            }
        }

        var script = document.createElement('script');
        script.id = gtag;
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
        script.type = 'text/javascript';
        script.async = true;
        document.head.appendChild(script);

        script.onload = function () {
            (window as any).gtag('js', new Date());
            (window as any).gtag('config', id, config);
            // gtag('js', new Date());
            // gtag('config', id, { send_page_view: false }); // Adjust config as needed
        };
    },
    gtag: function (command: any, value: any, params: any) {
        (window as any).gtag(command, value, params);
    },
    ecommerce: function (event: any, data: any) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
            event: event,
            ecommerce: data
        });
    },
    initializeTagManager2: function (id: string, cookieConsent: any) {
        if (typeof window === 'undefined') {
            return;
        }
        var gtag = 'gtagManager';
        if (document.getElementById(gtag)) {
            return;
        }
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
            event: 'default_consent',
            consent: cookieConsent || 'pending', // Set 'pending' if consent is not resolved yet
        });
        // Load GTM script dynamically
        const gtmScript = document.createElement('script');
        gtmScript.id = gtag;
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
        gtmScript.type = 'text/javascript';

        document.head.appendChild(gtmScript);

        if (!(window as any).dataLayer) {
            (window as any).dataLayer = [];
        }
        (window as any).gtag = function () {
            (window as any).dataLayer.push(arguments);
        };
        (window as any).gtag('js', new Date());
        if (cookieConsent) {
            (window as any).gtag('consent', 'default', cookieConsent);
        }
        (window as any).gtag('config', id, {
            send_page_view: false
        });
    },
}

export default ReactGtag;