import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import PageLayout from "../../components/layout/PageLayout";
import {useParams} from "react-router-dom";
import {api} from "../../services/Api/api";
import {ApiError, PageApi} from "../../services/Api/types";
import LoadingContainer from "../../components/Common/LoadingContainer";
import handleException from "../../services/Api/handleException";
import ErrorFormAlertView from "../../components/Common/ErrorFormAlertView";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;
const DynamicPage = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<PageApi | undefined>(undefined);
    const [errors, setErrors] = useState<ApiError[]>([]);
    const {t} = useTranslation();
    const {slug} = useParams();

    useEffect(() => {
        fetch();
    }, [slug]);

    const fetch = async () => {
        if (!slug) return;

        try {
            const response = await api.getPage(slug);
            setPage(response);
            setErrors([]);
        } catch (err) {
            const exceptionErrors = handleException(err);
            setErrors(exceptionErrors);
        }
        setLoading(false);
    }

    if (loading) {
        return (
            <PageLayout>
                <LoadingContainer/>
            </PageLayout>
        )
    }

    if (!page) {
        return (
            <PageLayout>
                <div className="container">
                    <h2>
                        {t("constraints.internal.page_not_exist")}
                    </h2>
                    <div>
                        <ErrorFormAlertView errors={errors}/>
                    </div>
                </div>
            </PageLayout>
        )
    }

    return (
        <PageLayout>
            <div className="page">
                <div className="container">
                    <div className="header-container">
                        <h1>{page.title}</h1>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: page.content}}/>
                </div>
            </div>
        </PageLayout>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);

export default connector(DynamicPage);