import {StoreActionTypes, StoreState} from "./types";
import {Reducer} from "redux";
import {RootState} from "../../../reducers";
import {ConfigApi} from "../Api/types";
import StoreUtils from "./StoreUtils";
import {StoreActions} from "./actions";


const initialState: StoreState = {}


export const storeConfigReducer: Reducer<StoreState, StoreActions> = (state = initialState, action) => {
    switch (action.type) {
        case StoreActionTypes.PENDING:
            return {
                ...state,
                pending: true
            }
        case StoreActionTypes.ERROR:
            return {
                ...state,
                pending: false,
                errors: action.errors,
            }
        case StoreActionTypes.SUCCESS:
            const config = action.payload as ConfigApi
            const newConfig = StoreUtils.mergeConfigs(state.default_config, config);
            return {
                ...state,
                pending: false,
                errors: undefined,
                config: newConfig
            }
        case StoreActionTypes.SUCCESS_DEFAULT:
            const defaultConfig = action.payload as ConfigApi
            return {
                ...state,
                pending: false,
                errors: undefined,
                default_config: defaultConfig
            }
        case StoreActionTypes.CLEAN:
            return {
                ...state,
                config: undefined,
            }
        default: {
            return state;
        }
    }
};

export default storeConfigReducer;
export const getStoreState = (state: StoreState) => state;
export const getStoreConfig = (state: StoreState) => state.config;
export const getStoreStatePending = (state: StoreState) => state.pending;
export const getStoreStateError = (state: StoreState) => state.errors;
export const getStoreConfigLoyalty = (state: StoreState) => state.default_config?.loyalty;
export const getStoreLayoutComponentsConfig = (state: StoreState) => state.config?.layout?.components;
export const getStoreDefaultConfig = (state: StoreState) => state.default_config;

export const selectStore = (state: RootState) => {
    return state.config;
};
export const isPreview = (state: StoreState) => !state.config?.ordering?.enabled;
