class CartHelper {

    static isOpen(order: any) {
        if (order.status === 'CONFIRMED' && (order.payment_status === "OPEN" || order.payment_status === "FAILURE")) {
            return true;
        }
        return false;
    }

    static isConfirmed(order: any) {
        if (order.status === 'CONFIRMED' && (order.payment_status === "OPEN" || order.payment_status === "FAILURE")) {
            return true;
        }
        return false;
    }

    static getItemName(items: any, id: any, name: any) {
        let obj = items.filter((item: any) => item.id === id)[0];
        if (obj) {
            return obj.translation.name;
        }
        return name;
    }

    static getAvailablePaymentMethods(storePaymentMethods: any[], paymentMethods: any, orderType: any) {
        let availablePaymentMethods = paymentMethods.filter((x: any) => {
            let storePaymentMethod = storePaymentMethods.filter((y: any) => y.id == x.id)[0];
            return storePaymentMethod.order_types === undefined || storePaymentMethod.order_types.length === 0 || storePaymentMethod.order_types.includes(orderType);
        });
        return availablePaymentMethods;
    }

    static getDefaultPaymentMethodId(paymentMethodId: any, paymentMethods: any) {
        let paymentMethod = undefined;
        if (paymentMethodId !== undefined && paymentMethodId) {
            paymentMethod = paymentMethods.filter((x: any) => x.id === paymentMethodId)[0];
        }
        if (paymentMethod === undefined) {
            if (paymentMethods.length === 1) {
                paymentMethod = paymentMethods[0];
            }
        }
        if (paymentMethod === undefined) {
            return null;
        }
        return paymentMethod.id;
    }
}


export default CartHelper;