import {useTranslation} from "react-i18next";


interface Props {
    day?: string,
    month?: string,
    value: any
}

const FormattedDate = (props:Props)=>{
    const { i18n } = useTranslation();
    let options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }  as any
    if(props.day !== undefined) options.day = props.day;
    if(props.month !== undefined) options.month = props.month;
    return (<>{new Intl.DateTimeFormat(i18n.language, options).format(props.value)}</>);
}

export default FormattedDate;