import axios from "axios";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import FormatDate from "./components/Formatters/FormatDate";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    window.location.hostname.includes(".local")
);
const isDemo = Boolean(window.location.hostname.startsWith("demo"));
const defaultNs = ["translation"];

export function buildI18n(
    version: string,
    cdnUrls: any,
    ns?: string[],
    alwaysCdn?: boolean,
    languages?: string[],
    defaultCurrentLang?: string
): any {
    if (ns) ns = [...defaultNs, ...ns];
    else ns = [...defaultNs];
    // let currentLang = getLangOnly();
    // currentLang = languages && languages.includes(currentLang) ? currentLang : "pl";
    let currentLang = "pl";
    if(defaultCurrentLang && languages){
        currentLang =  languages.includes(defaultCurrentLang) ? defaultCurrentLang : currentLang;
    }
    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            ns,
            fallbackLng: isLocalhost ? [currentLang, "xxa"] : [currentLang],
            interpolation: {
                escapeValue: false,
                format: (value, rawFormat, lng) => {
                    if (!rawFormat) return value;
                    const [format, ...additionalValues] = rawFormat.split(",").map((v) => v.trim());
                    switch (format) {
                        case "uppercase":
                            return value.toUpperCase();
                        case "money":
                            return Intl.NumberFormat(lng, {
                                style: "currency",
                                currency: additionalValues[0],
                            }).format(value);
                        case "datetime":
                            if (value && value instanceof Date) {
                                value = FormatDate(value);
                            }
                            return value;
                        default:
                            return value;
                    }
                },
            },
            backend: {
                request(options: any, url: any, payload: any, callback: any) {
                    const splitUrl = url.split("|");
                    const lang = splitUrl[0];
                    const ns = splitUrl[1];
                    let link;
                    const cdnUrl = cdnUrls[ns];
                    switch (ns) {
                        case "translation":
                        case "admin":
                            if (isLocalhost || !cdnUrl) {
                                link = `/translations/${lang}/${ns}.json?v=${version}`;
                            } else if (alwaysCdn) {
                                link = `${cdnUrl}/${lang}/${ns}.json?v=${version}`;
                            } else {
                                link = `/translations/${lang}/${ns}.json?v=${version}`;
                            }
                            break;
                        case "lib":
                            if (isLocalhost) {
                                link = `/translations/lib/${lang}/lib.json?v=${version}`;
                            } else if (alwaysCdn) {
                                link = `https://delivery.localazy.com/_a8002558574820540816c31365f0/_e0/f501e35ce7fc9845d3ed88d5b990fd69b26b32ff/${lang}/lib.json?v=${version}`;
                            } else {
                                link = `/translations/lib/${lang}/lib.json?v=${version}`;
                            }
                            break;
                    }
                    if (!link) return;
                    axios
                        .get(link)
                        .then((res) => {
                            callback(null, {status: 200, data: res.data});
                        })
                        .catch((err) => callback(err));
                },
                loadPath: "{{lng}}|{{ns}}",
                queryStringParams: {v: version},
                crossDomain: true,
            },
            react: {
                useSuspense: false,
                // wait: true
            },
        });
    return i18n;
}

export function buildI18n1(version: string, cdnUrl: string, ns?: string[]): any {
    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            ns: ["admin", "lib", "translation"],
            backend: {
                loadPath: "/translations/{{lng}}/{{ns}}.json",
                queryStringParams: {v: version},
                crossDomain: true,
            },
        });
    return i18n;
}
