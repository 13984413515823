import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RootState} from "../../../../reducers";
import LoadingContainer from "../../../components/Common/LoadingContainer";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";
import StorageStore from "../../../services/Common/Storage/storageStore";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const UserAuthPage = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const authService = useAuth();
    const {t} = useTranslation();

    const queryParams = new URLSearchParams(location.search);
    const getQueryParam = (param: any) => {
        const value = queryParams.get(param);
        return value ? value : undefined;
    };

    const authorizationCode = StorageStore.getItem('authorization_code');
    // const authorizationCode = getQueryParam('ac');
    const ref = getQueryParam('ref');


    useEffect(() => {
        send()
    }, []);

    const send = async () => {
        if(!authorizationCode){
            setErrors([{
                message: t("constraints.internal.login_failed")
            }])
            setLoading(false)
            return;
        }
        setLoading(true)
        try {
            await authService.loginAuthorizationCode(authorizationCode);
            if(ref){
                navigate(`/${ref}`)
            }else{
                navigate("/")
            }
            setLoading(false);
        } catch (e) {
            console.log(e)
            setErrors([{
                message: t("constraints.internal.login_failed")
            }]);
            setLoading(false);
        }
    }
    if (loading) {
        return (
            <>
                <LoadingContainer>
                    {t("modules.login.field.login_pending.title")}
                </LoadingContainer>
            </>
        )
    }
    return (
        <>
            <div className={"login-box"}>
                <div className={"container-fluid"}>
                    <div>
                        <h4>
                            {t("modules.login.field.error.title")}
                        </h4>
                    </div>
                    <>
                        <ErrorFormAlertView errors={errors}/>
                    </>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserAuthPage));
