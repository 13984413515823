import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "../../../utils/ErrorFormHelper";
import ErrorFormView from "../../../components/Common/ErrorFormView";
import {api} from "../../../services/Api/api";
import IframeWidget from "../../../utils/IframeWidget";

interface Props {
    order: any,
    onHide: () => void,
    onSuccess: (data: any) => void
}

const SummaryCancelModal = (props: Props) => {
    const {t} = useTranslation();
    const [cancelReason, setCancelReason] = useState("");
    const [cancelErrors, setCancelErrors] = useState<any[]>([]);
    const [cancelLoading, setCancelLoading] = useState(false);

    const cancel = async () => {
        setCancelLoading(false);
        try {
            const data = await api.cancelOrder(props.order.id, props.order.token, cancelReason);
            props.onSuccess(data);
        } catch (error: any) {
            setCancelLoading(false);
            setCancelErrors(error.errors);
        }
    }
    const handleChangeCancelReason = (event: any) => {
        setCancelReason(event.target.value as any);
    }
    return (
        <>
            <Modal dialogClassName="modal-full" show={true}
                   onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow()}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("modules.cancel_order_modal.header.title")}
                        </Modal.Title>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        <ErrorFormAlertView errors={cancelErrors}/>

                        <div className="form-group">
                            <input type="text" value={cancelReason}
                                   onChange={handleChangeCancelReason}
                                   className={"form-control" + ErrorFormHelper(cancelErrors, "cancel_reason")}
                                   id="street"
                                   placeholder={t("modules.cancel_order_modal.field.cancel_reason.title")}/>
                            <label
                                htmlFor="street">{t("modules.cancel_order_modal.field.cancel_reason.title")}</label>
                            <ErrorFormView errors={cancelErrors} field="cancel_reason"/>
                        </div>
                    </div>
                    <button
                        className={"btn-spinner btn-block btn-primary btn btn-submit" + (cancelLoading ? ' loading' : '')}
                        onClick={(e) => cancel()}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.cancel_order")}
                    </button>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default SummaryCancelModal;