import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {ReactComponent as UserIco} from "../../../images/icons/user-circle.svg";
import {RootState} from "../../../reducers";
import {getUser} from "../../services/User/reducer";
import {getStoreConfigLoyalty} from "../../services/Store/reducer";
import LoginUserModal from "../User/LoginUserModal";
import UserUtils from "../../services/User/UserUtils";
import {usePrefix} from "../../services/Prefix";
import UserModal from "../User/UserModal";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function NavbarUser(props: Props) {
    const {user} = props;
    const [modal, setModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const prefix = usePrefix();

    if (!UserUtils.isLoyaltyEnabled(props.config_loyalty)) return null;

    const showModal = () => {
        setModal(true)
    }
    return (
        <>
            {user.pending ? (
                <>
                    <button className={"btn btn-icon btn-spinner loading"} onClick={() => showModal()}>
                        <span className="left spinner-border spinner-border-sm"></span>
                    </button>
                </>
            ) : (
                <>
                    {(user && user.name) ? (
                        <button className={"btn btn-icon"} onClick={() => setUserModal(true)}>
                            <UserIco/>
                        </button>
                        // <Link to={AppConfig.getLinkWithPrefix("/user", prefix)}
                        //       className={"btn btn-icon btn-transparent1"}>
                        //     <UserIco/>
                        // </Link>
                    ) : (
                        <>
                            <button className={"btn btn-icon"} onClick={() => showModal()}>
                                <UserIco/>
                            </button>
                        </>
                    )}
                    {modal && (
                        <LoginUserModal onHide={() => setModal(false)} show={true}/>
                    )}
                    {userModal && (
                        <UserModal onHide={() => setUserModal(false)} show={true}/>
                    )}
                </>
            )}
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config_loyalty: getStoreConfigLoyalty(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarUser));