import React from 'react';
import {useTranslation} from "react-i18next";

interface Props {
    order: any
}

const getDefaultCustomFields = (orderCustomFields: any) => {
    let customFields = []
    for (var key in orderCustomFields) {
        var value = orderCustomFields[key];
        customFields.push({
            slug: key,
            value: value
        })
    }
    return customFields
}
const SummaryOrderDataComponent = (props: Props) => {
    const {t} = useTranslation();
    const customFields = getDefaultCustomFields(props.order.custom_fields);
    if(!props.order.tax_id_no && customFields.length <=0) return null;
    return (
        <>
            <div className={"card-cart"}>
                <div className={"container-fluid"}>
                    <h3 className={"card-cart-header"}>{t("modules.summary.field.order_data.title")}</h3>
                    <div className={"card-cart-body"}>

                        <div className={"cart-card-info"}>
                            {props.order.tax_id_no && (
                                <dl>
                                    <dt>{t("common.word.tax_id_no")}:</dt>
                                    <dd>{props.order.tax_id_no}</dd>
                                </dl>
                            )}
                            {customFields.map(customField => {
                                return (
                                    <dl key={customField.slug}>
                                        <dt>{customField.slug}:</dt>
                                        <dd>{customField.value}</dd>
                                    </dl>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {props.order.comment && (
                <div className={"card-cart card-cart-items"}>
                    <div className={"container-fluid"}>
                        <h3 className={"card-cart-header"}>{t("modules.summary.field.order_comments.title")}</h3>
                        <div className={"card-cart-body"}>
                            <div className={"cart-card-info"}>
                                {props.order.comment}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default SummaryOrderDataComponent;