import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import 'leaflet/dist/leaflet.css';

import {ReactComponent as LocationSVG} from "../../../../../../images/icons/location.svg";
import {ReactComponent as ListSVG} from "../../../../../../images/icons/list.svg";
import {RootState} from "../../../../../../reducers";
import {getSession} from "../../../../../services/Location/reducer";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {PlaceApi, StoreConfigCoordinatesApi} from "../../../../../services/Api/types";
import {ReactComponent as MarkerSVG} from "../../../../../../images/icons/marker-solid.svg";
import {ReactComponent as CurrentMarkerSVG} from "../../../../../../images/icons/current_marker.svg";
import PlaceItemMultistoreComponent from "../../../components/PlaceItemMultistoreComponent";
import {Link} from "react-router-dom";
import ChangeLocationButtonComponent from "../../../components/ChangeLocationButtonComponent";
import {getStoreDefaultConfig} from "../../../../../services/Store/reducer";


interface OwnProps {
    coordinates: StoreConfigCoordinatesApi;
    zoom: number;
    places: PlaceApi[],
    geolocation: (coordinates: StoreConfigCoordinatesApi) => void,
    nearestPlace?: PlaceApi,
    hoverPlace?: string
}

const defaultCenter = {
    latitude: 50.07364,
    longitude: 19.9574
} as StoreConfigCoordinatesApi;

type Position = [number, number];
const FlyToOnPositionChange: React.FC<{ position: Position }> = ({position}) => {
    const map = useMap();
    map.setView(position, map.getZoom()); // Smooth transition
    return null;
};
const mapCoordinates = (coordinats: StoreConfigCoordinatesApi) => {
    return [coordinats.latitude, coordinats.longitude] as [number, number]
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreMapComponent = (props: Props) => {
    const [center, setCenter] = useState<StoreConfigCoordinatesApi>(defaultCenter);
    const [selectedPlace, setSelectedPlace] = useState<PlaceApi | undefined>(undefined);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const {t} = useTranslation();
    const iconFromConfig = props.default_config?.layout.ico_link?.small;

    const geolocation = async () => {
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(function (position) {
            props.geolocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
            setLoadingLocation(false);
        }, (err) => {
            console.log(err)
            if (err && err.message === "User denied Geolocation") {
                alert(t("constraints.internal.user_blocked_geolocation"));
            } else {
                alert(t("constraints.internal.location_fetching_failed"));
            }
            setLoadingLocation(false);
        });
    }

    const currentIcon = L.divIcon({
        className: "",
        html: ReactDOMServer.renderToString(
            <div className="marker-current-icon">
                <CurrentMarkerSVG className="icon"/>
            </div>
        ),
    });
    const icon = L.divIcon({
        className: "",
        html: ReactDOMServer.renderToString(
            <div className="marker-place-icon">
                {iconFromConfig ? <img alt='store-icon' src={iconFromConfig}/> : <MarkerSVG className="icon"/>}
            </div>
        ),
    });
    const selectedIcon = L.divIcon({
        className: "",
        html: ReactDOMServer.renderToString(
            <div className="marker-place-icon active">
                {iconFromConfig ? <img alt='store-icon' src={iconFromConfig}/> : <MarkerSVG className="icon"/>}
            </div>
        ),
    });
    const handleMarkerClick = (place: PlaceApi) => {
        setSelectedPlace(place)
        if (place.coordinates)
            setCenter(place.coordinates)
    };

    useEffect(() => {
        if (props.hoverPlace && props.places) {
            const hoverPlace = props.places.find(place => place.domain === props.hoverPlace);
            if (hoverPlace?.coordinates) {
                setCenter(hoverPlace.coordinates);
            }
        } else if (props.coordinates) {
            setCenter(props.coordinates);
        }
    }, [props.hoverPlace, props.coordinates, props.places]);
    useEffect(() => {
        setSelectedPlace(props.nearestPlace);
    }, [props.nearestPlace]);

    return (
        <>
            <div className={"map-wrapper"}>

                <div className={"map-container"}>
                    <MapContainer
                        // center={mapCoordinates(props.coordinates ? props.coordinates : defaultCenter)}
                        center={mapCoordinates(center)}
                        // center={center2}
                        zoom={props.zoom}
                        // scrollWheelZoom={false} // Disable scroll wheel zoom
                        // dragging={false}        // Disable dragging
                        // doubleClickZoom={false} // Disable double-click zoom
                        // zoomControl={false}
                        style={{width: '100%'}}
                        className="map-container"
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {
                            props.places.map(place => {
                                if (!place.coordinates) return null;
                                return (
                                    <React.Fragment key={place.domain}>
                                        <Marker position={mapCoordinates(place.coordinates)}
                                                icon={selectedPlace && selectedPlace.domain == place.domain ? selectedIcon : icon}
                                                eventHandlers={{
                                                    click: () => handleMarkerClick(place), // Attach click event handler
                                                }}>
                                            {/*<Popup>{place.name}</Popup>*/}
                                        </Marker>
                                    </React.Fragment>
                                )
                            })
                        }
                        {props.coordinates && (
                            <Marker position={mapCoordinates(props.coordinates)}
                                    icon={currentIcon}>
                                <Popup>This is your selected location!</Popup>
                            </Marker>
                        )}
                        <FlyToOnPositionChange position={mapCoordinates(center)}/>
                    </MapContainer>
                    <div className={"multistore-map-search"}>
                        <ChangeLocationButtonComponent hideGeolocation={true} size={"normal"}
                                                       config={props.default_config}/>
                    </div>
                    <div className={"multistore-map-controls multistore-controls"}>
                        <button
                            className={`btn btn-default btn-icon btn-spinner multistore-controls-action-geolocation ${loadingLocation ? " loading" : ""}`}
                            onClick={geolocation}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {!loadingLocation && (
                                <LocationSVG/>
                            )}
                        </button>
                        <Link to={"/list"} className={"btn btn-primary btn-icon multistore-controls-action-list"}>
                            <ListSVG/>
                        </Link>
                    </div>
                </div>
                <div className={"multistore-map-bottom"}>
                    {selectedPlace && (
                        <div className={"multistore-map-item"} key={selectedPlace.domain}>
                            <PlaceItemMultistoreComponent place={selectedPlace}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    default_config: getStoreDefaultConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MultistoreMapComponent));

