import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import ItemHelper from "../../../services/Menu/services/Item/ItemHelper";
import LoadingContainer from "../../Common/LoadingContainer";
import Utils from "../../../utils/Utils";
import Money from "../../Common/Money";
import {getCategories, getItems} from "../../../services/Menu/reducer";
import {api} from "../../../services/Api/api";
import IframeWidget from "../../../utils/IframeWidget";


interface OwnProps {
    name: any,
    promotionId: any,
    onHide: () => void,
    onShow?: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function PromotionInfoModalComponent(props: Props) {
    const [name, setName] = useState(props.name);
    const [promotion, setPromotion] = useState<any | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    let {t} = useTranslation();

    useEffect(() => {
        showPromotionInfo(props.promotionId)
    }, [])

    const fetchPromotion = async (promotionId: any) => {
        let promotion = await api.getPromotion(promotionId);
        return promotion;
    }

    const showPromotionInfo = async (promotionId: any) => {
        try {
            let promotionConfig = await fetchPromotion(promotionId)
            setPromotion(promotionConfig);
        } catch (err) {
            setError(t('modules.promotion.field.problem_with_promotion_fetching.title'));
        }
    }

    const renderTypes = () => {
        let types = [];
        if (promotion.dine_in) {
            types.push("DINE_IN");
        }
        if (promotion.delivery) {
            types.push("DELIVERY");
        }
        if (promotion.takeaway) {
            types.push("PICK_UP");
        }
        if (promotion.room_service) {
            types.push("ROOM_SERVICE");
        }
        if (types.length <= 0) {
            return undefined;
        }

        return types.map(type => t("enums.order.type." + type)).join(", ");
    }

    const types = promotion ? renderTypes() : undefined;

    const renderConditions = (conditions: any) => {
        let conditionNames = conditions.map((condition: any) => {
            if (condition.type == "ITEM") {
                let item = ItemHelper.getItem(props.items, condition.entity_id);
                if (!item) return undefined;
                return item.translation.name;
            }
            if (condition.type == "CATEGORY") {
                let category = props.categories.filter((categoryItem: any) => categoryItem.id === condition.entity_id)[0];
                if (!category) return undefined;
                return category.translation.name;
            }
            return undefined;
        }).filter((x: any) => x !== undefined);
        return conditionNames.join(", ");
    }

    const getConditions = (conditions: any) => {
        let conditionNames = conditions.map((condition: any) => {
            if (condition.type == "ITEM") {
                let item = ItemHelper.getItem(props.items, condition.entity_id);
                if (!item) return undefined;
                return item.translation.name;
            }
            if (condition.type == "CATEGORY") {
                let category = props.categories.filter((categoryItem: any) => categoryItem.id === condition.entity_id)[0];
                if (!category) return undefined;
                return category.translation.name;
            }
            return undefined;
        }).filter((x: any) => x !== undefined);

        return conditionNames;
    }

    return (
        <Modal dialogClassName="modal-full" show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(props.onShow)} className="promotion-info-modal">
            <div className="">
                <Modal.Header>
                    <Modal.Title>
                        {t("modules.promotion.field.conditions.title")}
                    </Modal.Title>
                    <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"/>
                </Modal.Header>
            </div>
            <Modal.Body>
                {!promotion ? (
                    <LoadingContainer/>
                ) : (
                    <>
                        <h6>
                            {name}
                        </h6>
                        {promotion.description && (
                            <p>
                                {Utils.nl2brString(promotion.description)}
                            </p>
                        )}
                        {error ? (
                            <div className={"alert alert-danger"}>{error}</div>
                        ) : (
                            <div>
                                <ul className="list-unstyled">
                                    <dl>
                                        {types && (
                                            <>
                                                <dt>
                                                    {t("modules.promotion.field.order_type.title")}
                                                </dt>
                                                <dd>{types}</dd>
                                            </>
                                        )}
                                        {promotion.min_basket_money && (
                                            <>
                                                <dt>{t("common.word.min_order_price")}</dt>
                                                <dd><Money value={promotion.min_basket_money}/></dd>
                                            </>
                                        )}

                                        {promotion.min_condition_quantity && (
                                            <>
                                                <dt>
                                                    {t("modules.promotion.field.min_positions_amount.title")}
                                                </dt>
                                                <dd>{promotion.min_condition_quantity}</dd>
                                            </>
                                        )}
                                        {(promotion.conditions && promotion.conditions.length > 0) && (
                                            <>
                                                <dt>
                                                    {t("modules.promotion.field.required_positions.title")}
                                                </dt>
                                                <dd>
                                                    <ul>
                                                        {getConditions(promotion.conditions).map((condition: any, index: any) => {
                                                            return (
                                                                <li>
                                                                    {condition}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                </ul>
                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    categories: getCategories(state.menu),
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(PromotionInfoModalComponent));
