import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useNavigate} from "react-router-dom";
import DateService from "../../../../services/Common/Date/DateService";
import AvailableHelper from "../../../../services/Menu/services/Availability/AvailableHelper";
import {getAvailabilities} from "../../../../services/Menu/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import MenuHelper from "../../../../services/Menu/services/MenuHelper";
import {RootState} from "../../../../../reducers";
import {getSession} from "../../../../services/Location/reducer";
import AppConfig from "../../../../services/AppConfig";
import {usePrefix} from "../../../../services/Prefix";

interface OwnProps {
    menus: any[],
    menu_id?: number,
    onSearch: (value?: string) => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuMenusFilterComponent = (props: Props) => {
    const prefix = usePrefix();
    const {availabilities} = props;
    const [open, setOpen] = useState(false);
    let {t} = useTranslation();
    let menus = [...props.menus].sort((a, b) => a.name.localeCompare(b.name));
    let selectedMenuId = props.menu_id;
    const navigate = useNavigate();

    const changeMenu = (menuId: string) => {
        navigate(AppConfig.getLinkWithPrefix("/menu/" + menuId, prefix))
    }
    const renderMenuItem = (menu: any, selectedMenuId: any) => {
        let orderTypeMenuInfo = undefined;
        let menuNotAvailableNow = undefined;
        let menuErrorString = undefined;
        let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(props.session.pickup_at));

        let menuErrors = MenuHelper.getMenuErrors(menu, props.session.order_type, props.session.pickup_at, availabilities);
        menuErrors.forEach(menuError => {
            switch (menuError) {
                case "unavailable_type":
                    let menuTypes = [];
                    if (menu.delivery) menuTypes.push(t("enums.order.type.DELIVERY"));
                    if (menu.dine_in) menuTypes.push(t("enums.order.type.DINE_IN"));
                    if (menu.takeaway) menuTypes.push(t("enums.order.type.PICK_UP"));
                    if (menu.room_service) menuTypes.push(t("enums.order.type.ROOM_SERVICE"));
                    orderTypeMenuInfo = (
                        <small className="nav-link-error">
                            {t("modules.menu.field.available_types.title")}: {menuTypes.join(", ")}
                        </small>
                    )
                    menuErrorString = `${t("modules.menu.field.available_types.title")}: ${menuTypes.join(", ")}`
                    break;
                case "unavailable_pickup_date":
                    menuNotAvailableNow = (
                        <small className="nav-link-error">{t("constraints.internal.unavailable_pickup_date")}</small>
                    )
                    menuErrorString = `${t("constraints.internal.unavailable_pickup_date")}`
                    break;
                case "unavailable_date":
                    menuNotAvailableNow = (
                        <small
                            className="nav-link-error">{props.session.pickup_at ? t("constraints.internal.unavailable_pickup_date") : t("constraints.internal.unavailable_today_pickup_date")}</small>
                    )
                    menuErrorString = `${props.session.pickup_at ? t("constraints.internal.unavailable_pickup_date") : t("constraints.internal.unavailable_today_pickup_date")}`
                    break;
                case "unavailable_time":
                    menuNotAvailableNow = (
                        <small
                            className="nav-link-error">{props.session.pickup_at ? t("constraints.internal.unavailable_pickup_time") : t("constraints.internal.unavailable_now")}</small>
                    )
                    menuErrorString = `${props.session.pickup_at ? t("constraints.internal.unavailable_pickup_time") : t("constraints.internal.unavailable_now")}`
                    break;
            }
        })
        let hours = undefined;
        if (openHours && openHours.length > 0) {
            hours = openHours.map((openHour) => {
                    return `${openHour.hour_from} - ${openHour.hour_to}`
                }
            )
        }
        return (
            <button key={menu.id} className={"btn btn-default me-2" + (selectedMenuId === menu.id ? ' active' : '')}
                    onClick={() => changeMenu(menu.id)}>
                {menu.translation.name}
                {hours && (
                    <>
                        {" "}({hours.join(", ")})
                    </>
                )}
                {menuErrorString && (
                    <>
                        {" "}
                        <span className={"badge badge-danger-light"}>{menuErrorString}</span>
                    </>
                )}
                {/*{menuNotAvailableNow}*/}
                {orderTypeMenuInfo}
            </button>
        )
    }
    useEffect(() => {
        if (open) {
            setOpen(false);
        }
    }, [selectedMenuId])

    return (
        <div className={"container-fluid"}>
            <div className="list-nowraps menu-filters">
                {menus.map((menu) => {
                        return renderMenuItem(menu, selectedMenuId)
                    }
                )}
            </div>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    availabilities: getAvailabilities(state.menu)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuMenusFilterComponent));
