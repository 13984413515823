import React from 'react';
import IframeWidget from "./IframeWidget";
import AppConfig from "../services/AppConfig";

const ScrollMenuTo = (value: any) => {

    let sectionMargin = 30;
    if (AppConfig.isWidget()) {
        sectionMargin = 90;
    }

    function scrollTo(elementId: any, e: any) {
        e.preventDefault();
        let element = document.getElementById(elementId);
        if (element !== null) {
            let scrollToValue = element.offsetTop - sectionMargin;
            if (AppConfig.isWidget()) {
                IframeWidget.scrollTo(scrollToValue);
            } else {
                if (value.isFirstElement) {
                    window.scrollTo({top: scrollToValue, left: 0, behavior: 'smooth'});
                }
                else {
                    window.scrollTo({top: scrollToValue, behavior: 'smooth'});
                }

            }
        }
    }

    return (
        <a href="#" className="nav-link" onClick={(e) => scrollTo(value.to, e)}>
            {value.children}
        </a>
    )
}
export default ScrollMenuTo;

