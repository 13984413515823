import React from 'react';

interface Props {
    icon: JSX.Element;
	size?: 'large' | "medium";
	className?: string;
}

const RoundedIcon = ({ icon, size, className }: Props) => {
	return (
		<div className={`rounded-icon ${size ? size : "large"} ${className ?? ""}`}>
            {icon}
		</div>
	)
}

export default RoundedIcon;