import React, {FC} from 'react';
import {connect, DispatchProp} from "react-redux";
import {Route, Routes} from "react-router-dom";
import {RootState} from "../../../reducers";
import {getStoreDefaultConfig, getStoreState} from "../../services/Store/reducer";
import Navbar from "../../components/Navbar/Navbar";
import LoginPage from "./LoginPage";
import Footer from "../../components/layout/Footer/Footer";
import AppConfig from "../../services/AppConfig";
import NavbarMulti from "../../components/Navbar/NavbarMulti";

type Props = ReturnType<typeof mapStateToProps> & DispatchProp;
const LoginLayout: FC<Props> = (props) => {
    return (
        <>
            {AppConfig.isMultistore() ? (
                <NavbarMulti config={props.default_config}/>
            ) : (
                <Navbar/>
            )}
            <main>
                <Routes>
                    <Route path="*" element={<LoginPage/>}/>
                </Routes>
            </main>
            {AppConfig.isMultistore() ? (
                <Footer config={props.default_config}/>
            ) : (
                <Footer config={props.store_state.config}/>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    store_state: getStoreState(state.config),
    default_config: getStoreDefaultConfig(state.config),
})

const connector = connect(mapStateToProps);

export default connector(LoginLayout);