import React from "react";

interface QueryParamsProps {
    lang?:string;
    username?:string;
    password?:string;
    city?:string;
    street?:string;
    build_nr?:string;
    flat_nr?:string;
    user_token?:string;
    ac?:string;
    order_type?:string;
    dark_mode?:string;
}
class Utils {

    static getQueryParams(qs:any) {
        qs = qs.split('+').join(' ');

        var params = {} as any,
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params as QueryParamsProps;
    }

    static getUrlForLocale(newLocale:string, pathname:string, queryParams = {}) {
        // Define a regular expression to match the locale in the pathname
        const localeRegex = /^\/([a-z]{2})\//i;
        let newPathname = pathname;

        // Check if the pathname contains a locale
        if (localeRegex.test(pathname)) {
            // Replace the existing locale with the new locale
            newPathname = pathname.replace(localeRegex, newLocale ? `/${newLocale}/` : '/');
        } else {
            // Add the new locale if one is provided
            newPathname = newLocale ? `/${newLocale}${pathname}` : pathname;
        }

        // Construct query parameters string
        const queryString = new URLSearchParams(queryParams).toString();
        if (queryString) {
            newPathname += `?${queryString}`;
        }

        return newPathname;
    }
    static removeQueryParam(keys: string | string[]) {
        // Get the current URL and query parameters
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;

        // Remove the specified keys
        if (Array.isArray(keys)) {
            keys.forEach((key) => searchParams.delete(key));
        } else {
            searchParams.delete(keys);
        }

        // Update the URL
        if ([...searchParams].length === 0) {
            // If no params remain, remove the query string entirely
            window.history.replaceState({}, document.title, url.pathname);
        } else {
            // Otherwise, update the URL with the remaining params
            window.history.replaceState({}, document.title, `${url.pathname}?${searchParams.toString()}`);
        }
    }
    // static removeQueryParam(keys:any, history : any) {
    //     const searchParams = new URLSearchParams(window.location.search);
    //     if(Array.isArray(keys)){
    //         keys.forEach(key => searchParams.delete(key));
    //     }else{
    //         searchParams.delete(keys);
    //     }
    //     history.replace({search: searchParams.toString()});
    // }
    static parseToUrl(params: QueryParamsProps){
        const queryString = Object.entries(params)
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`)
            .join('&');
        return queryString;
    }
    static nl2br(text:any, skipLastNewLine?: boolean) {
        if(!text) return null;

        const textParts = text.split('\n');
        return textParts.map((item:any, key:any) => {
            const renderInNewLine = skipLastNewLine === undefined ? true : key === textParts.length - 1 ? !skipLastNewLine : true;
            return <React.Fragment key={key}>
                {item}{renderInNewLine ? <br/> : <></>}
            </React.Fragment>
        });
    }
    static nl2brString(text:any, skipLastNewLine?: boolean){
        if(!text) return null;

        const textParts = text.split('\n');
        return text.split('\n').map((item:any, key:any) => {
            const renderInNewLine = skipLastNewLine === undefined ? true : key === textParts.length - 1 ? !skipLastNewLine : true;

            if (renderInNewLine)  return `${item}<br/>`;
            return `${item}`;
        }).join("");
    }
    static timeout(delay:any) {
        return new Promise( res => setTimeout(res, delay) );
    }
    static countries(countriesValue:any){
        if(!countriesValue) return [];
        let countries = undefined;
        if(Array.isArray(countriesValue)){
            countries = countriesValue;
        }else{
            countries = countriesValue.split(",");
        }
        // let codes = [];
        return countries.map((x:any) => {
            if(x.includes(":")){
                return {
                    key: x.split(":")[0],
                    value: x.split(":")[1],
                };
            }
            return {
                key: x,
                value: x
            };
        })
    }
    static zeroIfNull(value:any){
        return value ?? 0;
    }

    static calculateDistance(latitude1: number, longitude1: number, latitude2: number, longitude2: number) {

        const toRadian = (n: number) => (n * Math.PI) / 180

        let lat2 = latitude2
        let lon2 = longitude2
        let lat1 = latitude1
        let lon1 = longitude1

        // console.log(lat1, lon1+"==="+lat2, lon2)
        let R = 6371  // km
        let x1 = lat2 - lat1
        let dLat = toRadian(x1)
        let x2 = lon2 - lon1
        let dLon = toRadian(x2)
        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        let d = R * c
        // console.log("coordinates==?",lat1, lon1, lat2, lon2)
        // console.log("distance==?",d)
        return d;
    }
    static getSubdomainFromHostname(hostname: string): string | null {
        // Split the hostname into parts
        const parts = hostname.split('.');

        // For a domain like "example.com", there are only two parts and no subdomain.
        if (parts.length <= 2) {
            return null;
        }

        // Assume the last two parts are the primary domain and TLD,
        // and everything before that is the subdomain.
        const subdomainParts = parts.slice(0, parts.length - 2);
        return subdomainParts.join('.');
    }
}
export default Utils;
