interface Props {
    value: any
}

const FormattedPhone = (props: Props) => {
    let value = props.value;

    if (value.length === 11) {
        if(value.startsWith("48")){
            value = value.substring(2);
        }else{
            return `+${value.slice(0, 2)} ${value.slice(2, 5)} ${value.slice(5, 8)} ${value.slice(8)}`;
        }
    }
    if (value.length === 9) {
        return `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
    }
    return value
}

export default FormattedPhone;