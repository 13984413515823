import {parseDate} from "./DateHelper";

class DateService {

    static serverDate?:any = undefined;
    static dateNow?:Date = undefined;
    static init(serverDate:any){
        // this.serverDate = serverDate && serverDate instanceof Date ? serverDate : parseDate(serverDate);
        this.serverDate = serverDate ? serverDate instanceof Date ? serverDate : new Date(serverDate) : undefined;
        this.dateNow = new Date();
    }
    static now(){
        if(!this.serverDate){
            return new Date();
        }
        var difference = new Date().getTime() - (this.dateNow?.getTime() || 0);
        return new Date(this.serverDate.getTime() + difference);
    }
    static emptyOrNow(date:any){
        // return date !== null && date ? new Date(date) : this.now();
        let das=  (date !== null && date !== undefined) && date ? parseDate(date) : this.now();
        return das;
    }

}
export default DateService;