import React, {ReactNode} from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {
    getAvailabilities,
    getItems,
    getMenus,
    getModifierGroups,
    getPromotions
} from "../../../../services/Menu/reducer";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import DateService from "../../../../services/Common/Date/DateService";
import AvailableHelper from "../../../../services/Menu/services/Availability/AvailableHelper";
import Utils from "../../../../utils/Utils";
import {useTranslation, withTranslation} from "react-i18next";
import PromotionHelper from "../../../../services/Menu/services/Promotion/PromotionHelper";
import {ReactComponent as StarIco} from "../../../../../images/icons/star.svg";
import {RootState} from "../../../../../reducers";
import MenuEditItemViewHelper from "../../../../services/Menu/services/ItemView/MenuEditItemViewHelper";
import Money from "../../../../components/Common/Money";
import LoadingContainer from "../../../../components/Common/LoadingContainer";
import ErrorFormAlertView from "../../../../components/Common/ErrorFormAlertView";
import MenuModifierGroupsPickComponent from "./MenuModifierGroupsPickComponent";
import MenuModifierGroupsComponent from "./MenuModifierGroupsComponent";
import {getSession} from "../../../../services/Location/reducer";
import {getOrderPending} from "../../../../services/Cart/reducer";
import {ReactComponent as PlusSVG} from "../../../../../images/icons/plus.svg";
import {ReactComponent as MinusSVG} from "../../../../../images/icons/minus.svg";
import MenuAvailabilityDateButton from "../MenuAvailability/MenuAvailabilityDateButton";
import {getStoreConfig} from "../../../../services/Store/reducer";


interface OwnProps {
    errors: any,
    stateItem: any,
    menuId: any,
    id: any,
    addItem: (menuId: any, newItem: any) => void,
    onUpdate: (stateItem: any) => void,
    onChangePage: (name: any, data?: any, render?: any) => void,
    parent: any,
    children?: ReactNode
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuEditItemVariantComponent = (props: Props) => {
    let errors = props.errors;
    let stateItem = props.stateItem;
    const {
        items,
        modifier_groups,
        menus,
        availabilities
    } = props;
    const {t} = useTranslation();
    const pending = props.cartPending;

    const addQuantity = () => {
        setQuantity(stateItem.quantity + 1);
    };
    const subQuantity = () => {
        setQuantity(stateItem.quantity - 1);
    };
    const addItemInternal = () => {
        let menuId = parseInt(props.menuId);
        let newItem = stateItem;
        props.addItem(menuId, newItem);
    }
    const scrollToSection = () => {
        const headerOffset = 80; // Adjust this value based on your desired offset
        const modals = document.querySelectorAll('.modal');
        if (modals.length > 0) {
            const lastModal = modals[modals.length - 1];
            const errorElement = lastModal.querySelector('.error');
            if (errorElement) {
                const targetTop = errorElement.getBoundingClientRect().top + lastModal.scrollTop - headerOffset;
                lastModal.scrollTo({
                    top: targetTop,
                    behavior: 'smooth'
                });
            }
        }
    };
    const updateState = () => {
        props.onUpdate(stateItem);
    }
    const handleChangeComment = (newComment: string) => {
        props.onUpdate({
            ...stateItem,
            comment: newComment
        });
    }

    const setQuantity = (quantity: any) => {
        let newQuantity = quantity;
        if (newQuantity <= 0) {
            newQuantity = 1;
        }
        stateItem.quantity = newQuantity;
        updateState();
    }

    const getItemView = (items: any, modiferGroups: any, availabilities: any, id: any, parentItem: any, parentModifierGroup: any, level: any, pick_date: any, order_type?: string) => {
        return MenuEditItemViewHelper.getItemView(items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date, stateItem, order_type);
    }
    const validateState = (itemView: any, availabilities: any, pick_date: any, lvl?: any) => {
        return MenuEditItemViewHelper.validateState(itemView, availabilities, pick_date, lvl, t);
    }
    const renderPromotions = () => {
        let promotions = PromotionHelper.getForItem(props.promotions, stateItem.item_id);
        if (promotions === undefined || promotions.length <= 0) return null;
        return (
            <div className={"promotions"}>
                {promotions.map((promotion: any) => {
                    return (
                        <div className={"btn btn-promotion"}>
                            <div className={"star"}>
                                <StarIco/>
                            </div>
                            <div className={"content"}>
                                {promotion.name}
                                <small>
                                    {`${t("modules.menu.field.min_cart_value.title")}: `} <Money value={promotion.min_basket_money}/>
                                </small>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    let id = parseInt(props.id);
    let menuId = parseInt(props.menuId);
    let itemView = getItemView(items, modifier_groups, availabilities, id, undefined, undefined, 1, props.session.pickup_at, props.session.order_type);
    if (!itemView) {
        return (
            <LoadingContainer/>
        )
    }

    let isValid = validateState(itemView, availabilities, props.session.pickup_at);

    let isMenuAvailabile = true;
    let errorView = null;
    if (!props.parent) {
        let menusById = menus.filter((menu: any) => menu.id === menuId);
        if (menusById.length > 0) {
            let menu = menusById[0];
            let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id)
            if (!AvailableHelper.isAvailabile(availability, DateService.emptyOrNow(props.session.pickup_at))) {
                if (!AvailableHelper.isAvailabileDate(availability, DateService.emptyOrNow(props.session.pickup_at))) {
                    let pickDateText = "";
                    if (menu.pick_date) {
                        pickDateText = props.session.pickup_at ? t("constraints.internal.item_unavailable_pickup_date") : t("constraints.internal.unavailable_today_pickup_date");
                    } else {
                        pickDateText = props.session.pickup_at ? t("constraints.internal.item_unavailable_pickup_date") : t("constraints.internal.item_unavailable_today");
                    }
                    errorView = (
                        <div className="alert alert-warning">
                            <div className="d-flex flex-column">
                                {pickDateText}
                                {props.config?.ordering.pick_date && (
                                    <MenuAvailabilityDateButton menu={menu} />
                                )}
                            </div>
                        </div>
                    )
                } else {
                    isMenuAvailabile = false;
                    let todayOpenHours = ItemHelper.getTodayOpenHoursString(menusById[0].availability, DateService.emptyOrNow(props.session.pickup_at));
                    let todayOpenHoursView = null;
                    if (todayOpenHours) {
                        todayOpenHoursView = t("constraints.internal.item_available_time") + ": " + todayOpenHours
                    } else {
                        todayOpenHoursView = t("constraints.internal.item_unavailable_today");
                    }
                    errorView = (
                        <div className="alert alert-warning">
                            <div className="d-flex flex-column">
                                {todayOpenHoursView}
                                {props.config?.ordering.pick_date && (
                                    <MenuAvailabilityDateButton menu={menu} />
                                )}
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    let disabled = pending || !isValid || !isMenuAvailabile || itemView.disabled || !props.session.order_type;
    let loading = pending;

    const isPickModifierGroupView = (modifierGroup: any) => {
        if (props.parent) return false;
        let exist = modifierGroup.options.some((option: any) => option.modifier_groups.length > 0);
        return exist;
    }
    let anyPickModifierGroup = itemView.modifier_groups.some((x: any) => isPickModifierGroupView(x));
    const renderModifiers = () => {
        if (itemView.modifier_groups.length <= 0) return null;
        return (
            <div className="product-item-modifiers">
                {
                    itemView.modifier_groups.sort((a: any, b: any) => a.position - b.position || a.name.localeCompare(b.name)).map((modifierGroup: any) => {
                            if (anyPickModifierGroup) {
                                return (
                                    <MenuModifierGroupsPickComponent onChangePage={props.onChangePage} stateItem={stateItem}
                                                                     onUpdate={props.onUpdate}
                                                                     modifierGroup={modifierGroup}
                                                                     itemView={itemView}
                                                                     key={modifierGroup.id}></MenuModifierGroupsPickComponent>
                                )
                            }
                            return (
                                <MenuModifierGroupsComponent onUpdate={updateState}
                                                             stateItem={stateItem}
                                                             modifierGroup={modifierGroup}
                                                             key={modifierGroup.id}></MenuModifierGroupsComponent>
                            )
                        }
                    )
                }
            </div>
        )
    }

    return (
        <>
            <div className="product-item">
                <div className={"product-item-content"}>
                    {itemView.content
                        ? <p className="product-item-description"
                             dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.content)}}></p>
                        : itemView.description
                            ? <p className="product-item-description"
                                 dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.description)}}></p>
                            : null
                    }
                    {errorView}
                    {props.children}
                    {(!props.session.order_type) && (
                        <ErrorFormAlertView errors={[t("constraints.internal.order_type_required")]}/>
                    )}
                    {(itemView.disabled) && (
                        <ErrorFormAlertView errors={t("constraints.internal.item_not_available")}/>
                    )}
                    {!props.parent && renderPromotions()}

                    <>{renderModifiers()}</>
                    {!props.parent && (
                        <div>
                            <div className="form-group ">
                                <input type="text" value={props.stateItem.comment}
                                       onChange={(e) => handleChangeComment(e.target.value)}
                                       className={"form-control"}
                                       id="name"
                                       placeholder={t("modules.menu.field.position_comment.title")}/>
                                <label
                                    htmlFor="name">
                                    {t("modules.menu.field.position_comment.title")}
                                </label>
                            </div>
                        </div>
                    )}
                </div>

                <>
                    <div className="product-item-footer row">
                        <ErrorFormAlertView errors={errors}/>
                        <div className="btn-group-submit">
                            {!props.parent && (
                                <div className="input-group input-group-quantity">
                                    <div className="input-group-prepend">
                                        <button type="button" className="btn btn-default btn-icon"
                                                onClick={subQuantity}>
                                            <MinusSVG/>
                                        </button>
                                    </div>
                                    <span className="input-group-text">{stateItem.quantity}</span>
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-default btn-icon" onClick={addQuantity}>
                                            <PlusSVG/>
                                        </button>
                                    </div>
                                </div>
                            )}


                            <button
                                className={`btn-spinner w-100 btn-primary btn btn-submit btn-submit-fixed-bottom ${loading ? " loading" : ""}${disabled ? " disabled-view" : ""}`}
                                disabled={false} onClick={() => {
                                if (disabled) {
                                    scrollToSection()
                                    return;
                                }
                                addItemInternal()
                            }}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {!props.parent ? (
                                    <>{t("common.action.add_item", {quantity: stateItem.quantity})}</>
                                ) : (
                                    <>{t("common.action.save")}</>
                                )}
                                <div className="btn-cart-price">
                                    {stateItem.discount_price !== undefined ? (
                                        <div className={"prices"}>
                                         <span className="price text-decoration-line-through">
                                             <Money
                                                 value={stateItem.total_price}
                                                 currency='PLN'/>
                                         </span>
                                            <span className="price price-discount ms-1">
                                             <Money
                                                 value={stateItem.discount_price}
                                                 currency='PLN'/>
                                         </span>
                                        </div>
                                    ) : (

                                        <span className="price">
                                            <Money
                                                value={stateItem.total_price}
                                                currency='PLN'/>
                                        </span>
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>
                </>
            </div>
        </>
    )

}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    menus: getMenus(state.menu),
    modifier_groups: getModifierGroups(state.menu),
    availabilities: getAvailabilities(state.menu),
    session: getSession(state.session),
    config: getStoreConfig(state.config),
    promotions: getPromotions(state.menu),
    cartPending: getOrderPending(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuEditItemVariantComponent));