import StorageStore from "../Common/Storage/storageStore";
import AppConfig from "../AppConfig";

class CartContext {
    static version?: string = undefined;
    static orderId?: string = undefined;
    static orderToken?: string = undefined;

    static parseKey(key: string) {
        return AppConfig.placeId ? `${key}_${AppConfig.placeId}` : key;
    }

    static initialize() {
        let pathname = window.location.pathname;
        let pathanemSplit = pathname.split("/");
        let startVariableParse = false;
        let i = 0;
        pathanemSplit.forEach(pathVar => {
            if (startVariableParse) {
                if (i == 0) {
                    this.orderId = pathVar;
                    i++;
                } else if (i == 1) {
                    this.orderToken = pathVar;
                    i++;
                }
            }
            if (pathVar == 'order') {
                startVariableParse = true;
            }
        })
    }

    static getOrderId() {
        if (this.orderId && AppConfig.version() === this.version) {
            return this.orderId;
        }
        return StorageStore.getItem(this.parseKey("order_id"));
    }

    static cleanOrder() {
        this.orderId = undefined;
        this.orderToken = undefined;
        StorageStore.removeItem(this.parseKey('order_id'));
        StorageStore.removeItem(this.parseKey('order_token'));
    }

    static setOrderId(id: string) {
        this.orderId = id;
        this.version = AppConfig.version();
        StorageStore.setItem(this.parseKey('order_id'), id);
    }

    static setOrderToken(token: string) {
        this.orderToken = token;
        this.version = AppConfig.version();
        StorageStore.setItem(this.parseKey('order_token'), token);
    }

    static getOrderToken() {
        if (this.orderToken && AppConfig.version() === this.version) {
            return this.orderToken;
        }
        return StorageStore.getItem(this.parseKey("order_token"));
    }
}


export default CartContext;