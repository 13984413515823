import React, {useState} from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {acceptRules} from "../../services/Cart/actions";
import {getAvailabilities, getCustomFields} from "../../services/Menu/reducer";
import {RootState} from "../../../reducers";
import {useTranslation, withTranslation} from "react-i18next";
import ErrorFormView from "../Common/ErrorFormView";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import {getStoreConfig, getStoreLayoutComponentsConfig} from "../../services/Store/reducer";
import {Link} from "react-router-dom";
import {StoreConfigRulesApi} from "../../services/Api/types";
import Utils from "../../utils/Utils";
import StringHelper from "../../services/Common/StringHelper";
import {getCart} from "../../services/Cart/reducer";

interface OwnProps {
    errors: any[]
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartRulesComponent = (props: Props) => {
    const [customFields, setCustomFields] = useState<any[]>([]);
    const cart = props.cart;
    const rules = props.config?.ordering.rules;
    const {t} = useTranslation();

    const updateRuleAccepted = (linkId: any, e: any) => {
        const target = e.target;
        const value = target.checked;
        let newAcceptedRules = [];
        cart.accepted_rules.forEach((acceptedRule: any) => {
            if (linkId !== acceptedRule) {
                newAcceptedRules.push(acceptedRule);
            }
        });
        if (value) {
            newAcceptedRules.push(linkId);
        }

        props.dispatch(acceptRules(newAcceptedRules));
    }

    const updateCustomFieldValue = (slug: any, value: any) => {
        let newCustomFields = customFields as any[];

        let customFieldObj = customFields.filter((x: any) => x.slug == slug)[0] as any;
        if (customFieldObj === undefined) {
            customFieldObj = {
                slug: slug,
                value: value
            } as any
            newCustomFields.push(customFieldObj);
        } else {
            customFieldObj.value = value;
        }

        setCustomFields(newCustomFields);

        cart.custom_fields[slug] = value;
    }

    const pageComponents = props.configComponents ? props.configComponents.filter(x => x.placement == "before_rules") : [];

    let defaultMarketingContent = () => {
        let company = props.config?.company;
        let companyName = null;
        let addressView = null;
        if (company) {
            companyName = company?.legal_name;
            if (company.address) {
                addressView = StringHelper.getAddressName(company.address);
            }
        }
        return (
            <>
                {t("modules.cart.field.consent_commercial_information.title", {companyName, addressView})}
            </>
        )
    }


    let defaultRulesContent = (withLinks: boolean) => {
        return (
            <>
                {t("modules.cart.field.accept_rules.title")}
                {withLinks && (
                    <>
                        (<Link to={"rules"} target="_blank">
                            {t("modules.cart.field.policy_privacy_and_rules.title")}
                        </Link>)
                    </>
                )}
            </>
        )
    }
    const renderLinks = (rule: StoreConfigRulesApi) => {
        if (!rule.link) return null;
        if (rule.link == 'rules') {
            return (
                <Link to={`/${rule.link}`} target="_blank">
                    {t("modules.cart.field.link.title")}
                </Link>
            )
        }
        let links = [];
        let storeLinks = rule.link.split("|");
        if (storeLinks.length > 1) {
            storeLinks.forEach(storeLink => {
                let storeLinkSplit = storeLink.split("::");
                if (storeLinkSplit.length > 1) {
                    links.push({
                        "title": storeLinkSplit[0],
                        "link": storeLinkSplit[1]
                    })
                } else {
                    links.push({
                        "title": "Link",
                        "link": storeLinkSplit[0]
                    })
                }
            });
        } else {
            links.push({
                "title": t("modules.cart.field.link.title"),
                "link": rule.link
            })
        }
        return (
            <>
                {links.map((link, index) => {
                    return (
                        <React.Fragment key={link.link}>
                            <a href={link.link} target="_blank">{link.title}</a>{index < links.length - 1 && ", "}
                        </React.Fragment>
                    )
                })}
            </>
        )
    }
    const renderRules = (rule: StoreConfigRulesApi) => {
        const type = rule.type;
        let field = "custom_rules_" + rule.slug;
        const id = 'rules-link-' + rule.slug;
        let ruleId = rule.slug;
        let content = undefined as undefined | any;
        switch (type) {
            case "rules_and_privacy":
            case "RULES":
                field = "acceptedRules";
                if (rule.slug.includes("link_")) {
                    ruleId = rule.slug.replaceAll("link_", "");
                }
                if (!rule.content) {
                    content = defaultRulesContent(!rule.link);
                }
                break;
            case "marketing":
                field = "acceptMarketing";
                if (!rule.content) {
                    content = defaultMarketingContent();
                }
                break;
        }
        const value = cart.accepted_rules.includes(ruleId);
        return (
            <div className="form-check" key={rule.slug}>
                <input checked={value}
                       onChange={(e) => updateRuleAccepted(ruleId, e)}
                       className={"form-check-input" + ErrorFormHelper(props.errors, field)}
                       type="checkbox" value="1" id={id}/>
                {content ? (
                    <label className={"form-check-label"}
                           htmlFor={id}>{content} {renderLinks(rule)}</label>
                ) : (
                    <label className={"form-check-label"}
                           htmlFor={id}
                    >
                        <span dangerouslySetInnerHTML={{__html: `${Utils.nl2brString(rule.content)}`}}></span>
                        {renderLinks(rule)}
                    </label>
                )}
                <ErrorFormView errors={props.errors} field="acceptedRules"/>
            </div>
        );
    }

    return (
        <>
            {(pageComponents && pageComponents.length > 0) && (
                <>
                    {pageComponents.map((pageComponent) => <>
                        <div key={pageComponent.slug} dangerouslySetInnerHTML={{__html: pageComponent.content}}/>
                    </>)}
                    <hr/>
                </>
            )}
            {rules && (
                <>
                    {
                        rules.map((rule) => {
                                return renderRules(rule);
                            }
                        )
                    }
                </>
            )}
        </>
    );
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    custom_fields: getCustomFields(state.menu),
    availabilities: getAvailabilities(state.menu),
    configComponents: getStoreLayoutComponentsConfig(state.config),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartRulesComponent));