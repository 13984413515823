import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as GiftSVG} from "../../../../../images/icons/gift-solid.svg";
import {ReactComponent as CalendarSVG} from "../../../../../images/icons/calendar.svg";
import {RootState} from "../../../../../reducers";
import VoucherProductConfirmationModal from "./VoucherProductConfirmationModal";
import {VoucherApi} from "../../../../services/Api/types";

interface OwnProps {
    onHide: () => void,
    setPage: (page?: string) => void,
    product: any,
    onSuccess: (voucher?: VoucherApi) => void;
    onSuccessUserModal?: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function VoucherProductComponent(props: Props) {
    const [modal, setModal] = useState(false);
    const product = props.product;
    const {t} = useTranslation();

    const isAvailableVoucher = (voucher: any) => {
        return true;
    }
    const onSuccess = (voucher?: VoucherApi) => {
        setModal(false);
        props.onSuccess(voucher)
    }

    return (
        <>
            {modal && (
                <VoucherProductConfirmationModal product={product} onSuccess={onSuccess} onHide={() => setModal(false)} onSuccessUserModal={props.onSuccessUserModal}/>
            )}
            <div
                className={"voucher-card flex-fill " + (!isAvailableVoucher(product) ? ' disabled' : '')}
                onClick={(e) => setModal(true)}>

                <div className={"card-content"}>
                    <div className="card-body">
                        <div className={"card-img"}>
                            {product.image ? (
                                <img src={product.image}
                                     className="card-img-top" alt="..."/>
                            ) : (
                                    <GiftSVG/>
                            )}
                        </div>
                        <div className={"card-text-content"}>
                            <h5 className="card-title">{product.name}</h5>
                            <p className="card-text">{product.description}</p>
                        </div>
                    </div>
                    <div className="card-footer">
                        <span className={"valid-time"}>
                            {/*<CalendarSVG /> {`${t("modules.voucher.field.valid_to.title")}: 12.01.25`}*/}
                        </span>
                        <button className="btn ">
                            {product.cost ? `${t("modules.voucher.action.buy.title")} ${product.cost} pkt` : t("modules.voucher.action.collect_for_free.title")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(VoucherProductComponent));

