import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import MenuShowItemSingleComponent from "../Item/MenuShowItemSingleComponent";
import IframeWidget from "../../../../utils/IframeWidget";
import ItemHelper from "../../../../services/Menu/services/Item/ItemHelper";
import MenuEditItemStateHelper from "../../../../services/Menu/services/State/MenuEditItemStateHelper";
import {getItemGroups, getItems} from "../../../../services/Menu/reducer";


interface OwnProps {
    orderItem: any,
    onHide: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuEditItemModalComponent = (props: Props) => {
    const {t} = useTranslation();
    let id = props.orderItem.item_id;
    let item = ItemHelper.getItem(props.items, id);
    if (!item) {
        return null;
    }
    let productImgView = null;
    if (item.image_link) {
        productImgView = (
            <div className="product-item-img">
                <img src={item.image_link.default}/>
            </div>
        )
    }
    let itemGroup = ItemHelper.getItemGroup(props.itemGroups, item.id);
    let stateItem = MenuEditItemStateHelper.parseOrderItemToStateItem(props.orderItem);

    return (
        <Modal dialogClassName={"modal-full modal-product modal-product-full"} show={true}
               onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow()}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        {productImgView}
                    </div>
                    <div className={"col-md-6 modal-product-full-right"}>
                        <MenuShowItemSingleComponent itemId={item.id} itemGroup={itemGroup} onUpdate={() => IframeWidget.onHide(props.onHide)}
                                                     stateItem={stateItem}
                                                     orderItemId={props.orderItem.id}
                                                     onHide={() => IframeWidget.onHide(props.onHide)}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = (state: RootState) => ({
    items: getItems(state.menu),
    itemGroups: getItemGroups(state.menu)
})

const connector = connect(mapStateToProps);

export default connector(MenuEditItemModalComponent);
