import ItemHelper from "./ItemHelper";
import DateService from "../../../Common/Date/DateService";
import PromotionHelper from "../Promotion/PromotionHelper";

class ItemViewHelper {

    static parseItemGroup(itemGroup: any, items: any, availabilities: any, promotions: any, pick_date: any, position: any, cart: any, orderType?: string) {
        let sortedItems = [...itemGroup.items].sort((a: any, b: any) => a.position - b.position);
        let firstItemId = sortedItems[0].item_id;
        let firstItem = items.filter((item: any) => item.id == firstItemId)[0];
        let itemGroupsItems = itemGroup.items;
        if (!firstItem) {
            sortedItems.forEach((sortedItem: any) => {
                if (!firstItem) {
                    firstItem = items.filter((item: any) => item.id == sortedItem.item_id)[0];
                }
            })
        }

        return {
            "id": itemGroup.reference_id,
            "reference_id": itemGroup.reference_id,
            "name": itemGroup.translation ? itemGroup.translation.name : itemGroup.name,
            "item": this.parseItem(firstItem, availabilities, promotions, pick_date, position, cart, orderType),
            "items": itemGroupsItems,
            "type": "ITEM_GROUP",
            "position": position
        }
    }

    static parseItem(item: any, availabilities: any, promotions: any, pick_date: any, position: any, cart?: any, orderType?: string) {
        if (item == undefined) {
            return;
        }
        let disabled = !ItemHelper.isAvailabile(item, availabilities, DateService.emptyOrNow(pick_date), orderType);
        const orderTypePrices = {
            DELIVERY: (item.order_types_min_price.DELIVERY && item.order_types_min_price.DELIVERY > 0) ? item.order_types_min_price.DELIVERY : item.order_types_full_price.DELIVERY,
            PICK_UP: (item.order_types_min_price.PICK_UP && item.order_types_min_price.PICK_UP > 0) ? item.order_types_min_price.PICK_UP : item.order_types_full_price.PICK_UP,
            DINE_IN: (item.order_types_min_price.DINE_IN && item.order_types_min_price.DINE_IN > 0) ? item.order_types_min_price.DINE_IN : item.order_types_full_price.DINE_IN,
            ROOM_SERVICE: (item.order_types_min_price.ROOM_SERVICE && item.order_types_min_price.ROOM_SERVICE > 0) ? item.order_types_min_price.ROOM_SERVICE : item.order_types_full_price.ROOM_SERVICE
        }
        let price = (item.min_possible_price && item.min_possible_price > 0) ? item.min_possible_price : item.full_price;
        if(orderType){
            if(orderType === "DELIVERY") price = orderTypePrices.DELIVERY;
            if(orderType === "PICK_UP") price = orderTypePrices.PICK_UP;
            if(orderType === "DINE_IN") price = orderTypePrices.DINE_IN;
            if(orderType === "ROOM_SERVICE") price = orderTypePrices.ROOM_SERVICE;
        }
        let entityItem = {
            id: item.id,
            name: item.translation ? item.translation.name : item.name,
            position: position,
            description: item.translation ? item.translation.description : item.description,
            image_link: item.image_link,
            disabled: disabled,
            price: price,
            // price: (item.min_possible_price && item.min_possible_price > 0) ? item.min_possible_price : item.full_price,
            order_type_prices: orderTypePrices,
            selected_items: item.selected_items
        } as any;
        let discountPrice = this.getDiscountPrice(item.id, 1, item.full_price, promotions, cart, item.price.amount);
        if (discountPrice) {
            entityItem.discount_price = {currency: item.price.currency, amount: discountPrice};
        }
        return entityItem;
    }

    static getDiscountPrice(itemId: any, quantity: any, unitPrice: any, promotions: any, cart: any, unitBasePrice: any) {
        let itemPromotion = PromotionHelper.getForItemWithCart(promotions, itemId, cart).filter((promotion: any) => promotion.type === "ITEM_FIXED_PRICE")[0];
        if (!itemPromotion) return undefined;
        let itemPromotionEntity = itemPromotion.entities.filter((x: any) => x.entity_id == itemId)[0];
        if (!itemPromotionEntity) return undefined;
        if (!itemPromotionEntity.fixed_money) return undefined;
        let diffPrice = unitPrice - unitBasePrice;
        let newPrice = itemPromotionEntity.fixed_money.amount + diffPrice;
        return newPrice + (unitPrice * (quantity - 1));
    }
}


export default ItemViewHelper;