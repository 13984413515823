import axios from "axios";
import {
    CityApi,
    ConfigAddressApi,
    ConfigApi,
    ContactApi,
    ItemResponse,
    LocationCheckApi,
    LoginApi,
    PageApi,
    PlaceApi,
    StoreConfigCoordinatesApi,
    UpdateContactApi,
    VoucherApi
} from "./types";
import {MockApi} from "./Mock/mock-api";
import applyUnauthorizedAppInterceptor from "./applyUnauthorizedAppInterceptor";
import AppConfig from "../AppConfig";

const mockRestApi = axios.create({
    // baseURL: '/api/'
    baseURL: '/data/'
})
let defaultUrl = "https://store.goorder.pl/api/";
const restApi = axios.create({
    baseURL: defaultUrl,
    headers: {
        'Content-Type': 'application/json', // Default to application/json
    },
})

// 'Content-Type': 'application/json',
applyUnauthorizedAppInterceptor(restApi);
let initializedMock = false;
let source: any;


const generateToken = () => {
    if (typeof source != typeof undefined) {
        source.cancel("Operation canceled due to new request.")
    }
    const CancelToken = axios.CancelToken;

    source = CancelToken.source();
    let token = source.token;
    return token;
}

class api {
    static defaultBaseUrl = process.env.REACT_APP_API_DOMAIN + '';
    static placeId: string | undefined = undefined;

    static restoreBaseUrl() {
        restApi.defaults.baseURL = defaultUrl;
    }

    static setBaseUrl(baseUrl: string) {
        if (baseUrl) {
            let newBaseUrl = this.defaultBaseUrl + "/" + baseUrl;
            restApi.defaults.baseURL = newBaseUrl;
            // restApi.defaults.baseURL = "https://demoshop.gopos.pl" + newBaseUrl;
            // restApi.defaults.baseURL = "https://demoorder.gopos.pl" + newBaseUrl.replace("api", "store_api");
            // restApi.defaults.baseURL = "http://shop.goorder.local" + newBaseUrl;
            // restApi.defaults.baseURL = "https://store2.goorder.pl" + newBaseUrl;
        } else {
            let newBaseUrl = this.defaultBaseUrl + "/";
            restApi.defaults.baseURL = newBaseUrl;
        }
    }

    static setPlaceId(id?: string) {
        this.placeId = id;
    }

    static version() {
        return AppConfig.version();
    }

    static async initMock() {
        if (initializedMock) {
            return;
        }
        const configResponse = await mockRestApi.get<ItemResponse<ConfigApi>>("config.json");
        MockApi.init(configResponse.data.data);

        initializedMock = true;
    }


    static async getConfigForPlace(placeId: string, language?: string, signal?: any): Promise<ConfigApi> {
        let params = {
            lang: language
        }
        let headers = {} as any
        headers["place-id"] = placeId;
        const response = await restApi.get<ItemResponse<ConfigApi>>(`config`, {
            params: params,
            headers: headers,
            signal: signal
        });
        const serverDateString = response.headers['date'];
        // const serverDate = new Date(serverDateString);
        let config = response.data.data;
        config.date = serverDateString;
        return response.data.data;
    }

    static async getConfig(language?: string, signal?: any): Promise<ConfigApi> {
        let params = {
            lang: language
        }
        let headers = {} as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.get<ItemResponse<ConfigApi>>(`config`, {
            params: params,
            headers: headers,
            signal: signal
        });
        const serverDateString = response.headers['date'];
        // const serverDate = new Date(serverDateString);
        let config = response.data.data;
        config.date = serverDateString;
        return response.data.data;
    }

    //LOCATION

    static async getLocationCheck(address: ConfigAddressApi, controller?: AbortController): Promise<LocationCheckApi> {
        let params = {} as any;
        if (address !== undefined && address) {
            if (address.city) {
                params.city = address.city
            }
            if (address.street) {
                params.street = address.street
            }
            if (address.build_nr) {
                params.build_nr = address.build_nr
            }
            if (address.country) {
                params.country = address.country
            }
            if (address.zip_code) {
                params.zip_code = address.zip_code
            }
        }
        let headers = {} as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.get<ItemResponse<LocationCheckApi>>(`menus/locations`, {
            params: params,
            signal: controller?.signal,
            headers: headers
        });
        return response.data.data;
    }

    static async getCities(query: string): Promise<CityApi[]> {
        let params = {
            query: query
        } as any;

        let headers = {}
        const response = await restApi.get<ItemResponse<any>>(`config/cities`, {
            params: params,
            headers: headers
        });
        return response.data.data;
    }

    static async getStreets(city: string, query: string): Promise<any> {
        let params = {
            query: query,
            city: city
        } as any;

        let headers = {}
        const response = await restApi.get<ItemResponse<any>>(`menus/streets`, {
            params: params,
            headers: headers
        });
        return response.data.data;
    }

    //MENUS

    static async getMenus(locale?: string, signal?: any): Promise<any> {
        let params = {} as any;
        if (locale) {
            params.locale = locale;
        }

        let headers = {} as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.get<ItemResponse<any>>(`config/menus`, {
            params: params,
            headers: headers,
            signal: signal
        });
        return response.data.data;
    }

    static async getPaymentGateways(id: string): Promise<any> {
        let params = {} as any;

        let headers = {} as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.get<ItemResponse<any>>(`payment/${id}/gateways`, {
            params: params,
            headers: headers
        });
        return response.data.data;
    }

    static async getPromotion(id: string): Promise<any> {
        let params = {} as any;

        let headers = {} as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.get<ItemResponse<any>>(`promotions/${id}?include=conditions`, {
            params: params,
            headers: headers
        });
        return response.data.data;
    }


    //USER

    static async login(username: string, password: string, provider: string): Promise<LoginApi> {
        let data = {
            username: username,
            password: password,
            provider: provider
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`login`, data);
        return response.data.data;
    }

    static async loginAuthorizationCode(code: string): Promise<LoginApi> {
        let data = {
            code: code
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`login/authorization_code`, data);
        return response.data.data;
    }

    static async resetPassword(email: string) {
        let data = {
            email: email,
        }
        await restApi.post<void>(`login/reset-password`, data);
    }

    static async changePassword(email?: string, code?: string, password?: string, confirmPassword?: string) {
        let data = {
            email: email,
            code: code,
            password: password,
            password_confirmation: confirmPassword,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`login/change-password`, data);
        return response.data.data;
    }

    static async sendActivationLink(email?: string) {
        let data = {
            email: email,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`login/activation-link`, data);
        return response.data.data;
    }

    static async registerActivation(email?: string, code?: string): Promise<LoginApi> {
        let data = {
            email: email,
            code: code,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`login/register-activation`, data);
        return response.data.data;
    }

    static async register(email: string, name: string, phone: string, password: string, confirmPassword: string, acceptRules: boolean, acceptMarketingRules: boolean, cardCode?: string, customFields?: Record<string, string | undefined>) {
        let data = {
            email: email,
            password: password,
            name: name,
            phone: phone,
            password_confirmation: confirmPassword,
            accept_rules: acceptRules,
            accept_marketing_rules: acceptMarketingRules,
            card_code: cardCode,
            custom_fields: customFields
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`login/register`, data);
        return response.data.data;
    }

    static async getMe(token: string): Promise<ContactApi> {
        let headers = {
            "user-token": token
        }
        const response = await restApi.get<ItemResponse<ContactApi>>(`login/get`, {headers: headers});
        return response.data.data;
    }

    static async getVouchers(token: string): Promise<VoucherApi[]> {
        let headers = {
            "user-token": token
        }
        const response = await restApi.get<ItemResponse<VoucherApi[]>>(`contacts/vouchers`, {headers: headers});
        return response.data.data;
    }

    static async getQrCode(token: string): Promise<string> {
        let headers = {
            "user-token": token
        }
        const response = await restApi.get<string>(`login/qrcode`, {headers: headers});
        return response.data;
    }

    static async buyVoucher(productId: string, provider: string, token: string): Promise<VoucherApi> {
        let headers = {
            "user-token": token
        }

        let data = {
            product_id: productId,
            provider: provider
        }
        const response = await restApi.post<ItemResponse<VoucherApi>>(`contacts/vouchers`, data, {headers: headers});
        return response.data.data;
    }

    static async deleteUser(password: string, token: string) {
        let headers = {
            "user-token": token
        }

        let data = {
            password: password,
        }
        await restApi.post<ItemResponse<LoginApi>>(`login/delete`, data, {headers: headers});
    }

    static async updateUser(data: UpdateContactApi, token: string) {
        let headers = {
            "user-token": token
        }

        const response = await restApi.put<ItemResponse<ContactApi>>(`contacts`, data, {headers: headers});
        return response.data.data;
    }

    //PRODUCTS

    static async getProducts(): Promise<any> {
        let headers = {}
        const response = await restApi.get<any>(`contacts/products`, {headers: headers});
        return response.data.data;
    }


    //ORDER

    static async updateOrder(orderId: number, data: any, token: string, userToken?: string, notValidate?: boolean): Promise<any> {
        let headers = {
            "order-token": token,
            "user-token": userToken,
            "not-validate": notValidate
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.put<ItemResponse<any>>(`orders/${orderId}`, data, {headers: headers});
        return response.data.data;
    }

    static async payOrder(orderId: number, data: any, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<any>>(`orders/${orderId}/pay`, data, {headers: headers});
        return response.data;
    }

    static async addOrderVoucher(orderId: number, couponCode: string, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const data = {
            code: couponCode
        }
        const response = await restApi.post<ItemResponse<any>>(`orders/${orderId}/vouchers`, data, {headers: headers});
        return response.data;
    }


    static async updateOrderCoupon(orderId: number, data: any, token: string): Promise<any> {
        let headers = {
            "order-token": token,
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<any>>(`orders/${orderId}/coupon`, data, {headers: headers});
        return response.data;
    }

    static async removePromotion(orderId: number, promotionId: number, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.delete<ItemResponse<any>>(`orders/${orderId}/promotions/${promotionId}`, {headers: headers});
        return response.data;
    }

    static async createOrder(data: any, userToken?: string): Promise<any> {
        let headers = {
            "user-token": userToken
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<any>>(`orders`, data, {headers: headers});
        return response.data.data;
    }

    static async addOrderItem(orderId: number, data: any, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`orders/${orderId}/items`, data, {headers: headers});
        return response.data.data;
    }

    static async updateOrderItem(orderId: number, orderItemId: number, data: any, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.put<ItemResponse<LoginApi>>(`orders/${orderId}/items/${orderItemId}`, data, {headers: headers});
        return response.data.data;
    }

    static async deleteOrderItem(orderId: number, orderItemId: number, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.delete<ItemResponse<LoginApi>>(`orders/${orderId}/items/${orderItemId}`, {headers: headers});
        return response.data.data;
    }

    static async getOrder(orderId: number, token: string, params?: any): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.get<ItemResponse<LoginApi>>(`orders/${orderId}?include=all&checkStatus=1`, {
            headers: headers,
            params: params
        });
        return response.data.data;
    }

    static async cancelOrder(orderId: number, token: string, reason: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        let data = {'reason': reason};
        const response = await restApi.post<ItemResponse<LoginApi>>(`orders/${orderId}/cancel?include=all`, data, {headers: headers});
        return response.data.data;
    }

    static async copyOrder(orderId: number, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`orders/${orderId}/copy?include=all`, {}, {headers: headers});
        return response.data.data;
    }

    static async fixOrder(orderId: number, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`orders/${orderId}/fix?include=all`, {}, {headers: headers});
        return response.data.data;
    }

    static async updateOrderContact(orderId: number, token: string, userToken: string): Promise<any> {
        let headers = {
            "order-token": token,
            "user-token": userToken,
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`orders/${orderId}/channel/contact?include=all`, {}, {headers: headers});
        return response.data.data;
    }

    static async deleteOrderContact(orderId: number, token: string): Promise<any> {
        let headers = {
            "order-token": token
        } as any
        if (this.placeId) {
            headers["place-id"] = this.placeId;
        }
        const response = await restApi.delete<ItemResponse<LoginApi>>(`orders/${orderId}/contact?include=all`, {headers: headers});
        return response.data.data;
    }


    //PAGE
    static async getPage(slug: string): Promise<PageApi> {

        const response = await restApi.get<ItemResponse<PageApi>>(`pages/${slug}`, {params: {}});
        return response.data.data;
    }

    //MULTISTORE
    static async getPlaces(coordinates?: StoreConfigCoordinatesApi, params?: any): Promise<PlaceApi[]> {
        const newParams = params ? {...params} : {}
        if (coordinates) {
            newParams.coordinates = `${coordinates.latitude},${coordinates.longitude}`;
        }
        const response = await restApi.get<ItemResponse<PlaceApi[]>>(`config/places`, {params: newParams});
        return response.data.data;
    }

}

export {restApi, api}