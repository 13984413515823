import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {DateHelper} from "../../services/Common/Date/DateHelper";
import {getAvailabilities, getMenus} from "../../services/Menu/reducer";
import AvailableHelper from "../../services/Menu/services/Availability/AvailableHelper";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getLocation} from "../../services/Location/reducer";
import {getStoreConfig} from "../../services/Store/reducer";
import {useLocationService} from "../../services/Location/locationService";
import PickUpDateModal from "./PickUpDateModal/PickUpDateModal";
import ChangeDateModal from "./ChangeDateModal";


interface OwnProps {
    pickDate: any;
    showModal: boolean;
    onHide: () => void;
    menuId?: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function ChangePickDateModal(props: Props) {
    const {t} = useTranslation();
    const locationService = useLocationService();

    const savePickDate = async (date: any) => {
        const dateValue = date ? DateHelper.format(date) : "";
        await locationService.update({
            ...props.session_state,
            pickup_at: dateValue
        });
        props.onHide();
    }

    const menus = props.menuId ? props.menus.filter((menu: any) => menu.id == props.menuId) : props.menus;
    const dates = AvailableHelper.getAllDates(menus, props.availabilities);
    const weekDays = AvailableHelper.getAllWeekDays(menus, props.availabilities);
    const menuAvailabilities = AvailableHelper.getAvailabilities(props.availabilities, menus.map((menu: any) => menu.availability_id));

    //  before was ChangeDateModal
   return <ChangeDateModal config={props.config} orderType={props.session_state.order_type} dates={dates}
                     weekDays={weekDays} availabilities={menuAvailabilities}
                     button_clear_title={t("common.action.set_as_soon_as_possible")} onSave={savePickDate}
                     title={t("modules.date_modal.header.title")} value={props.pickDate}
                     showModal={props.showModal} onHide={props.onHide}/>
}

const mapStateToProps = (state: RootState) => ({
    availabilities: getAvailabilities(state.menu),
    menus: getMenus(state.menu),
    session_state: getLocation(state.session),
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(ChangePickDateModal));