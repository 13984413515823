import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../reducers";
import CookiesStore from "../../services/Common/Storage/cookiesStore";
import ErrorFormAlertView from "../Common/ErrorFormAlertView";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import ErrorFormView from "../Common/ErrorFormView";
import IframeWidget from "../../utils/IframeWidget";


interface OwnProps {
    password: string,
    onLogged: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function FirewallModalComponent(props: Props) {
    const [errors, setErrors] = useState<any[]>([]);
    const [password, setPassword] = useState("");
    const {t} = useTranslation();
    const loading = false;

    const login = async () => {
        if (props.password == password) {
            let now = new Date();
            let expirationTime = new Date(now.getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds

            CookiesStore.set("passwordLogged", true, {expires: expirationTime});
            props.onLogged();
        } else {
            setErrors([
                {
                    "code": "wrong_login",
                    "message": t("constraints.internal.login_failed")
                }
            ])
        }
    }

    return (
        <React.Fragment>
            <Modal dialogClassName="modal-full" show={true} onHide={() => IframeWidget.onHide()} onShow={() => IframeWidget.onShow()}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("common.word.password")}
                        </Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                                   className={"form-control" + ErrorFormHelper(errors, "password")}
                                   placeholder={t("common.word.password")} aria-label="Recipient's username"
                                   aria-describedby="cart-password"/>
                            <label htmlFor="cart-password">{t("common.word.password")}</label>
                            <ErrorFormView errors={errors} field="password"/>
                        </div>
                    </div>
                    <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} onClick={() => {
                        login()
                    }}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.login")}
                    </button>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(FirewallModalComponent));