import React, {FC, useEffect, useState} from 'react';
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import FetchData from "../../../components/Fetch/FetchData";
import classNames from "classnames";
import Hero from "../../HomePage/components/Hero/Hero";
import AgeRestrictedContext from "../../../services/Common/AgeRestrictedContext";
import MenuComponent from "../components/MenuComponent";
import MenuAddItemPage from "./Item/MenuAddItemPage";
import CartButtonComponent from "../../../components/Cart/CartButtonComponent";
import CartStickyComponent from "../../../components/Cart/CartStickyComponent";
import AgeRestrictedModalComponent from "../components/AgeRestricted/AgeRestrictedModalComponent";
import MenuAdvertComponent from "../components/MenuAdvertComponent";
import {ReactComponent as MenuSVG} from "../../../../images/icons/menu.svg";
import {getStoreConfig, getStoreDefaultConfig} from "../../../services/Store/reducer";
import AppConfig from "../../../services/AppConfig";
import {usePrefix} from "../../../services/Prefix";
import StoreUtils from "../../../services/Store/StoreUtils";
import CartStickyContextPage from "../../Contexts/CartStickyContextPage";
import IframeWidget from "../../../utils/IframeWidget";
import {ReactComponent as ArrowTopSVG} from "../../../../images/icons/arrow-top.svg";
import {useTranslation} from "react-i18next";
import MenuPromotionsComponent from "../components/Promotion/MenuPromotionsComponent";
import {getPromotions} from "../../../services/Menu/reducer";


type Props = ReturnType<typeof mapStateToProps> & DispatchProp;

const HomeNavbarPage: FC<Props> = (props) => {
    const [showAgeRestricted, setShowAgeRestricted] = useState(!AgeRestrictedContext.isAccepted() && AgeRestrictedContext.isAlcohol());
    const {t} = useTranslation();
    const {menuId} = useParams();
    let navigate = useNavigate();
    const prefix = usePrefix();
    const goToCart = () => {
        navigate(AppConfig.getLinkWithPrefix('/cart', prefix));
    }
    const scrollTop = () => {
        if (AppConfig.isWidget()) {
            IframeWidget.scrollTo(0);
        } else {
            window.scrollTo(0, 0);
        }
    }
    return (
        <FetchData menus={true} order={true}>
            <CartStickyContextPage>
                <div className="menu-page">

                    <div className={"navigate-bottom"}>
                        <button className={"btn btn-default btn-icon btn-lg"} onClick={scrollTop}>
                            <ArrowTopSVG/>
                        </button>
                    </div>
                    <CartStickyComponent goToCart={goToCart} showVouchers/>

                    {(!AppConfig.isNativeMobile()) && (
                        <>
                            {!StoreUtils.isMultistore(props.defaultConfig) && (
                                <Hero config={props.config}/>
                            )}
                        </>
                    )}
                    {(!AppConfig.isNativeMobile()) && (
                        <>
                            <MenuAdvertComponent/>
                        </>
                    )}
                    <div className={"container-fluid"}>
                        <MenuPromotionsComponent promotions={props.promotions}/>
                        <h2>
                            <MenuSVG/> {t("modules.menu.field.menu.title")}
                        </h2>
                        <p>
                            {t("modules.menu.field.different_menu.title")}
                        </p>
                    </div>
                    {showAgeRestricted ? (
                        <>
                            <div>
                                <AgeRestrictedModalComponent show={true} onSave={() => {
                                    setShowAgeRestricted(false)
                                }}/>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classNames('menu body-container body-container-cart')}>
                                <MenuComponent menuId={menuId}/>
                            </div>
                            <div className="btn-submit-bottom fixed-bottom btn-submit-container">
                                <CartButtonComponent goToCart={goToCart}/>
                            </div>

                            <Routes>
                                <Route path={`menu/:menuId/add/:id`} element={<MenuAddItemPage/>}/>
                                <Route path={`add/:id`} element={<MenuAddItemPage/>}/>
                            </Routes>
                            {/*<Route path={`/menu/:menuId/add/:id`} component={MenuAddItem}/>*/}
                            {/*<Route path={`/test`} component={Test}/>*/}
                        </>
                    )}
                </div>


                {/*<Routes>*/}
                {/*<Route path={`menu/:menuId`} element={<Menu />}/>*/}
                {/*/!*<Route path={`${props.match.url}:pageId`} component={Page}/>*!/*/}
                {/*<Route path={``} element={<Menu />}/>*/}
                {/*</Routes>*/}
            </CartStickyContextPage>
        </FetchData>
    )
};

const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    defaultConfig: getStoreDefaultConfig(state.config),
    promotions: getPromotions(state.menu),
})

const connector = connect(mapStateToProps);
export default connector(HomeNavbarPage);
