export const parseDate = (s:any) => {
    var a = s.split(/[^0-9]/);
    var d=new Date (a[0],a[1]-1,a[2],a[3],a[4],a[5] );
    var now_utc =  Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
        d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());

    return new Date(now_utc);
}

export class DateHelper {
    static convertUTC(date:any){
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: "UTC"}));
    }
    static convertTZ(date:any, tzString:any){
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
    }
    static parseZero(value:any){
        return value < 10 ? '0'+value :value;
    }
    static fromString(s:any){
        var a = s.split(/[^0-9]/);
        var d=new Date (a[0],a[1]-1,a[2],a[3],a[4],a[5] );
        var now_utc =  Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
            d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());

        return new Date(now_utc);
    }
    static toString(date:Date){
        return date.toString();
    }
    static format(date:any){
        return date.getFullYear() + "-" + this.parseZero(date.getMonth() + 1) + "-" + this.parseZero(date.getDate()) + " " + this.parseZero(date.getHours()) + ":" + this.parseZero(date.getMinutes()) + ":" + this.parseZero(date.getSeconds())
    }
    static formatOnlyDate(date:any){
        return date.getFullYear() + "-" + this.parseZero(date.getMonth() + 1) + "-" + this.parseZero(date.getDate())
    }
    static formatUTC(date:any){
        return date.getUTCFullYear() + "-" + this.parseZero(date.getUTCMonth() + 1) + "-" + this.parseZero(date.getUTCDate()) + " " + this.parseZero(date.getUTCHours()) + ":" + this.parseZero(date.getUTCMinutes()) + ":" + this.parseZero(date.getUTCSeconds())
    }
}

const weekdayDateMap = {
    monday: new Date('2020-01-06'),
    tuesday: new Date('2020-01-07'),
    wednesday: new Date('2020-01-08'),
    thursday: new Date('2020-01-09'),
    friday: new Date('2020-01-10'),
    saturday: new Date('2020-01-11'),
    sunday: new Date('2020-01-12')
}as any;
export const getWeekDays = () => {
    return Object.keys(weekdayDateMap);
}
export const getDayOfWeek = (shortName:any, locale = 'en-US', length = 'long') =>
    new Intl.DateTimeFormat(locale, { weekday: length as any }).format(weekdayDateMap[shortName.toLowerCase()]);

export const getWeekDay = (date:any, locale:any, short?: boolean) => {
    return new Intl.DateTimeFormat(locale, { weekday: short ? "short" : "long" }).format(date);
}
export const formatDate = (date:any) => {
    var dd = date.getDate();
    var mm = date.getMonth()+1;
    var yyyy = date.getFullYear();
    if(dd<10)
    {
        dd='0'+dd;
    }

    if(mm<10)
    {
        mm='0'+mm;
    }
    return dd+'-'+mm+'-'+yyyy;
}

export const sameDateWithoutTime = (dateFirst:any, dateSecond:any) => {

    dateFirst = new Date(dateFirst);
    dateFirst.setHours(0, 0, 0, 0);
    dateSecond = new Date(dateSecond);
    dateSecond.setHours(0, 0, 0, 0);
    return dateFirst.getTime() == dateSecond.getTime();
}

export const isTimeAllDay = (time:any) => {
    if(time instanceof Date){
        return time.getHours() == 0 && time.getMinutes() == 0;
    }
    return time === "00:00" ? true : false;
}

export const setTimeByString = (date:any, time:any) => {
    date.setHours(time.substring(0, 2), time.substring(3, 5),0); // 6.30 pm
    return date;
}
export const getHourTimeString = (date:any) => {
    return ("0" + date.getHours()).slice(-2) + ":" +("0" + date.getMinutes()).slice(-2);
}