import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {connect, DispatchProp} from "react-redux";
import QRCode from "qrcode";
import {RootState} from "../../../../reducers";
import {useNavigate} from "react-router-dom";
import ErrorFormAlertView from "../../Common/ErrorFormAlertView";
import {getLocation, selectLocale} from "../../../services/Location/reducer";
import {getStoreConfig, getStoreConfigLoyalty} from "../../../services/Store/reducer";
import {useTranslation, withTranslation} from "react-i18next";
import UserUtils from "../../../services/User/UserUtils";
import {useCart} from "../../../services/Cart/cartService";
import {usePrefix} from "../../../services/Prefix";
import handleException from "../../../services/Api/handleException";
import {VoucherApi} from "../../../services/Api/types";
import {ReactComponent as GiftIco} from "../../../../images/icons/gift-solid.svg";
import RoundedIcon from "../../Common/RoundedIcon";
import appConfig from "../../../services/AppConfig";
import IframeWidget from "../../../utils/IframeWidget";

interface OwnProps {
    voucher: any,
    show: boolean,
    onHide: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function VoucherModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);
    const canvasRef = useRef(null);
    const voucher = props.voucher;
    const product = voucher.product;
    const navigate = useNavigate();
    let cartService = useCart();
    const prefix = usePrefix();
    let {t} = useTranslation();
    const isLocationSelected = !!props.config;

    const isVoucherAvailableForLocation = (voucher:VoucherApi) => {
        // return voucher.promotion_id || voucher.credit;
        return true;
    }
    const voucherAvailableForLocation = isVoucherAvailableForLocation(voucher);

    const addVoucher = async (voucher: any) => {
        setLoading(true);
        if(!isLocationSelected){
            setLoading(false);
            setErrors([{
                message: t("constraints.internal.choose_restaurant"),
            }]);
            return;
        }
        try {
            await cartService.addOrderVoucher(voucher.code);
            setLoading(false);
            props.onHide();
            // navigate(AppConfig.getLinkWithPrefix("/", prefix));
        } catch (e) {
            console.log(e);
            setErrors(handleException(e));
            setLoading(false);
        }
    }
    useEffect(() => {
        if (canvasRef.current) {
            QRCode.toCanvas(canvasRef.current, voucher.code, {width: 172}, (error: any) => {
                if (error) console.error(error);
            });
        }
    }, []);
    const onShow = () => {
    }

    return (
        <>
            <Modal className='voucher-modal small-modal' dialogClassName="modal-full modal-login" show={props.show} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"voucher-full voucher-modal-body"}>
                        {(isLocationSelected && !voucherAvailableForLocation) && (
                            <>
                                <ErrorFormAlertView errors={[t("constraints.internal.voucher_not_available_for_location")]}/>
                            </>
                        )}
                        <ErrorFormAlertView errors={errors}/>

                        <div className="d-flex w-100 align-items-center justify-content-center mb-3">
                            <RoundedIcon icon={<GiftIco/>}/>
                        </div>
                        <div className={`${appConfig.isDarkMode() ? "mb-4" : "mb-3"}`}>
                            <h5 className="mb-0">
                                {product.name}
                            </h5>
                            {product.description && <p className="card-text text-center">{product.description}</p>}
                            <ErrorFormAlertView errors={errors}/>
                        </div>

                        {UserUtils.isLoyaltyQrcodeEnabled(props.config_loyalty) && (
                            <>
                                {voucher.code && (
                                    <>
                                        <div className={"voucher-full-qrcode"}>
                                            <div className={"qrcode"}>
                                                <canvas ref={canvasRef}/>
                                            </div>
                                            <p className={"info"}>
                                                {t("modules.user.field.scan_code.title")}
                                            </p>
                                        </div>

                                        <div className="horizontal-divider">
                                            <span>{t("common.word.or")}</span>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <Button
                            className={`btn btn-spinner btn-primary w-100 ${loading ? " loading disabled" : ""}${voucherAvailableForLocation ? "" : " disabled"}`}
                            onClick={() => addVoucher(voucher)}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            {t("modules.user.action.add_to_cart.title")}
                        </Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    location: getLocation(state.session),
    locale: selectLocale(state.session),
    config_loyalty: getStoreConfigLoyalty(state.config),
    config: getStoreConfig(state.config)
})
const connector = connect(mapStateToProps);
export default withTranslation()(connector(VoucherModal));
