import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {ReactComponent as InfoSVG} from "../../../../images/icons/info.svg";
import {Link, useLocation} from "react-router-dom";
import {RootState} from "../../../../reducers";
import LoadingContainer from "../../../components/Common/LoadingContainer";
import ErrorFormAlertView from "../../../components/Common/ErrorFormAlertView";
import {getUser} from "../../../services/User/reducer";
import {useAuth} from "../../../services/User/auth";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const UserActivationPage = (props: Props) => {
    const [successSendLink, setSuccessSendLink] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any[]>([]);
    const location = useLocation();
    const authService = useAuth();
    const {t} = useTranslation();

    const queryParams = new URLSearchParams(location.search);
    const getQueryParam = (param: any) => {
        const value = queryParams.get(param);
        return value ? value : undefined;
    };

    const code = getQueryParam('code');
    const email = getQueryParam('email');
    const isValid = () => {
        return code && email;
    }

    useEffect(() => {
        if (isValid()) {
            activation();
        }
    }, []);


    const activation = async () => {
        setLoading(true);
        try {
            let response = await authService.activation(email, code)
            setLoading(false);
        } catch (e) {
            console.log(e)
            setErrors([{
                message: t("constraints.internal.account_verification_failed")
            }]);
            setLoading(false);
        }
    }
    const send = async () => {
        setLoading(true)
        try {
            await authService.sendActivation(email)
            setLoading(false);
            setSuccessSendLink(true);
        } catch (e) {
            console.log(e)
            setErrors([{
                message: t("constraints.internal.cannot_send_verification_link")
            }]);
            setLoading(false);
        }
    }
    if (loading) {
        return (
            <>
                <LoadingContainer/>
            </>
        )
    }
    if (successSendLink) {
        return (
            <>
                <div>
                    <h4>
                        {t("modules.login.field.account_verification.title")}
                    </h4>
                </div>
                <div className="alert alert-light" role="alert">
                    <small>
                        <InfoSVG className={"icon me-2"}/>
                        {t("modules.login.field.account_verification.description")}
                    </small>
                </div>
                <div className={"login-box-register-info"}>
                    <hr/>
                    <div className={"text-center"}>
                        {t("modules.login.field.message_not_arrived.title")} <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        send();
                    }}>
                        {t("modules.login.action.send_again.title")}
                    </a>
                    </div>
                </div>
            </>
        )
    }
    if (!code || !email) {
        return (
            <>
                <div className="alert alert-danger" role="alert">
                    <small>
                        <InfoSVG className={"icon me-2"}/>
                        {t("constraints.internal.improper_verification_link")}
                    </small>
                </div>
                <div className={"login-box-register-info"}>
                    <hr/>
                    <div className={"text-center"}>
                        {t("modules.login.field.improper_verification_link.title")}
                        <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        send();
                    }}>
                        {t("modules.login.action.send_again.title")}
                    </a>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={"login-box"}>
                <>
                    <div>
                        <h4>
                            {t("modules.login.field.ready.title")}
                        </h4>
                    </div>
                    {(errors && errors.length > 0) ? (
                        <>
                            <ErrorFormAlertView errors={errors}/>
                            <div className={"login-box-register-info"}>
                                <hr/>
                                <div className={"text-center"}>
                                    {t("constraints.internal.improper_verification_link")} <a href={"#"} onClick={(e) => {
                                    e.preventDefault();
                                    send();
                                }}>
                                    {t("modules.login.action.send_again.title")}
                                </a>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="alert alert-success" role="alert">
                                <small>
                                    <InfoSVG className={"icon me-2"}/>
                                    {t("modules.login.field.account_activated.title")} ({email}).
                                </small>
                            </div>

                            <Link
                                to={"/"}
                                className={"btn-block btn-primary btn btn-submit"}>
                                {t("modules.login.action.go_to_home_page.title")}
                            </Link>
                        </>
                    )}
                </>
            </div>
        </>
    )
}


const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(UserActivationPage));
