import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getStoreConfig} from "../../../services/Store/reducer";
import {RootState} from "../../../../reducers";
import {ReactComponent as MenuSVG} from "../../../../images/icons/menu.svg";
import {ReactComponent as ArrowRightSVG} from "../../../../images/icons/arrow-right.svg";
import {Link} from "react-router-dom";
import StoreUtils from "../../../services/Store/StoreUtils";
import UserUtils from "../../../services/User/UserUtils";
import AdvertUserComponent from "../../../components/User/components/AdvertUser";
import {getUser} from "../../../services/User/reducer";

interface OwnProps {

}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const HomePageMenusComponent = (props: Props) => {
    const [addressModal, setAddressModal] = useState(false);
    const {config} = props;
    let {t} = useTranslation();
    if (!config) return null;

    return (
        <div className={"homepage-menus"}>
            <div className={"container-fluid"}>
                <div className="nav flex-column nav-pills w-100 nav-complex">
                    <Link to={"menu"} className="nav-link nav-link-homepage">
                        <div className={"icon-square"}>
                            <MenuSVG/>
                        </div>
                        <div>
                            <h4>
                                {t("modules.home_page.field.menu.title")}
                            </h4>
                            <p>
                                {t("modules.home_page.field.order_types_info.title")}
                            </p>
                        </div>
                        <ArrowRightSVG/>
                    </Link>
                    {/*{(UserUtils.isLoyaltyRegistration(props.config) && (!props.user || !props.user.name)) && (*/}
                    {/*    <Link to={"menu"} className="nav-link nav-link-homepage">*/}
                    {/*        <div className={"homepage-menu-link-icon"}>*/}
                    {/*            <MenuSVG/>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <h4>*/}
                    {/*                Założ konto*/}
                    {/*            </h4>*/}
                    {/*            <p>*/}
                    {/*                Załóż konto aby uzyskać 10% zniżki na pierwsze zamówienie!*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*        <ArrowRightSVG/>*/}
                    {/*    </Link>*/}
                    {/*)}*/}
                    {/*{((props.user && props.user.name)) && (*/}
                    {/*    <Link to={"menu"} className="nav-link nav-link-homepage">*/}
                    {/*        <div className={"homepage-menu-link-icon"}>*/}
                    {/*            <MenuSVG/>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <h4>*/}
                    {/*                Twoje konto*/}
                    {/*            </h4>*/}
                    {/*            <p>*/}
                    {/*                Kliknij żeby zobaczyć swoje nagrody lub zeskanować qrcode*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*        <ArrowRightSVG/>*/}
                    {/*    </Link>*/}
                    {/*)}*/}
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    user: getUser(state.user),
    // orderType: getOrderType(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(HomePageMenusComponent));