import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../reducers";
import {getUser} from "../../services/User/reducer";
import {getStoreConfig} from "../../services/Store/reducer";
import DateService from "../../services/Common/Date/DateService";
import HoursHelper from "../../services/Menu/services/Availability/HoursHelper";
import StoreInfoModal from "../Modal/StoreInfoModal";


interface OwnProps {
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function NavbarHours(props: Props) {
    const [modal, setModal] = useState(false);
    const {t} = useTranslation();

    if (!props.config) return null;
    const isOpen = HoursHelper.isOpen(props.config.hours, DateService.now());
    let openHours = props.config ? HoursHelper.getOpenHours(props.config.hours, DateService.now()) : undefined;
    const minHours = HoursHelper.closetOpenHours(props.config.hours, DateService.now());
   
    const showModal = () => {
        setModal(true)
    }
    return (
        <>
            {modal && (
                <StoreInfoModal onHide={() => setModal(false)} />
            )}
            {isOpen ? (
                <>
                    <button className={"btn text-success btn-sm"} onClick={() => showModal()}>
                        <span className="dot bg-success rounded-circle me-2"></span>
                        {t("modules.navbar.field.open.title")}
                    </button>
                </>
            ) : (
                <>
                    {(openHours && openHours.length > 0) ? (
                        <button className={"btn text-warning btn-sm"} onClick={() => showModal()}>
                            <span className="dot bg-warning rounded-circle me-2"></span>
                            {t("modules.navbar.field.opening_time.title", {hour: minHours})}
                        </button>
                    ) : (
                        <>
                            <button className={"btn text-danger btn-sm"}
                                    onClick={() => showModal()}>
                                <span className="dot bg-danger rounded-circle me-2"></span>
                                {t("modules.navbar.field.closed_today.title")}
                            </button>
                        </>
                        )}
                </>
            )}
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    user: getUser(state.user),
    config: getStoreConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(NavbarHours));