import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {withTranslation, useTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {ReactComponent as GiftIco} from "../../../../../images/icons/gift-solid.svg";
import {ReactComponent as UserIco} from "../../../../../images/icons/user.svg";
import {ReactComponent as TrophyStarIco} from "../../../../../images/icons/trophy-star.svg";
import handleException from "../../../../services/Api/handleException";
import {RootState} from "../../../../../reducers";
import {getLocation} from "../../../../services/Location/reducer";
import ErrorFormAlertView from "../../../Common/ErrorFormAlertView";
import {useAuth} from "../../../../services/User/auth";
import {getUser} from "../../../../services/User/reducer";
import LoginUserModal from "../../LoginUserModal";
import UserUtils from "../../../../services/User/UserUtils";
import RoundedIcon from "../../../Common/RoundedIcon";
import IframeWidget from "../../../../utils/IframeWidget";

interface OwnProps {
    product: any,
    onSuccess: (voucher?: any) => void
    onHide: () => void;
    onSuccessUserModal?: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function VoucherProductConfirmationModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [register, setRegister] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);
    const product = props.product;
    const auth = useAuth();
    const {t} = useTranslation();

    const buy = async (product: any) => {
        setLoading(true);
        try {
            const response = await auth.buyVoucher(product.id);
            const vouchers = await auth.syncVouchers();
            await auth.refresh();
            if (vouchers && response) {
                let userVoucher = vouchers.filter(x => x.id === response?.id)[0];
                props.onSuccess(userVoucher);
            } else {
                props.onSuccess(undefined);
            }
            setLoading(false);
        } catch (e: any) {
            const errors = handleException(e);
            setErrors(errors);
            setLoading(false);
        }

    }
    const onShow = () => {
    }

    const handleRegister = () => {
        setRegister(true);
        setLoginModal(true);
    }


    const handleHideLoginModal = () => {
        if(register) {
            setRegister(false);
        }
        setLoginModal(false);
    }

    return (
        <>
            <Modal dialogClassName="modal-full modal-login" className="small-modal" show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(onShow)}>
                <div className="">
                    <Modal.Header>
                        <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"voucher-full"}>
                        {
                            UserUtils.isLogged(props.user) ? (
                                <>
                                    <div className="w-100 d-flex align-items-center justify-content-center mb-3">
                                        <RoundedIcon icon={<GiftIco/>}/>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                        <h5 className={`${product.description ? 'mb-2' : 'mb-4'}`}>
                                            {product.name}
                                        </h5>
                                        {product.description && <p className="card-text mb-4">{product.description}</p>}
                                        <ErrorFormAlertView errors={errors}/>
                                        <Button
                                            className={`btn btn-spinner btn-primary w-100 ${loading ? " loading disabled" : ""}`}
                                            onClick={() => buy(product)}>
                                            <span className="left spinner-border spinner-border-sm"/>
                                            {product.cost ? `${t("modules.voucher.action.buy_for.title")} ${product.cost} pkt` : t("modules.voucher.action.collect_for_free.title") }
                                            <TrophyStarIco className='ms-2'/>
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="w-100 d-flex align-items-center justify-content-center mb-3">
                                        <RoundedIcon icon={<UserIco/>}/>
                                    </div>
                                     <h5 className="mb-4">{t('modules.voucher.field.log_in_to_buy_voucher.title')}</h5>
                                    <div className='d-flex align-items-center justify-content-between gap-3'>
                                        <Button
                                            className={`btn btn-spinner w-100 ${loading ? " loading disabled" : ""}`}
                                            onClick={() => setLoginModal(true)} variant='outline-secondary'>
                                            <span className="left spinner-border spinner-border-sm"/>
                                            {t("common.action.login")}
                                        </Button>
                                        <Button
                                            className={`btn btn-spinner w-100 ${loading ? " loading disabled" : ""}`}
                                            onClick={handleRegister} variant='outline-secondary'>
                                            <span className="left spinner-border spinner-border-sm"/>
                                            {t("modules.voucher.action.register.title")}
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                    </div>

                </Modal.Body>
            </Modal>
            {loginModal && (
                <LoginUserModal page={register ? "REGISTER" : undefined} onHide={handleHideLoginModal} onSuccess={props.onSuccessUserModal}/>
            )}
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    location: getLocation(state.session),
    user: getUser(state.user)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(VoucherProductConfirmationModal));
