import React, {useState} from 'react';
import {connect, DispatchProp} from "react-redux";
import DateService from "../../../../services/Common/Date/DateService";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import ChangeLocationModal from "../../../../components/Modal/ChangeLocationModal";
import HeroInfoHours from "./HeroInfoHours";
import HoursHelper from "../../../../services/Menu/services/Availability/HoursHelper";
import StringHelper from "../../../../services/Common/StringHelper";
import {ConfigApi} from "../../../../services/Api/types";

interface OwnProps {
    config: ConfigApi
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const Hero = (props: Props) => {
    const [addressModal, setAddressModal] = useState(false);
    const {config} = props;
    let {t} = useTranslation();
    if (!config) return null;

    const renderOpenHours = (hours: any) => {
        let openHours = HoursHelper.getOpenHours(hours, DateService.now());
        if (!openHours) return null;
        let isOpenView = null;
        const isOpen = HoursHelper.isOpen(hours, DateService.now())
        if (isOpen !== null) {
            if (isOpen) {
                isOpenView = (
                    <span className="badge bg-success">{t("enums.store_status.open")}</span>
                );
            } else {
                isOpenView = (
                    <span className="badge bg-danger">{t("enums.store_status.closed")}</span>
                );
                if (!openHours || openHours.length <= 0) {
                    return (
                        <div className="cover-time">
                            <span className="badge bg-danger">{t("enums.store_status.closed_today")}</span>
                        </div>
                    );
                }
            }
        }

        return (
            <div className="cover-time">
                <ul className="list-inline">
                    {
                        openHours.map((openHour: any, index: any) =>
                            <li key={index + "-" + openHour.hour_from + "-" + openHour.hour_to}>{openHour.hour_from} - {openHour.hour_to}</li>
                        )
                    }
                </ul>
                {isOpenView}
            </div>
        );
    }


    let addressView = null;
    if (config.address) {
        let addressFullString = StringHelper.getAddressName(config.address);
        addressView = (
            <div className="cover-address">
                {addressFullString}
            </div>
        )
    }

    let openHours = renderOpenHours(config.hours);
    let coverImgView = null;
    if (config.layout.cover_link) {
        coverImgView = (
            <img src={config.layout.cover_link.default}/>
        )
    } else {
        coverImgView = (
            <img src="/images/test/cover.jpg"/>
        )
    }
    if (1 == 1) {
        return (
            <div className={"container-fluid"}>
                <div className={"cover-new"}>
                    {coverImgView}
                </div>
                <div className={"cover-header"}>
                    <h1>
                        {config.name}
                    </h1>
                    {config.header && (
                        <p>
                            {config.header}
                        </p>
                    )}
                    {(config.tags && config.tags.length > 0) && (
                        <div className="badge-container d-flex overflow-auto hide-scrollbar">
                            {config.tags.map((tag: string) =>
                                <span key={tag} className="badge me-2">{tag}</span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={"cover-wrapper"}>
            <div className="cover">
                <div className="cover-img">
                    <div>
                        {coverImgView}
                    </div>
                </div>
                <div className="cover-content">

                    <div className={"cover-content-center"}>
                        <div className={"cover-content-center-bg-close"}></div>
                        <div className={"cover-content-center-content"}>
                            <h1>
                                {config.name}
                            </h1>
                            <div className={"cover-info"}>
                                {openHours}
                                {addressView}
                            </div>
                            <HeroInfoHours className="btn btn-more"/>
                        </div>
                    </div>
                </div>
                <ChangeLocationModal showModal={addressModal}
                                     onHide={() => setAddressModal(false)}/>
            </div>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(Hero));