import React from 'react';
import 'leaflet/dist/leaflet.css';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../../../reducers";
import {PlaceApi} from "../../../../services/Api/types";
import ScrollableTabs from "../../../../components/Common/ScrollableTabs";

interface OwnProps {
    places: PlaceApi[],
    city: string,
    onChange: (value: any) => void,
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistorePlaceFilterCitiesComponent = (props: Props) => {
    const cities = Array.from(new Set(props.places?.filter(x => x.address && x.address.city).map(x => x.address.city).sort((a, b) => a.localeCompare(b))));
    const {t} = useTranslation();

    return (
        <ScrollableTabs hideScrollbars={false} className="mb-3">
            <>
                <li className={`nav-item${!props?.city ? " active" : ""}`}>
                    <div className="nav-link"
                         onClick={() => props.onChange(undefined)}>
                        {t("modules.multistore.field.all.title")}
                    </div>
                </li>
                {cities.map(city => {
                    return (
                        <li key={city} className={`nav-item${city == props.city ? " active": ""}`}>
                            <div onClick={() => props.onChange(city)}
                                 className="nav-link">{city}</div>
                        </li>
                    )
                })}
            </>
        </ScrollableTabs>
    );
};


const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MultistorePlaceFilterCitiesComponent));

