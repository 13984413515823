import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactComponent as ClockSVG} from "../../../../images/icons/clock.svg";
import {ReactComponent as CheckSVG} from "../../../../images/icons/check.svg";
import {ReactComponent as RemoveSVG} from "../../../../images/icons/remove.svg";
import Utils from "../../../utils/Utils";
import {ConfigApi} from "../../../services/Api/types";
import AppConfig from "../../../services/AppConfig";
import {useCart} from "../../../services/Cart/cartService";
import {useNavigate} from "react-router-dom";
import {usePrefix} from "../../../services/Prefix";
import LoadingContainer from "../../../components/Common/LoadingContainer";
import SummaryCancelModal from "./SummaryCancelModal";
import {parseDate} from "../../../services/Common/Date/DateHelper";
import DateService from "../../../services/Common/Date/DateService";
import FormattedShortDateTime from "../../../components/Common/FormattedShortDateTime";

interface Props {
    order: any,
    config?: ConfigApi,
    onUpdate: (order: any) => void
}

const SummaryStatusComponent = (props: Props) => {
    const {t} = useTranslation();
    const [orderAgainLoading, setOrderAgainLoading] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    let cartService = useCart();
    let navigate = useNavigate();
    const prefix = usePrefix();
    let cancellationTime = props.config?.ordering.cancellation_time;
    let textWaitingForConfirmation = props.config?.layout.custom_texts.text_waiting_for_confirmation;
    let textCustomerSuccessInfo = props.config?.layout.custom_texts.customer_success_info;
    let textCustomerRejectInfo = props.config?.layout.custom_texts.customer_reject_info;
    let colorClass = "warning";
    if (props.order.status === "CANCELED" || props.order.status === "REJECTED") {
        colorClass = "danger";
    }
    if (props.order.status === "ACCEPTED") {
        colorClass = "success";
    }
    if (props.order.status === "WAITING_FOR_ACCEPTED") {
        colorClass = "warning";
    }
    if (props.order.status === "CONFIRMED") {
        colorClass = "warning";
        if (props.order.payment_status === "FAILURE") {
            colorClass = "danger";
        }
        if (props.order.payment_status === "PROGRESS") {
            colorClass = "warning";
        }
    }

    const orderAgain = async (id: any, token: any) => {
        setOrderAgainLoading(true);
        try {
            await cartService.copyOrder(id, token);
            setOrderAgainLoading(false);
            navigate(AppConfig.getLinkWithPrefix("/", prefix));
        } catch (error) {
            setOrderAgainLoading(false);
        }
    }
    const renderInfo = () => {
        if (props.order.status === "CANCELED" || props.order.status === "REJECTED") {
            return renderCancellationOrder();
        }
        if (props.order.status === "WAITING_FOR_ACCEPTED") {
            return renderWaitingForAcceptedOrder();
        }
        if (props.order.status === "ACCEPTED") {
            return renderAcceptedOrder();
        }
        if (props.order.status === "CONFIRMED") {
            if (props.order.payment_status === "PROGRESS") {
                return renderWaitingForPaymentOrder();
            }
            if (props.order.payment_status === "FAILURE") {
                return renderFailurePaymentOrder();
            }
        }
        return null;
    }
    const isCancelPossible = () => {
        if (cancellationTime && cancellationTime > 0) {
            let confirmedAt = parseDate(props.order.confirmed_at);
            let now = DateService.now();
            let addMiliseconds = cancellationTime * 60 * 1000;
            let nowWithCancellationTime = new Date(now.getTime() - addMiliseconds);
            if (confirmedAt > nowWithCancellationTime) {
                return true;
            }
        }
        return false;
    }

    const getRejectInfoText = () => {
        let text = '';
        if (props.order.reason) {
            text += Utils.nl2brString(props.order.reason, true);
        }
        if (textCustomerRejectInfo) {
            text += `. ${Utils.nl2brString(textCustomerRejectInfo)}`
        }

        return text;
    }

    const renderCancellationOrder = () => {
        return (
            <div className={"summary-response"}>
                <div className={"pb-2"}>
                    <h3 className={"mb-2"}>
                        {t("modules.summary.field.order_rejected.title")}
                    </h3>
                    {getRejectInfoText() && (
                        <div className={"summary-response-reason mb-2"}
                             dangerouslySetInnerHTML={{__html: getRejectInfoText()}}>
                        </div>
                    )}
                </div>
                <button className={`btn btn-lg btn-primary mb-2 btn-spinner${orderAgainLoading ? " loading" : ""}`}
                        disabled={orderAgainLoading}
                        onClick={(e) => orderAgain(props.order.id, props.order.token)}>
                    <span className="left spinner-border spinner-border-sm"></span>
                    {t("common.action.order_again")}
                </button>
            </div>
        )
    }
    const renderWaitingForAcceptedOrder = () => {
        let textWaitingForAccept = textWaitingForConfirmation;
        if (!textWaitingForAccept) {
            textWaitingForAccept = t("modules.summary.field.waiting_for_accept.title");
        }
        return (
            <div className={"summary-response"}>
                <div className={"pb-2"}>
                    <h3 className={"mb-2"}>
                        {textWaitingForAccept}
                    </h3>
                </div>
                <div>
                    <LoadingContainer>{" "}</LoadingContainer>
                </div>
                {isCancelPossible() && (
                    <>
                        <button className="btn btn-lg btn-default mb-2"
                                onClick={(e) => setCancelModal(true)}>
                            {t("common.action.cancel_order")}
                        </button>
                        {cancelModal && (
                            <SummaryCancelModal order={props.order} onHide={() => setCancelModal(false)}
                                                onSuccess={props.onUpdate}/>
                        )}
                    </>
                )}
            </div>
        )
    }
    const renderAcceptedOrder = () => {
        let estimatedDate = props.order.estimated_preparation_at;
        if (!estimatedDate) {
            estimatedDate = props.order.pickup_at;
        }
        let estimatedDateText = t("modules.summary.field.estimate_pickup_time.title");
        if (props.order.type === 'DELIVERY') estimatedDateText = t("modules.summary.field.estimate_delivery_time.title");
        if (props.order.type === 'DINE_IN' || props.order.type === 'ROOM_SERVICE') estimatedDateText = t("modules.summary.field.estimate_preparation_time.title");
        if (props.order.type === 'PICK_UP') estimatedDateText = t("modules.summary.field.estimate_pickup_time.title");

        let numberViewText = t("modules.summary.field.your_number.title");
        if (props.order.number) {
            if (props.order.type === 'ROOM_SERVICE') {
                numberViewText = t("modules.summary.field.your_room_number.title");
            } else if (props.order.type === 'DINE_IN') {
                numberViewText = t("modules.summary.field.your_table_number.title");
            }
        }
        return (
            <div className={"summary-response"}>
                <div className={"pb-2"}>
                    <div className='mb-4'>
                        <h3 className={"mb-2"}>
                            {t("modules.summary.field.order_accepted.title")}
                        </h3>
                        {textCustomerSuccessInfo && (
                            <div className={"summary-response-reason mb-2"}>
                                {Utils.nl2br(textCustomerSuccessInfo)}
                            </div>
                        )}
                    </div>
                    <div className={"summary-response-box mb-2"}>
                        {props.order.number && (
                            <dl>
                                <dt>{numberViewText}:</dt>
                                <dd>{props.order.number}</dd>
                            </dl>
                        )}
                        {estimatedDate && (
                            <dl>
                                <dt>{estimatedDateText}:</dt>
                                <dd>
                                    <FormattedShortDateTime date={parseDate(estimatedDate)}/>
                                </dd>
                            </dl>
                        )}
                    </div>
                </div>
                {isCancelPossible() && (
                    <>
                        <button className="btn btn-lg btn-default mb-2"
                                onClick={(e) => setCancelModal(true)}>
                            {t("common.action.cancel_order")}
                        </button>
                        {cancelModal && (
                            <SummaryCancelModal order={props.order} onHide={() => setCancelModal(false)}
                                                onSuccess={props.onUpdate}/>
                        )}
                    </>
                )}
            </div>
        )
    }
    const renderWaitingForPaymentOrder = () => {
        return (
            <div className={"summary-response"}>
                <div className={"pb-2"}>
                    <h3 className={"mb-2"}>
                        {t("modules.summary.field.waiting_payment.title")}
                    </h3>
                </div>
                <div>
                    <LoadingContainer>{" "}</LoadingContainer>
                </div>
            </div>
        )
    }
    const renderFailurePaymentOrder = () => {
        return (
            <div className={"summary-response"}>
                <div className={"pb-2"}>
                    <h3 className={"mb-2"}>
                        {t("modules.summary.field.order_payment_error.title")}
                    </h3>
                </div>
            </div>
        )
    }
    return (
        <div className={"container-fluid"}>
            <div className={`summary-loading-new ${colorClass}`}>


                <div className="circle-container">
                    <div className={"center"}>
                        <div className="circle circle1"></div>
                    </div>
                    <div className={"center"}>
                        <div className="circle circle2"></div>
                    </div>
                    <div className={"center"}>
                        <div className="circle circle3"></div>
                    </div>
                    <div className={"center"}>
                        <div className="circle circle4"></div>
                    </div>
                    <div className={"center"}>
                        <div className={"center-svg"}>
                            {colorClass === "danger" ? (
                                <RemoveSVG/>
                            ) : colorClass === "success" ? (
                                <CheckSVG/>
                            ) : (
                                <ClockSVG/>
                            )}
                        </div>
                    </div>
                </div>
                <div className={"summary-content"}>
                    {renderInfo()}
                </div>
            </div>
        </div>
    )
}
export default SummaryStatusComponent;