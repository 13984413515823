import {useDispatch} from "react-redux";
import {UserActionTypes, UserState} from "./types";
import {api} from "../Api/api";
import UserTokenContext from "./UserTokenContext";
import {ContactApi, UpdateContactApi, VoucherApi} from "../Api/types";
import handleException from "../Api/handleException";
import {NotFoundException} from "../Api/exceptions";
import {addFlash} from "../Notifications/actions";


interface AuthService {
    logout: () => Promise<void>;
    loginToken: (token: string) => Promise<UserState | undefined>;
    loginAuthorizationCode: (code: string) => Promise<UserState | undefined>;
    login: (email: string, password: string) => Promise<UserState>;
    deleteUser: (password: string) => Promise<void>;
    updateUser: (data: UpdateContactApi) => Promise<ContactApi>;
    register: (email: string, name: string, phone: string, password: string, confirmPassword: string, acceptRules: boolean, acceptMarketingRules: boolean, cardCode?: string, customFields?: Record<string, string | undefined>) => Promise<UserState>;
    refresh: () => Promise<UserState | undefined>;
    activation: (email?: string, token?: string) => Promise<UserState>;
    qrcode: () => Promise<string | undefined>;
    syncVouchers: () => Promise<VoucherApi[]>;
    buyVoucher: (productId: string) => Promise<VoucherApi | undefined>;
    changePassword: (email?: string, code?: string, password?: string, passwordConfirmation?: string) => Promise<void>
    sendActivation: (email?: string) => Promise<void>
}

export const useAuth = (): AuthService => {
    const dispatch = useDispatch();

    const logout = async () => {
        dispatch({
            type: UserActionTypes.LOGOUT,
            payload: undefined,
        });
        UserTokenContext.setToken(undefined);
    };
    const loginToken = async (token: string) => {
        if (!token) return;
        let data = await api.getMe(token);
        if (data === undefined) {
            dispatch(addFlash('error', "NOT_FOUND"));
            throw new Error();
        }

        return await refreshUserState(data, token);
    }

    const loginAuthorizationCode = async (code: string) => {
        if (!code) return;
        let data = await api.loginAuthorizationCode(code);
        if (data === undefined) {
            dispatch(addFlash('error', "NOT_FOUND"));
            throw new Error();
        }
        return await refreshUserState(data.contact, data.token);
    }
    const login = async (email: string, password: string) => {
        let data = await api.login(email, password, "GOCRM");
        if (data === undefined) {
            dispatch(addFlash('error', "NOT_FOUND"));
            throw new Error();
        }

        return await refreshUserState(data.contact, data.token);
    };

    const register = async (email: string, name: string, phone: string, password: string, confirmPassword: string, acceptRules: boolean, acceptMarketingRules: boolean, cardCode?: string, customFields?: Record<string, string | undefined>) => {
        let data = await api.register(email, name, phone, password, confirmPassword, acceptRules, acceptMarketingRules, cardCode, customFields);
        if (data === undefined) {
            dispatch(addFlash('error', "NOT_FOUND"));
            throw new Error();
        }

        return await refreshUserState(data.contact, data.token);
    };

    const refresh = async () => {
        let token = UserTokenContext.getToken();
        if (!token) return;
        try {
            let data = await api.getMe(token);
            if (data === undefined) {
                dispatch(addFlash('error', "NOT_FOUND"));
                throw new Error();
            }
            return await refreshUserState(data, token)
        } catch (err) {
            if (err instanceof NotFoundException) {
                await logout();
                return undefined;
            }
            const exceptionErrors = handleException(err);
            // if(exceptionErrors && exceptionErrors[0] && (exceptionErrors[0].code === "resource_not_found" || exceptionErrors[0].code === "not_found")){
            if (exceptionErrors && exceptionErrors[0] && (exceptionErrors[0].code === "resource_not_found")) {
                await logout();
                // throw err;
                return undefined;
            }
            throw err;
        }

    };
    const refreshUserState = async (contact: ContactApi, token: string) => {
        let userState = {
            id: contact.id,
            name: contact.name,
            email: contact.email,
            phone_number: contact.phone_number,
            token: token,
            verified: contact.verified,
            points: contact.points,
            promotional_credits: contact.available_promotional_credit_price ? contact.available_promotional_credit_price.amount : undefined,
            group_name: contact.group_name
        } as UserState
        dispatch({
            type: UserActionTypes.LOGIN,
            payload: userState,
        });
        UserTokenContext.setToken(userState.token);
        if (userState.token) {
            try {
                await syncVouchers();
            } catch (err) {
                console.log(err);
            }
        }
        return userState;
    }
    const syncVouchers = async () => {
        const token = UserTokenContext.getToken();
        let vouchers = await api.getVouchers(token);
        dispatch({
            type: UserActionTypes.VOUCHERS,
            vouchers: vouchers,
        })
        return vouchers;
    }

    const deleteUser = async (password: string) => {
        const token = UserTokenContext.getToken();
        await api.deleteUser(password, token);
        await logout();
    };

    const updateUser = async (data: UpdateContactApi) => {
        const token = UserTokenContext.getToken();
        let response = await api.updateUser(data, token);
        if (response === undefined) {
            throw new Error();
        }
        let userState = {
            id: response.id,
            name: response.name,
            email: response.email,
            verified: response.verified,
            points: response.points,
            promotional_credits: response.available_promotional_credit_price ? response.available_promotional_credit_price.amount : undefined,
            group_name: response.group_name
        } as UserState
        dispatch({
            type: UserActionTypes.UPDATE,
            payload: userState,
        });
        return response;
    };

    const activation = async (email?: string, code?: string) => {
        // let response = await axios.post(url, loginData, {headers: ApiService.getHeaders()});
        let data = await api.registerActivation(email, code);
        if (data === undefined) {
            throw new Error();
        }

        return await refreshUserState(data.contact, data.token);
    }
    const qrcode = async () => {
        let token = UserTokenContext.getToken();
        if (!token) return undefined;
        let data = await api.getQrCode(token);
        if (data === undefined) {
            return undefined;
        }
        dispatch({
            type: UserActionTypes.UPDATE_QRCODE,
            qrcode: data,
        });
        return data;
    }

    const buyVoucher = async (productId: string) => {
        let token = UserTokenContext.getToken();
        if (!token) return undefined;
        let data = await api.buyVoucher(productId, "GoCRM", token);
        return data;
    }


    const changePassword = async (email?: string, code?: string, password?: string, passwordConfirmation?: string) => {
        await api.changePassword(email, code, password, passwordConfirmation);
    }

    const sendActivation = async (email?: string) => {
        await api.sendActivationLink(email);
    }

    return {
        sendActivation,
        changePassword,
        deleteUser,
        updateUser,
        login,
        register,
        logout,
        loginToken,
        loginAuthorizationCode,
        activation,
        refresh,
        qrcode,
        syncVouchers,
        buyVoucher
    };
};
