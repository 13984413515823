import React, {ReactNode} from 'react';
import {connect, DispatchProp} from "react-redux";
import {withTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {RootState} from "../../../../../reducers";
import IframeWidget from "../../../../utils/IframeWidget";


interface OwnProps {
    children?: ReactNode,
    image: any,
    onHide: () => void,
    onShow: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MenuItemBaseModalComponent = (props: Props) => {
    return (
        <Modal dialogClassName="modal-full modal-product modal-product-full" show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(props.onShow)}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        {props.image && (
                            <div className="product-item-img">
                                <img src={props.image}/>
                            </div>
                        )}
                    </div>
                    <div className={"col-md-6 modal-product-full-right"}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MenuItemBaseModalComponent));