import React from 'react';

import 'leaflet/dist/leaflet.css';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getSession} from "../../../services/Location/reducer";
import {RootState} from "../../../../reducers";
import {getStoreDefaultConfig} from "../../../services/Store/reducer";
import {ReactComponent as CarSVG} from "../../../../images/icons/car.svg";
import {getCart} from "../../../services/Cart/reducer";
import Money from "../../../components/Common/Money";


interface OwnProps {
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartDeliveryInfoComponent = (props: Props) => {
    const {t} = useTranslation();

    if(props.cart.type !== "DELIVERY") return null;

    const freeDeliveryPrice = props.session.free_delivery_price_money?.amount;

    if(!props.session.in_delivery_area) return null;

    const cartPrice = props.cart.sub_total?.amount;
    const toFreeDeliveryPrice = freeDeliveryPrice - cartPrice;
    const percent = toFreeDeliveryPrice > 0 ? cartPrice / freeDeliveryPrice : 100;

    return (
        <>
            <div className={"cart-progress text-success"}>
                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow={0}
                     aria-valuemin={0} aria-valuemax={100}>
                    <div className={`progress-bar${toFreeDeliveryPrice <= 0 ? " bg-success" : ""}`} style={{width: `${percent*100}%`}}></div>
                </div>
                <div className={`cart-progress-info${toFreeDeliveryPrice <= 0 ? " text-success" : ""}`}>
                    <CarSVG className={"me-1"} />
                    {toFreeDeliveryPrice <= 0 ? (
                        <>
                            <div>
                                {t("modules.cart.field.free_delivery.title")}
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                {`${t("modules.cart.field.amount_to_free_delivery.title")}: `}
                                <strong>
                                    <Money value={toFreeDeliveryPrice}/>
                                </strong>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
};


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    default_config: getStoreDefaultConfig(state.config),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartDeliveryInfoComponent));

