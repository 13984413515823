import React, {useState} from 'react';
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import {useTranslation, withTranslation} from "react-i18next";
import Loading from "../Common/Loading";

const parseObjOrString = (obj:any) => {
    if (!obj || typeof obj === 'string') {
        return {
            "description": obj !== undefined ? obj : ""
        }
    }
    return obj;
}

interface Props {
    onChange: (value: any) => void
    value: any,
    fetch: (query: any) => any,
    onBlur: (query: any) => void,
    placeholder: any,
    className?: string
}

const BaseSearchComponent = (props: Props) => {
    let options = {}
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [items, setItems] = useState<any[]>([]);
    const [selected, setSelected] = useState([
        parseObjOrString(props.value)
    ]);
    const {t} = useTranslation();

    let _handleSearch = async (query: any) => {
        props.onChange({
            description: query
        });
        if (query.length <= 1) {
            return;
        }
        setLoading(true);

        try {
            let response = await props.fetch(query);
            setItems(response);
            setLoading(false);
        }catch (err){
            setError(t("constraints.internal.data_fetching_failed"))
            setLoading(false);
        }
    }
    let searchOnBlur = (e: any, v: any) => {
        let value = e.target.value;
        let selectedItem = selected !== undefined && selected.length > 0 && selected[0] ? selected[0] : {};
        selectedItem.description = value;
        props.onChange(selectedItem)
        props.onBlur(selectedItem);
        // if(selected !== null && selected.length > 0){
        //     selected[0].description = props.filter.address?.full;
        //     setSelected(selected)
        // }
        // if(this.props.location.address !== null){
        //     this.props.dispatch(updateSelectedValue(this.props.location.address.fullAddres));
        // }
    }
    let handleSearchOnChange = async (newSelected: any) => {
        let selectedItem;
        if (newSelected === undefined || newSelected.length <= 0) {
            if (selected === undefined || selected.length <= 0) {
                props.onChange({description: ""});
                setSelected([])
            } else {
                // selectedItem = selected[0];
                // props.onChange(selectedItem);
                // setSelected([selectedItem])
            }
            // let selectedItem = {description:""};
            // props.onChange(selectedItem);
            // setSelected([selectedItem])
            return;
        } else {
            selectedItem = newSelected[0];
        }
        props.onChange(selectedItem);
        setSelected([selectedItem])
    }
    let _renderMenu = (results: any, menuProps: any, state: any) => {
        delete menuProps.newSelectionPrefix;
        delete menuProps.paginationText;
        delete menuProps.renderMenuItemChildren;
        const items = results.map((option: any, index: any) => (
            <MenuItem key={index} option={option} position={index}>
                {option.renderText ? (
                    <>
                        {option.renderText()}
                    </>
                ) : (
                    <>
                        {option.description}
                    </>
                )}
            </MenuItem>
        ));
        return <Menu {...menuProps}>
            {items}
            {/*<div className="dropdown-divider"></div>*/}
            {/*<div className="dropdown-divider"></div>*/}
        </Menu>;
    }
    let handleSearchValue = async (value: any) => {
        if (!value) {
            if (selected[0] !== undefined) {
                selected[0] = {description: ""}
                // selected[0].description = ""
            }
            props.onChange({description: ""})
            return;
        } else {
            // props.onChange({description: value});
        }
        if (selected[0] !== undefined) {
            // selected[0].description = value
            selected[0] = {description: value}
            setSelected(selected)
        }
    }

    return (
        <AsyncTypeahead
            {...options}
            id="typehead"
            isLoading={loading}
            onSearch={_handleSearch}
            minLength={0}
            useCache={false}
            onBlur={searchOnBlur as any}
            filterBy={(option, props) => {
                return true;
            }}
            options={items}
            labelKey={(option: any) => {
                return option.description ? option.description : "";
            }}
            selected={selected}
            onChange={handleSearchOnChange}
            renderMenu={_renderMenu}
            className={`search-input${props.className ? ` ${props.className}` : ""}`}
            inputProps={{ className: props.className ? ` ${props.className}` : "" }}
            placeholder={props.placeholder}
            clearButton={true}
            onInputChange={handleSearchValue}
            searchText={<>
                <Loading>{t("modules.search.field.search_loading.title")}</Loading>
            </>}
            promptText={t("modules.search.field.type_to_search.title")}
            emptyLabel={`${error ? ` ${error}` : t("constraints.internal.search_not_found")}`}
        />
    )
}

export default withTranslation()(BaseSearchComponent)
