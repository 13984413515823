import React, {useState} from 'react';

import 'leaflet/dist/leaflet.css';
import {RootState} from "../../../../../../reducers";
import {getSession} from "../../../../../services/Location/reducer";
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {PlaceApi, StoreConfigCoordinatesApi} from "../../../../../services/Api/types";
import {Link} from "react-router-dom";
import {ReactComponent as MapSVG} from "../../../../../../images/icons/map.svg";
import {ReactComponent as LocationSVG} from "../../../../../../images/icons/location.svg";
import ChangeLocationButtonComponent from "../../../components/ChangeLocationButtonComponent";
import {getStoreDefaultConfig} from "../../../../../services/Store/reducer";
import MultistoreListContentComponent from "./MultistoreListContentComponent";
import MultistorePlaceFilterCitiesComponent from "../../components/MultistorePlaceFilterCitiesComponent";


interface OwnProps {
    places: PlaceApi[],
    filter?: any,
    onChangeCity: (value: any) => void,
    geolocation: (coordinates: StoreConfigCoordinatesApi) => void,
    hoverPlace: (place?: string) => void
}


type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const MultistoreListComponent = (props: Props) => {
    const cities = Array.from(new Set(props.places?.filter(x => x.address && x.address.city).map(x => x.address.city).sort((a, b) => a.localeCompare(b))));
    const [loadingLocation, setLoadingLocation] = useState(false);
    const {t} = useTranslation();
    // const center: [number, number] = [50.061947, 19.936856]; // Example: Kraków, Poland

    const geolocation = async () => {
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(function (position) {
            props.geolocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
            setLoadingLocation(false);
        }, (err) => {
            if (err && err.message === "User denied Geolocation") {
                alert(t("constraints.internal.user_blocked_geolocation"));
            } else {
                alert(t("constraints.internal.location_fetching_failed"));
            }
            setLoadingLocation(false);
        });
    }
    let filterPlaces = props.places;
    // for (let i = 0; i < 50; i++) {
    //     filterPlaces = [...filterPlaces, ...props.places];
    // }
    if (props.filter?.city) filterPlaces = filterPlaces?.filter(x => x.address.city == props.filter.city);

    let hoverPlace = (domain: string | undefined) => {
        if (props.hoverPlace) props.hoverPlace(domain);
    }
    return (
        <div className={"multistore-list"}>
            <div className={"multistore-list-navs"}>
                <div className={"container-fluid"}>

                    <MultistorePlaceFilterCitiesComponent places={props.places} city={props.filter?.city}
                                                          onChange={(value: string) => props.onChangeCity(value)}/>
                </div>
            </div>
            <div className={"multistore-list-content"}>
                <div className={"container-fluid"}>
                    <div className={"multistore-places-content"}>
                        <ChangeLocationButtonComponent hideGeolocation={true} config={props.default_config}/>
                        <MultistoreListContentComponent places={filterPlaces} hoverPlace={hoverPlace}/>
                    </div>
                </div>

                <div className={"multistore-list-controls multistore-controls"}>
                    <button className={`btn btn-default btn-icon btn-spinner${loadingLocation ? " loading" : ""}`}
                            onClick={geolocation}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {!loadingLocation && (
                            <LocationSVG/>
                        )}
                    </button>
                    <Link to={"/map"} className={"btn btn-primary btn-icon"}>
                        <MapSVG/>
                    </Link>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state: RootState) => ({
    session: getSession(state.session),
    default_config: getStoreDefaultConfig(state.config)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(MultistoreListComponent));

