import React, {useEffect} from 'react';
import {useTranslation, withTranslation} from "react-i18next";

interface Props {
    onClose: () => void,
    message: any
}

let timeout = 7000;
const Alert = (props: Props) => {
    const {t} = useTranslation();
    useEffect(() => {
        const timer = setTimeout(props.onClose, timeout);
        return () => clearTimeout(timer);
    }, []);

    const alertClass = (type: string): string => {
        const classes: Record<string, string> = {
            error: 'alert-danger',
            alert: 'alert-warning',
            notice: 'alert-info',
            success: 'alert-success',
        };
        return classes[type] || classes.success;
    };

    const alertClassName = `alert alert-dismissible ${alertClass(props.message.type)} fade1 in`;
    const translationMessage = t(`constraints.${props.message.code}`);

    return (
        <div className={alertClassName}>
            {translationMessage}
            <button
                type="button"
                className="btn-close"
                onClick={props.onClose}
                data-bs-dismiss="alert"
                aria-label="Close"
            ></button>
        </div>
    );
}
export default withTranslation()(Alert);