import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, DispatchProp} from "react-redux";
import {RootState} from "../../../../reducers";
import ItemHelper from "../../../services/Menu/services/Item/ItemHelper";
import LoadingContainer from "../../Common/LoadingContainer";
import Utils from "../../../utils/Utils";
import Money from "../../Common/Money";
import {getCategories, getItems} from "../../../services/Menu/reducer";
import {api} from "../../../services/Api/api";
import {ConfigApi, StoreConfigLoyaltyApi} from "../../../services/Api/types";
import IframeWidget from "../../../utils/IframeWidget";


interface OwnProps {
    config_loyalty: StoreConfigLoyaltyApi,
    onHide: () => void,
    onShow?: () => void,
    onClick: () => void
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;

function LoyaltyCashbackInfoModalComponent(props: Props) {
    let {t} = useTranslation();

    return (
        <Modal dialogClassName="modal-full" show={true} onHide={() => IframeWidget.onHide(props.onHide)} onShow={() => IframeWidget.onShow(props.onShow)}>
            <div className="">
                <Modal.Header>
                    <Modal.Title>
                        {t("modules.user.field.cashback_program.title")}
                    </Modal.Title>
                    <button onClick={() => IframeWidget.onHide(props.onHide)} className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </Modal.Header>
            </div>
            <Modal.Body>
                <p>
                    {t("modules.user.field.cashback_program.description")}
                </p>
                {props.config_loyalty.cashback?.info && (
                    <p>
                        {props.config_loyalty.cashback?.info}
                    </p>
                )}
                <button className={"btn btn-default w-100"} onClick={props.onHide}>
                    Ok
                </button>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(LoyaltyCashbackInfoModalComponent));
