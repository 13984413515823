import React from 'react';
import {connect, DispatchProp} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {getAvailabilities, getCustomFields} from "../../services/Menu/reducer";
import {RootState} from "../../../reducers";
import {getStoreConfig} from "../../services/Store/reducer";
import ErrorFormView from "../Common/ErrorFormView";
import CustomFieldHelper from "../../services/Cart/Helper/CustomFieldHelper";
import ErrorFormHelper from "../../utils/ErrorFormHelper";
import {getCart} from "../../services/Cart/reducer";


interface OwnProps {
    errors: any[],
    onChange: (cart: any) => void,
    data: any
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps & DispatchProp;
const CartClientDataComponent = (props: Props) => {
    const cart = props.cart;
    const {t} = useTranslation();
    const orderCustomFields = props.config?.old_store.order_custom_fields;

    const updateContactData = (key: any, value: any) => {
        // props.onChange(key, value);
        props.onChange({
            ...props.data,
            contact: {
                ...props.data.contact,
                [key]: value
            }
        })
        // props.contact[key] = value;
    }
    const handleChangeName = (value: any) => {
        updateContactData("name", value);
        // cart.contact.name = value;
    }
    const handleChangeEmail = (value: any) => {
        updateContactData("email", value);
        // cart.contact.email = value;
    }
    const handleChangePhone = (value: any) => {
        updateContactData("phone", value);
        // cart.contact.phone = value;
    }

    let phoneVisible = CustomFieldHelper.getVisible("phone", orderCustomFields, cart.type);
    let emailVisible = CustomFieldHelper.getVisible("email", orderCustomFields, cart.type);
    let nameVisible = CustomFieldHelper.getVisible("name", orderCustomFields, cart.type);

    return (
        <div className="card-cart">
            <div className={"container-fluid"}>
                <h5 className={"card-cart-header"}>
                    {t("common.word.your_data")}
                </h5>
                <div className={"card-cart-body"}>
                    <div className="form-row row">
                        {CustomFieldHelper.isVisible(nameVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.contact.name}
                                       onChange={(e) => handleChangeName(e.target.value)}
                                       className={"form-control" + ErrorFormHelper(props.errors, "contact.name")}
                                       id="name"
                                       placeholder={t("modules.cart.field.name.title") + (CustomFieldHelper.isRequired(nameVisible) ? "*" : "")}/>
                                <label
                                    htmlFor="name">{t("modules.cart.field.name.title")}{CustomFieldHelper.isRequired(nameVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="contact.name"/>
                            </div>
                        )}
                        {CustomFieldHelper.isVisible(emailVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.contact.email}
                                       onChange={(e) => handleChangeEmail(e.target.value)}
                                       className={"form-control" + ErrorFormHelper(props.errors, "contact.email")}
                                       id="email"
                                       placeholder={t("common.word.email") + (CustomFieldHelper.isRequired(emailVisible) ? "*" : "")}/>
                                <label
                                    htmlFor="email">{t("common.word.email")}{CustomFieldHelper.isRequired(emailVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="contact.email"/>
                            </div>
                        )}
                        {CustomFieldHelper.isVisible(phoneVisible) && (
                            <div className="form-group col-lg-6">
                                <input type="text" value={props.data.contact.phone}
                                       onChange={(e) => handleChangePhone(e.target.value)}
                                       className={"form-control" + ErrorFormHelper(props.errors, "contact.phone")}
                                       id="phone"
                                       placeholder={t("common.word.phone_number") + (CustomFieldHelper.isRequired(phoneVisible) ? "*" : "")}/>
                                <label
                                    htmlFor="phone">{t("common.word.phone_number")}{CustomFieldHelper.isRequired(phoneVisible) && "*"}</label>
                                <ErrorFormView errors={props.errors} field="contact.phone"/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: RootState) => ({
    config: getStoreConfig(state.config),
    custom_fields: getCustomFields(state.menu),
    availabilities: getAvailabilities(state.menu),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default withTranslation()(connector(CartClientDataComponent));
