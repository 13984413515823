import {useDispatch} from "react-redux";
import {api} from "../Api/api";
import {MenuState} from "./types";
import {fetchMenuError, fetchMenusSuccess} from "./actions";
import handleException from "../Api/handleException";
import {CanceledError} from "axios";

interface MenuService {
    fetch: (locale?: string, signal?: any) => Promise<MenuState>;
}

export const useMenuService = (): MenuService => {
    const dispatch = useDispatch();

    const fetch = async (locale?: string, signal?: any) => {
        try {
            let response = await api.getMenus(locale, signal);
            dispatch(fetchMenusSuccess(response, locale));
            return response;
        } catch (err) {
            if (err instanceof CanceledError) {
                return;
            }
            // const errors = handleException(err);
            dispatch(fetchMenuError(handleException(err)));
            // dispatch(orderErrorNotValid(errors));
            throw err;
        }
    }

    return {
        fetch
    };
};
