import React, {FC, ReactNode, useEffect, useState} from 'react';
import {connect, DispatchProp, useSelector} from "react-redux";
import {RootState} from "../../../reducers";
import LoadingContainer from "../Common/LoadingContainer";
import {useCart} from "../../services/Cart/cartService";
import {getMenuState} from "../../services/Menu/reducer";
import ErrorView from "../Common/ErrorView";
import {getSession} from "../../services/Location/reducer";
import {addFlash} from "../../services/Notifications/actions";
import {useMenuService} from "../../services/Menu/menuService";
import {getCart} from "../../services/Cart/reducer";
import {useTranslation} from "react-i18next";


interface OwnProps {
    children?: ReactNode,
    menus: boolean,
    order: boolean
}

type Props = ReturnType<typeof mapStateToProps> & DispatchProp & OwnProps;

const FetchData: FC<Props> = (props) => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cartService = useCart();
    let menuService = useMenuService();
    const [loading, setLoading] = useState(false);
    const cart = props.cart;
    const {t} = useTranslation();

    let initCart = async () => {
        if (props.order) {
            if (!cart.initialized && !cart.pending) {
                await cartService.refresh();
            }
        }
    }
    let initMenu = async () => {
        if (props.menus) {
            if (!props.menu_state.initialized && !props.menu_state.pending) {
                await menuService.fetch(props.session.locale, signal);
            }
        }
    }
    const init = async () => {
        await initMenu()
        await initCart()
        setLoading(false);
    }
    useEffect(() => {
        init()
        return () => {
            controller.abort(); // Step 3: Cancel the request on unmount
        };
    }, [])
    const refreshMenu = async () => {
        if (!props.menu_state.pending) {
            setLoading(true)
            try {
                await menuService.fetch(props.session.locale);
                setLoading(false)
            } catch (err: any) {
                props.dispatch(addFlash('error', err.errors));
                setLoading(false)
            }
        }
    }
    if (loading) {
        return (
            <LoadingContainer/>
        )
    }

    if (props.menus) {
        if (props.menu_state.fetch_status === "NEW") {
            return (
                <LoadingContainer/>
            );
        }
        if (!props.menu_state.initialized || props.menu_state.pending) {
            return (
                <LoadingContainer/>
            );
        }
    }

    if (props.order) {
        if (cart.fetch_status === "NEW") {
            return (
                <LoadingContainer/>
            );
        }
        if (!cart.initialized && cart.pending) {
            return (
                <LoadingContainer/>
            );
        }
    }

    if (props.menu_state.error) {
        if (props.menu_state.error === 404) {
            return (
                <ErrorView message={t("constraints.internal.menu_fetching_failed")}>
                    <button onClick={refreshMenu} className={"btn btn-primary"}>
                        {t("common.action.refresh")}
                    </button>
                </ErrorView>
            )
        } else {
            return (
                <ErrorView/>
            )
        }
    }
    return (
        <>
            {props.children}
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    menu_state: getMenuState(state.menu),
    session: getSession(state.session),
    cart: getCart(state.cart)
})

const connector = connect(mapStateToProps);
export default connector(FetchData);
