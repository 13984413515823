import {useDispatch, useSelector} from "react-redux";
import {ConfigAddressApi, ConfigApi, StoreConfigCoordinatesApi} from "../Api/types";
import {updateLocationCheck, updateSession, updateSessionCoordinates, updateSessionLocation} from "./actions";
import {api} from "../Api/api";
import {useCart} from "../Cart/cartService";
import StorageStore from "../Common/Storage/storageStore";
import {selectSession} from "./reducer";
import {SessionState} from "./types";
import StoreUtils from "../Store/StoreUtils";
import CartUtils from "../Cart/CartUtils";
import {DateHelper} from "../Common/Date/DateHelper";
import {useRef} from "react";

interface LocationService {
    updateLocation: (location: ConfigAddressApi) => Promise<any>;
    updateCoordinates: (coordinates: StoreConfigCoordinatesApi) => Promise<void>;
    update: (session: SessionState, disableUpdateCart?: boolean) => Promise<SessionState>;
    init: (locale: string, config: ConfigApi) => Promise<SessionState>;
}

const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000;

export const useLocationService = (): LocationService => {
    const dispatch = useDispatch();
    const locationState = useSelector(selectSession);
    const cartService = useCart("location");
    const locationAbortController = useRef<AbortController | undefined>(undefined);

    const init = async (locale: string, config: ConfigApi) => {
        let defaultOrderType = config.ordering.default_order_type;
        const orderTypes = StoreUtils.getOrderTypes(config);
        if (!defaultOrderType) {
            defaultOrderType = orderTypes[0];
        }
        let sessionState = {
            pickup_at: locationState.pickup_at,
            table_number: locationState.table_number,
            room_number: locationState.room_number,
            scan_time: locationState.scan_time,
            order_type: defaultOrderType
        } as SessionState
        const locationJson = StorageStore.getItem("location");
        if (locationJson) {
            let location = undefined;
            try {
                location = JSON.parse(locationJson) as ConfigAddressApi;
                if (location) {
                    sessionState.address = location;
                    // await update(location);
                }
            } catch (error) {
                StorageStore.removeItem("location")
            }
        }
        const coordinatesJson = StorageStore.getItem("coordinates");
        if (coordinatesJson) {
            let coordinates = undefined;
            try {
                coordinates = JSON.parse(coordinatesJson) as StoreConfigCoordinatesApi;
                if (coordinates) {
                    sessionState.coordinates = coordinates;
                }
            } catch (error) {
                StorageStore.removeItem("coordinates")
            }

        }
        const contactJson = StorageStore.getItem("contact");
        let contact = undefined;
        if (contactJson) {
            try {
                contact = JSON.parse(contactJson);

                if (contact) {
                    sessionState.contact = contact
                }
            } catch (error) {
                console.log("ERROR CONTACT")
                // StorageStore.removeItem("contact")
            }
        }

        const sessionCartJson = StorageStore.getItem("session_cart");

        if (sessionCartJson) {
            let sessionCart = undefined;
            try {
                sessionCart = JSON.parse(sessionCartJson);
                if (sessionCart) {
                    if (sessionCart.table_number) sessionState.table_number = sessionCart.table_number;
                    if (sessionCart.room_number) sessionState.room_number = sessionCart.room_number;
                    if (sessionCart.pickup_at) {
                        const now = new Date();
                        const pickupAt = new Date(sessionCart.pickup_at as string);
                        if (now.getTime() < pickupAt.getTime()) {
                            sessionState.pickup_at = sessionCart.pickup_at;
                        }
                    }
                    if (sessionCart.order_type) sessionState.order_type = sessionCart.order_type;
                    if (sessionCart.scan_time) sessionState.scan_time = sessionCart.scan_time;
                }
            } catch (error) {
                StorageStore.removeItem("session_cart")
            }
        }
        if (locale) {
            sessionState.locale = locale;
        }
        const orderType = StorageStore.getItem("order_type");
        if (orderType) {
            if (orderType === "DELIVERY" || orderType === "PICK_UP" || orderType === "ROOM_SERVICE" || orderType === "DINE_IN") {
                sessionState.order_type = orderType;
            }
        }
        sessionState.default_delivery_price_money = config.ordering.delivery_price_money;
        sessionState.default_min_order_price_money = config.ordering.min_order_price_money;
        if (sessionState.scan_time) {
            const now = new Date();
            const scanTime = new Date(sessionState.scan_time);
            if (now.getTime() - scanTime.getTime() > THIRTY_MINUTES_IN_MS) {
                sessionState.scan_time = undefined;
                sessionState.table_number = undefined;
                sessionState.room_number = undefined;
            }
        }
        await updateCache(sessionState);
        return sessionState;
    }
    const updateCache = async (session: SessionState) => {
        dispatch(updateSession(session));
        if (session.address) {
            StorageStore.setItem('location', JSON.stringify(session.address));
        } else {
            StorageStore.removeItem('location');
        }
        let sessionCart = {} as any;
        if (session.pickup_at) sessionCart.pickup_at = session.pickup_at;
        if (session.table_number) sessionCart.table_number = session.table_number;
        if (session.room_number) sessionCart.room_number = session.room_number;
        if (session.order_type) sessionCart.order_type = session.order_type;
        if (session.scan_time) sessionCart.scan_time = session.scan_time;

        if (session.order_type) {
            StorageStore.setItem('order_type', session.order_type);
        } else {
            StorageStore.removeItem('order_type');
        }

        if (Object.keys(sessionCart).length > 0) {
            StorageStore.setItem('session_cart', JSON.stringify(sessionCart));
        }
    }
    const update = async (session: SessionState, disableUpdateCart?: boolean) => {
        if (locationState.room_number !== session.room_number || locationState.table_number !== session.table_number) {
            session.scan_time = DateHelper.toString(new Date());
        }
        await updateCache(session);
        let locationCheck = await api.getLocationCheck(session.address);
        dispatch(updateLocationCheck(locationCheck));
        if (disableUpdateCart) {
            return session;
        }
        try {
            let data = {
                pickup_at: session.pickup_at,
                type: session.order_type,
                number: CartUtils.getCartNumber(session),
                address: session.address
            }
            await cartService.update(data, true);
            return session;
        } catch (err) {
            console.log(err)
        }
        return session;
    }
    const updateLocation = async (newLocation: ConfigAddressApi) => {
        if (locationState.address.street == newLocation?.street
            && locationState.address.city == newLocation?.city
            && locationState.address.zip_code == newLocation?.zip_code
            && locationState.address.build_nr == newLocation?.build_nr
            && locationState.address.flat_nr == newLocation?.flat_nr
            && locationState.address.country == newLocation?.country
            && !locationState.address_dirty
        ) {
            return {
                address: newLocation,
                coordinates: locationState.coordinates
            };
        }
        dispatch(updateSessionLocation(newLocation));
        StorageStore.setItem('location', JSON.stringify(newLocation));

        if (locationAbortController.current) {
            locationAbortController.current.abort();
        }
        locationAbortController.current = new AbortController();
        let locationCheck = await api.getLocationCheck(newLocation, locationAbortController.current);
        dispatch(updateLocationCheck(locationCheck));
        StorageStore.setItem('coordinates', JSON.stringify(locationCheck.coordinates));
        try {
            let data = {
                pickup_at: locationState.pickup_at,
                type: locationState.order_type,
                room_number: locationState.room_number,
                table_number: locationState.table_number,
                address: newLocation
            }
            await cartService.update(data);
        } catch (err) {
            console.log(err)
        }
        return {
            address: newLocation,
            coordinates: locationCheck.coordinates
        };
    }

    const updateCoordinates = async (newCoordinates: StoreConfigCoordinatesApi) => {
        dispatch(updateSessionCoordinates(newCoordinates));
        StorageStore.setItem('coordinates', JSON.stringify(newCoordinates));
    }
    return {
        updateLocation,
        updateCoordinates,
        update,
        init
    };
};
